import StaticPageLayout from 'components/StaticPageLayout'
import Input from 'components/UI/Input'
import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'
import { Text, Flex, Label, Box, Button } from 'theme-ui'
import React, { ReactElement, useState } from 'react'
import { graphql } from 'gatsby'
import getEnglishStrings from 'utils/getEnglishStrings'
import InternalLink from 'components/UI/InternalLink'
import { requestResetPassword } from 'network/forgot-password'
import Subtract from 'images/subtract.svg'

export default function ForgotPasswordPage({
  data,
}: {
  data: Queries.ForgotPasswordPageQuery
}): ReactElement {
  const title = getEnglishStrings(data.directus.forgot_password?.strings)?.title
  const subtitle = getEnglishStrings(data.directus.forgot_password?.strings)?.subtitle
  const resetPasswordText = getEnglishStrings(
    data.directus.forgot_password?.strings,
  )?.reset_password_button
  const backToLogin = getEnglishStrings(data.directus.forgot_password?.strings)?.back_to_login
  const errorMessage = getEnglishStrings(
    data.directus.forgot_password?.strings,
  )?.invalid_email_format

  const forgotPasswordRequested = getEnglishStrings(
    data.directus.forgot_password?.strings,
  )?.forgot_password_requested
  const forgotPasswordSubtitle = getEnglishStrings(
    data.directus.forgot_password?.strings,
  )?.password_requested_subtitle

  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const requestPasswordChange = async () => {
    const response = await requestResetPassword(email)
    setIsSuccess(response)
  }

  if (isSuccess) {
    return (
      <StaticPageLayout title={title ?? ''}>
        <TitleAndSubtitle title={forgotPasswordRequested} subtitle={forgotPasswordSubtitle} />
      </StaticPageLayout>
    )
  }

  return (
    <StaticPageLayout title={`${title} | Windscribe` ?? ''}>
      <Flex sx={{ flexDirection: 'column', font: 'IBM Plex Sans', alignItems: 'center' }}>
        <TitleAndSubtitle title={title} subtitle={subtitle} />
        <Flex sx={{ flexDirection: 'column', width: ['28rem', '48rem', '48rem'] }}>
          <Flex sx={{ alignItems: 'center' }}>
            <Label
              sx={{
                marginLeft: '1.6rem',
                marginBottom: '0.8rem',
                color: 'white50',
                fontSize: '1.6rem',
                marginTop: '8rem',
              }}
            >
              Email Address
            </Label>
            {error && (
              <Box
                sx={{
                  p: 0,
                  pr: '1.9rem',
                  display: 'flex',
                  marginBottom: '0.8rem',
                  marginTop: '8rem',
                }}
              >
                <Subtract />
              </Box>
            )}
          </Flex>
          <Box sx={{ marginBottom: '2.2rem' }}>
            <Input
              sx={{ mb: '0.8rem' }}
              onChange={({ target }) => {
                setEmail(target.value)
              }}
              onBlur={({ target }) => {
                setError(!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(target.value))
              }}
              data-testid="forgotPassword_email"
            />
            {error && (
              <Text data-testid="forgotPassword_errorMessage" sx={{ color: 'red', ml: '2.4rem' }}>
                {errorMessage}
              </Text>
            )}
          </Box>
          <Button
            variant="neon"
            sx={{ marginBottom: '4.8rem' }}
            onClick={requestPasswordChange}
            data-testid="forgotPassword_submit"
            disabled={error}
          >
            {resetPasswordText}
          </Button>
          <InternalLink href="/" sx={{ width: 'fit-content', alignSelf: 'center' }}>
            <Text sx={{ fontWeight: 'bold', color: 'white50' }}>{backToLogin}</Text>
          </InternalLink>
        </Flex>
      </Flex>
    </StaticPageLayout>
  )
}

export const data = graphql`
  query ForgotPasswordPage {
    directus {
      forgot_password {
        strings {
          title
          subtitle
          reset_password_button
          invalid_email_format
          forgot_password_requested
          password_requested_subtitle
          back_to_login
          languages_code {
            code
          }
        }
      }
    }
  }
`
