import { Flex, Text } from 'theme-ui'

export default function PlatformTitle({
  title,
  minWidth = ['13.2rem'],
}: {
  title: string
  minWidth?: (string | number)[]
}): JSX.Element {
  return (
    <Flex
      sx={{
        justifyContent: 'center',
      }}
    >
      <Flex
        sx={{
          minWidth,
          backgroundColor: 'white10',
          justifyContent: 'center',
          alignItems: 'center',
          height: '3.6rem',
          borderRadius: '2.1rem',
        }}
      >
        <Text
          sx={{
            letterSpacing: '0.375rem',
            textTransform: 'uppercase',
            fontSize: '1.6rem',
            fontWeight: 'bold',
            color: 'white50',
          }}
        >
          {title}
        </Text>
      </Flex>
    </Flex>
  )
}
