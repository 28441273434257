import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function AdPrivacyPage({
  data,
}: {
  data: Queries.AdPrivacyPageQuery
}): ReactElement {
  const adPrivacyData = getEnglishStrings(data.directus.ad_privacy?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={adPrivacyData?.meta_description ?? ''}
      metaKeywords={adPrivacyData?.meta_keywords ?? []}
      title={adPrivacyData?.title ?? ''}
      subtitle={adPrivacyData?.subtitle ?? ''}
      gridItems={[
        {
          title: adPrivacyData?.privacy_not ?? '',
          description: adPrivacyData?.privacy_not_subtitle ?? '',
        },
        {
          title: adPrivacyData?.opt_out ?? '',
          description: adPrivacyData?.opt_out_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {adPrivacyData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[]}
    />
  )
}

export const data = graphql`
  query AdPrivacyPage {
    directus {
      use_for_free {
        strings {
          languages_code {
            code
          }
          title
          subtitle
          summary_text
          ten_gig_free
          ten_gig_free_subtitle
          unlimited_connection_subtitle
          unlimited_connections
          all_app
          all_app_subtitle
          servers_in
          servers_in_subtitle
          emails_optional
          emails_optional_subtitle
          affordable_plans
          affordable_plans_subtitle
          cta
          get_an_extra
          remember_the_vpn
          meta_description
          meta_keywords
        }
      }
      ad_privacy {
        strings {
          languages_code {
            code
          }
          cta
          opt_out
          opt_out_subtitle
          privacy_not
          privacy_not_subtitle
          subtitle
          title
          meta_description
          meta_keywords
        }
      }
    }
  }
`
