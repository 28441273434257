import Svg from 'components/UI/SVG'
import chevron from 'images/chevron.svg'
import { ReactElement, useState } from 'react'
import { Flex, Grid, Text } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import useCountries from 'utils/useCountries'

export default function NetworkPageCountries({
  data,
}: {
  data?: Queries.LargeNetworkQuery
}): ReactElement {
  const [shouldDisplayMore, setShouldDisplayMore] = useState(false)

  const generalTranslations = getEnglishStrings(data?.directus.general_translations?.strings)
  const { countries, flags } = useCountries()

  return (
    <>
      <Grid
        columns={[2, 4, 5]}
        sx={{
          flexWrap: 'wrap',
          alignItems: 'center',
          columnGap: ['3.2rem', '2.4rem', '5.3rem'],
          rowGap: '3.2rem',
          maxWidth: ['100%', '90%', 'maxContentWidth'],
          maxHeight: [shouldDisplayMore ? '100%' : '35rem', '100%', '100%'],
          overflow: 'hidden',
        }}
      >
        {countries?.map(country => {
          //Directus sends us the countries with an underscode, like united_states, we need to set it to united-states to match the flag
          const currentFlag = flags.find(
            x => x.slug === country.name.toLowerCase().replaceAll('_', '-'),
          )
          //Return the country
          return (
            <Flex sx={{ flexDirection: 'column' }} key={country.name}>
              <Flex sx={{ gap: '2.4rem', alignItems: 'center' }}>
                <div sx={{ position: 'relative' }}>
                  <Svg svg={currentFlag?.body} sx={{ width: '4rem', height: '4rem' }} />
                </div>
                <Flex sx={{ flexDirection: 'column' }}>
                  <Text
                    sx={{
                      fontSize: '1.6rem',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    {generalTranslations?.[country.name.toLowerCase()]}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          )
        })}
      </Grid>
      <Flex
        sx={{ gap: '1rem', alignItems: 'center', display: ['flex', 'none', 'none'], mt: '2.4rem' }}
        onClick={() => setShouldDisplayMore(current => !current)}
      >
        <Text color="white50" sx={{ fontSize: '1.8rem', fontWeight: 'bold' }}>
          {shouldDisplayMore ? generalTranslations?.show_less : generalTranslations?.show_more}
        </Text>
        <Svg svg={chevron} sx={{ transform: shouldDisplayMore ? 'rotate(180deg)' : '' }}></Svg>
      </Flex>
    </>
  )
}
