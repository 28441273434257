import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function GPSSpoofing({
  data,
}: {
  data: Queries.GPSSpoofingPageQuery
}): ReactElement {
  const gpsSpoofingData = getEnglishStrings(data?.directus?.gps_spoofing?.strings)
  const generalTranslationsData = getEnglishStrings(data?.directus?.general_translations?.strings)
  const queryParam = useFeaturePageSlug()

  return (
    <FeaturePageSkeleton
      title={gpsSpoofingData?.title ?? ''}
      subtitle={gpsSpoofingData?.subtitle ?? ''}
      summaryText={gpsSpoofingData?.summary_text ?? ''}
      gridItems={[
        {
          title: gpsSpoofingData?.why_should_i ?? '',
          description: gpsSpoofingData?.why_should_i_subtitle ?? '',
        },
        {
          title: gpsSpoofingData?.deep_fake ?? '',
          description: gpsSpoofingData?.deep_fake_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {gpsSpoofingData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [gpsSpoofingData?.we_recommend ?? ''],
        },
      ]}
      metaDescription={gpsSpoofingData?.meta_description ?? ''}
      metaKeywords={gpsSpoofingData?.meta_keywords ?? []}
    />
  )
}

export const data = graphql`
  query GPSSpoofingPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      gps_spoofing {
        strings {
          languages_code {
            code
          }
          cta
          deep_fake
          deep_fake_subtitle
          meta_description
          meta_keywords
          subtitle
          summary_text
          title
          we_recommend
          why_should_i
          why_should_i_subtitle
        }
      }
    }
  }
`
