import { ChartUnit } from 'pages/transparency'
import { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'

export default function TransparencyReportChart({
  data,
  color,
  divider,
  horizontalColums,
  bodyRef,
}: {
  data: ChartUnit[][]
  color: string
  divider: number
  //must be between 1 and 10
  horizontalColums: number
  bodyRef?: React.RefObject<HTMLTableSectionElement>
}): ReactElement {
  return (
    <div
      sx={{
        width: ['calc(100vw - 12rem)', 'calc(100vw - 12rem)', '100%'],
        maxWidth: ['', '', 'maxContentWidth'],
        overflow: ['scroll', 'scroll', 'visible'],
        position: 'relative',
        pl: '0.7rem',
      }}
      ref={bodyRef}
    >
      <table
        className={`charts-css column show-${horizontalColums}-secondary-axes show-primary-axis show-labels labels-align-inline-start show-data-axes`}
        id="transparency-chart"
      >
        <tbody
          sx={{
            '& > tr td': {
              '--color': color,
              maxWidth: ['0.86rem'],
              mx: ['0.2rem'],
              borderRadius: '0.2rem 0.2rem 0rem 0rem',
              border: `1px solid transparent`,
            },
            '& > tr': {
              minWidth: '6.2rem',
            },
            '& > tr:last-of-type': {
              minWidth: 'auto',
            },
          }}
        >
          {data.map((chunk, index) => (
            <tr key={index}>
              <th scope="row">
                {index < data.length - 1 && data[index].length === 6 && (
                  <Text
                    sx={{
                      background: 'white10',
                      p: '0.4rem',
                      borderRadius: '3.2rem',
                      color: 'white50',
                      fontSize: '1rem',
                    }}
                  >
                    {data[index]?.[0]?.label}
                  </Text>
                )}
              </th>
              {chunk.map(({ label, value }) => (
                <td
                  key={label}
                  sx={{
                    position: 'relative',
                    '--size': `calc( ${Number(value)} / ${divider} )`,
                  }}
                >
                  <Flex sx={{ flexDirection: 'column', gap: '0.5rem' }} className="tooltip">
                    <span sx={{ fontWeight: 'bold' }}>{label}</span>
                    <span>{value}</span>
                  </Flex>
                  <span className="data">{'\u00A0'}</span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
