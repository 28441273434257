import React, { Dispatch, ReactElement, SetStateAction, useEffect } from 'react'
import { getPlatformInformation } from 'utils/getPlatformInformation'
import ContactSupportRadioButton from './ContactSupportRadioButton'

export default function TechnicalIssuesRadioButtons({
  setTechnicalIssuesRadioSelection,
  technicalIssuesdRadioSelection,
}: {
  setTechnicalIssuesRadioSelection: Dispatch<SetStateAction<string>>
  technicalIssuesdRadioSelection: string
}): ReactElement {
  useEffect(() => {
    const { browserName } = getPlatformInformation()

    if (browserName) {
      setTechnicalIssuesRadioSelection(browserName)
    }
    // we only want to run this on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const radioContents = [
    {
      id: 'chrome-radio',
      value: 'chrome',
      text: 'Chrome',
    },
    {
      id: 'firefox-radio',
      value: 'firefox',
      text: 'Firefox',
    },
    {
      id: 'desktop-radio',
      value: 'desktop',
      text: 'Desktop',
    },
    {
      id: 'mobile-radio',
      value: 'mobile',
      text: 'Mobile',
    },
    {
      id: 'other-radio',
      value: 'other',
      text: 'Other',
    },
  ]

  return (
    <fieldset
      id="app-radio"
      name="app-radio"
      data-testid="app-radio"
      sx={{
        border: 'none',
        display: 'flex',
        gap: ['1.6rem 1.6rem', '2.4rem 2.4rem'],
        fontSize: ['1.8rem', '1.6rem'],
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      {radioContents.map(radioContent => {
        return (
          <ContactSupportRadioButton
            key={radioContent.value}
            name={'app-radio'}
            id={radioContent.id}
            value={radioContent.value}
            text={radioContent.text}
            setRadioSelection={setTechnicalIssuesRadioSelection}
            checked={technicalIssuesdRadioSelection === radioContent.value}
          />
        )
      })}
    </fieldset>
  )
}
