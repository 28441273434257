/// <reference types="user-agent-data-types" />
import { useLocation } from '@reach/router'

export default function useCurrentPlatform(): {
  osName: string
  browser: string
  platform: string
} {
  const location = useLocation()

  const urlParams = new URLSearchParams(location.search)
  const platformParam = urlParams.get('platform')
  const osParam = urlParams.get('os')

  if (typeof window === 'undefined') {
    return { osName: '', browser: '', platform: '' }
  }

  //As per Gatsby's documentation, we should import the detector-js library like this
  //https://www.gatsbyjs.com/docs/debugging-html-builds/#how-to-check-if-window-is-defined
  //the downside of this is that we lose type safety
  const { Detector } = typeof window !== `undefined` ? require('detector-js') : null

  if (!Detector) {
    return { osName: '', browser: '', platform: '' }
  }
  const isEdge = navigator.userAgentData?.brands.find(
    brand => brand.brand === 'Microsoft Edge',
  )?.brand
  //detector.platform can be 'desktop', 'tablet' or 'smartphone'
  const detector = new Detector()

  //The following properties do exist in the detector-js library, but are not typed
  const osName: string = platformParam === 'desktop' && osParam ? osParam : detector.os.name

  //@ts-ignore
  const browser =
    platformParam === 'extension' && osParam
      ? osParam
      : isEdge
      ? 'Microsoft Edge'
      : detector.browser.name

  const platform = detector.platform

  const ua = window.navigator.userAgent
  const isIpad =
    ua.toLowerCase().indexOf('macintosh') > -1 &&
    !!navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2

  const currentPlatform = () => {
    if (
      platform === 'tablet' ||
      platform === 'smartphone' ||
      osName.toLowerCase() === 'android' ||
      isIpad
    ) {
      return 'mobile'
    }
    return platform
  }

  return { osName, browser, platform: currentPlatform() }
}
