import ExternalLink from 'components/UI/ExternalLink'
import { graphql, useStaticQuery } from 'gatsby'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { Flex, Text } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'

export default function WireGuardDisclaimer(): ReactElement {
  const data = useStaticQuery<Queries.WireguardQuery>(graphql`
    query Wireguard {
      directus {
        download_page {
          strings {
            languages_code {
              code
            }
            wireguard_is_a_registered
          }
        }
      }
    }
  `)
  const translations = getEnglishStrings(data.directus.download_page?.strings)

  return (
    <Flex
      sx={{
        my: ['4.8rem', '4.8rem', '8rem'],
        gap: 0,
        flexWrap: 'wrap',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Text sx={{ color: 'white50' }} as="p">
        {translations?.wireguard_is_a_registered}
        &nbsp;
        <ExternalLink
          sxx={{
            ':hover': { cursor: 'pointer' },
            width: 'unset',
            display: 'inline',
            textDecoration: 'underline',
          }}
          href="/terms/oss"
        >
          Open Source Software Attributions.
        </ExternalLink>
      </Text>
    </Flex>
  )
}
