import { alpha, darken } from '@theme-ui/color'
import ExternalLink from 'components/UI/ExternalLink'
import Svg from 'components/UI/SVG'
import breadcrumbArrowIcon from 'images/breadcrumb-arrow.svg'
import playIcon from 'images/play.svg'
import { ReactElement } from 'react'
import { Flex, Text, get } from 'theme-ui'

export default function WatchAVideoFeature({
  colour,
  text,
  href,
}: {
  colour: string
  text: string
  href: string
}): ReactElement {
  return (
    <ExternalLink href={href} target="_blank" sx={{ width: '32.8rem', mt: '2.4rem' }}>
      <Flex
        sx={{
          backgroundColor: alpha(colour, 0.15),
          borderRadius: '4.3rem',
          p: '1.2rem 1.6rem',
          justifyContent: 'center',
          alignItems: 'center',
          transition: theme => get(theme, 'transitions.default'),
          ':hover': {
            backgroundColor: alpha(colour, 1),
            cursor: 'pointer',
            'svg path': {
              fill: darken(colour, 0.6),
            },
            span: {
              color: darken(colour, 0.6),
            },
          },
          'svg path': {
            transition: theme => get(theme, 'transitions.default'),
          },
          span: {
            transition: theme => get(theme, 'transitions.default'),
          },
        }}
      >
        <Svg
          svg={playIcon}
          sx={{
            color: colour,
            width: '2.4rem',
            height: '2.4rem',
            '& path': {
              fill: colour,
            },
          }}
        />
        <Text sx={{ color: colour, ml: '1.6rem' }}>{text}</Text>
        <Svg
          svg={breadcrumbArrowIcon}
          sx={{
            '& path': {
              fill: colour,
            },
            width: '1.6rem',
            height: '1.6rem',
            ml: '0.8rem',
          }}
        />
      </Flex>
    </ExternalLink>
  )
}
