import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function CruiseControlPage({
  data,
}: {
  data: Queries.AutoPilotPageQuery
}): ReactElement {
  const autoPilotData = getEnglishStrings(data.directus.autopilot?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={autoPilotData?.meta_description ?? ''}
      metaKeywords={autoPilotData?.meta_keywords ?? []}
      title={autoPilotData?.title ?? ''}
      subtitle={autoPilotData?.subtitle ?? ''}
      summaryText={autoPilotData?.summary_text ?? ''}
      gridItems={[
        {
          title: autoPilotData?.maximize_speeds ?? '',
          description: autoPilotData?.maximize_speeds_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {autoPilotData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [autoPilotData?.the_best_location ?? ''],
        },
      ]}
    />
  )
}

export const data = graphql`
  query AutoPilotPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      autopilot {
        strings {
          languages_code {
            code
          }
          cta
          maximize_speeds
          maximize_speeds_subtitle
          meta_description
          meta_keywords
          subtitle
          summary_text
          the_best_location
          title
        }
      }
    }
  }
`
