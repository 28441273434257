import getEnglishStrings from './getEnglishStrings'
import slugify from './slugify'

export default function getArticleSlug(
  article: Queries.KnowledgeBaseQuery['directus']['knowledge_base_articles'][0],
): string {
  if (article.slug) {
    return article.slug
  }

  return slugify(getEnglishStrings(article.strings)?.title ?? undefined)
}
