import { keyframes } from '@emotion/react'

export const OpenToCloseButton = keyframes({
  from: { transform: 'rotate(-45deg)' },
  to: { transform: 'rotate(0)' },
})

export const CloseToOpenButton = keyframes({
  from: { transform: 'rotate(45deg)' },
  to: { transform: 'rotate(0)' },
})
