import { alpha } from '@theme-ui/color'
import Svg from 'components/UI/SVG'
import { ReactElement } from 'react'
import { ColorModesScale, ThemeUIStyleObject, Flex } from 'theme-ui'

export default function CircleIconContainer({
  icon,
  colour,
  sxx,
}: {
  icon: React.ElementType
  colour?: ColorModesScale[string]
  sxx?: ThemeUIStyleObject
}): ReactElement {
  return (
    <Flex
      sx={{
        width: '6.8rem',
        height: '6.8rem',
        borderRadius: '50%',
        border: '0.1rem solid',
        borderColor: colour ?? 'transparent',
        backgroundColor: colour && alpha(colour, 0.1),
        justifyContent: 'center',
        alignItems: 'center',
        ...sxx,
      }}
    >
      <Svg svg={icon} />
    </Flex>
  )
}
