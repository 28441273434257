import React, { Dispatch, ReactElement, SetStateAction } from 'react'
import { Flex } from 'theme-ui'
import Select from 'components/UI/Select'
import { SupportCategory } from 'redux/api/wsApi'

export default function SupportTopicDropdown({
  setTopicDropdownSelection,
  setAccountAdministrationRadioSelection,
  setTechnicalIssuesRadioSelection,
  setOsDropdownSelection,
  setSubjectText,
  setProblemText,
  setEmailText,
  setUsernameText,
  topicDropdownSelection,
}: {
  topicDropdownSelection: SupportCategory
  setTopicDropdownSelection: Dispatch<SetStateAction<SupportCategory>>
  setAccountAdministrationRadioSelection: Dispatch<SetStateAction<string>>
  setTechnicalIssuesRadioSelection: Dispatch<SetStateAction<string>>
  setOsDropdownSelection: Dispatch<SetStateAction<string>>
  setSubjectText: Dispatch<SetStateAction<string>>
  setProblemText: Dispatch<SetStateAction<string>>
  setEmailText: Dispatch<SetStateAction<string>>
  setUsernameText: Dispatch<SetStateAction<string>>
}): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '48rem',
        px: ['1.6rem', 0, 0],
      }}
    >
      <label
        htmlFor="support-topic-dropdown"
        sx={{
          marginBottom: ['2.4rem', '4.8rem'],
          fontSize: ['2.1rem', '2.4rem'],
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        What do you need help with?
      </label>
      <Flex
        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%', px: ['1.6rem', 0, 0] }}
      >
        <Select
          id="support-topic-dropdown"
          data-testid="support-topic-dropdown"
          value={topicDropdownSelection}
          onChange={event => {
            setTopicDropdownSelection(event.target.value as unknown as SupportCategory)
            setAccountAdministrationRadioSelection('')
            setTechnicalIssuesRadioSelection('')
            setOsDropdownSelection('')
            setSubjectText('')
            setProblemText('')
            setEmailText('')
            setUsernameText('')
          }}
          sx={{
            width: '100%',
            maxWidth: '48rem',
          }}
        >
          <option value={SupportCategory.NOT_SELECTED}>Choose one</option>
          <option value={SupportCategory.ACCOUNT_ISSUE}>Account Administration/Changes</option>
          <option value={SupportCategory.TECHNICAL_PROBLEM}>Technical Issues</option>
          <option value={SupportCategory.SALES_QUESTION}>Sales Question</option>
          <option value={SupportCategory.FEEDBACK}>Feedback/Suggestion</option>
        </Select>
      </Flex>
    </Flex>
  )
}
