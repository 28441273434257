import { Breadcrumb } from 'components/knowledge-base/breadcrumbs/Breadcrumbs'
import GarrySearchBar from 'components/knowledge-base/GarrySearchBar'
import SocialMediaLinks from 'components/knowledge-base/SocialMediaLinks'
import Tags from 'components/knowledge-base/Tags'
import TitleAndSubtitle from '../../UI/TitleAndSubtitle'
import StaticPageLayout from 'components/StaticPageLayout'
import Svg from 'components/UI/SVG'
import ArticlesArrow from 'images/articles-arrow.svg'
import React, { ReactElement, useState } from 'react'
import { Flex, Text, get } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import { allPlatformsId, getPlatformCategoryTags } from 'utils/getPlatformCategoryTags'
import usePlatformTagId from 'utils/usePlatformTagId'
import getArticleSlug from 'utils/getArticleSlug'
import InternalLink from 'components/UI/InternalLink'
import slugify from 'utils/slugify'

export interface CategoryArticlesContext {
  title: string
  articlesInCategoryOrTag: Queries.DirectusData_knowledge_base_articles[]
  pageData: Queries.DirectusData_knowledge_base_page
}

export default function CategoryArticles({
  pageContext: { title, articlesInCategoryOrTag, pageData },
}: {
  pageContext: CategoryArticlesContext
}): ReactElement {
  const [hasInputValue, setHasInputValue] = useState(false)
  const { selectedPlatformTagId, setSelectedPlatformTagId } = usePlatformTagId()
  const [selectedNonPlatformTagId, setSelectedNonPlatformTagId] =
    useState<string | undefined>(undefined)
  const pageDataStrings = getEnglishStrings(pageData?.strings)
  const allCategoryTags = articlesInCategoryOrTag.map(article => article.tags).flat()

  const nonPlatformCategoryTags = allCategoryTags
    .filter(tag => !tag?.knowledge_base_tags_id?.is_platform)
    .map(tag => {
      return {
        id: tag?.knowledge_base_tags_id?.slug ?? '',
        label: getEnglishStrings(tag?.knowledge_base_tags_id?.strings)?.name ?? '',
      }
    })

  const platformCategoryTags = getPlatformCategoryTags(articlesInCategoryOrTag)
  const uniqueTags = nonPlatformCategoryTags.filter(
    (tag, index) => nonPlatformCategoryTags.findIndex(t => tag.id === t.id) === index,
  )

  const articlesToBeDisplayed = articlesInCategoryOrTag.filter(articleInCategoryOrTag => {
    const matchesPlatformFilter =
      selectedPlatformTagId === allPlatformsId ||
      articleInCategoryOrTag.tags?.find(
        tag => tag?.knowledge_base_tags_id?.slug === selectedPlatformTagId,
      )

    const matchesNonPlatformFilter =
      !selectedNonPlatformTagId ||
      articleInCategoryOrTag.tags?.find(
        tag => tag?.knowledge_base_tags_id?.slug === selectedNonPlatformTagId,
      )

    return matchesPlatformFilter && matchesNonPlatformFilter
  })
  const breadcrumbs: Breadcrumb[] = [
    {
      title: 'HELP',
      href: selectedPlatformTagId
        ? `/knowledge-base?platform=${selectedPlatformTagId}`
        : '/knowledge-base',
    },
    {
      title: title ?? '',
      href: `/knowledge-base/${title === 'Popular' ? 'tags' : 'categories'}/${slugify(title)}`,
    },
  ]

  return (
    <StaticPageLayout
      title={`${title} | Windscribe`}
      metaDescription={pageDataStrings?.meta_description}
      metaKeywords={pageDataStrings?.meta_keywords}
    >
      <TitleAndSubtitle title={pageDataStrings?.title} subtitle={pageDataStrings?.subtitle} />
      <GarrySearchBar
        hasInputValue={hasInputValue}
        setHasInputValue={setHasInputValue}
        breadcrumbs={breadcrumbs}
      />
      {!hasInputValue && (
        <>
          <Tags
            tags={platformCategoryTags}
            selectedTagId={selectedPlatformTagId}
            setSelectedTagId={setSelectedPlatformTagId}
            shouldAllowNoSelection={false}
          />
          <Flex
            data-testid="category-article"
            sx={{
              width: ['100%', '100%', '100%'],
              maxWidth: '133rem',
              backgroundColor: 'bodyBG',
              borderRadius: '3.2rem',
              padding: ['2.4rem 1.6rem 4.8rem', '5.4rem 4.8rem 8rem', '5.4rem 10rem 8rem'],
              marginTop: '3.2rem',
              marginBottom: '-6rem',
              flexDirection: 'column',
            }}
          >
            <Text
              sx={{
                fontSize: ['3.4rem', '4.8rem', '4.8rem'],
                fontWeight: 'bold',
                color: 'white50',
                py: '2.4rem',
                textAlign: ['center', 'left', 'left'],
              }}
              data-testid="category-article-count"
            >{`${title}${breadcrumbs[1].href?.includes('tags') ? ' Articles' : ''} (${
              articlesToBeDisplayed.length
            })`}</Text>
            {!hasInputValue && (
              <Tags
                tags={uniqueTags}
                selectedTagId={selectedNonPlatformTagId}
                setSelectedTagId={setSelectedNonPlatformTagId}
                shouldAllowNoSelection={true}
              />
            )}
            {articlesToBeDisplayed.map((articleInCategory, index) => {
              const articleString = getEnglishStrings(articleInCategory.strings)
              const articleSlug = getArticleSlug(articleInCategory)
              return (
                <Flex
                  key={`${articleInCategory.slug} ${index}`}
                  data-testid={`category-article-${articleSlug}`}
                  sx={{
                    py: '2.4rem',
                    borderBottom: '0.1rem solid',
                    borderBottomColor: 'white15',
                    transition: theme => `${get(theme, 'transitions.default')}`,
                    ':hover': {
                      '.articles-arrow': {
                        transform: 'translateX(1rem)',
                        opacity: 1,
                      },
                      borderBottomColor: 'white',
                    },
                  }}
                >
                  {/*@ts-ignore*/}
                  <InternalLink href={`/knowledge-base/articles/${articleSlug}`}>
                    <Flex
                      sx={{
                        display: 'flex',
                        textDecoration: 'none',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        data-testid="article-title"
                        sx={{
                          fontSize: ['2.1rem', '2.4rem', '2.4rem'],
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                      >
                        {articleString?.title}
                      </Text>
                      <Svg
                        svg={ArticlesArrow}
                        className="articles-arrow"
                        sx={{
                          opacity: 0.25,
                          transition: theme => `${get(theme, 'transitions.default')}`,
                        }}
                      />
                    </Flex>
                  </InternalLink>
                </Flex>
              )
            })}
          </Flex>
        </>
      )}
      <Flex sx={{ marginTop: ['6rem', '8rem'] }}>
        <SocialMediaLinks />
      </Flex>
    </StaticPageLayout>
  )
}
