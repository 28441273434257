import React, { ReactElement, useState } from 'react'
import { Flex } from 'theme-ui'
import { graphql } from 'gatsby'
import StaticPageLayout from 'components/StaticPageLayout'
import Categories from 'components/knowledge-base/Categories'
import SocialMediaLinks from 'components/knowledge-base/SocialMediaLinks'
import GarrySearchBar from 'components/knowledge-base/GarrySearchBar'
import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'
import getEnglishStrings from 'utils/getEnglishStrings'
import Tags from 'components/knowledge-base/Tags'
import { allPlatformsId, getPlatformCategoryTags } from 'utils/getPlatformCategoryTags'
import usePlatformTagId from 'utils/usePlatformTagId'
import { ArrayElement } from 'utils/types'

export interface KnowledgeBaseIndexPageContext {
  articles: Queries.DirectusData_knowledge_base_articles[]
}

// markup
export default function IndexPage({
  data,
  pageContext,
}: {
  data: Queries.KnowledgeBaseIndexPageQuery
  pageContext: KnowledgeBaseIndexPageContext
}): ReactElement {
  const { articles } = pageContext
  const [hasInputValue, setHasInputValue] = useState(false)
  const KnowledgeBaseIndexPageStrings = getEnglishStrings(
    data.directus.knowledge_base_page?.strings,
  )
  const { setSelectedPlatformTagId, selectedPlatformTagId } = usePlatformTagId()

  const platformCategoryTags = getPlatformCategoryTags(articles)

  const filterTagSelectedPlatform = (
    article: ArrayElement<NonNullable<Queries.DirectusData_knowledge_base_articles[]>>,
  ) => {
    return article?.tags?.filter(tag => {
      return selectedPlatformTagId === allPlatformsId
        ? tag?.knowledge_base_tags_id?.is_platform
        : tag?.knowledge_base_tags_id?.slug === selectedPlatformTagId
    })
  }

  const filteredArticles = articles.filter(article => {
    return (
      !article?.tags?.length ||
      filterTagSelectedPlatform(article)?.length ||
      selectedPlatformTagId === allPlatformsId
    )
  })

  return (
    <StaticPageLayout
      title={`${KnowledgeBaseIndexPageStrings?.head_title} | Windscribe`}
      metaDescription={KnowledgeBaseIndexPageStrings?.meta_description}
      metaKeywords={KnowledgeBaseIndexPageStrings?.meta_keywords}
    >
      <Flex sx={{ flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        <Flex sx={{ my: '2.4rem' }}>
          <TitleAndSubtitle
            title={KnowledgeBaseIndexPageStrings?.title}
            subtitle={KnowledgeBaseIndexPageStrings?.subtitle}
          />
        </Flex>
        <GarrySearchBar
          hasInputValue={hasInputValue}
          setHasInputValue={setHasInputValue}
          breadcrumbs={[{ title: 'HELP', href: '/knowledge-base' }]}
        />
        {!hasInputValue && (
          <>
            <Tags
              tags={platformCategoryTags}
              selectedTagId={selectedPlatformTagId ?? allPlatformsId}
              setSelectedTagId={setSelectedPlatformTagId}
              shouldAllowNoSelection={false}
            />
            <Categories
              articles={filteredArticles}
              categories={data.directus.knowledge_base_categories}
              categoryViewAllText={KnowledgeBaseIndexPageStrings?.category_view_all}
            />
          </>
        )}
      </Flex>
      <SocialMediaLinks />
    </StaticPageLayout>
  )
}

export const pageQuery = graphql`
  query KnowledgeBaseIndexPage {
    directus {
      knowledge_base_categories {
        slug
        strings {
          name
          languages_code {
            code
          }
        }
        id
      }
      knowledge_base_page {
        strings {
          category_view_all
          contact_support_button
          contact_support_title
          discord_link_subtitle
          discord_link_title
          garry_link_subtitle
          garry_link_title
          reddit_link_subtitle
          reddit_link_title
          social_links_title
          subtitle
          title
          meta_description
          meta_keywords
          head_title
          languages_code {
            code
          }
        }
      }
    }
  }
`
