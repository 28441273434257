import { alpha } from '@theme-ui/color'
import { ColorModesScale, Flex, Text } from 'theme-ui'

export default function FeatureBox({
  title,
  subtitle,
  colour,
}: {
  title: string
  subtitle: string
  colour: ColorModesScale[string]
}): JSX.Element {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      <Flex
        sx={{
          px: '2.4rem',
          py: '1.6rem',
          border: '0.2rem solid',
          borderRadius: '0.8rem',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderColor: alpha(colour, 0.5),
          backgroundColor: alpha(colour, 0.1),
        }}
      >
        <Text sx={{ fontWeight: 'bold', color: colour }}>{title}</Text>
      </Flex>
      <Flex
        sx={{
          border: '0.2rem solid',
          borderTop: 0,
          borderColor: alpha(colour, 0.25),
          borderRadius: '0.8rem',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          minHeight: '11rem',
          flex: 1,
        }}
      >
        <Text sx={{ m: '2.4rem', color: 'white50' }}>{subtitle}</Text>
      </Flex>
    </Flex>
  )
}
