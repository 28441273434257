import Svg from 'components/UI/SVG'
import Inactive from 'components/download-page/Inactive'
import CheckIcon from 'images/checkbox.svg'
import DesktopIcon from 'images/desktop-icon.svg'
import ExtensionIcon from 'images/extension-icon.svg'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { Fragment } from 'theme-ui/jsx-runtime'
import getEnglishStrings from 'utils/getEnglishStrings'

interface Feature {
  description: string
  availableWithDesktopApp: boolean
  availableWithExtension: boolean
}

export default function BenefitsTable({
  translations,
  isDesktop,
}: {
  translations: Queries.DownloadPageQuery
  isDesktop: boolean
}): JSX.Element {
  const downloadPageStrings = getEnglishStrings(translations.directus.download_page?.strings)
  const generalTranslationStrings = getEnglishStrings(
    translations.directus.general_translations?.strings,
  )

  const tableStyle: ThemeUIStyleObject = {
    px: [0, '2.4rem', 0],
    tr: {
      height: '4.8rem',
      '&>td:not(:first-of-type)': {
        textAlign: 'center',
      },
      p: '2rem',
      '&:nth-of-type(even)': {
        backgroundColor: 'white05',
      },
    },
    'th, td': {
      px: '2rem',
    },
    borderSpacing: 0,
    'td:first-of-type': {
      borderTopLeftRadius: '0.8rem',
      borderBottomLeftRadius: '0.8rem',
    },
    'td:last-child': {
      borderTopRightRadius: '0.8rem',
      borderBottomRightRadius: '0.8rem',
    },
    pt: ['4.8rem', '4.8rem', '8rem'],
  }

  const tableContent: Feature[] = [
    {
      description: downloadPageStrings?.servers_in_over ?? '',
      availableWithDesktopApp: true,
      availableWithExtension: true,
    },
    {
      description: downloadPageStrings?.six_different_protocols ?? '',
      availableWithDesktopApp: true,
      availableWithExtension: false,
    },
    {
      description: downloadPageStrings?.network_and_per_app ?? '',
      availableWithDesktopApp: true,
      availableWithExtension: false,
    },
    {
      description: generalTranslationStrings?.firewall ?? '',
      availableWithDesktopApp: true,
      availableWithExtension: true,
    },
    {
      description: downloadPageStrings?.censorship_circumvention ?? '',
      availableWithDesktopApp: true,
      availableWithExtension: false,
    },
    {
      description: downloadPageStrings?.ad_and_tracker ?? '',
      availableWithDesktopApp: true,
      availableWithExtension: true,
    },
    {
      description: downloadPageStrings?.double_hop ?? '',
      availableWithDesktopApp: false,
      availableWithExtension: false,
    },
    {
      description: downloadPageStrings?.webrtc_blocker ?? '',
      availableWithDesktopApp: false,
      availableWithExtension: true,
    },
  ]

  if (!isDesktop) {
    return <></>
  }

  return (
    <>
      <table
        sx={{
          borderSpacing: 0,
          minWidth: ['', '', 'maxContentWidth'],
          width: '100%',
          tableLayout: 'fixed',
          overflowWrap: 'break-word',
          display: ['table', 'none', 'none'],
          tr: {
            'th, td': {
              textAlign: 'center',
            },
          },
          span: {
            display: 'inline-grid',
            fontSize: '1.4rem',
            lineHeight: '2.7rem',
          },
          mt: ['4.8rem', 0, 0],
        }}
      >
        <thead>
          <tr>
            <th>
              <Svg svg={DesktopIcon} />
            </th>
            <th>
              <Svg svg={ExtensionIcon} />
            </th>
            <th>
              <Svg svg={DesktopIcon} />
              <Svg svg={ExtensionIcon} />
            </th>
          </tr>
          <tr>
            <td>
              <Flex sx={{ justifyContent: 'center' }}>
                <Text sx={{ fontWeight: 'bold', maxWidth: '6.3rem' }}>
                  {downloadPageStrings?.desktop_app}
                </Text>
              </Flex>
            </td>
            <td>
              <Text sx={{ fontWeight: 'bold', maxWidth: '7.5rem', display: 'block' }}>
                {downloadPageStrings?.browser_extension}
              </Text>
            </td>
            <td>
              <Text sx={{ fontWeight: 'bold', maxWidth: '7.5rem', display: 'block' }}>
                {downloadPageStrings?.desktop_and_browser}
              </Text>
            </td>
          </tr>
          <tr sx={{ height: '1.6rem' }}></tr>
        </thead>
        <tbody
          sx={{
            'th, td': {
              py: '2rem',
            },
          }}
        >
          {tableContent.map(feature => (
            <Fragment key={feature.description}>
              <tr
                sx={{
                  '&:nth-of-type(odd)': {
                    backgroundColor: 'white05',
                  },
                }}
              >
                <td colSpan={3}>
                  <Text sx={{ maxWidth: '25.8rem' }}>{feature.description}</Text>
                </td>
              </tr>
              <tr>
                <td>{feature.availableWithDesktopApp ? <Svg svg={CheckIcon} /> : <Inactive />}</td>
                <td>{feature.availableWithExtension ? <Svg svg={CheckIcon} /> : <Inactive />}</td>
                <td>
                  {feature.availableWithDesktopApp ||
                  feature.availableWithExtension ||
                  feature.description === downloadPageStrings?.double_hop ? (
                    <Svg svg={CheckIcon} />
                  ) : (
                    <Inactive />
                  )}
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
      <table
        sx={{
          ...tableStyle,
          display: ['none', 'table', 'table'],
          minWidth: [0, '76.8rem', 'maxContentWidth'],
        }}
      >
        <thead>
          <tr>
            <th></th>
            <th>
              <Svg svg={DesktopIcon} />
            </th>
            <th>
              <Svg svg={ExtensionIcon} />
            </th>
            <th>
              <Svg svg={DesktopIcon} />
              <Svg svg={ExtensionIcon} />
            </th>
          </tr>
          <tr>
            <td>
              <Text sx={{ fontWeight: 'bold' }}>{generalTranslationStrings?.features}</Text>
            </td>
            <td>
              <Text sx={{ fontWeight: 'bold' }}>{downloadPageStrings?.desktop_app}</Text>
            </td>
            <td>
              <Text sx={{ fontWeight: 'bold' }}>{downloadPageStrings?.browser_extension}</Text>
            </td>
            <td>
              <Text sx={{ fontWeight: 'bold' }}>{downloadPageStrings?.desktop_and_browser}</Text>
            </td>
          </tr>
        </thead>
        <tbody>
          {tableContent.map(feature => (
            <Fragment key={feature.description}>
              <tr>
                <td>
                  <Text>{feature.description}</Text>
                </td>
                <td>{feature.availableWithDesktopApp ? <Svg svg={CheckIcon} /> : <Inactive />}</td>
                <td>{feature.availableWithExtension ? <Svg svg={CheckIcon} /> : <Inactive />}</td>
                <td>
                  {feature.availableWithDesktopApp ||
                  feature.availableWithExtension ||
                  feature.description === downloadPageStrings?.double_hop ? (
                    <Svg svg={CheckIcon} />
                  ) : (
                    <Inactive />
                  )}
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </>
  )
}
