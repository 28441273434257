import Svg from 'components/UI/SVG'
import React, { ReactElement, ReactNode } from 'react'
import { Box, Flex, Text, get } from 'theme-ui'
import ArticlesArrow from 'images/articles-arrow.svg'
import LargeNetworkFeaturesIcon from 'images/large-network-features-icon.svg'
import RobertFeaturesIcon from 'images/robert-features-icon.svg'
import NoLogsFeaturesIcon from 'images/no-logs-features-icon.svg'
import EncryptionFeaturesIcon from 'images/strongest-encryption-features-icon.svg'
import ConfigFeaturesIcon from 'images/config-features-icon.svg'
import ScribeForceFeaturesIcon from 'images/scribe-force-features-icon.svg'
import SimpleClientsFeaturesIcon from 'images/simple-clients-features-icon.svg'
import FreeFeaturesIcon from 'images/use-for-free-features-icon.svg'
import StaticIpFeaturesIcon from 'images/static-ip-features-icon.svg'
import PortForwardingFeaturesIcon from 'images/port-forwarding-features-icon.svg'
import SplitTunnelingFeaturesIcon from 'images/split-tunneling-features-icon.svg'
import CircumventCensorshipFeaturesIcon from 'images/circumvent-censorship.svg'
import getEnglishStrings from 'utils/getEnglishStrings'
import InternalLink from 'components/UI/InternalLink'
import slugify from 'utils/slugify'
import ReactMarkdown from 'react-markdown'
import Tag from 'components/Tag'

export interface FeaturesPageFeatureContent {
  title?: string | null
  description?: string | null
  icon: React.ElementType
}

export default function Features({
  featuresData,
}: {
  featuresData: Queries.KnowledgeBaseFeaturesPageQuery['directus']['features_page']
}): ReactElement {
  const featuresDataStrings = getEnglishStrings(featuresData?.strings)
  const featureList: FeaturesPageFeatureContent[] = [
    {
      title: featuresDataStrings?.large_network_title,
      description: featuresDataStrings?.large_network_subtitle,
      icon: LargeNetworkFeaturesIcon,
    },
    {
      title: featuresDataStrings?.robert_title,
      description: featuresDataStrings?.robert_subtitle,
      icon: RobertFeaturesIcon,
    },
    {
      title: featuresDataStrings?.identifying_logs_title,
      description: featuresDataStrings?.identifying_logs_subtitle,
      icon: NoLogsFeaturesIcon,
    },
    {
      title: featuresDataStrings?.strongest_encryption_title,
      description: featuresDataStrings?.strongest_encryption_subtitle,
      icon: EncryptionFeaturesIcon,
    },
    {
      title: featuresDataStrings?.config_generators_title,
      description: featuresDataStrings?.config_generators_subtitle,
      icon: ConfigFeaturesIcon,
    },
    {
      title: featuresDataStrings?.scribeforce_title,
      description: featuresDataStrings?.scribeforce_subtitle,
      icon: ScribeForceFeaturesIcon,
    },
    {
      title: featuresDataStrings?.simple_clients_title,
      description: featuresDataStrings?.simple_clients_subtitle,
      icon: SimpleClientsFeaturesIcon,
    },
    {
      title: featuresDataStrings?.use_for_free_title,
      description: featuresDataStrings?.use_for_free_subtitle,
      icon: FreeFeaturesIcon,
    },
    {
      title: featuresDataStrings?.static_ips_title,
      description: featuresDataStrings?.static_ips_subtitle,
      icon: StaticIpFeaturesIcon,
    },
    {
      title: featuresDataStrings?.port_forwarding_title,
      description: featuresDataStrings?.port_forwarding_subtitle,
      icon: PortForwardingFeaturesIcon,
    },
    {
      title: featuresDataStrings?.split_tunneling_title,
      description: featuresDataStrings?.split_tunneling_subtitle,
      icon: SplitTunnelingFeaturesIcon,
    },
    {
      title: featuresDataStrings?.circumvent_censorship,
      description: featuresDataStrings?.circumvent_censorship_subtitle,
      icon: CircumventCensorshipFeaturesIcon,
    },
  ]
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        position: 'relative',
        width: ['100vw', '100vw', '100%'],
        alignItems: 'center',
        fontFamily: 'IBM Plex Sans',
      }}
    >
      <Box sx={{ mb: '4.8rem' }}>
        <Tag title={featuresDataStrings?.feature_list_title} backgroundColour="white10" />
      </Box>
      <FeatureWrapper>
        {featureList.map(featureListItem => {
          return (
            <Feature
              key={featureListItem.title}
              title={featureListItem.title}
              description={featureListItem.description}
              icon={featureListItem.icon}
            />
          )
        })}
      </FeatureWrapper>
    </Flex>
  )
}

export function Feature({
  title,
  description,
  icon,
}: {
  title: string | undefined
  description: string | undefined
  icon: React.ElementType
}): ReactElement {
  return (
    <Flex data-testid={title?.toLowerCase().replaceAll(' ', '-').replaceAll('.', '')}>
      <InternalLink href={slugify(title?.replaceAll('.', ''))}>
        <Flex
          sx={{
            maxWidth: '29rem',
            flexDirection: 'column',
            alignItems: 'center',
            '.features-arrow, .features-description, .features-image': {
              transition: theme => `${get(theme, 'transitions.default')}`,
            },
            ':hover': {
              '.features-arrow ': {
                transform: 'translateX(1rem)',
              },
              '.features-description': {
                color: 'white',
              },
              '.features-image': {
                transform: 'scale(1.1)',
              },
            },
          }}
        >
          <Flex
            className="features-image"
            sx={{
              maxWidth: '14.8rem',
              maxHeight: '14.5rem',
              marginBottom: '1.6rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Svg svg={icon} sx={{ objectFit: 'scale-down' }} />
          </Flex>
          <Flex sx={{ gap: '0.8rem', alignItems: 'center', paddingBottom: '1.6rem' }}>
            <Text variant="heading2" sx={{ color: 'white', fontWeight: 'bold' }}>
              {title}
            </Text>
            <Svg
              svg={ArticlesArrow}
              className="features-arrow"
              sx={{ width: '2.4rem', height: '2.4rem' }}
            />
          </Flex>
          <Text
            variant="body2"
            className="features-description"
            sx={{ color: 'white50', textAlign: 'center' }}
          >
            <ReactMarkdown>{description ?? ''}</ReactMarkdown>
          </Text>
        </Flex>
      </InternalLink>
    </Flex>
  )
}

export function FeatureWrapper({ children }: { children: ReactNode }): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        maxWidth: '98rem',
        flexDirection: ['column', 'row', 'row'],
        flexWrap: ['nowrap', 'wrap', 'wrap'],
        gap: '4.8rem',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </Flex>
  )
}
