import React, { ReactElement } from 'react'
import { Button, Flex, Text, get } from 'theme-ui'

export default function CreateAccountButtons({
  createAccountButton,
  createAccountDescription,
  voucher,
  add,
  haveAccount,
  login,
}: {
  createAccountButton: string | undefined
  createAccountDescription: string | undefined
  voucher: string | undefined
  add: string | undefined
  haveAccount: string | undefined
  login: string | undefined
}): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        maxWidth: '48rem',
        marginTop: '4.8rem',
        alignItems: 'center',
      }}
    >
      <Button
        sx={{
          width: '100%',
          height: '4.8rem',
          borderRadius: '4.6rem',
          border: '0.2rem solid',
          borderColor: 'neonGreen50',
          backgroundColor: 'neonGreen25',
          marginBottom: '2.4rem',
          color: 'neonGreen',
          fontWeight: 'bold',
          fontSize: '1.8rem',
          transition: theme => `${get(theme, 'transitions.default')}`,
          ':hover': {
            borderColor: 'neonGreen',
            backgroundColor: 'neonGreen',
            color: 'bodyBG',
          },
        }}
      >
        {createAccountButton}
      </Button>
      <Text
        sx={{
          fontSize: '1.4rem',
          color: 'white50',
          textAlign: 'center',
          width: '100%',
          maxWidth: '35rem',
          marginBottom: '4rem',
        }}
      >
        {createAccountDescription}
      </Text>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '2.4rem',
          fontSize: '1.6rem',
          marginBottom: '20rem',
          alignItems: 'center',
          fontWeight: 'bold',
        }}
      >
        <Flex sx={{ gap: '0.8rem' }}>
          <Text sx={{ color: 'white' }}>{voucher}</Text>
          <Text sx={{ color: 'neonGreen', fontWeight: 'bold' }}>{add}</Text>
        </Flex>
        <Flex sx={{ color: 'white50.', letterSpacing: '0.4rem' }}>...</Flex>
        <Flex sx={{ gap: '0.8rem' }}>
          <Text sx={{ color: 'white' }}>{haveAccount}</Text>
          <Text sx={{ color: 'neonGreen', fontWeight: 'bold' }}>{login}</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
