import BasePlatform, { DownloadPlatforms } from 'components/download-page/BasePlatform'
import DownloadPlatformButton from 'components/download-page/DownloadPlatformButton'
import PlatformTitle from 'components/download-page/PlatformTitle'
import ChromeIcon from 'images/chrome-icon.svg'
import EdgeIcon from 'images/edge-icon.svg'
import FirefoxIcon from 'images/firefox-icon.svg'
import { Dispatch, SetStateAction } from 'react'
import ReactMarkdown from 'react-markdown'
import { Flex, Text } from 'theme-ui'
import { AvailablePlatforms } from 'utils/downloadPageUtils'
import getEnglishStrings from 'utils/getEnglishStrings'

const platforms: DownloadPlatforms = {
  Chrome: {
    icon: ChromeIcon,
    name: 'Chrome',
    learnMoreUrl: '/features/chrome',
    installGuideUrl: '/knowledge-base/articles/how-to-use-with-windscribe-on-chrome ',
    sourceCodeUrl: 'https://github.com/Windscribe/browser-extension',
  },
  Firefox: {
    icon: FirefoxIcon,
    name: 'Firefox',
    learnMoreUrl: '/features/firefox',
    installGuideUrl: '/knowledge-base/articles/getting-started-with-windscribe-on-firefox',
    sourceCodeUrl: 'https://github.com/Windscribe/browser-extension',
  },
  Edge: {
    icon: EdgeIcon,
    name: 'Edge',
    learnMoreUrl: '/features/edge',
    installGuideUrl: '/knowledge-base/articles/getting-started-with-windscribe-on-microsoft-edge ',
    sourceCodeUrl: 'https://github.com/Windscribe/browser-extension',
  },
}

export default function ExtensionPlatform({
  platformName,
  setPlatform,
  translations,
  featuresList,
  image,
  isDesktop,
}: {
  platformName: AvailablePlatforms
  setPlatform: Dispatch<SetStateAction<AvailablePlatforms>>
  translations: Queries.DownloadPageQuery
  featuresList: string[]
  image: React.ReactNode
  isDesktop: boolean
}): JSX.Element {
  const downloadPageStrings = getEnglishStrings(translations.directus.download_page?.strings)
  const generalTranslationStrings = getEnglishStrings(
    translations.directus.general_translations?.strings,
  )

  return (
    <BasePlatform
      platformName={platformName}
      setPlatform={setPlatform}
      featuresList={featuresList}
      image={image}
      dataTestId="extension-platform"
      title={
        {
          Chrome: downloadPageStrings?.windscribe_for_chrome ?? '',
          Firefox: downloadPageStrings?.windscribe_for_firefox ?? '',
          Edge: downloadPageStrings?.windscribe_for_edge ?? '',
        }[platformName]
      }
      downloadSection={
        {
          Chrome: (
            <DownloadPlatformButton
              platformIcon={platforms[platformName].icon}
              downloadText={generalTranslationStrings?.download ?? ''}
              downloadUrl="/install/extension/chrome"
              platformType="extension"
              style="bright"
            />
          ),
          Firefox: (
            <DownloadPlatformButton
              platformIcon={platforms[platformName].icon}
              downloadText={generalTranslationStrings?.download ?? ''}
              downloadUrl="/install/extension/firefox"
              platformType="extension"
              style="bright"
            />
          ),
          Edge: (
            <DownloadPlatformButton
              platformIcon={platforms[platformName].icon}
              downloadText={generalTranslationStrings?.download ?? ''}
              downloadUrl="/install/extension/edge"
              platformType="extension"
              style="bright"
            />
          ),
        }[platformName]
      }
      header={
        <Flex sx={{ flexDirection: 'column' }}>
          <PlatformTitle title={generalTranslationStrings?.browser ?? ''} />
          {isDesktop && (
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                mt: ['1.6rem', '2.8rem', '3.6rem'],
              }}
            >
              <Text
                sx={{
                  fontSize: ['2.4rem', '3.2rem', '3.2rem'],
                  fontWeight: 'bold',
                  maxWidth: ['54rem', '77rem', '70%'],
                  textAlign: 'center',
                }}
              >
                {downloadPageStrings?.desktop_app_alone}
              </Text>
              <Text
                sx={{
                  fontSize: '2.1rem',
                  color: 'white50',
                  maxWidth: ['40rem', '60rem', '50%'],
                  textAlign: 'center',
                  mt: ['1.6rem', '2.8rem', '3.6rem'],
                }}
              >
                <ReactMarkdown>
                  {downloadPageStrings?.the_windscribe_desktop_app ?? ''}
                </ReactMarkdown>
              </Text>
            </Flex>
          )}
        </Flex>
      }
      translations={translations}
      platforms={platforms}
    />
  )
}
