import React, { Dispatch, ReactElement, SetStateAction } from 'react'
import { Flex } from 'theme-ui'

export default function ContactSupportRadioButton({
  name,
  id,
  value,
  text,
  setRadioSelection,
  checked,
}: {
  name: string
  id: string
  value: string
  text: string
  setRadioSelection: Dispatch<SetStateAction<string>>
  checked?: boolean
}): ReactElement {
  return (
    <Flex data-testid={id}>
      <input
        type="radio"
        id={id}
        value={value}
        name={name}
        onChange={event => {
          setRadioSelection(event.target.value)
        }}
        sx={{
          display: 'none',
          ':checked + label': {
            borderColor: 'neonGreen',
            color: 'neonGreen',
            cursor: 'pointer',
          },
        }}
        checked={checked}
      />
      <label
        htmlFor={id}
        sx={{
          display: 'flex',
          width: '14.4rem',
          height: '4.8rem',
          borderRadius: '3.2rem',
          backgroundColor: 'white10',
          justifyContent: 'center',
          alignItems: 'center',
          border: 'solid 0.1rem',
          borderColor: 'white10',
          cursor: 'pointer',
        }}
      >
        {text}
      </label>
    </Flex>
  )
}
