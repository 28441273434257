import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import PacketSize from 'images/packet-size.png'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function PacketSizePage({
  data,
}: {
  data: Queries.PacketSizePageQuery
}): ReactElement {
  const packetSizeData = getEnglishStrings(data.directus.packet_size?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={packetSizeData?.meta_description ?? ''}
      metaKeywords={packetSizeData?.meta_keywords ?? []}
      title={packetSizeData?.title ?? ''}
      subtitle={packetSizeData?.subtitle ?? ''}
      featureImage={PacketSize}
      summaryText={packetSizeData?.summary_text ?? ''}
      gridItems={[
        {
          title: packetSizeData?.what_is ?? '',
          description: packetSizeData?.what_is_subtitle ?? '',
        },
        {
          title: packetSizeData?.when_to ?? '',
          description: packetSizeData?.when_to_subtitle ?? '',
        },
        {
          title: packetSizeData?.auto_detection ?? '',
          description: packetSizeData?.auto_detection_subtitle ?? '',
        },
        {
          title: packetSizeData?.manual_mode ?? '',
          description: packetSizeData?.manual_mode_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {packetSizeData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [packetSizeData?.check_out_our ?? ''],
        },
      ]}
    />
  )
}

export const data = graphql`
  query PacketSizePage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      packet_size {
        strings {
          auto_detection
          auto_detection_subtitle
          check_out_our
          cta
          languages_code {
            code
          }
          manual_mode
          manual_mode_subtitle
          meta_description
          meta_keywords
          subtitle
          summary_text
          title
          what_is
          what_is_subtitle
          when_to
          when_to_subtitle
        }
      }
    }
  }
`
