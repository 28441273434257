import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function WorkerBlockPage({
  data,
}: {
  data: Queries.WebWorkerPageQuery
}): ReactElement {
  const webWorkerData = getEnglishStrings(data.directus.web_worker?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={webWorkerData?.meta_description ?? ''}
      metaKeywords={webWorkerData?.meta_keywords ?? []}
      title={webWorkerData?.title ?? ''}
      subtitle={webWorkerData?.subtitle ?? ''}
      gridItems={[
        {
          title: webWorkerData?.what_are ?? '',
          description: webWorkerData?.what_are_subtitle ?? '',
        },
        {
          title: webWorkerData?.can_break ?? '',
          description: webWorkerData?.can_break_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {webWorkerData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [webWorkerData?.this_feature ?? ''],
        },
      ]}
    />
  )
}

export const data = graphql`
  query WebWorkerPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      web_worker {
        strings {
          languages_code {
            code
          }
          can_break
          can_break_subtitle
          cta
          meta_description
          meta_keywords
          subtitle
          this_feature
          title
          what_are
          what_are_subtitle
        }
      }
    }
  }
`
