import { alpha } from '@theme-ui/color'
import ExternalLink from 'components/UI/ExternalLink'
import InternalLink from 'components/UI/InternalLink'
import Svg from 'components/UI/SVG'
import ArticlesArrow from 'images/articles-arrow.svg'
import { Dispatch, ReactElement, SetStateAction, useEffect } from 'react'
import { Flex, Text, get } from 'theme-ui'
import useBreakpointIndex from 'theme/useBreakpointIndex'
import getEnglishStrings from 'utils/getEnglishStrings'
import headerLinkIconMap from '../headerLinkIconMap'
import ToggleNavigationMenuButton from './ToggleNavigationMenuButton'

export default function ToggleNavigationMenuTray({
  headerLinks,
  setIsMobileNavMenuOpen,
  isMobileNavOpen,
}: {
  headerLinks: Queries.HeaderLinksQuery['directus']['header_links']
  setIsMobileNavMenuOpen: Dispatch<SetStateAction<boolean>>
  isMobileNavOpen: boolean
}): ReactElement {
  const breakpointIndex = useBreakpointIndex()
  const isMobileOrTablet = breakpointIndex === 0 || breakpointIndex === 1

  // TODO: remove this when we refactor trays to use a presenter component
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    if (body) {
      if (isMobileNavOpen && isMobileOrTablet) {
        const scrollY = window.scrollY
        body.classList.add('non-scrollable')
        // store the scroll position so we can return to it when the menu is closed
        localStorage.setItem('scrollY', scrollY.toString())
      } else {
        body.classList.remove('non-scrollable')
      }
    }
  }, [isMobileOrTablet, isMobileNavOpen])

  return (
    <Flex>
      {!isMobileNavOpen ? (
        <ToggleNavigationMenuButton
          isMobileNavOpen={isMobileNavOpen}
          setIsMobileNavOpen={setIsMobileNavMenuOpen}
        />
      ) : (
        <Flex
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          <Flex
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'transparent',
              zIndex: 9,
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
          <Flex
            sx={{
              display: ['flex', 'flex', 'none'],
              flexDirection: 'column',
              fontSize: '1.8rem',
              width: ['100%', '40rem'],
              height: '100%',
              padding: ['1.6rem', '2.4rem'],
              backgroundColor: alpha('bodyBG', 0.96),
              position: 'fixed',
              top: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            <Flex sx={{ justifyContent: 'flex-end', marginLeft: [0, '-1.2rem'] }}>
              <ToggleNavigationMenuButton
                isMobileNavOpen={isMobileNavOpen}
                setIsMobileNavOpen={setIsMobileNavMenuOpen}
              />
            </Flex>
            {headerLinks?.map(headerLink => {
              const headerLinkStrings = getEnglishStrings(headerLink?.strings)
              return headerLink.slug === '/features' ? (
                <ExternalLink key={headerLink?.slug} href={headerLink?.slug || ''}>
                  <NavLinkContent
                    title={headerLinkStrings?.name ?? ''}
                    slug={headerLink?.slug}
                    setIsMobileNavMenuOpen={setIsMobileNavMenuOpen}
                  />
                </ExternalLink>
              ) : (
                <InternalLink key={headerLink?.slug} href={headerLink?.slug || ''}>
                  <NavLinkContent
                    title={headerLinkStrings?.name ?? ''}
                    slug={headerLink?.slug}
                    setIsMobileNavMenuOpen={setIsMobileNavMenuOpen}
                  />
                </InternalLink>
              )
            })}
            <Flex
              sx={{
                alignItems: 'flex-end',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
              }}
            >
              <ExternalLink
                href="/download"
                sxx={{
                  width: '100%',
                  display: 'flex',
                  variant: 'buttons.neon',

                  justifyContent: 'center',
                }}
                data-testid="menuTray/download"
              >
                Get Windscribe
              </ExternalLink>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

interface NavLinkContentProps {
  setIsMobileNavMenuOpen: Dispatch<SetStateAction<boolean>>
  slug: string
  title: string
}

const NavLinkContent = ({
  setIsMobileNavMenuOpen,
  slug,
  title,
}: NavLinkContentProps): ReactElement => {
  return (
    <Flex
      onClick={() => {
        setIsMobileNavMenuOpen(false)
      }}
      sx={{
        marginTop: '0.8rem',
        width: '100%',
        borderBottom: '0.1rem solid',
        borderBottomColor: 'white10',
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: theme => `${get(theme, 'transitions.default')}`,
        color: location.pathname === slug ? 'white' : 'white50',
        ':hover': {
          '.articles-arrow': {
            transform: 'translateX(1rem)',
            opacity: 1,
          },
          '.header-link-icon': {
            opacity: 1,
          },
          color: 'white',
        },
        '.articles-arrow, .header-link-icon': {
          transition: theme => `${get(theme, 'transitions.default')}`,
        },
      }}
    >
      <Flex sx={{ py: '1.6rem', gap: '1.6rem' }}>
        <Svg
          className="header-link-icon"
          svg={headerLinkIconMap[slug || '']}
          sx={{ opacity: location.pathname === slug ? 1 : 0.5 }}
        />
        <Text sx={{ fontWeight: 'bold' }}>{title}</Text>
      </Flex>
      {location.pathname !== slug && (
        <Svg svg={ArticlesArrow} className="articles-arrow" sx={{ opacity: 0.25 }} />
      )}
    </Flex>
  )
}
