import React, { ReactElement } from 'react'
import { Text } from 'theme-ui'
import StaticPageLayout from 'components/StaticPageLayout'

// markup
export default function IndexPage(): ReactElement {
  return (
    <StaticPageLayout title="Windscribe - Free VPN and Ad Block">
      <Text>This is the home page!</Text>
    </StaticPageLayout>
  )
}
