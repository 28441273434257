import Svg from 'components/UI/SVG'
import React, { Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'store/hooks'
import { Button, Flex, Text } from 'theme-ui'
import validateEmail from 'utils/validateEmail'
import ErrorIcon from 'images/error-icon.svg'

interface ContactSupportTextInputProps {
  subjectText: string
  setSubjectText: Dispatch<SetStateAction<string>>
  problemText: string
  setProblemText: Dispatch<SetStateAction<string>>
  emailText: string
  setEmailText: Dispatch<SetStateAction<string>>
  usernameText: string
  setUsernameText: Dispatch<SetStateAction<string>>
  isRequestInFlight: boolean
  setIsRequestInFlight: Dispatch<SetStateAction<boolean>>
}

export default function ContactSupportTextInput({
  subjectText,
  setSubjectText,
  problemText,
  setProblemText,
  emailText,
  setEmailText,
  usernameText,
  setUsernameText,
  isRequestInFlight,
}: ContactSupportTextInputProps): ReactElement {
  const { data: session } = useAppSelector(state => state.session)

  const isValidEmail = useMemo(() => validateEmail(emailText), [emailText])
  const [shouldShowInvalidEmailError, setShouldShowInvalidEmailError] = useState(false)

  useEffect(() => {
    session?.email && setEmailText(session.email)
    session?.username && setUsernameText(session.username)
    //We only want to fill this information when the session changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  useEffect(() => {
    if (isValidEmail) {
      setShouldShowInvalidEmailError(false)
    }
  }, [isValidEmail])

  const isSendButtonDisabled = useMemo(
    () => !isValidEmail || subjectText === '' || problemText === '' || isRequestInFlight,
    [isValidEmail, subjectText, problemText, isRequestInFlight],
  )

  return (
    <Flex
      data-testid="contact-support-text-input"
      sx={{ flexDirection: 'column', alignItems: 'center', width: '100%', px: ['1.6rem', 0, 0] }}
    >
      <label
        sx={{
          margin: ['4.8rem 0 3.2rem 0', '8rem 0 4.8rem 0'],
          fontSize: ['2.1rem', '2.4rem'],
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        htmlFor="subject"
      >
        Please describe your issue.
      </label>
      <Flex
        sx={{ width: '100%', px: ['1.6rem', 0, 0], flexDirection: 'column', alignItems: 'center' }}
      >
        <input
          name="subject"
          type="text"
          required
          placeholder="Subject"
          data-testid="subject-text-input"
          onChange={event => {
            setSubjectText(event.target.value)
          }}
          value={subjectText}
          sx={{
            width: '100%',
            maxWidth: '44rem',
            height: '6.5rem',
            px: '2.2rem',
            borderRadius: '3.2rem 3.2rem 0 0',
            backgroundColor: 'white10',
            color: 'white',
            border: 'none',
            borderBottom: 'solid 0.1rem',
            borderBottomColor: 'bodyBG',
            fontSize: '1.6rem',
            ':placeholder': {
              color: 'white50',
            },
          }}
        />
        <textarea
          required
          placeholder="Your message..."
          data-testid="problem-text-input"
          onChange={event => setProblemText(event.target.value)}
          value={problemText}
          sx={{
            width: '100%',
            maxWidth: '44rem',
            height: '25rem',
            padding: '2.2rem',
            borderRadius: '0 0 3.2rem 3.2rem',
            backgroundColor: 'white10',
            color: 'white',
            border: 'none',
            boxShadow: 'none',
            resize: 'none',
            outline: 'none',
            fontFamily: 'inherit',
            fontSize: '1.8rem',
            ':placeholder': {
              color: 'white50',
            },
          }}
        />
      </Flex>
      <Flex
        sx={{ flexDirection: 'column', width: '100%', alignItems: 'center', px: ['1.6rem', 0, 0] }}
      >
        <Flex
          sx={{
            width: '100%',
            maxWidth: '44rem',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: ['3.2rem 0 0.8rem 0', '4.8rem 0 0.8rem 0'],
          }}
        >
          <label
            htmlFor="email"
            sx={{
              fontSize: '1.6rem',
              color: 'white50',
              fontWeight: 'bold',
            }}
          >
            Your email
          </label>
          <Svg svg={ErrorIcon} sx={{ display: shouldShowInvalidEmailError ? 'block' : 'none' }} />
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            width: '100%',
            position: 'relative',
            alignItems: 'center',
          }}
        >
          <input
            type="text"
            required
            data-testid="email-text-input"
            onChange={event => setEmailText(event.target.value)}
            onBlur={() => {
              setShouldShowInvalidEmailError(emailText !== '' && !isValidEmail)
            }}
            value={emailText}
            sx={{
              width: '100%',
              maxWidth: '44rem',
              height: '4.8rem',
              px: '2.2rem',
              borderRadius: '3.2rem',
              backgroundColor: 'white10',
              color: 'white50',
              border: 'none',
              fontSize: '1.6rem',
            }}
          />
          <Text
            sx={{
              display: shouldShowInvalidEmailError ? 'block' : 'none',
              position: 'absolute',
              top: ['calc(100% + 0.4rem)', 'calc(100% + 0.8rem)'],
              width: '100%',
              maxWidth: '44rem',
              fontSize: '1.2rem',
              color: 'red',
            }}
          >
            That&apos;s not a valid email.
          </Text>
        </Flex>
        <Flex
          sx={{
            width: '100%',
            maxWidth: '44rem',
            justifyContent: 'flex-start',
            margin: ['3.2rem 0 0.8rem 0', '4.8rem 0 0.8rem 0'],
          }}
        >
          <label
            htmlFor="username"
            sx={{
              fontSize: '1.6rem',
              color: 'white50',
              fontWeight: 'bold',
            }}
          >
            Your Windscribe username, if you have one
          </label>
        </Flex>
        <input
          type="text"
          data-testid="username-text-input"
          onChange={event => setUsernameText(event.target.value)}
          value={usernameText}
          sx={{
            width: '100%',
            maxWidth: '44rem',
            height: '4.8rem',
            px: '2.2rem',
            borderRadius: '3.2rem',
            backgroundColor: 'white10',
            color: 'white50',
            border: 'none',
            fontSize: '1.6rem',
          }}
        />
      </Flex>
      <Button
        type="submit"
        variant="neon"
        data-testid="submit-button"
        disabled={isSendButtonDisabled}
        sx={{
          width: '100%',
          maxWidth: '48rem',
          height: '4.8rem',
          marginTop: ['4.8rem', '8rem'],
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        Send
      </Button>
    </Flex>
  )
}
