import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'

interface SupportPageSubmissionProps {
  ticketNumber?: number
  error?: string
}
export default function SupportPageSubmission({
  ticketNumber,
  error,
}: SupportPageSubmissionProps): ReactElement {
  return (
    <Flex
      data-testid="ticket-submission-page"
      sx={{
        width: '100%',
        maxWidth: '48rem',
        flexDirection: 'column',
        textAlign: 'center',
        gap: ['2.4rem', '4.8rem'],
        px: ['1.6rem', 0],
      }}
    >
      {ticketNumber && (
        <>
          <Text sx={{ fontSize: '2.4rem', fontWeight: 'bold' }}>
            Your message was sent successfully to one of our support agents.
          </Text>
          <Text sx={{ color: 'white50', fontSize: '1.8rem' }}>
            Your support ticket number is #{ticketNumber}. Someone will get back to you ASAP. Be
            sure to check your Spam folder.
          </Text>
          <Text sx={{ color: 'white50', fontSize: '1.8rem' }}>
            In the meantime, you can always check the support page.
          </Text>
        </>
      )}

      {error && (
        <Text
          data-testid="support-ticket-submission-error"
          sx={{ color: 'red', fontSize: '1.8rem' }}
        >
          There was an error: {error}. Please try again later.
        </Text>
      )}
    </Flex>
  )
}
