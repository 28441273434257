import React, { ReactElement } from 'react'
import { Button, Flex, Text, get } from 'theme-ui'
import GarryIcon from 'images/garry-icon.svg'
import RedditIcon from 'images/reddit-icon.svg'
import DiscordIcon from 'images/discord-icon.svg'
import ArticlesArrow from 'images/articles-arrow.svg'
import Svg from 'components/UI/SVG'
import ExternalLink from 'components/UI/ExternalLink'
import { graphql, useStaticQuery } from 'gatsby'
import getEnglishStrings from 'utils/getEnglishStrings'
import InternalLink from 'components/UI/InternalLink'
import { useAppSelector } from 'store/hooks'

export default function SocialMediaLinks(): ReactElement {
  const isPremiumUser = useAppSelector(state => !!state.session?.data?.is_premium)

  const data = useStaticQuery<Queries.SocialMediaLinksQuery>(graphql`
    query SocialMediaLinks {
      directus {
        knowledge_base_page {
          strings {
            contact_support_button
            contact_support_title
            discord_link_subtitle
            discord_link_title
            garry_link_subtitle
            garry_link_title
            reddit_link_subtitle
            reddit_link_title
            social_links_title
            languages_code {
              code
            }
          }
        }
      }
    }
  `)
  const socialLinkInformation = getEnglishStrings(data.directus.knowledge_base_page?.strings)
  return (
    <Flex
      sx={{
        width: '100vw',
        justifyContent: 'center',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          width: '100%',
          maxWidth: '100%',
          alignItems: 'center',
          fontFamily: 'IBM Plex Sans',
          pt: '8rem',
        }}
      >
        <Flex
          sx={{
            borderRadius: '2.1rem',
            backgroundColor: 'white10',
            width: '20rem',
            height: '4.2rem',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            sx={{
              color: 'white',
              fontWeight: 'bold',
              fontSize: '1.8rem',
              lineHeight: '2.3rem',
              letterSpacing: '0.375rem',
            }}
          >
            {socialLinkInformation?.social_links_title?.toUpperCase()}
          </Text>
        </Flex>
        <Flex
          sx={{
            gap: ['3.2rem', '2rem', '4rem'],
            px: ['1.6rem', '2.4rem', '2.4rem'],
            textAlign: 'center',
            marginTop: '5rem',
            flexDirection: ['column', 'row', 'row'],
            justifyContent: 'space-between',
          }}
        >
          <Flex
            data-testid="garry-link"
            sx={{
              cursor: 'pointer',
              flexDirection: 'column',
              width: '100%',
              maxWidth: '25rem',
              height: ['23rem', '25rem', '25rem'],
              alignItems: 'center',
              color: 'white50',
              transition: theme => `${get(theme, 'transitions.default')}`,
              ':focus-visible,button:focus-visible': {
                outline: '-webkit-focus-ring-color auto 1px',
              },
              ':hover': {
                '.arrow': {
                  transform: 'translateX(1rem)',
                  opacity: 1,
                },
                color: 'white',
              },
            }}
            onClick={() => document.getElementById('garry-toggle')?.click()}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                textDecoration: 'none',
                ':hover': {
                  '.garry-icon': {
                    transform: 'scale(1.1)',
                  },
                },
                '.garry-icon': {
                  transition: theme => `${get(theme, 'transitions.default')}`,
                },
              }}
            >
              <Svg
                svg={GarryIcon}
                className="garry-icon"
                descriptiveText="Link to Garry"
                sx={{ width: '10rem', height: '10rem' }}
              />
              <Flex
                sx={{
                  marginTop: '2.4rem',
                  marginBottom: '1.2rem',
                  marginLeft: '2.8rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text sx={{ fontSize: '2.8rem', fontWeight: 'bold' }}>
                  {socialLinkInformation?.garry_link_title}
                </Text>
                <Svg
                  svg={ArticlesArrow}
                  className="arrow"
                  sx={{
                    marginLeft: '1.6rem',
                    opacity: 0.25,
                    transition: theme => `${get(theme, 'transitions.default')}`,
                  }}
                />
              </Flex>
              <Text sx={{ fontSize: '1.8rem' }}>{socialLinkInformation?.garry_link_subtitle}</Text>
            </Flex>
          </Flex>
          <Flex
            data-testid="reddit-link"
            sx={{
              flexDirection: 'column',
              width: '100%',
              maxWidth: '25rem',
              height: '25rem',
              alignItems: 'center',
              ':hover': {
                '.articles-arrow': {
                  transform: 'translateX(1rem)',
                  opacity: 1,
                },
              },
              '.articles-arrow': {
                transition: theme => `${get(theme, 'transitions.default')}`,
              },
            }}
          >
            <ExternalLink href="https://www.reddit.com/r/windscribe" target="_blank">
              <Flex
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  textDecoration: 'none',
                  ':hover': {
                    '.reddit-icon': {
                      transform: 'scale(1.1)',
                    },
                  },
                  '.reddit-icon': {
                    transition: theme => `${get(theme, 'transitions.default')}`,
                  },
                }}
              >
                <Svg
                  svg={RedditIcon}
                  descriptiveText="Link to Reddit"
                  className="reddit-icon"
                  sx={{ width: '10rem', height: '10rem' }}
                />
                <Flex
                  sx={{
                    marginTop: '2.4rem',
                    marginBottom: '1.2rem',
                    marginLeft: '2.8rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text sx={{ fontSize: '2.8rem', fontWeight: 'bold' }}>
                    {socialLinkInformation?.reddit_link_title}
                  </Text>
                  <Svg
                    svg={ArticlesArrow}
                    className="articles-arrow"
                    sx={{
                      marginLeft: '1.6rem',
                      opacity: 0.25,
                      transition: theme => `${get(theme, 'transitions.default')}`,
                    }}
                  />
                </Flex>
                <Text sx={{ fontSize: '1.8rem' }}>
                  {socialLinkInformation?.reddit_link_subtitle}
                </Text>
              </Flex>
            </ExternalLink>
          </Flex>
          <Flex
            data-testid="discord-link"
            sx={{
              flexDirection: 'column',
              width: '100%',
              maxWidth: '25rem',
              height: '25rem',
              alignItems: 'center',
              ':hover': {
                '.articles-arrow': {
                  transform: 'translateX(1rem)',
                  opacity: 1,
                },
                '.discord-icon': {
                  transform: 'scale(1.1)',
                },
              },
              '.discord-icon': {
                transition: theme => `${get(theme, 'transitions.default')}`,
              },
            }}
          >
            <ExternalLink href="https://discord.com/invite/vpn" target="_blank">
              <Flex
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                <Svg
                  svg={DiscordIcon}
                  descriptiveText="Link to Discord"
                  className="discord-icon"
                  sx={{ width: '10rem', height: '10rem' }}
                />
                <Flex
                  sx={{
                    marginTop: '2.4rem',
                    marginBottom: '1.2rem',
                    marginLeft: '2.8rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text sx={{ fontSize: '2.8rem', fontWeight: 'bold' }}>
                    {socialLinkInformation?.discord_link_title}
                  </Text>
                  <Svg
                    svg={ArticlesArrow}
                    className="articles-arrow"
                    sx={{
                      marginLeft: '1.6rem',
                      opacity: 0.25,
                      transition: theme => `${get(theme, 'transitions.default')}`,
                    }}
                  />
                </Flex>
                <Text sx={{ fontSize: '1.8rem' }}>
                  {socialLinkInformation?.discord_link_subtitle}
                </Text>
              </Flex>
            </ExternalLink>
          </Flex>
        </Flex>
        <Text sx={{ fontSize: '4.8rem', fontWeight: 'bold', marginTop: '8.1rem' }}>
          {socialLinkInformation?.contact_support_title}
        </Text>
        <Flex
          sx={{ width: '100%', justifyContent: 'center', marginTop: '7rem', marginBottom: '10rem' }}
        >
          {/*
          TS-ignore the hacky approach of either handling the click via the button or the link
          depending on whether we want to open Garry or not. This can be improved in the future.
          @ts-ignore*/}
          <InternalLink
            focusSx={{
              ':focus': { outline: 'none' },
            }}
            {...(isPremiumUser ? { href: '/contact-support' } : {})}
          >
            <Flex sx={{ width: '100%', justifyContent: 'center' }}>
              <Button
                onClick={
                  isPremiumUser
                    ? undefined
                    : () => {
                        document.getElementById('garry-toggle')?.click()
                        ;(
                          document.querySelector(
                            '[data-testid="splash-screen-button"]',
                          ) as HTMLElement
                        )?.click()
                      }
                }
                data-testid="contact-support-button"
                sx={{
                  borderRadius: '3rem',
                  fontSize: '1.8rem',
                  color: 'periwinkle',
                  fontWeight: 'bold',
                  maxWidth: '25rem',
                  border: '0.2rem solid',
                  borderColor: 'periwinkle50',
                  backgroundColor: 'periwinkle25',
                  px: '5rem',
                  py: '1.8rem',
                  transition: theme => `${get(theme, 'transitions.default')}`,
                  cursor: 'pointer',
                  ':hover': {
                    borderColor: 'periwinkle',
                    backgroundColor: 'periwinkle',
                    color: 'bodyBG',
                  },
                }}
              >
                {socialLinkInformation?.contact_support_button}
              </Button>
            </Flex>
          </InternalLink>
        </Flex>
      </Flex>
    </Flex>
  )
}
