import React, { ReactElement, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import RobertCheckboxButton from 'images/robert-checkbox-button.svg'
import Svg from 'components/UI/SVG'

export default function RobertCheckbox({
  robertTitle,
  robertDescription,
}: {
  robertTitle: string | undefined
  robertDescription: string | undefined
}): ReactElement {
  const [robertEnabled, setRobertEnabled] = useState(false)
  return (
    <Flex
      sx={{
        width: '28rem',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2.4rem 2.2rem 2rem 2.2rem',
        borderRadius: '1.6rem',
        border: 'solid 0.2rem',
        borderColor: 'white10',
      }}
    >
      <Flex sx={{ gap: '1.6rem', marginBottom: '1.6rem' }}>
        <Flex
          onClick={() => setRobertEnabled(!robertEnabled)}
          sx={{
            width: '2.4rem',
            height: '2.4rem',
            borderRadius: '0.6rem',
            border: robertEnabled ? 'none' : 'solid 0.2rem',
            borderColor: robertEnabled ? 'none' : 'white10',
            backgroundColor: 'transparent',
          }}
        >
          {robertEnabled && (
            <Svg svg={RobertCheckboxButton} sx={{ width: '100%', height: '100%' }} />
          )}
        </Flex>
        <Text sx={{ fontSize: '1.6rem', color: 'white50', fontWeight: 'bold' }}>{robertTitle}</Text>
      </Flex>
      <Text sx={{ textAlign: 'center', fontSize: '1.4rem' }}>{robertDescription}</Text>
    </Flex>
  )
}
