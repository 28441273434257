import { Flex } from 'theme-ui'

export default function Inactive(): JSX.Element {
  return (
    <Flex sx={{ justifyContent: 'center' }}>
      <div
        sx={{
          width: '2.4rem',
          height: '2.4rem',
          backgroundColor: 'white10',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          sx={{ width: '0.4rem', height: '0.4rem', backgroundColor: 'white', borderRadius: '50%' }}
        />
      </div>
    </Flex>
  )
}
