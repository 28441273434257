import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import SimpleClients from 'images/simple-clients.png'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function SimpleClientsPage({
  data,
}: {
  data: Queries.SimpleClientsPageQuery
}): ReactElement {
  const simpleClientsData = getEnglishStrings(data.directus.simple_clients?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={simpleClientsData?.meta_description ?? ''}
      metaKeywords={simpleClientsData?.meta_keywords ?? []}
      title={simpleClientsData?.title ?? ''}
      subtitle={simpleClientsData?.subtitle ?? ''}
      featureImage={SimpleClients}
      summaryText={simpleClientsData?.summary_text ?? ''}
      gridItems={[
        {
          title: simpleClientsData?.set_up_in_seconds ?? '',
          description: simpleClientsData?.set_up_in_seconds_subtitle ?? '',
        },
        {
          title: simpleClientsData?.fully_featured ?? '',
          description: simpleClientsData?.fully_featured_subtitle ?? '',
        },
        {
          title: simpleClientsData?.anti_leak ?? '',
          description: simpleClientsData?.anti_leak_subtitle ?? '',
        },
        {
          title: simpleClientsData?.circumvent_censorship ?? '',
          description: simpleClientsData?.circumvent_censorship_subtitle ?? '',
        },
        {
          title: simpleClientsData?.custom_configurations ?? '',
          description: simpleClientsData?.custom_configurations_subtitle ?? '',
        },
        {
          title: simpleClientsData?.available_in_multiple ?? '',
          description: simpleClientsData?.available_in_multiple_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink sx={{ variant: 'buttons.neon' }} href={`/download?${queryParam}`}>
          {simpleClientsData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [simpleClientsData?.we_offer_privacy ?? ''],
        },
      ]}
    />
  )
}

export const data = graphql`
  query SimpleClientsPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      simple_clients {
        strings {
          languages_code {
            code
          }
          subtitle
          title
          anti_leak
          anti_leak_subtitle
          available_in_multiple
          available_in_multiple_subtitle
          circumvent_censorship
          circumvent_censorship_subtitle
          cta
          custom_configurations
          custom_configurations_subtitle
          fully_featured
          fully_featured_subtitle
          set_up_in_seconds
          set_up_in_seconds_subtitle
          summary_text
          we_offer_privacy
          meta_description
          meta_keywords
        }
      }
    }
  }
`
