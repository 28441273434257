import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import WireGuardDisclaimer from 'components/features-page/WireGuardDisclaimer'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'
import FlexibleConnectivityImage from 'images/flexible-connectivity.png'

export default function FlexibleConnectivity({
  data,
}: {
  data: Queries.FlexibleConnectivityPageQuery
}): ReactElement {
  const flexibleConnectivityData = getEnglishStrings(data.directus.flexible_connectivity?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()

  return (
    <FeaturePageSkeleton
      featureImage={FlexibleConnectivityImage}
      summaryText={flexibleConnectivityData?.summary_text ?? ''}
      title={flexibleConnectivityData?.title ?? ''}
      subtitle={flexibleConnectivityData?.subtitle ?? ''}
      gridItems={[
        {
          title: flexibleConnectivityData?.six_protocols ?? '',
          description: flexibleConnectivityData?.six_protocols_subtitle ?? '',
        },
        {
          title: flexibleConnectivityData?.wireguard ?? '',
          description: flexibleConnectivityData?.wireguard_subtitle ?? '',
        },
        {
          title: flexibleConnectivityData?.ikev2 ?? '',
          description: flexibleConnectivityData?.ikev2_subtitle ?? '',
        },
        {
          title: flexibleConnectivityData?.openvpn ?? '',
          description: flexibleConnectivityData?.openvpn_subtitle ?? '',
        },
        {
          title: flexibleConnectivityData?.stealth ?? '',
          description: flexibleConnectivityData?.stealth_subtitle ?? '',
        },
        {
          title: flexibleConnectivityData?.wstunnel ?? '',
          description: flexibleConnectivityData?.wstunnel_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {flexibleConnectivityData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [
            flexibleConnectivityData?.windscribe_offers ?? '',
            flexibleConnectivityData?.still_having ?? '',
          ],
        },
      ]}
      metaDescription={flexibleConnectivityData?.meta_description ?? ''}
      metaKeywords={flexibleConnectivityData?.meta_keywords}
    >
      <WireGuardDisclaimer />
    </FeaturePageSkeleton>
  )
}

export const data = graphql`
  query FlexibleConnectivityPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      flexible_connectivity {
        strings {
          languages_code {
            code
          }
          cta
          ikev2
          ikev2_subtitle
          openvpn
          six_protocols
          openvpn_subtitle
          six_protocols_subtitle
          stealth
          stealth_subtitle
          still_having
          subtitle
          summary_text
          title
          windscribe_offers
          wireguard
          wireguard_subtitle
          wstunnel
          wstunnel_subtitle
          meta_description
          meta_keywords
        }
      }
    }
  }
`
