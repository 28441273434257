import Input from 'components/UI/Input'
import Svg from 'components/UI/SVG'
import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import GenerateUsernameIcon from 'images/generate-username-icon.svg'
import PasswordHiddenIcon from 'images/password-hidden-icon.svg'

export default function SignupPageTextInput({
  username,
  password,
  confirmPassword,
  email,
  emailDescription,
}: {
  username: string | undefined
  password: string | undefined
  confirmPassword: string | undefined
  email: string | undefined
  emailDescription: string | undefined
}): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        maxWidth: '48rem',
        alignItems: 'center',
        color: 'white50',
      }}
    >
      <Flex sx={{ width: '100%', flexDirection: 'column', marginBottom: '4.2rem' }}>
        <label
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
            px: '1.6rem',
            fontSize: '1.6rem',
            fontWeight: 'bold',
          }}
        >
          {username}
        </label>
        <Input
          sx={{ width: '100%', maxWidth: '48rem', marginTop: '0.8rem' }}
          rightContent={<Svg svg={GenerateUsernameIcon} />}
        />
      </Flex>

      <Flex sx={{ width: '100%', gap: '2.2rem', marginBottom: '4.2rem' }}>
        <Flex sx={{ flexDirection: 'column', width: '100%' }}>
          <label
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
              px: '1.6rem',
              fontSize: '1.6rem',
              fontWeight: 'bold',
            }}
          >
            {password}
          </label>
          <Input
            sx={{ width: '100%', maxWidth: '22.8rem', marginTop: '0.8rem' }}
            rightContent={<Svg svg={GenerateUsernameIcon} />}
          />
        </Flex>
        <Flex sx={{ flexDirection: 'column', width: '100%' }}>
          <label
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
              px: '1.6rem',
              fontSize: '1.6rem',
              fontWeight: 'bold',
            }}
          >
            {confirmPassword}
          </label>
          <Input
            sx={{ width: '100%', maxWidth: '22.8rem', marginTop: '0.8rem' }}
            rightContent={<Svg svg={PasswordHiddenIcon} />}
          />
        </Flex>
      </Flex>
      <Flex sx={{ width: '100%', flexDirection: 'column', marginBottom: '4.2rem' }}>
        <label
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
            px: '1.6rem',
            fontSize: '1.6rem',
            fontWeight: 'bold',
          }}
        >
          {email}
        </label>
        <Input
          sx={{ width: '100%', maxWidth: '48rem', my: '0.8rem' }}
          rightContent={<Text sx={{ fontSize: '1.4rem', color: 'white50' }}>+8GB/m</Text>}
        />
        <Text sx={{ width: '100%', fontSize: '1.4rem', px: '1.6rem' }}>{emailDescription}</Text>
      </Flex>
    </Flex>
  )
}
