import md5 from 'md5'
import { ApiResponse } from 'network/api-response'
import transformProductionApiUrlTld from 'utils/transformProductionApiUrlTld'

const notReallyASecret = '952b4412f002315aa50751032fcaab03'

export async function requestResetPassword(email: string): Promise<boolean> {
  const time = Date.now()

  const body = {
    time,
    client_auth_hash: md5(`${notReallyASecret}${time}`),
    email,
  }

  const url = `${transformProductionApiUrlTld(
    process.env.GATSBY_WINDSCRIBE_API_BASE_URL ?? '',
  )}/PasswordRecovery`

  const untypedResponse = (await fetch(url, {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`,
    },
    body: JSON.stringify(body),
  })) as ApiResponse

  return untypedResponse.ok
}
