import React, { FormEvent, ReactElement, useCallback, useEffect, useState } from 'react'
import { Button, Flex } from 'theme-ui'
import Svg from 'components/UI/SVG'
import ContactSupportGarry from 'images/contact-support-garry.svg'
import { SubmitSupportTicketRequestBody, SupportCategory, wsApi } from 'redux/api/wsApi'
import AccountAdministrationRadioButtons from './AccountAdministrationRadioButtons'
import ContactSupportTextInput from './ContactSupportTextInput'
import SupportTopicDropdown from './SupportTopicDropdown'
import TechnicalIssuesDropdown from './TechnicalIssuesDropdown'
import TechnicalIssuesRadioButtons from './TechnicalIssuesRadioButtons'
import SupportPageSubmission from './SupportPageSubmission'
import { getCookieByName } from 'utils/cookie'
import { useAppSelector } from 'store/hooks'
import getEnglishStrings from 'utils/getEnglishStrings'
import { graphql, useStaticQuery } from 'gatsby'

type SessionTokenState = 'pending' | 'absent' | 'present'

export default function ContactSupportForm(): ReactElement {
  const [sessionTokenState, setSessionTokenState] = useState<SessionTokenState>('pending')
  const sessionData = useAppSelector(state => state.session?.data)
  const sessionError = useAppSelector(state => state.session?.error)
  const isPremiumUser = useAppSelector(state => state.session?.data?.is_premium === 1)

  const shouldShowLoadingSpinner =
    sessionTokenState === 'pending' ||
    (sessionTokenState === 'present' && !sessionData && !sessionError)
  const shouldShowContactSupportForm = isPremiumUser && !shouldShowLoadingSpinner
  const shouldShowAskGarryButton =
    sessionTokenState === 'absent' || (!isPremiumUser && !shouldShowLoadingSpinner)

  useEffect(() => {
    setSessionTokenState(!!getCookieByName('ws_session_auth_hash') ? 'present' : 'absent')
  }, [])

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        position: 'relative',
        width: ['100%', '100%', '133rem'],
        height: '100%',
        alignItems: 'center',
        marginTop: ['11.8rem', '14.8rem'],
      }}
    >
      <Svg
        svg={ContactSupportGarry}
        sx={{
          position: 'absolute',
          top: ['-7.5rem', '-10rem'],
          width: ['15rem', '20rem'],
          height: ['15rem', '20rem'],
        }}
      />
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          backgroundColor: 'bodyBG',
          alignItems: 'center',
          borderRadius: ['0rem', '3.2rem', '3.2rem'],
          py: ['12rem', '18rem'],
        }}
      >
        {shouldShowLoadingSpinner && <LoadingView />}
        {shouldShowContactSupportForm && <ContactHumanSupportForm />}
        {shouldShowAskGarryButton && <AskGarryButton />}
      </Flex>
    </Flex>
  )
}

function ContactHumanSupportForm(): ReactElement {
  const [topicDropdownSelection, setTopicDropdownSelection] = useState<SupportCategory>(
    SupportCategory.NOT_SELECTED,
  )
  const [accountAdministrationRadioSelection, setAccountAdministrationRadioSelection] = useState('')
  const [technicalIssuesRadioSelection, setTechnicalIssuesRadioSelection] = useState('')
  const [osDropdownSelection, setOsDropdownSelection] = useState('')
  const [subjectText, setSubjectText] = useState('')
  const [problemText, setProblemText] = useState('')
  const [emailText, setEmailText] = useState('')
  const [usernameText, setUsernameText] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [ticketNumber, setTicketNumber] = useState<number | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState('')
  const [isRequestInFlight, setIsRequestInFlight] = useState(false)

  const [sendSupportTicket, sendSupportTicketResponse] = wsApi.useSendSupportTicketMutation()

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>): Promise<void> => {
      const sessionToken = getCookieByName('ws_session_auth_hash')

      event.preventDefault()
      setIsRequestInFlight(true)
      const body: SubmitSupportTicketRequestBody = {
        support_email: emailText,
        support_message: problemText,
        support_subject: subjectText,
        support_category: topicDropdownSelection,
        session_auth_hash: sessionToken,
      }
      if (usernameText !== '') {
        body['support_name'] = usernameText
      }
      // Gotcha for testing: the "support_message" has to have more than one word in it for the API to accept this

      sendSupportTicket(body)
    },
    [emailText, problemText, subjectText, topicDropdownSelection, usernameText],
  )

  useEffect(() => {
    if (sendSupportTicketResponse.fulfilledTimeStamp) {
      setIsSubmitted(true)
      setTicketNumber(sendSupportTicketResponse.data?.data?.ticket_id)
      setErrorMessage(sendSupportTicketResponse.data?.errorDescription ?? '')
      setIsRequestInFlight(false)
    }
  }, [sendSupportTicketResponse])

  if (isSubmitted) {
    return <SupportPageSubmission ticketNumber={ticketNumber} error={errorMessage} />
  } else {
    return (
      <form
        onSubmit={handleSubmit}
        sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <SupportTopicDropdown
          topicDropdownSelection={topicDropdownSelection}
          setTopicDropdownSelection={setTopicDropdownSelection}
          setAccountAdministrationRadioSelection={setAccountAdministrationRadioSelection}
          setTechnicalIssuesRadioSelection={setTechnicalIssuesRadioSelection}
          setOsDropdownSelection={setOsDropdownSelection}
          setSubjectText={setSubjectText}
          setProblemText={setProblemText}
          setEmailText={setEmailText}
          setUsernameText={setUsernameText}
        />
        {topicDropdownSelection == SupportCategory.ACCOUNT_ISSUE && (
          <Flex sx={{ flexDirection: 'column', alignItems: 'center', px: ['1.6rem', 0, 0] }}>
            <label
              htmlFor="account-administration-radio"
              sx={{
                margin: ['4.8rem 0 2.4rem 0', '8rem 0 4.8rem 0'],
                fontSize: ['2.1rem', '2.4rem'],
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {"What's the problem exactly?"}
            </label>
            <AccountAdministrationRadioButtons
              setAccountAdministrationRadioSelection={setAccountAdministrationRadioSelection}
            />
          </Flex>
        )}
        {topicDropdownSelection == SupportCategory.TECHNICAL_PROBLEM && (
          <Flex sx={{ flexDirection: 'column', alignItems: 'center', px: ['1.6rem', '8rem', 0] }}>
            <label
              htmlFor="technical-issues-radio"
              sx={{
                margin: ['4.8rem 0 2.4rem 0', '8rem 0 4.8rem 0'],
                fontSize: ['2.1rem', '2.4rem'],
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              For which app?
            </label>
            <TechnicalIssuesRadioButtons
              setTechnicalIssuesRadioSelection={setTechnicalIssuesRadioSelection}
              technicalIssuesdRadioSelection={technicalIssuesRadioSelection}
            />
          </Flex>
        )}
        {technicalIssuesRadioSelection && (
          <TechnicalIssuesDropdown
            osDropdownSelection={osDropdownSelection}
            setOsDropdownSelection={setOsDropdownSelection}
            technicalIssuesRadioSelection={technicalIssuesRadioSelection}
          />
        )}
        {(accountAdministrationRadioSelection ||
          osDropdownSelection ||
          topicDropdownSelection == SupportCategory.SALES_QUESTION ||
          topicDropdownSelection == SupportCategory.FEEDBACK) && (
          <ContactSupportTextInput
            subjectText={subjectText}
            setSubjectText={setSubjectText}
            problemText={problemText}
            setProblemText={setProblemText}
            emailText={emailText}
            setEmailText={setEmailText}
            usernameText={usernameText}
            setUsernameText={setUsernameText}
            isRequestInFlight={isRequestInFlight}
            setIsRequestInFlight={setIsRequestInFlight}
          />
        )}
      </form>
    )
  }
}

function AskGarryButton(): ReactElement {
  const data = useStaticQuery<Queries.AskGarryButtonQuery>(graphql`
    query AskGarryButton {
      directus {
        contact_page {
          strings {
            languages_code {
              code
            }
            cta_ask_garry
          }
        }
      }
    }
  `)
  const englishStrings = getEnglishStrings(data?.directus?.contact_page?.strings)
  return (
    <Button
      data-testid="ask-garry-button"
      variant="neonGreen"
      onClick={() => {
        document.getElementById('garry-toggle')?.click()
        ;(document.querySelector('[data-testid="splash-screen-button"]') as HTMLElement)?.click()
      }}
    >
      {englishStrings?.cta_ask_garry}
    </Button>
  )
}

function LoadingView(): ReactElement {
  return (
    <Flex
      sx={{
        '@keyframes pulse': {
          '0%': {
            opacity: 0.05,
          },
          '30%': {
            opacity: 0.15,
          },
          '70%': {
            opacity: 0.15,
          },
          '100%': {
            opacity: 0.05,
          },
        },
        gap: '4.8rem',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          backgroundColor: 'white',
          maxWidth: '100%',
          borderRadius: '3.9rem',
          animation: 'pulse 1s infinite',
        },
      }}
    >
      <Flex
        sx={{
          width: '33.5rem',
          height: '3.3rem',
        }}
      ></Flex>
      <Flex
        sx={{
          width: '22.7rem',
          height: '4.8rem',
        }}
      ></Flex>
    </Flex>
  )
}
