import { Link } from 'gatsby'
import React, { forwardRef, ReactElement, ReactNode } from 'react'
import { get, LinkProps, ThemeUIStyleObject } from 'theme-ui'

export interface InternalLinkProps extends LinkProps {
  children: ReactNode
  href: string
  focusSx?: ThemeUIStyleObject
  sxx?: ThemeUIStyleObject
  dataTestId?: string
}

export default forwardRef<HTMLAnchorElement, InternalLinkProps>(function InternalLink(
  props: InternalLinkProps,
  ref,
): ReactElement {
  const { children, href, focusSx, sxx, dataTestId, ...rest } = props

  return (
    <Link
      to={href}
      // The ref type is incompatible with `forwardRef`.
      // This is a Gatsby issue: https://github.com/gatsbyjs/gatsby/issues/34325
      // @ts-ignore
      ref={ref}
      sx={{
        fontFamily: 'IBM Plex Sans',
        textDecoration: 'none',
        display: 'flex',
        width: '100%',
        color: 'white50',
        transition: theme => `${get(theme, 'transitions.default')}`,
        ':focus-visible': {
          outline: '-webkit-focus-ring-color auto 1px',
          ...focusSx,
        },
        ':hover': {
          color: 'white',
        },
        ...sxx,
      }}
      data-testid={dataTestId}
      {...rest}
    >
      {children}
    </Link>
  )
})
