import React, { Dispatch, ReactElement, SetStateAction, useEffect } from 'react'
import { Flex } from 'theme-ui'
import Select from 'components/UI/Select'
import { getPlatformInformation } from 'utils/getPlatformInformation'

export default function TechnicalIssuesDropdown({
  osDropdownSelection,
  setOsDropdownSelection,
  technicalIssuesRadioSelection,
}: {
  osDropdownSelection: string
  setOsDropdownSelection: Dispatch<SetStateAction<string>>
  technicalIssuesRadioSelection: string
}): ReactElement {
  const { platformName } = getPlatformInformation()

  useEffect(() => {
    if (platformName) {
      setOsDropdownSelection(platformName)
    }
    // we only want to run this on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex
      data-testid="technical-issues-dropdown"
      sx={{
        width: '100%',
        maxWidth: '48rem',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: ['4.8rem', '8rem'],
        px: ['1.6rem', 0, 0],
      }}
    >
      <label
        htmlFor="os-dropdown"
        sx={{
          width: '100%',
          marginBottom: ['2.4rem', '4.8rem'],
          fontSize: ['2.1rem', '2.4rem'],
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {"What's your operating system?"}
      </label>
      <Flex
        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%', px: ['1.6rem', 0, 0] }}
      >
        <Select
          id="os-dropdown"
          data-testid="os-dropdown"
          onChange={event => setOsDropdownSelection(event.target.value)}
          sx={{
            width: '100%',
            maxWidth: '48rem',
          }}
        >
          <option value="">Choose one</option>
          {['chrome', 'firefox', 'desktop', 'other'].includes(technicalIssuesRadioSelection) && (
            <>
              <option selected={osDropdownSelection === 'mac'} value="mac">
                Mac
              </option>
              <option selected={osDropdownSelection === 'windows'} value="windows">
                Windows
              </option>
              <option selected={osDropdownSelection === 'linux'} value="linux">
                Linux
              </option>
            </>
          )}

          {technicalIssuesRadioSelection !== 'desktop' && (
            <>
              <option selected={osDropdownSelection === 'ios'} value="ios">
                IOS
              </option>
              <option selected={osDropdownSelection === 'android'} value="android">
                Android
              </option>
            </>
          )}

          <option selected={osDropdownSelection === 'other'} value="other">
            Other
          </option>
        </Select>
      </Flex>
    </Flex>
  )
}
