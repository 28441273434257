import Svg from 'components/UI/SVG'
import WatchAVideoFeature from 'components/features-page/WatchAVideoFeature'
import { ReactElement, ReactNode } from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'

export default function FeatureImage({
  firstSubtitle,
  title,
  secondSubtitle,
  icon,
  children,
  category,
  categoryColour,
  videoButton,
  imageSide,
  imageStyles,
}: {
  imageSide: 'left' | 'right'
  title: string
  category: string
  firstSubtitle: string
  secondSubtitle?: string
  icon: React.ElementType
  categoryColour: string
  children: ReactNode
  videoButton?: {
    text: string
    href: string
  }
  imageStyles?: ThemeUIStyleObject
}): ReactElement {
  const alignItems = imageSide === 'right' ? 'flex-start' : 'end'
  const textAlign = imageSide === 'left' ? 'end' : 'start'

  return (
    <Flex
      sx={{
        flexDirection: [
          'column-reverse',
          'column-reverse',
          imageSide === 'left' ? 'row-reverse' : 'row',
        ],
        '& > *': {
          flex: '1 1 0px',
        },
        maxWidth: theme => `calc(${theme.sizes?.['maxContentWidth']} + 0.8rem)`,
        alignItems: ['center', 'center', 'normal'],
        gap: '2.4rem',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: ['center', 'center', alignItems],
          justifyContent: 'center',
          minWidth: ['32rem', '60rem', '32rem'],
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            gap: '2.4rem',
            flexDirection: ['column', 'row', imageSide === 'left' ? 'row-reverse' : 'row'],
          }}
        >
          <Svg svg={icon} />
          <Text
            sx={{
              color: categoryColour,
              fontSize: ['2.4rem', '2.8rem', '2.8rem'],
              fontWeight: 'bold',
              textAlign: imageSide === 'left' ? 'center' : 'end',
            }}
          >
            {category}
          </Text>
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: ['center', 'center', imageSide === 'left' ? 'end' : 'start'],
          }}
        >
          <Text
            sx={{
              fontSize: ['2.4rem', '2.8rem', '2.8rem'],
              fontWeight: 'bold',
              textAlign: ['center', 'center', textAlign],
              my: ['1.6rem', '2.4rem', '2.4rem'],
              maxWidth: ['70%', '60%', '100%'],
            }}
          >
            {title}
          </Text>
          <Flex
            sx={{
              flexDirection: 'column',
              gap: '2.4rem',
              alignItems: ['center', 'center', textAlign],
            }}
          >
            <Text
              sx={{
                color: 'white50',
                textAlign: ['center', 'center', textAlign],
                maxWidth: ['75%', '65%', '90%'],
              }}
            >
              {firstSubtitle}
            </Text>
            {secondSubtitle && (
              <Text
                sx={{
                  color: 'white50',
                  textAlign: ['center', 'center', textAlign],
                  maxWidth: ['75%', '65%', '90%'],
                }}
              >
                {secondSubtitle}
              </Text>
            )}
          </Flex>
        </Flex>
        {videoButton?.text && (
          <WatchAVideoFeature
            colour={categoryColour}
            text={videoButton.text}
            href={videoButton.href}
          />
        )}
      </Flex>
      <Flex sx={imageStyles}>{children}</Flex>
    </Flex>
  )
}
