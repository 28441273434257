/**
 * Adds a blurred letterboxing effect to `<img>` tags in the given document.
 */

export default function addBlurredLetterboxingToImages(doc: Document): void {
  const imageTags = doc.querySelectorAll('img')

  if (imageTags.length !== 0) {
    imageTags.forEach(imageTag => {
      imageTag.classList.add('image-style')
      const src = imageTag.getAttribute('src')
      const letterboxWrapper = doc.createElement('div')
      letterboxWrapper?.classList?.add('article-image')
      const backdropContainer = doc.createElement('div')
      backdropContainer?.classList?.add('backdrop-container')
      backdropContainer.appendChild(imageTag.cloneNode(true))
      letterboxWrapper.appendChild(backdropContainer)
      const imageContainer = doc.createElement('div')
      imageContainer.style.backgroundImage = `url(${src})`
      imageContainer?.classList?.add('image-container')
      backdropContainer.append(imageContainer)
      imageTag.parentNode?.replaceChild(letterboxWrapper, imageTag)
    })
  }
}
