import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import UseForFree from 'images/use-for-free.png'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function UseForFreePage({
  data,
}: {
  data: Queries.UseForFreePageQuery
}): ReactElement {
  const useForFreeData = getEnglishStrings(data.directus.use_for_free?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={useForFreeData?.meta_description ?? ''}
      metaKeywords={useForFreeData?.meta_keywords ?? []}
      title={useForFreeData?.title ?? ''}
      subtitle={useForFreeData?.subtitle ?? ''}
      featureImage={UseForFree}
      summaryText={useForFreeData?.summary_text ?? ''}
      gridItems={[
        {
          title: useForFreeData?.ten_gig_free ?? '',
          description: useForFreeData?.ten_gig_free_subtitle ?? '',
        },
        {
          title: useForFreeData?.unlimited_connections ?? '',
          description: useForFreeData?.unlimited_connection_subtitle ?? '',
        },
        {
          title: useForFreeData?.all_app ?? '',
          description: useForFreeData?.all_app_subtitle ?? '',
        },
        {
          title: useForFreeData?.servers_in ?? '',
          description: useForFreeData?.servers_in_subtitle ?? '',
        },
        {
          title: useForFreeData?.emails_optional ?? '',
          description: useForFreeData?.emails_optional_subtitle ?? '',
        },
        {
          title: useForFreeData?.affordable_plans ?? '',
          description: useForFreeData?.affordable_plans_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {useForFreeData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [useForFreeData?.get_an_extra ?? '', useForFreeData?.remember_the_vpn ?? ''],
        },
      ]}
    />
  )
}

export const data = graphql`
  query UseForFreePage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      use_for_free {
        strings {
          languages_code {
            code
          }
          title
          subtitle
          summary_text
          ten_gig_free
          ten_gig_free_subtitle
          unlimited_connection_subtitle
          unlimited_connections
          all_app
          all_app_subtitle
          servers_in
          servers_in_subtitle
          emails_optional
          emails_optional_subtitle
          affordable_plans
          affordable_plans_subtitle
          cta
          get_an_extra
          remember_the_vpn
          meta_description
          meta_keywords
        }
      }
    }
  }
`
