import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'

export default function TitleAndSubtitle({
  title,
  subtitle,
  updatedAt,
  dataTestId,
}: {
  title?: string | null
  subtitle?: string | null
  updatedAt?: string | null
  dataTestId?: string
}): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        px: ['3.2rem', '0', '0'],
        textAlign: 'center',
        gap: '1.6rem',
        maxWidth: '96rem',
      }}
    >
      <Text
        sx={{ fontSize: ['3.4rem', '4.8rem', '4.8rem'], fontWeight: 'bold' }}
        data-testid={dataTestId}
      >
        {title}
      </Text>
      {updatedAt && <Text sx={{ color: 'white50', fontSize: '1.6rem' }}>{updatedAt}</Text>}
      {subtitle && (
        <Flex sx={{ justifyContent: 'center' }}>
          <Text
            className="feature-subtitle"
            sx={{ fontSize: ['1.8rem', '2.1rem'], color: 'white50', maxWidth: '72.8rem' }}
          >
            {subtitle}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}
