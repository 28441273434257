import { graphql } from 'gatsby'
import PortForwarding from 'images/port-forwarding.png'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'

import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { Text } from 'theme-ui'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function PortForwardingPage({
  data,
}: {
  data: Queries.PortForwardingPageQuery
}): ReactElement {
  const portForwardingStrings = getEnglishStrings(data.directus.port_forwarding_page?.strings)
  const generalTranslations = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()

  return (
    <FeaturePageSkeleton
      metaDescription={portForwardingStrings?.meta_description ?? ''}
      metaKeywords={portForwardingStrings?.meta_keywords ?? []}
      title={portForwardingStrings?.title ?? ''}
      subtitle={portForwardingStrings?.subtitle ?? ''}
      featureImage={PortForwarding}
      summaryText={portForwardingStrings?.summary_text ?? ''}
      gridItems={[
        {
          title: portForwardingStrings?.connect_remotely ?? '',
          description: portForwardingStrings?.connect_remotely_subtitle ?? '',
        },
        {
          title: portForwardingStrings?.supported_devices ?? '',
          description: portForwardingStrings?.supported_devices_subtitle ?? '',
        },
        {
          title: portForwardingStrings?.your_connection_title ?? '',
          description: portForwardingStrings?.your_connection_subtitle ?? '',
        },
        {
          title: portForwardingStrings?.free_ddos_title ?? '',
          description: portForwardingStrings?.free_ddos_subtitle ?? '',
        },
        {
          title: portForwardingStrings?.your_external_ip_title ?? '',
          description: portForwardingStrings?.your_external_ip_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink
          href={`/knowledge-base/articles/setting-up-port-forwarding?${queryParam}`}
          sx={{ variant: 'buttons.neon' }}
        >
          <Text>{portForwardingStrings?.cta ?? ''}</Text>
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslations?.by_the_way ?? '',
          items: [portForwardingStrings?.all_pro_users ?? ''],
        },
      ]}
    />
  )
}

export const query = graphql`
  query PortForwardingPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      port_forwarding_page {
        strings {
          languages_code {
            code
          }
          title
          subtitle
          all_pro_users
          connect_remotely
          connect_remotely_subtitle
          cta
          free_ddos
          free_ddos_body
          free_ddos_subtitle
          free_ddos_title
          summary_text
          supported_devices
          supported_devices_subtitle
          your_connection_subtitle
          your_connection_title
          your_external_ip_subtitle
          your_external_ip_title
          meta_description
          meta_keywords
        }
      }
    }
  }
`
