import ContactSupportForm from 'components/contact-support-page/ContactSupportForm'
import StaticPageLayout from 'components/StaticPageLayout'
import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'
import { graphql } from 'gatsby'
import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'

export default function ContactSupportPage({
  data,
}: {
  data: Queries.ContactSupportQueryQuery
}): ReactElement {
  const dataStrings = getEnglishStrings(data.directus.contact_page?.strings)

  return (
    <StaticPageLayout
      title={`${dataStrings?.head_title} | Windscribe`}
      metaDescription={dataStrings?.meta_description}
      metaKeywords={dataStrings?.meta_keywords}
    >
      <Flex sx={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}>
        <TitleAndSubtitle title={dataStrings?.title} />
        <ContactSupportForm />
      </Flex>
    </StaticPageLayout>
  )
}

export const contactSupportQuery = graphql`
  query ContactSupportQuery {
    directus {
      contact_page {
        strings {
          languages_code {
            code
          }
          meta_description
          meta_keywords
          head_title
          title
          subtitle_fill_out_form
          cta_ask_garry
        }
      }
    }
  }
`
