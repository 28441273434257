import ExternalLink from 'components/UI/ExternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import StaticIp from 'images/static-ip.png'
import { ReactElement } from 'react'
import { Text } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function StaticIpsPage({
  data,
}: {
  data: Queries.StaticIpsPageQuery
}): ReactElement {
  const staticIpsData = getEnglishStrings(data.directus.static_ips?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={staticIpsData?.meta_description || ''}
      metaKeywords={staticIpsData?.meta_keywords || []}
      title={staticIpsData?.title || ''}
      subtitle={staticIpsData?.subtitle || ''}
      featureImage={StaticIp}
      summaryText={staticIpsData?.summary_text || ''}
      gridItems={[
        {
          title: staticIpsData?.data_center_ip || '',
          description: staticIpsData?.data_center_ip_text || '',
        },
        {
          title: staticIpsData?.residential_ip || '',
          description: staticIpsData?.residential_ip_text || '',
        },
        {
          title: staticIpsData?.permanent_port_forwarding || '',
          description: staticIpsData?.permanent_port_forwarding_subtitle || '',
        },
        {
          title: staticIpsData?.less_annoying || '',
          description: staticIpsData?.less_annoying_text || '',
        },
        {
          title: staticIpsData?.reduced_chance_of_blacklisting || '',
          description: staticIpsData?.reduced_chance_of_blacklisting_text || '',
        },
        {
          title: staticIpsData?.access_control || '',
          description: staticIpsData?.access_control_text || '',
        },
      ]}
      cta={
        <ExternalLink variant="buttons.neon" href={`/staticips?${queryParam}`}>
          <Text>{staticIpsData?.cta}</Text>
        </ExternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way || '',
          items: [staticIpsData?.we_dont_offer || '', staticIpsData?.you_must_have_unlimited || ''],
        },
      ]}
    />
  )
}

export const data = graphql`
  query StaticIpsPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      static_ips {
        strings {
          languages_code {
            code
          }
          id
          title
          subtitle
          summary_text
          data_center_ip
          data_center_ip_text
          residential_ip
          residential_ip_text
          permanent_port_forwarding
          permanent_port_forwarding_subtitle
          less_annoying
          less_annoying_text
          reduced_chance_of_blacklisting
          reduced_chance_of_blacklisting_text
          access_control
          access_control_text
          cta
          we_dont_offer
          you_must_have_unlimited
          meta_description
          meta_keywords
        }
      }
    }
  }
`
