import ExternalLink from 'components/UI/ExternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import ScribeForce from 'images/scribeforce.png'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function ScribeForcePage({
  data,
}: {
  data: Queries.ScribeForcePageQuery
}): ReactElement {
  const scribeForceData = getEnglishStrings(data.directus.scribe_force?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={scribeForceData?.meta_description ?? ''}
      metaKeywords={scribeForceData?.meta_keywords ?? []}
      title={scribeForceData?.title ?? ''}
      subtitle={scribeForceData?.subtitle ?? ''}
      featureImage={ScribeForce}
      summaryText={scribeForceData?.summary_text ?? ''}
      gridItems={[
        {
          title: scribeForceData?.full_pro_access ?? '',
          description: scribeForceData?.full_pro_access_subtitle ?? '',
        },
        {
          title: scribeForceData?.easy_billing ?? '',
          description: scribeForceData?.easy_billing_subtitle ?? '',
        },
        {
          title: scribeForceData?.simple_account ?? '',
          description: scribeForceData?.simple_account_subtitle ?? '',
        },
        {
          title: scribeForceData?.three_times_cheaper ?? '',
          description: scribeForceData?.three_times_cheaper_subtitle ?? '',
        },
        {
          title: scribeForceData?.share_static_ips ?? '',
          description: scribeForceData?.share_static_ips_subtitle ?? '',
        },
        {
          title: scribeForceData?.scale_usage ?? '',
          description: scribeForceData?.scale_usage_subtitle ?? '',
        },
      ]}
      cta={
        <ExternalLink
          sx={{
            variant: 'buttons.neon',
          }}
          href={`/scribeforce?${queryParam}`}
        >
          {scribeForceData?.cta}
        </ExternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [scribeForceData?.you_need_to_purchase ?? ''],
        },
      ]}
    />
  )
}

export const data = graphql`
  query ScribeForcePage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      scribe_force {
        strings {
          languages_code {
            code
          }
          cta
          title
          subtitle
          summary_text
          three_times_cheaper
          three_times_cheaper_subtitle
          you_need_to_purchase
          full_pro_access
          full_pro_access_subtitle
          easy_billing
          easy_billing_subtitle
          simple_account
          simple_account_subtitle
          share_static_ips
          share_static_ips_subtitle
          scale_usage
          scale_usage_subtitle
          meta_description
          meta_keywords
        }
      }
    }
  }
`
