import React, { Dispatch, ReactElement, SetStateAction } from 'react'
import ContactSupportRadioButton from './ContactSupportRadioButton'

export default function AccountAdministrationRadioButtons({
  setAccountAdministrationRadioSelection,
}: {
  setAccountAdministrationRadioSelection: Dispatch<SetStateAction<string>>
}): ReactElement {
  const radioContents = [
    {
      id: 'billing-issue-radio',
      value: 'billing-issue',
      text: 'Billing Issue',
    },
    {
      id: 'other-radio',
      value: 'other',
      text: 'Other',
    },
  ]
  return (
    <fieldset
      id="account-administration-radio"
      name="account-administration-radio"
      data-testid="account-administration-radio"
      sx={{
        border: 'none',
        display: 'flex',
        gap: ['1.4rem', '2.4rem'],
        fontSize: ['1.8rem', '1.6rem'],
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      {radioContents.map(radioContent => {
        return (
          <ContactSupportRadioButton
            key={radioContent.value}
            name={'account-administration-radio'}
            id={radioContent.id}
            value={radioContent.value}
            text={radioContent.text}
            setRadioSelection={setAccountAdministrationRadioSelection}
          />
        )
      })}
    </fieldset>
  )
}
