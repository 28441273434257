import { useLocation } from '@reach/router'
import Footer from 'components/Footer'
import ExternalLink from 'components/UI/ExternalLink'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundPattern from 'images/bg-pttrn.png'
import WindscribeLogoFull from 'images/windscribe-logo-full.svg'
import WindscribeLogoMini from 'images/windscribe-logo-mini.svg'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useAppDispatch } from 'store/hooks'
import { getSessionData } from 'store/session'
import { Flex, Text, get } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import InternalLink from './UI/InternalLink'
import Svg from './UI/SVG'
import ToggleNavigationMenuTray from './knowledge-base/navigation-bar/ToggleNavigationMenuTray'

export interface StaticPageNavMenuLink {
  text: string
  href: string
  icon: React.ElementType
}

const headerWidth = '124.8rem'
export const headerPaddingX = '2.4rem'
export const headerPaddingXMobile = '1.6rem'

export default function StaticPageLayout({
  children,
  title,
  metaDescription,
  metaKeywords,
  ctaQueryParams,
}: {
  children?: ReactNode
  title: string
  metaDescription?: string
  metaKeywords?: string[]
  ctaQueryParams?: string
}): ReactElement {
  const dispatch = useAppDispatch()
  const [isAtTheTop, setIsAtTheTop] = React.useState(true)
  const location = useLocation()

  useEffect(() => {
    dispatch(getSessionData())
  }, [dispatch])

  useEffect(() => {
    const handleScroll = () => {
      const isTop = window.scrollY === 0
      setIsAtTheTop(isTop)
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const headerLinksQueryResult = useStaticQuery<Queries.HeaderLinksQuery>(graphql`
    query HeaderLinks {
      directus {
        header_links {
          slug
          internal
          strings {
            name
            languages_code {
              code
            }
          }
        }
        seo {
          description
          keywords
        }
      }
    }
  `)
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta httpEquiv="x-ua-compatible" content="ie=edge"></meta>
        <meta
          name="keywords"
          content={
            !!metaKeywords?.join()
              ? metaKeywords?.join()
              : headerLinksQueryResult.directus.seo?.keywords
          }
        />
        <meta
          name="description"
          content={metaDescription ?? headerLinksQueryResult.directus.seo?.description}
        />
        <script type="text/javascript">
          {`
    var _paq = _paq || [];
    // tracker methods like "setCustomDimension" should be called before "trackPageView"
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function() {
        var u="https://stats.windscribe.com/";
        _paq.push(['setTrackerUrl', u+'piwik.php']);
        _paq.push(['setSiteId', '5']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);
    })();
          `}
        </script>
        {/* we have lots of different domains/subdomains (windscribe.net, translation subdomains, etc)
        so we set the main domain to the root domain (windscribe.com in production for example) to 
        avoid duplicate content being indexed */}
        <link rel="canonical" href={`${process.env.GATSBY_WEBSITE_BASE_URL}${location.pathname}`} />
        <link rel="icon shortcut" href="/icons/ws-favicon.png" type="image/png" />
      </Helmet>
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'absolute',
          color: 'white',
          minHeight: '100%',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <TransparencyLayer />
        <SquareBackground />
        <Header
          headerLinksQuery={headerLinksQueryResult}
          ctaQueryParams={ctaQueryParams}
          isAtTheTop={isAtTheTop}
        />
        <ContentBlock>{children}</ContentBlock>
        <Footer />
      </Flex>
    </>
  )
}

function SquareBackground(): ReactElement {
  return (
    <Flex
      sx={{
        width: '100vw',
        height: '33.7rem',
        position: 'absolute',
        backgroundImage: `url(${BackgroundPattern})`,
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'left top',
        objectFit: 'scale-down',
      }}
    ></Flex>
  )
}

function TransparencyLayer(): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        height: '29.7rem',
        position: 'absolute',
        background: ({ colors }): string =>
          `linear-gradient(to bottom, ${colors?.midnight}, ${colors?.background})`,
      }}
    ></Flex>
  )
}

function Header({
  headerLinksQuery,
  ctaQueryParams,
  isAtTheTop,
}: {
  headerLinksQuery: Queries.HeaderLinksQuery
  ctaQueryParams?: string
  isAtTheTop?: boolean
}): ReactElement {
  const location = useLocation()
  const headerLinks = headerLinksQuery.directus.header_links
  const [isMobileNavOpen, setIsMobileNavMenuOpen] = useState(false)

  return (
    <Flex
      sx={{
        alignItems: 'center',
        width: '100%',
        fontFamily: 'IBM Plex Sans',
        fontWeight: 'bold',
        justifyContent: 'center',
        position: 'sticky',
        top: '0px',
        isolation: 'isolate',
        zIndex: 3,
        boxShadow: ({ colors }): string => (isAtTheTop ? '' : `0 2px 0 0 ${colors?.white05}`),
        backdropFilter: isAtTheTop || isMobileNavOpen ? '' : 'blur(8px)',
        backgroundColor: 'rgba(255 255 255, 0.6)',
      }}
    >
      <Flex
        sx={{
          px: [headerPaddingXMobile, headerPaddingX],
          width: '100%',
          justifyContent: 'space-between',
          maxWidth: [
            `calc(${headerWidth} + ${headerPaddingXMobile} * 2)`,
            `calc(${headerWidth} + ${headerPaddingX} * 2)`,
          ],
          alignItems: 'center',
          py: '1.6rem',
        }}
      >
        <ExternalLink data-testid="header-logo" href="/" sxx={{ width: 'auto' }}>
          <Svg
            svg={WindscribeLogoFull}
            sx={{
              width: '17rem',
              height: '2.6rem',
              display: ['none', 'flex', 'flex'],
            }}
          />
          <Svg
            svg={WindscribeLogoMini}
            sx={{
              display: ['flex', 'none', 'none'],
              width: '4.8rem',
              height: '4.8rem',
            }}
          />
        </ExternalLink>

        <Flex sx={{ alignItems: 'center' }}>
          <Flex
            sx={{
              display: ['none', 'none', 'flex'],
              gap: '3rem',
              marginRight: '3rem',
              fontSize: '1.8rem',
            }}
          >
            {headerLinks?.map(headerLink => {
              const headerLinkString = getEnglishStrings(headerLink?.strings)

              const campaignParams = ctaQueryParams ? `?${ctaQueryParams}` : ''

              const modifiedHeaderLink = headerLink?.slug?.includes('/upgrade')
                ? `${headerLink.slug}${campaignParams}`
                : headerLink?.slug

              if (headerLink?.internal) {
                return (
                  <Flex key={headerLink?.slug}>
                    <InternalLink href={modifiedHeaderLink ?? ''}>
                      <Text
                        key={headerLink?.slug}
                        data-testid={`header-link-${headerLink?.slug}`}
                        sx={{
                          color: location.pathname === headerLink?.slug ? 'white' : 'white50',
                          width: '100%',
                          transition: theme => `${get(theme, 'transitions.default')}`,
                          ':hover': {
                            color: 'white',
                          },
                        }}
                      >
                        {headerLinkString?.name}
                      </Text>
                    </InternalLink>
                  </Flex>
                )
              }

              return (
                <Flex key={headerLink?.slug}>
                  <ExternalLink href={modifiedHeaderLink ?? ''}>
                    <Text
                      key={headerLink?.slug}
                      data-testid={`header-link-${headerLink?.slug}`}
                      sx={{
                        color: location.pathname === (headerLink?.slug || '') ? 'white' : 'white50',
                        width: '100%',
                        transition: theme => `${get(theme, 'transitions.default')}`,
                        ':hover': {
                          color: 'white',
                        },
                      }}
                    >
                      {headerLinkString?.name}
                    </Text>
                  </ExternalLink>
                </Flex>
              )
            })}
          </Flex>
          <Flex
            sx={{
              display: ['none', 'flex'],
            }}
          >
            <GetWindscribe ctaQueryParams={ctaQueryParams} />
          </Flex>
          <Flex sx={{ display: ['flex', 'flex', 'none'] }}>
            <ToggleNavigationMenuTray
              headerLinks={headerLinks}
              isMobileNavOpen={isMobileNavOpen}
              setIsMobileNavMenuOpen={setIsMobileNavMenuOpen}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export function ContentBlock({ children }: { children: ReactNode }): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        zIndex: 1,
        width: '100%',
        maxWidth: [
          `calc(${headerWidth} + ${headerPaddingXMobile} * 2)`,
          `calc(${headerWidth} + ${headerPaddingX} * 2)`,
        ],
        alignItems: 'center',
        flex: 1,
        justifyContent: 'flex-start',
        px: [headerPaddingXMobile, headerPaddingX],
      }}
    >
      {children}
    </Flex>
  )
}

export function GetWindscribe({ ctaQueryParams }: { ctaQueryParams?: string }): ReactElement {
  return (
    <InternalLink
      href={`/download${ctaQueryParams ? `?${ctaQueryParams}` : ''}`}
      sxx={{
        width: 'auto',
        variant: 'buttons.neon',
      }}
    >
      <Text data-testid="header-link-/download">Get Windscribe</Text>
    </InternalLink>
  )
}
