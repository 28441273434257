import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import ReactMarkdown from 'react-markdown'
import { Flex, Text } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function Firewall({ data }: { data: Queries.FirewallPageQuery }): ReactElement {
  const firewallData = getEnglishStrings(data.directus.firewall_page?.strings)
  const queryParam = useFeaturePageSlug()

  return (
    <FeaturePageSkeleton
      title={firewallData?.title ?? ''}
      subtitle={firewallData?.subtitle ?? ''}
      summaryText={firewallData?.summary_text ?? ''}
      gridItems={[
        {
          title: firewallData?.blocks_all_leaks ?? '',
          description: firewallData?.blocks_all_leaks_subtitle ?? '',
        },
        {
          title: firewallData?.feature_availability ?? '',
          description: firewallData?.feature_availability_subtitle ?? '',
        },
        {
          title: firewallData?.automatic_mode ?? '',
          description: firewallData?.automatic_mode_subtitle ?? '',
        },
        {
          title: firewallData?.manual_mode ?? '',
          description: firewallData?.manual_mode_subtitle ?? '',
        },
        {
          title: firewallData?.always_on ?? '',
          description: firewallData?.always_on_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {firewallData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[]}
      metaDescription={firewallData?.meta_description ?? ''}
      metaKeywords={firewallData?.meta_keywords}
      videoFeature={{
        src: 'https://www.youtube.com/embed/fUlAmYH8c4k',
        title: 'GOODBYE IP LEAKS | Windscribe’s Firewall Prevails Where Killswitches Fail',
      }}
      midSection={
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            mt: ['4.8rem', '8rem'],
            gap: ['3.2rem', '4.8rem'],
            maxWidth: '80rem',
          }}
        >
          <Text sx={{ fontSize: ['2.1rem', '4rem'] }}>
            {firewallData?.firewall_vs_killswitch ?? ''}
          </Text>
          <ReactMarkdown
            sx={{
              whiteSpace: 'pre-wrap',
              textAlign: 'center',
              fontSize: '1.8rem',
              color: 'white50',
              p: {
                m: 0,
              },
            }}
          >
            {firewallData?.a_firewall_blocks ?? ''}
          </ReactMarkdown>
        </Flex>
      }
    />
  )
}

export const data = graphql`
  query FirewallPage {
    directus {
      firewall_page {
        strings {
          languages_code {
            code
          }
          a_firewall_blocks
          always_on
          always_on_subtitle
          automatic_mode
          automatic_mode_subtitle
          blocks_all_leaks
          blocks_all_leaks_subtitle
          cta
          feature_availability
          feature_availability_subtitle
          manual_mode
          manual_mode_subtitle
          subtitle
          summary_text
          title
          firewall_vs_killswitch
          meta_description
          meta_keywords
        }
      }
    }
  }
`
