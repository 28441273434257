import CircleIconContainer from 'components/UI/CircleIconContainer'
import { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'

export default function DesktopFeatureIcon({
  icon,
  text,
}: {
  icon: React.ElementType
  text: string
}): ReactElement {
  return (
    <Flex
      sx={{
        gap: ['1.6rem', '2.4rem', '2.4rem'],
        flexDirection: ['row', 'column', 'column'],
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <CircleIconContainer
        icon={icon}
        sxx={{
          flexShrink: 0,
          width: ['4.8rem', '6.8rem', '6.8rem'],
          height: ['4.8rem', '6.8rem', '6.8rem'],
        }}
      />
      <Text
        color="white50"
        sx={{
          fontWeight: 'bold',
          wordBreak: 'break-word',
          textAlign: ['left', 'center', 'center'],
        }}
      >
        {text}
      </Text>
    </Flex>
  )
}
