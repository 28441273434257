import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit'
import { getSessionDataHttp, SessionData } from 'network/session'
import { getCookieByName } from 'utils/cookie'

export interface SessionState {
  data: SessionData | null
  error?: SerializedError
}

export const getSessionData = createAsyncThunk('session/get', async () => {
  const sessionToken = getCookieByName('ws_session_auth_hash')

  if (!sessionToken) {
    return null
  }

  return await getSessionDataHttp(decodeURIComponent(sessionToken))
})

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {} as SessionState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getSessionData.fulfilled, (state, action) => {
      state.data = action.payload?.data ?? null
      state.error =
        (action?.payload?.status || 200) >= 400
          ? { name: 'Error', message: action.payload?.statusText }
          : undefined
    })
    builder.addCase(getSessionData.rejected, (state, action) => {
      state.error = action.error
    })
  },
})

export const actions = sessionSlice.actions
