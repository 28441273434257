import React, { ReactElement, useState } from 'react'
import { Flex, ThemeUIStyleObject, Text } from 'theme-ui'
import Svg from 'components/UI/SVG'
import CloseIcon from 'images/collapsable-panel-x-icon.svg'
import OpenIcon from 'images/collapsable-panel-plus-icon.svg'
import { CloseToOpenButton, OpenToCloseButton } from 'utils/animation'

export default function CollapsableTextPanel({
  icon,
  title,
  innerText,
  sxx,
}: {
  icon: React.ElementType
  title?: string
  innerText?: string
  sxx?: ThemeUIStyleObject
}): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Flex
      sx={{
        width: '100%',
        padding: ['1.6rem', '2.4rem'],
        backgroundColor: 'white05',
        borderRadius: '1.6rem',
        flexDirection: 'column',
        cursor: 'pointer',
        ':hover': {
          '& div > *': {
            color: 'white',
            opacity: 1,
          },
        },
        ...sxx,
      }}
      onClick={() => setIsOpen(!isOpen)}
    >
      <Flex
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Flex
          sx={{
            opacity: isOpen ? 1 : 0.5,
            color: isOpen ? 'white' : 'white50',
            alignItems: 'center',
          }}
        >
          <Svg svg={icon} sx={{ width: '3.2rem', height: '3.2rem' }} />
          <Text
            sx={{
              fontSize: '2.1rem',
              fontWeight: 'bold',
              marginLeft: ['1.6rem', '2.4rem'],
            }}
          >
            {title}
          </Text>
        </Flex>
        {isOpen && (
          <Svg
            sx={{ opacity: 1, animation: `${OpenToCloseButton} 0.5s none ease-out` }}
            svg={CloseIcon}
          />
        )}
        {!isOpen && (
          <Svg
            sx={{ opacity: 0.5, animation: `${CloseToOpenButton} 0.5s none ease-out` }}
            svg={OpenIcon}
          />
        )}
      </Flex>
      {isOpen && (
        <Text
          sx={{
            width: '100%',
            fontSize: ['1.6rem', '1.8rem'],
            color: 'white50',
            marginTop: '1.8rem',
          }}
        >
          {innerText}
        </Text>
      )}
    </Flex>
  )
}
