import ExternalLink from 'components/UI/ExternalLink'
import Svg from 'components/UI/SVG'
import BasePlatform, { DownloadPlatforms } from 'components/download-page/BasePlatform'
import DownloadPlatformButton from 'components/download-page/DownloadPlatformButton'
import PlatformTitle from 'components/download-page/PlatformTitle'
import LinkIcon from 'images/desktop-download-icon.svg'
import LinuxIcon from 'images/linux-icon.svg'
import MacOSIcon from 'images/macos-icon.svg'
import WindowsIcon from 'images/platforms/windows.svg'
import { Dispatch, SetStateAction } from 'react'
import { Flex, Text, get } from 'theme-ui'
import { AvailablePlatforms } from 'utils/downloadPageUtils'
import getEnglishStrings from 'utils/getEnglishStrings'

const platforms: DownloadPlatforms = {
  Windows: {
    icon: WindowsIcon,
    name: 'Windows',
    learnMoreUrl: '/features/windows',
    installGuideUrl: '/knowledge-base/articles/getting-started-with-windscribe-on-windows',
    sourceCodeUrl: 'https://github.com/Windscribe/Desktop-App',
    downloadOptions: [
      {
        name: '64-bit (AMD64)',
        downloadUrl: '/install/desktop/windows',
      },
      {
        name: '64-bit (ARM)',
        downloadUrl: '/install/desktop/windows_arm64',
      },
      {
        name: '32-bit (Legacy)',
        downloadUrl: 'https://assets.totallyacdn.com/desktop/win/Windscribe_2.4.exe',
      },
    ],
  },
  MacOS: {
    icon: MacOSIcon,
    name: 'MacOS',
    learnMoreUrl: '/features/macos',
    installGuideUrl: '/knowledge-base/articles/getting-started-with-windscribe-on-macos',
    sourceCodeUrl: 'https://github.com/Windscribe/Desktop-App',
    downloadOptions: [
      {
        name: 'Universal',
        downloadUrl: '/install/desktop/osx',
      },
      {
        name: 'Legacy',
        downloadUrl: 'https://assets.totallyacdn.com/desktop/mac/Windscribe-legacy.dmg',
      },
    ],
  },
  Linux: {
    icon: LinuxIcon,
    name: 'Linux',
    learnMoreUrl: '/features/linux',
    installGuideUrl: '/knowledge-base/articles/how-to-set-up-windscribe-on-linux',
    sourceCodeUrl: 'https://github.com/Windscribe/Desktop-App',
    downloadOptions: [
      {
        name: 'Debian/Ubuntu (AMD64)',
        downloadUrl: '/install/desktop/linux_deb_x64',
      },
      {
        name: 'Fedora/CentoOS (AMD64)',
        downloadUrl: '/install/desktop/linux_rpm_x64',
      },
      {
        name: 'Arch (AMD64)',
        downloadUrl: '/install/desktop/linux_zst_x64',
      },
      {
        name: 'Debian/Ubuntu (AARCH64)',
        downloadUrl: ' /install/desktop/linux_deb_arm64',
      },
    ],
  },
}

export default function DesktopPlatform({
  platformName,
  setPlatform,
  translations,
  featuresList,
  image,
}: {
  platformName: AvailablePlatforms
  setPlatform: Dispatch<SetStateAction<AvailablePlatforms>>
  translations: Queries.DownloadPageQuery
  featuresList: string[]
  image: React.ReactNode
}): JSX.Element {
  const downloadPageStrings = getEnglishStrings(translations.directus.download_page?.strings)
  const generalTranslationStrings = getEnglishStrings(
    translations.directus.general_translations?.strings,
  )

  const recommendedText = {
    Windows: downloadPageStrings?.recommended_for_windows ?? '',
    Linux: downloadPageStrings?.recommended_for_linux ?? '',
    MacOS: downloadPageStrings?.recommended_for_macos ?? '',
  }[platformName]

  return (
    <BasePlatform
      platformName={platformName}
      setPlatform={setPlatform}
      featuresList={featuresList}
      image={image}
      dataTestId="desktop-platform"
      title={
        {
          Windows: downloadPageStrings?.windscribe_for_windows ?? '',
          Linux: downloadPageStrings?.windscribe_for_linux ?? '',
          MacOS: downloadPageStrings?.windscribe_for_macos ?? '',
        }[platformName]
      }
      downloadSection={
        {
          Windows: (
            <>
              <DownloadPlatformButton
                platformIcon={platforms[platformName].icon}
                downloadText={generalTranslationStrings?.download ?? ''}
                downloadUrl="/install/desktop/windows"
                platformType="desktop"
              />
              <Flex sx={{ mt: '0.8rem', width: '100%', justifyContent: 'center' }}>
                <Text sx={{ color: 'white50', fontSize: '1.2rem' }}>{recommendedText}</Text>
              </Flex>
              <Flex sx={{ mt: '2.4rem', flexDirection: 'column', gap: '0.8rem', width: '100%' }}>
                {platforms[platformName]?.downloadOptions?.map(downloadOption => (
                  <DownloadOption
                    name={downloadOption.name}
                    downloadUrl={downloadOption.downloadUrl}
                    downloadTranslation={generalTranslationStrings?.download ?? ''}
                    key={downloadOption.name}
                  />
                ))}
              </Flex>
            </>
          ),
          Linux: (
            <>
              <Flex
                sx={{
                  mt: '2.4rem',
                  flexDirection: 'column',
                  gap: '0.8rem',
                }}
              >
                {platforms[platformName]?.downloadOptions?.map(downloadOption => (
                  <DownloadOption
                    name={downloadOption.name}
                    downloadUrl={downloadOption.downloadUrl}
                    downloadTranslation={generalTranslationStrings?.download ?? ''}
                    key={downloadOption.name}
                  />
                ))}
              </Flex>
            </>
          ),
          MacOS: (
            <>
              <DownloadPlatformButton
                platformIcon={platforms[platformName].icon}
                downloadText={generalTranslationStrings?.download ?? ''}
                downloadUrl="/install/desktop/osx"
                platformType="desktop"
              />
              <Flex sx={{ mt: '0.8rem', width: '100%', justifyContent: 'center' }}>
                <Text sx={{ color: 'white50', fontSize: '1.2rem' }}>{recommendedText}</Text>
              </Flex>
              <Flex sx={{ mt: '2.4rem', flexDirection: 'column', gap: '0.8rem', width: '100%' }}>
                {platforms[platformName]?.downloadOptions?.map(downloadOption => (
                  <DownloadOption
                    name={downloadOption.name}
                    downloadUrl={downloadOption.downloadUrl}
                    downloadTranslation={generalTranslationStrings?.download ?? ''}
                    key={downloadOption.name}
                  />
                ))}
              </Flex>
            </>
          ),
        }[platformName]
      }
      header={<PlatformTitle title={generalTranslationStrings?.desktop ?? ''} />}
      translations={translations}
      platforms={platforms}
    />
  )
}

function DownloadOption({
  name,
  downloadUrl,
  downloadTranslation,
}: {
  name: string
  downloadUrl: string
  downloadTranslation: string
}): JSX.Element {
  return (
    <>
      <ExternalLink
        sxx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          minWidth: '30rem',
          ': hover': {
            cursor: 'pointer',
            '& span': {
              color: 'white',
            },
            '& svg path': {
              opacity: 1,
            },
          },
        }}
        href={downloadUrl}
      >
        <Text sx={{ color: 'white', fontSize: '1.6rem' }}>{name}</Text>
        <Flex sx={{ alignItems: 'center' }}>
          <Flex
            sx={{
              display: 'flex',
              gap: '0.8rem',
              alignItems: 'center',
              'span, svg path': {
                transition: theme => `${get(theme, 'transitions.default')}`,
              },
            }}
          >
            <Text color="white50">{downloadTranslation}</Text>
            <Svg
              svg={LinkIcon}
              sx={{
                '& path': {
                  fill: 'white',
                },
              }}
            />
          </Flex>
        </Flex>
      </ExternalLink>
      <div sx={{ width: '100%', height: '0.2rem', backgroundColor: 'white10', my: '0.8rem' }} />
    </>
  )
}
