import Breadcrumbs, { Breadcrumb } from 'components/knowledge-base/breadcrumbs/Breadcrumbs'
import SocialMediaLinks from 'components/knowledge-base/SocialMediaLinks'
import StaticPageLayout from 'components/StaticPageLayout'
import React, { ReactElement, useEffect, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import addBlurredLetterboxingToImages from 'utils/addBlurredLetterboxingToImages'
import getEnglishStrings from 'utils/getEnglishStrings'
import usePlatformTagId from 'utils/usePlatformTagId'

export interface KnowledgeBaseArticleContext {
  article: Queries.KnowledgeBaseQuery['directus']['knowledge_base_articles'][number]
}

export default function KnowledgeBaseArticle({
  pageContext: { article },
}: {
  pageContext: KnowledgeBaseArticleContext
}): ReactElement {
  const articleStrings = getEnglishStrings(article?.strings)
  const body = articleStrings?.body
  const [bodyWithBlurredLetterboxing, setBodyWithBlurredLetterboxing] = useState(body)
  const { selectedPlatformTagId } = usePlatformTagId()

  useEffect(() => {
    const bodyDoc = new DOMParser().parseFromString(body ?? '', 'text/html')
    addBlurredLetterboxingToImages(bodyDoc)
    setBodyWithBlurredLetterboxing(new XMLSerializer().serializeToString(bodyDoc))
  }, [body])

  const breadcrumbs: Breadcrumb[] = [
    {
      title: 'HELP',
      href: selectedPlatformTagId
        ? `/knowledge-base?platform=${selectedPlatformTagId}`
        : '/knowledge-base',
    },
    {
      title: article.category?.slug ?? '',
      href: `/knowledge-base/categories/${article.category?.slug}`,
    },
  ]

  return (
    <StaticPageLayout
      title={`${articleStrings?.title} | Windscribe`}
      metaDescription={articleStrings?.meta_description}
      metaKeywords={articleStrings?.meta_keywords}
    >
      <Flex sx={{ flex: 0 }}>
        <Text
          data-testid="article-title"
          sx={{
            fontWeight: 'bold',
            fontSize: ['3.4rem', '4.8rem', '4.8rem'],
            marginTop: ['6rem', '7.2rem', '7.2rem'],
            mb: '4.8rem',
            px: ['2.4rem', '4.8rem', '12.6rem'],
            width: '100%',
            textAlign: 'center',
          }}
          data-typesense-field="title"
        >
          {articleStrings?.title}
        </Text>
      </Flex>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: ['4rem', '4rem', '0'],
          width: '100%',
          flex: 1,
        }}
      >
        <Flex
          sx={{
            backgroundImage: ({ colors }) =>
              `linear-gradient(180deg, ${colors?.bodyBG} 0%, ${colors?.bodyBG0} 100%)`,
            borderRadius: '3.2rem 3.2rem 0 0',
            flexDirection: 'column',
            px: ['2.4rem', '4.8rem', '12.6rem'],
            py: ['4.8rem', '8rem', '8rem'],
            maxWidth: '138rem',
            width: '100%',
            marginTop: '4.8rem',
            flex: 1,
            alignItems: 'center',
          }}
        >
          <div
            data-typesense-field="body"
            dangerouslySetInnerHTML={{ __html: bodyWithBlurredLetterboxing ?? body ?? '' }}
            sx={{
              width: '100%',
              maxWidth: 'maxContentWidth',
              lineHeight: 1.5,
              fontSize: '1.8rem',
              h1: {
                fontWeight: 'bold',
                fontSize: ['3.4rem', '4.8rem', '4.8rem'],
                textAlign: 'center',
              },
              h2: { fontSize: ['3.4rem', '4.8rem', '4.8rem'], fontWeight: 'bold' },
              p: {
                fontSize: '1.8rem',
                lineHeight: '1.6',
              },
              li: { marginTop: '2.4rem' },
              img: {
                mx: 'auto',
                display: 'flex',
                height: 'unset !important',
                maxWidth: '100% !important',
              },
              ul: { marginBottom: '4.8rem', fontSize: '1.8rem', lineHeight: '1.6' },
              ol: { paddingLeft: '2.4rem', fontSize: '1.8rem', lineHeight: '1.6' },
              '.article-image': {
                width: '100%',
                marginTop: ['2.4rem', '4.8rem', '4.8rem'],
                borderRadius: '2.6rem',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
              },
              '.backdrop-container': {
                borderRadius: '2.6rem',
                display: 'flex',
                justifyContent: 'center',
                px: '2.4rem',
                position: 'relative',
                overflow: 'hidden',
              },
              '.image-container': {
                height: '100%',
                width: '100%',
                filter: 'blur(4rem)',
                position: 'absolute',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                borderRadius: '2.6rem',
              },
              '.image-style': {
                zIndex: '2',
              },
            }}
          />
        </Flex>
      </Flex>
      <SocialMediaLinks />
    </StaticPageLayout>
  )
}
