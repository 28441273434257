import FeaturesIcon from 'images/features-icon.svg'
import PlansIcon from 'images/plans-icon.svg'
import HelpIcon from 'images/help-icon.svg'
import MyAccountIcon from 'images/my-account-icon.svg'

export default {
  '/features': FeaturesIcon,
  '/upgrade': PlansIcon,
  '/knowledge-base': HelpIcon,
  '/myaccount': MyAccountIcon,
}
