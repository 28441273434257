import Svg from 'components/UI/SVG'
import React, { Dispatch, ReactElement, SetStateAction } from 'react'
import { Button, Flex } from 'theme-ui'
import NavMenuCloseIcon from 'images/nav-menu-close-icon.svg'
import HeaderDropdownIcon from 'images/header-dropdown-icon.svg'

export default function ToggleNavigationMenuButton({
  isMobileNavOpen,
  setIsMobileNavOpen,
}: {
  isMobileNavOpen: boolean
  setIsMobileNavOpen: Dispatch<SetStateAction<boolean>>
}): ReactElement {
  return (
    <Button
      onClick={() => {
        if (isMobileNavOpen) {
          const body = document.getElementsByTagName('body')[0]
          body.classList.remove('non-scrollable')
          window.scrollTo(0, parseInt(localStorage.getItem('scrollY') || '0'))
        }
        setIsMobileNavOpen(!isMobileNavOpen)
      }}
      sx={{
        display: ['flex', 'flex', 'none'],
        backgroundColor: 'transparent',
        ml: [0, '2.4rem', 0],
        padding: 0,
      }}
      data-testid="ToggleNavigationMenuButton_toggle"
    >
      <Flex
        sx={{
          width: '4.8rem',
          height: '4.8rem',
          borderRadius: '50%',
          backgroundColor: 'white10',
          justifyContent: 'center',
          alignItems: 'center',
          ':hover': {
            backgroundColor: 'white15',
          },
        }}
      >
        <Svg
          svg={isMobileNavOpen ? NavMenuCloseIcon : HeaderDropdownIcon}
          fill="white"
          sx={{ backgroundColor: 'transparent' }}
        />
      </Flex>
    </Button>
  )
}
