import BasePlatform, { DownloadPlatforms } from 'components/download-page/BasePlatform'
import DownloadPlatformButton from 'components/download-page/DownloadPlatformButton'
import PlatformTitle from 'components/download-page/PlatformTitle'
import FireTVIcon from 'images/firetv-icon.svg'
import AndroidIcon from 'images/platforms/android.svg'
import { Dispatch, SetStateAction } from 'react'
import { AvailablePlatforms } from 'utils/downloadPageUtils'
import getEnglishStrings from 'utils/getEnglishStrings'
import { Flex, Text } from 'theme-ui'
import ExternalLink from 'components/UI/ExternalLink'
import GetOnAmazon from 'images/amazon-icon.png'
import GetOnGooglePlay from 'images/get-on-google-play.png'

const platforms: DownloadPlatforms = {
  FireTV: {
    icon: FireTVIcon,
    name: 'FireTV',
    learnMoreUrl: '',
    installGuideUrl: '/knowledge-base/articles/how-to-set-up-windscribe-on-a-firetv-firestick',
    sourceCodeUrl: 'https://github.com/Windscribe/Android-App',
  },
  AndroidTV: {
    icon: AndroidIcon,
    name: 'AndroidTV',
    learnMoreUrl: '',
    installGuideUrl:
      '/knowledge-base/articles/how-to-set-up-windscribe-on-android-tv-nvidia-shield-devices',
    sourceCodeUrl: 'https://github.com/Windscribe/Android-App',
  },
}

export default function TVPlatform({
  platformName,
  setPlatform,
  translations,
  featuresList,
  image,
}: {
  platformName: AvailablePlatforms
  setPlatform: Dispatch<SetStateAction<AvailablePlatforms>>
  translations: Queries.DownloadPageQuery
  featuresList: string[]
  image: React.ReactNode
}): JSX.Element {
  const downloadPageStrings = getEnglishStrings(translations.directus.download_page?.strings)
  const generalTranslationStrings = getEnglishStrings(
    translations.directus.general_translations?.strings,
  )

  return (
    <BasePlatform
      platformName={platformName}
      setPlatform={setPlatform}
      featuresList={featuresList}
      image={image}
      title={
        {
          AndroidTV: downloadPageStrings?.windscribe_for_androidtv ?? '',
          FireTV: downloadPageStrings?.windscribe_for_firetv ?? '',
        }[platformName]
      }
      downloadSection={
        {
          FireTV: (
            <>
              <ExternalLink
                href="http://www.amazon.com/gp/mas/dl/android?p=com.windscribe.vpn"
                sxx={{ display: 'flex', justifyContent: 'center' }}
              >
                <img src={GetOnAmazon} alt="" sx={{ width: '24.8rem', height: '100%' }} />
              </ExternalLink>
              <Flex sx={{ flexDirection: 'column' }}>
                <Flex
                  sx={{
                    position: 'relative',
                    justifyContent: 'center',
                    minHeight: '2rem',
                    my: '2.6rem',
                  }}
                >
                  <Text
                    sx={{
                      backgroundColor: 'background',
                      fontWeight: 'bold',
                      fontSize: '1.8rem',
                      textTransform: 'uppercase',
                      minWidth: '3.9rem',
                      textAlign: 'center',
                    }}
                  >
                    {generalTranslationStrings?.or}
                  </Text>
                  <div
                    sx={{
                      position: 'absolute',
                      width: '100%',
                      height: '0.2rem',
                      backgroundColor: 'white15',
                      bottom: '50%',
                      zIndex: '-1',
                    }}
                  />
                </Flex>
                <DownloadPlatformButton
                  platformIcon={platforms[platformName].icon}
                  downloadText={generalTranslationStrings?.download ?? ''}
                  downloadUrl="/install/tv/firetv"
                  platformType="desktop"
                />
              </Flex>
            </>
          ),
          AndroidTV: (
            <>
              <ExternalLink
                href="https://play.google.com/store/apps/details?id=com.windscribe.vpn"
                sxx={{ display: 'flex', justifyContent: 'center' }}
              >
                <img src={GetOnGooglePlay} alt="" sx={{ width: '24.8rem', height: '100%' }} />
              </ExternalLink>
              <Flex sx={{ flexDirection: 'column' }}>
                <Flex
                  sx={{
                    position: 'relative',
                    justifyContent: 'center',
                    minHeight: '2rem',
                    my: '2.6rem',
                  }}
                >
                  <Text
                    sx={{
                      backgroundColor: 'background',
                      fontWeight: 'bold',
                      fontSize: '1.8rem',
                      textTransform: 'uppercase',
                      minWidth: '3.9rem',
                      textAlign: 'center',
                    }}
                  >
                    {generalTranslationStrings?.or}
                  </Text>
                  <div
                    sx={{
                      position: 'absolute',
                      width: '100%',
                      height: '0.2rem',
                      backgroundColor: 'white15',
                      bottom: '50%',
                      zIndex: '-1',
                    }}
                  />
                </Flex>
                <DownloadPlatformButton
                  platformIcon={platforms[platformName].icon}
                  downloadText={generalTranslationStrings?.download ?? ''}
                  downloadUrl="/install/tv/android-tv"
                  platformType="desktop"
                />
              </Flex>
            </>
          ),
        }[platformName]
      }
      header={<PlatformTitle title={generalTranslationStrings?.tv ?? ''} />}
      translations={translations}
      platforms={platforms}
    />
  )
}
