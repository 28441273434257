import StaticContentSkeleton, { TitledContent } from 'components/StaticContentSkeleton'
import Tag from 'components/Tag'
import InternalLink from 'components/UI/InternalLink'
import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'
import Background from 'components/features-page/background/Background'
import RoundedBorder from 'components/features-page/background/RoundedBorder'
import { graphql } from 'gatsby'
import { ReactElement, useState } from 'react'
import { Button, Flex } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'

export default function PrivacyPolicy({
  data,
}: {
  data: Queries.PrivacyPolicyPageQuery
}): ReactElement {
  const privacyPolicyData = getEnglishStrings(data.directus.privacy_page?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const [showMore, setShowMore] = useState(false)

  return (
    <StaticContentSkeleton
      title={privacyPolicyData?.title ?? ''}
      metaDescription={privacyPolicyData?.meta_description ?? ''}
      metaKeywords={privacyPolicyData?.meta_keywords ?? []}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: '8rem',
          width: '100%',
        }}
      >
        <TitleAndSubtitle
          title={privacyPolicyData?.title}
          updatedAt={privacyPolicyData?.updated_at}
          subtitle={privacyPolicyData?.subtitle}
        />

        <Background display={['flex', 'flex', 'flex']}>
          <RoundedBorder
            width={['100vw', '100vw', '100vw']}
            minHeight={['81.2rem', '81.2rem', '100rem']}
            roundedEdge="top"
            backgroundImage="linear-gradient(180deg, #030E1D 32.81%, rgba(3, 14, 29, 0.00) 100%)"
            maxWidth="128rem"
          />
        </Background>
      </Flex>
      <Flex sx={{ flexDirection: 'column', gap: '4.8rem', alignItems: 'center' }}>
        <Tag
          title={privacyPolicyData?.data_collection ?? ''}
          backgroundColour="white10"
          textColour="white"
          padding="1rem 3.2rem"
        />
        <TitledContent
          title={privacyPolicyData?.when_you_visit ?? ''}
          content={privacyPolicyData?.when_you_visit_subtitle ?? ''}
        />
      </Flex>
      <TitledContent
        title={privacyPolicyData?.when_you_sign_up ?? ''}
        content={privacyPolicyData?.when_you_sign_up_subtitle ?? ''}
      />

      <TitledContent
        title={privacyPolicyData?.when_you_use_our ?? ''}
        content={privacyPolicyData?.when_you_use_our_subtitle ?? ''}
      />

      <Flex sx={{ flexDirection: 'column', width: '100%', maxWidth: ['100%', 'maxContentWidth'] }}>
        <TitledContent
          title={privacyPolicyData?.when_you_are_actively ?? ''}
          content={privacyPolicyData?.when_you_are_actively_subtitle ?? ''}
        />
        <Button
          variant="buttons.link"
          onClick={() => {
            setShowMore(state => !state)
          }}
          sx={{ fontWeight: 'bold', mt: '3.2rem', width: 'fit-content' }}
        >
          {generalTranslationsData?.read_more}
        </Button>
        {showMore && (
          <>
            <TitledContent title={''} content={privacyPolicyData?.the_following_is_no ?? ''} />
            <TitledContent title={''} content={privacyPolicyData?.vast_majority ?? ''} />
          </>
        )}
      </Flex>

      <TitledContent
        title={privacyPolicyData?.when_you_leave ?? ''}
        content={privacyPolicyData?.when_you_leave_subtitle ?? ''}
      />

      <Flex sx={{ flexDirection: 'column', gap: '1.6rem' }}>
        <TitledContent
          title={privacyPolicyData?.user_data_requests ?? ''}
          content={privacyPolicyData?.user_data_requests_subtitle ?? ''}
        />
        <InternalLink sx={{ width: 'fit-content', variant: 'buttons.neon' }} href="/transparency">
          {privacyPolicyData?.cta}
        </InternalLink>
      </Flex>
      <TitledContent
        title={privacyPolicyData?.changes_to_policy ?? ''}
        content={privacyPolicyData?.changes_to_policy_subtitle ?? ''}
      />
    </StaticContentSkeleton>
  )
}

export const data = graphql`
  query PrivacyPolicyPage {
    directus {
      privacy_page {
        strings {
          changes_to_policy
          changes_to_policy_subtitle
          cta
          data_collection
          languages_code {
            code
          }
          meta_description
          meta_keywords
          subtitle
          the_following_is_no
          title
          user_data_requests
          updated_at
          user_data_requests_subtitle
          when_you_are_actively
          when_you_are_actively_subtitle
          when_you_leave
          when_you_leave_subtitle
          when_you_sign_up
          when_you_sign_up_subtitle
          when_you_use_our
          when_you_use_our_subtitle
          when_you_visit
          when_you_visit_subtitle
          read_more
          vast_majority
        }
      }
      general_translations {
        strings {
          languages_code {
            code
          }
          read_more
        }
      }
    }
  }
`
