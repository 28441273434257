import { ReactNode } from 'react'
import { Flex } from 'theme-ui'

export default function Background({
  children,
  display,
}: {
  children: ReactNode
  display: string[]
}): JSX.Element {
  return <Flex sx={{ position: 'relative', width: '100vw', display }}>{children}</Flex>
}
