interface Country {
  name: string
  code: string
}

const countries: Country[] = [
  {
    code: 'AL',
    name: 'Albania',
  },
  {
    code: 'AR',
    name: 'argentina',
  },
  {
    code: 'AU',
    name: 'australia',
  },
  {
    code: 'AT',
    name: 'austria',
  },
  {
    code: 'AZ',
    name: 'azerbaijan',
  },
  {
    code: 'BE',
    name: 'belgium',
  },
  {
    code: 'BA',
    name: 'bosnia',
  },
  {
    code: 'BR',
    name: 'brazil',
  },
  {
    code: 'BG',
    name: 'bulgaria',
  },
  {
    code: 'KH',
    name: 'cambodia',
  },
  {
    code: 'CA',
    name: 'canada',
  },
  {
    code: 'CL',
    name: 'chile',
  },
  {
    code: 'CO',
    name: 'colombia',
  },
  {
    code: 'HR',
    name: 'croatia',
  },
  {
    code: 'CY',
    name: 'cyprus',
  },
  {
    code: 'CZ',
    name: 'czech_republic',
  },
  {
    code: 'DK',
    name: 'denmark',
  },
  {
    code: 'EC',
    name: 'ecuador',
  },
  {
    code: 'EE',
    name: 'estonia',
  },
  {
    code: 'AQ',
    name: 'fake_antarctica',
  },
  {
    code: 'FI',
    name: 'finland',
  },
  {
    code: 'FR',
    name: 'france',
  },
  {
    code: 'GE',
    name: 'georgia',
  },
  {
    code: 'DE',
    name: 'germany',
  },
  {
    code: 'GH',
    name: 'ghana',
  },
  {
    code: 'GR',
    name: 'greece',
  },
  {
    code: 'HK',
    name: 'hong_kong',
  },
  {
    code: 'HU',
    name: 'hungary',
  },
  {
    code: 'IS',
    name: 'iceland',
  },
  {
    code: 'IN',
    name: 'india',
  },
  {
    code: 'ID',
    name: 'indonesia',
  },
  {
    code: 'IE',
    name: 'ireland',
  },
  {
    code: 'IL',
    name: 'israel',
  },
  {
    code: 'IT',
    name: 'italy',
  },
  {
    code: 'JP',
    name: 'japan',
  },
  {
    code: 'KE',
    name: 'kenya',
  },
  {
    code: 'LV',
    name: 'latvia',
  },
  {
    code: 'LT',
    name: 'lithuania',
  },
  {
    code: 'LU',
    name: 'luxembourg',
  },
  {
    code: 'MY',
    name: 'malaysia',
  },
  {
    code: 'MX',
    name: 'mexico',
  },
  {
    code: 'MD',
    name: 'moldova',
  },
  {
    code: 'NL',
    name: 'netherlands',
  },
  {
    code: 'NZ',
    name: 'new_zealand',
  },
  {
    code: 'MK',
    name: 'north_macedonia',
  },
  {
    code: 'NO',
    name: 'norway',
  },
  {
    code: 'PA',
    name: 'panama',
  },
  {
    code: 'PE',
    name: 'peru',
  },
  {
    code: 'PH',
    name: 'philippines',
  },
  {
    code: 'PL',
    name: 'poland',
  },
  {
    code: 'PT',
    name: 'portugal',
  },
  {
    code: 'RO',
    name: 'romania',
  },
  {
    code: 'RU',
    name: 'russia',
  },
  {
    code: 'RS',
    name: 'serbia',
  },
  {
    code: 'SG',
    name: 'singapore',
  },
  {
    code: 'SK',
    name: 'slovakia',
  },
  {
    code: 'ZA',
    name: 'south_africa',
  },
  {
    code: 'KR',
    name: 'south_korea',
  },
  {
    code: 'ES',
    name: 'spain',
  },
  {
    code: 'SE',
    name: 'sweden',
  },
  {
    code: 'CH',
    name: 'switzerland',
  },
  {
    code: 'TW',
    name: 'taiwan',
  },
  {
    code: 'TH',
    name: 'thailand',
  },
  {
    code: 'TR',
    name: 'turkey',
  },
  {
    code: 'US',
    name: 'united_states',
  },
  {
    code: 'UA',
    name: 'ukraine',
  },
  {
    code: 'AE',
    name: 'uae',
  },
  {
    code: 'GB',
    name: 'united_kingdom',
  },
  {
    code: 'VN',
    name: 'vietnam',
  },
  {
    code: 'MOM',
    name: 'your_moms_house',
  },
]

//Imports all images from the selected folder
function importAll(webpackContext: __WebpackModuleApi.RequireContext) {
  return webpackContext.keys().map(fileUrl => {
    //body is the image itself
    const body = webpackContext(fileUrl)
    //slug is the image name replaced the ./ and the -round.svg
    const slug: string = fileUrl.replace(/.\/|-round.svg/g, '')
    return {
      slug,
      body,
    }
  })
}
//Then we import all of the images into a flags object
const flags = importAll(require.context('images/flags', false, /\.(svg)$/))

export default function useCountries(): {
  flags: { slug: string; body: string }[]
  countries: Country[]
} {
  return { flags, countries }
}
