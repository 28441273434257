import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import LanTrafficImage from 'images/lan-traffic.png'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function LanTraffic({ data }: { data: Queries.LanTrafficPageQuery }): ReactElement {
  const lanTrafficData = getEnglishStrings(data.directus.lan_traffic?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={lanTrafficData?.meta_description ?? ''}
      metaKeywords={lanTrafficData?.meta_keywords ?? []}
      title={lanTrafficData?.title ?? ''}
      subtitle={lanTrafficData?.subtitle ?? ''}
      featureImage={LanTrafficImage}
      summaryText={lanTrafficData?.summary_text ?? ''}
      gridItems={[
        {
          title: lanTrafficData?.what_is ?? '',
          description: lanTrafficData?.what_is_subtitle ?? '',
        },
        {
          title: lanTrafficData?.allow_lan ?? '',
          description: lanTrafficData?.allow_lan_subtitle ?? '',
        },
        {
          title: lanTrafficData?.disable_lan ?? '',
          description: lanTrafficData?.disable_lan_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {lanTrafficData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [lanTrafficData?.lan_traffic_bypass ?? ''],
        },
      ]}
    />
  )
}

export const data = graphql`
  query LanTrafficPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      lan_traffic {
        strings {
          allow_lan
          allow_lan_subtitle
          cta
          disable_lan
          disable_lan_subtitle
          lan_traffic_bypass
          languages_code {
            code
          }
          meta_description
          meta_keywords
          subtitle
          title
          summary_text
          what_is
          what_is_subtitle
        }
      }
    }
  }
`
