import StaticContentSkeleton from 'components/StaticContentSkeleton'
import Tag from 'components/Tag'
import TransparencyReportChart from 'components/TransparencyReportChart'
import TransparencyReportChartScale from 'components/TransparencyReportChartScale'
import Svg from 'components/UI/SVG'
import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'
import Background from 'components/features-page/background/Background'
import RoundedBorder from 'components/features-page/background/RoundedBorder'
import { graphql } from 'gatsby'
import DesktopSkeleton from 'images/transparency-loading-desktop.svg'
import MobileSkeleton from 'images/transparency-loading-mobile.svg'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { wsApi } from 'redux/api/wsApi'
import { Flex, Text, ThemeUICSSObject } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'

//The reason for this is that the chart creates a vertical line for each tr, so we need to chunk the data to match the designs
const chunkSize = 6

export interface ChartUnit {
  label: string
  value: number
}

const transformer = (stats: Record<string, string>[]) =>
  Object.entries(stats).map(([label, value]) => ({
    label,
    value: Number(value),
  }))

const splitIntoChunks = ({ arr, chunkSize }: { arr: ChartUnit[]; chunkSize: number }) =>
  Array.from({ length: Math.ceil(arr.length / chunkSize) }, (_, index) =>
    arr.slice(index * chunkSize, index * chunkSize + chunkSize),
  )

const numberOfRequestsBaseStyles: ThemeUICSSObject = {
  width: '3.2rem',
  height: '1.6rem',
  borderRadius: '0.2rem',
}

export default function TransparencyPage({
  data,
}: {
  data: Queries.TransparencyPageQuery
}): ReactElement {
  const [isLoading, setIsLoading] = useState(true)
  const transparencyPageData = getEnglishStrings(data.directus.transparency_page?.strings)
  const { data: transparencyData, isFetching } = wsApi.useTransparencyReportQuery(
    'transparencyReport',
    { refetchOnMountOrArgChange: true },
  )

  const transformedStats = transformer(transparencyData?.data?.stats_dmca ?? [])

  const dmcaChunked = splitIntoChunks({ arr: transformedStats, chunkSize })

  const lawEnforcement = transformer(transparencyData?.data?.stats_data ?? [])
  const lawEnforcementChunked = splitIntoChunks({ arr: lawEnforcement, chunkSize })

  const dmcaRef = useRef<HTMLTableSectionElement>(null)
  const lawEnforcementRef = useRef<HTMLTableSectionElement>(null)

  const [displayDMCAShadow, setDisplayDMCAShadow] = useState(true)
  const [displayLawEnforcementShadow, setDisplayLawEnforcementShadow] = useState(true)

  const handleDMCAScroll = () => {
    if (dmcaRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = dmcaRef.current
      const scrolledPercentage = (scrollLeft + clientWidth) / scrollWidth
      setDisplayDMCAShadow(scrolledPercentage < 0.99)
    }
  }

  const handleLawEnforcementScroll = () => {
    if (lawEnforcementRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = lawEnforcementRef.current
      const scrolledPercentage = (scrollLeft + clientWidth) / scrollWidth
      setDisplayLawEnforcementShadow(scrolledPercentage < 0.99)
    }
  }

  useEffect(() => {
    const dmcaCurrent = dmcaRef.current
    const lawEnforcementCurrent = lawEnforcementRef.current
    if (dmcaCurrent) {
      dmcaCurrent.addEventListener('scroll', handleDMCAScroll)
    }
    if (lawEnforcementCurrent) {
      lawEnforcementCurrent.addEventListener('scroll', handleLawEnforcementScroll)
    }

    return () => {
      if (dmcaCurrent) {
        dmcaCurrent.removeEventListener('scroll', handleDMCAScroll)
      }
      if (lawEnforcementCurrent) {
        lawEnforcementCurrent.removeEventListener('scroll', handleLawEnforcementScroll)
      }
    }
  }, [])

  useEffect(() => {
    setIsLoading(isFetching)
  }, [isFetching])

  return (
    <>
      <StaticContentSkeleton
        title={transparencyPageData?.title ?? ''}
        metaDescription={transparencyPageData?.meta_description ?? ''}
        metaKeywords={transparencyPageData?.meta_keywords ?? []}
      >
        <Flex sx={{ flexDirection: 'column', alignItems: 'center', gap: '8rem', width: '100%' }}>
          <TitleAndSubtitle
            title={transparencyPageData?.title}
            subtitle={transparencyPageData?.subtitle}
          />

          <Background display={['none', 'flex', 'flex']}>
            <RoundedBorder
              width={['100vw', '100vw', '100vw']}
              minHeight={['81.2rem', '100rem', '100rem']}
              roundedEdge="top"
              backgroundImage="linear-gradient(180deg, #030E1D 32.81%, rgba(3, 14, 29, 0.00) 100%)"
            />
          </Background>
          <Background display={['flex', 'none', 'none']}>
            <RoundedBorder
              width={['100vw', '100vw', '100vw']}
              minHeight={['125rem', 0, 0]}
              roundedEdge="top"
              backgroundImage="linear-gradient(180deg, #030E1D 32.81%, #030E1D 88.37%, rgba(3, 14, 29, 0.00) 100%);"
              maxWidth="128rem"
            />
          </Background>
        </Flex>
        <Flex sx={{ flexDirection: 'column', alignItems: 'center', gap: '4.8rem' }}>
          <Tag
            title={transparencyPageData?.dmca_data_requests ?? ''}
            backgroundColour="white10"
            textColour="white"
            padding="1rem 3.2rem"
          />
          <Flex sx={{ flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            {isLoading ? (
              <LoadingSkeleton testId="dmca-skeleton" />
            ) : (
              <>
                <Flex sx={{ gap: '0.8rem', alignItems: 'center' }}>
                  <div
                    sx={{
                      ...numberOfRequestsBaseStyles,
                      border: '1px solid white15',
                      backgroundColor: 'white50',
                    }}
                  />

                  <Text color="white50">{transparencyPageData?.number_of_requests}</Text>
                </Flex>
                <Flex>
                  <Flex sx={{ position: 'relative', justifyContent: 'center' }}>
                    <TransparencyReportChartScale size={9} multiplier={10_000} />
                    <TransparencyReportChart
                      data={dmcaChunked}
                      color="#FFFFFF80"
                      divider={90_000}
                      horizontalColums={9}
                      bodyRef={dmcaRef}
                    />

                    {displayDMCAShadow && <ChartOverflow />}
                  </Flex>
                </Flex>
              </>
            )}
          </Flex>

          <Text sx={{ color: 'white50', variant: 'text.body1', textAlign: ['center'] }}>
            {transparencyPageData?.exactly_zero_requests}
          </Text>
        </Flex>

        <Flex sx={{ flexDirection: 'column', gap: '4.8rem', alignItems: 'center' }}>
          <Tag
            title={transparencyPageData?.law_enforcement_data ?? ''}
            backgroundColour="white10"
            textColour="white"
            padding="1rem 3.2rem"
          />
          <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
            {isLoading ? (
              <LoadingSkeleton testId="law-enforcement-skeleton" />
            ) : (
              <>
                <Flex sx={{ gap: '0.8rem', alignItems: 'center' }}>
                  <div
                    sx={{
                      ...numberOfRequestsBaseStyles,
                      border: '1px solid rgba(255, 85, 85, 0.15)',
                      backgroundColor: 'red',
                      opacity: 0.5,
                    }}
                  />
                  <Text color="white50">{transparencyPageData?.number_of_requests}</Text>
                </Flex>
                <Flex>
                  <Flex sx={{ position: 'relative' }}>
                    <TransparencyReportChartScale size={5} multiplier={5} />
                    <TransparencyReportChart
                      data={lawEnforcementChunked}
                      color="#74282F"
                      divider={25}
                      horizontalColums={5}
                      bodyRef={lawEnforcementRef}
                    />
                    {displayLawEnforcementShadow && <ChartOverflow />}
                  </Flex>
                </Flex>
              </>
            )}
          </Flex>
          <Text sx={{ color: 'white50', variant: 'text.body1', textAlign: ['center'] }}>
            {transparencyPageData?.exactly_zero_requests}
          </Text>
        </Flex>
      </StaticContentSkeleton>
    </>
  )
}

function ChartOverflow() {
  return (
    <div
      sx={{
        display: ['block', 'block', 'none'],
        position: 'absolute',
        top: '0',
        right: '-2px',
        width: '2rem',
        height: '100%',
        background:
          'linear-gradient(270deg, #040F1E 0%, #040F1E 21.51%, rgba(4, 15, 30, 0.80) 34.58%, rgba(4, 15, 30, 0.00) 55.7%);',
      }}
    />
  )
}

function LoadingSkeleton({ testId }: { testId: string }) {
  return (
    <>
      <Svg
        svg={DesktopSkeleton}
        sx={{
          width: '100%',
          display: ['none', 'block'],
        }}
        data-testid={testId}
      />
      <Svg
        svg={MobileSkeleton}
        sx={{
          width: '100%',
          display: ['block', 'none'],
        }}
        data-testid={`${testId}-mobile`}
      />
    </>
  )
}

export const data = graphql`
  query TransparencyPage {
    directus {
      transparency_page {
        strings {
          languages_code {
            code
          }
          dmca_data_requests
          exactly_zero_requests
          law_enforcement_data
          meta_description
          meta_keywords
          number_of_requests
          subtitle
          title
        }
      }
    }
  }
`
