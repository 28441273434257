import StaticContentSkeleton from 'components/StaticContentSkeleton'
import LocationStatus from 'components/UI/LocationStatus'
import Svg from 'components/UI/SVG'
import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'
import Background from 'components/features-page/background/Background'
import RoundedBorder from 'components/features-page/background/RoundedBorder'
import { graphql } from 'gatsby'
import MobileStatusLoading from 'images/status-loading-mobile.svg'
import StatusLoading from 'images/status-loading.svg'
import StatusSkeleton from 'images/status-skeleton.svg'
import { ReactElement, useEffect, useState } from 'react'
import { wsApi } from 'redux/api/wsApi'
import { Flex, Text } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'

const formattedValue = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
  }).format(value)
}

export default function StatusPage({ data }: { data: Queries.StatusPageQuery }): ReactElement {
  const privacyPolicyData = getEnglishStrings(data.directus.status_page?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)

  const {
    data: statusReportData,
    isFetching,
    isError,
  } = wsApi.useStatusReportQuery('statusReport', { refetchOnMountOrArgChange: true })

  const totalUsers = Number(statusReportData?.data?.totals?.users) ?? 0
  const totalConnections = statusReportData?.data?.totals?.connections ?? 0
  const networkUtilization = Number(statusReportData?.data?.totals?.usage.substring(0, 3))
  const locationsArray = Object.values(statusReportData?.data?.locations ?? []).sort((a, b) => {
    return a.name.localeCompare(b.name)
  })

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(isFetching)
  }, [isFetching])

  return (
    <StaticContentSkeleton
      title={privacyPolicyData?.title ?? ''}
      metaDescription={privacyPolicyData?.meta_description ?? ''}
      metaKeywords={privacyPolicyData?.meta_keywords ?? []}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: '8rem',
          width: '100%',
        }}
      >
        <TitleAndSubtitle title={privacyPolicyData?.title} subtitle={privacyPolicyData?.subtitle} />
        <Flex
          sx={{
            gap: '4.8rem',
            flexDirection: ['column', 'column', 'row'],
            span: {
              opacity: isFetching ? 0 : 1,
            },
          }}
        >
          {isLoading ? (
            <>
              <Svg svg={StatusLoading} />
              <Svg svg={StatusLoading} />
              <Svg svg={StatusLoading} />
            </>
          ) : (
            <>
              <StatusItem
                title={privacyPolicyData?.network_utilization ?? ''}
                subtitle={`${formattedValue(networkUtilization).toString()} Gb/S`}
                colour="neonGreen"
              />
              <StatusItem
                title={privacyPolicyData?.connected_users ?? ''}
                subtitle={formattedValue(totalConnections).toString()}
              />
              <StatusItem
                title={privacyPolicyData?.registered_users ?? ''}
                subtitle={formattedValue(totalUsers).toString()}
              />
            </>
          )}
        </Flex>
        <Flex sx={{ flexDirection: 'column', gap: '4.8rem', alignItems: 'center' }}>
          <Text variant="text.heading1">{privacyPolicyData?.location_status}</Text>
          <Background display={['flex', 'flex', 'flex']}>
            <RoundedBorder
              width={['100vw', '100vw', '100vw']}
              minHeight={['40.2rem', '81.2rem', isError ? '5rem' : '100rem']}
              // minHeight={['100%']}
              roundedEdge="top"
              backgroundImage="linear-gradient(180deg, #030E1D 32.81%, rgba(3, 14, 29, 0.00) 100%)"
              maxWidth="128rem"
            />
          </Background>
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '1.6rem',
            width: ['100%', '100%', 'maxContentWidth'],
          }}
        >
          {isLoading ? (
            <>
              <Svg
                svg={StatusSkeleton}
                sx={{ width: '100%', display: ['none', 'flex'] }}
                data-testid="status-skeleton"
              />
              <Svg svg={MobileStatusLoading} sx={{ display: ['flex', 'none'], minWidth: '100%' }} />
              <Svg svg={MobileStatusLoading} sx={{ display: ['flex', 'none'], minWidth: '100%' }} />
              <Svg svg={MobileStatusLoading} sx={{ display: ['flex', 'none'], minWidth: '100%' }} />
              <Svg svg={MobileStatusLoading} sx={{ display: ['flex', 'none'], minWidth: '100%' }} />
              <Svg svg={MobileStatusLoading} sx={{ display: ['flex', 'none'], minWidth: '100%' }} />
              <Svg svg={MobileStatusLoading} sx={{ display: ['flex', 'none'], minWidth: '100%' }} />
            </>
          ) : null}
          {!isLoading &&
            locationsArray.map(location => (
              <LocationStatus
                key={location.name}
                location={location}
                translations={{
                  usage: privacyPolicyData?.usage ?? '',
                  pops_are: privacyPolicyData?.pops_are ?? '',
                  disabled: generalTranslationsData?.disabled ?? '',
                  pro_only: privacyPolicyData?.pro_only ?? '',
                  pops: privacyPolicyData?.pops ?? '',
                }}
              />
            ))}
        </Flex>
      </Flex>
    </StaticContentSkeleton>
  )
}

function StatusItem({
  title,
  subtitle,
  colour = 'white',
}: {
  title: string
  subtitle: string
  colour?: string
}) {
  return (
    <>
      <Flex
        sx={{
          maxWidth: '30rem',
          p: '2.4rem',
          flexDirection: 'column',
          gap: '1.6rem',
          borderRadius: '2.5rem',
          backgroundColor: 'white05',
        }}
      >
        <Text variant="text.body1" color="white50" as="p">
          {title}
        </Text>
        <Text variant="text.heading1" color={colour}>
          {subtitle}
        </Text>
      </Flex>
    </>
  )
}

export const data = graphql`
  query StatusPage {
    directus {
      status_page {
        strings {
          languages_code {
            code
          }
          connected_users
          location_status
          network_utilization
          pops
          registered_users
          subtitle
          title
          usage
          meta_description
          meta_keywords
          pops_are
          pro_only
        }
      }
      general_translations {
        strings {
          languages_code {
            code
          }
          disabled
        }
      }
    }
  }
`
