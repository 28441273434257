import Svg from 'components/UI/SVG'
import WatchAVideoFeature from 'components/features-page/WatchAVideoFeature'
import { ReactElement, ReactNode } from 'react'
import { Flex, Text } from 'theme-ui'

export default function ImageEdgesFeatures({
  firstSubtitle,
  title,
  secondSubtitle,
  icon,
  imageLeft,
  imageRight,
  category,
  categoryColour,
  videoButton,
}: {
  title: string
  category: string
  firstSubtitle: string
  secondSubtitle?: string
  icon: React.ElementType
  categoryColour: string
  imageLeft: ReactNode
  imageRight: ReactNode
  videoButton?: {
    text: string
    href: string
  }
}): ReactElement {
  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        width: 'inherit',
        flexDirection: ['column', 'column', 'row'],
        alignItems: 'center',
      }}
    >
      <Flex
        sx={{
          mb: ['3.2rem', 0, 0],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {imageLeft}
      </Flex>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: ['center', 'center', 'center'],
          justifyContent: 'center',
          maxWidth: ['100%', '70%', '32.8rem'],
          my: ['3.2rem', '4.8rem', 0],
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            gap: '2.4rem',
            flexDirection: ['column', 'column', 'row'],
          }}
        >
          <Svg svg={icon} />
          <Text
            sx={{
              color: categoryColour,
              fontSize: '2.8rem',
              fontWeight: 'bold',
            }}
          >
            {category}
          </Text>
        </Flex>
        <Flex
          sx={{ flexDirection: 'column', alignItems: 'center', maxWidth: ['75%', '65%', '90%'] }}
        >
          <Text
            sx={{
              fontSize: '2.8rem',
              fontWeight: 'bold',
              my: '2.4rem',
              textAlign: 'center',
            }}
          >
            {title}
          </Text>
          <Flex
            sx={{
              flexDirection: 'column',
              gap: '2.4rem',
            }}
          >
            <Text sx={{ color: 'white50', textAlign: 'center' }}>{firstSubtitle}</Text>
            {secondSubtitle && (
              <Text sx={{ color: 'white50', textAlign: 'center' }}>{secondSubtitle}</Text>
            )}
          </Flex>
        </Flex>
        {videoButton?.text && (
          <WatchAVideoFeature
            colour={categoryColour}
            text={videoButton.text}
            href={videoButton.href}
          />
        )}
      </Flex>
      <Flex
        sx={{
          mt: ['3.2rem', 0, 0],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {imageRight}
      </Flex>
    </Flex>
  )
}
