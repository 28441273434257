import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function LocationWarp({
  data,
}: {
  data: Queries.LocationWarpPageQuery
}): ReactElement {
  const locationWarpData = getEnglishStrings(data?.directus?.location_warp?.strings)
  const queryParam = useFeaturePageSlug()

  return (
    <FeaturePageSkeleton
      title={locationWarpData?.title ?? ''}
      subtitle={locationWarpData?.subtitle ?? ''}
      gridItems={[
        {
          title: locationWarpData?.spoof_your_location ?? '',
          description: locationWarpData?.spoof_your_location_subtitle ?? '',
        },
        {
          title: locationWarpData?.bypass ?? '',
          description: locationWarpData?.bypass_subtitle ?? '',
        },
        {
          title: locationWarpData?.no_gps_antenna ?? '',
          description: locationWarpData?.no_gps_antenna_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {locationWarpData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[]}
      metaDescription={locationWarpData?.meta_description ?? ''}
      metaKeywords={locationWarpData?.meta_keywords ?? []}
    />
  )
}

export const data = graphql`
  query LocationWarpPage {
    directus {
      location_warp {
        strings {
          bypass
          bypass_subtitle
          cta
          languages_code {
            code
          }
          meta_description
          meta_keywords
          no_gps_antenna
          no_gps_antenna_subtitle
          spoof_your_location
          spoof_your_location_subtitle
          subtitle
          title
        }
      }
    }
  }
`
