import Tag from 'components/Tag'
import { AboutTheFeature } from 'components/features-page/FeaturePageSkeleton'
import { ReactElement } from 'react'
import ReactMarkdown from 'react-markdown'
import { Flex, Text } from 'theme-ui'

export default function FeatureInfo({ feature }: { feature: AboutTheFeature }): ReactElement {
  return (
    <Flex
      sx={{
        gap: ['3.2rem', '4.8rem'],
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Tag
        title={feature.title}
        backgroundColour="white10"
        textColour="white"
        padding="1rem 3.2rem"
      />
      <Flex
        sx={{
          padding: ['2.4rem 1.6rem', '2.4rem'],
          flexDirection: 'column',
          maxWidth: ['100%', '90%', '72.6rem'],
          backgroundColor: 'white05',
          borderRadius: '2.4rem',
          border: ({ colors }) => `0.1rem solid ${colors?.white15}`,
        }}
      >
        {feature.items.map((item, index) => (
          <Flex key={index} sx={{ alignItems: 'flex-start', flexDirection: 'column' }}>
            <Flex sx={{ gap: ['1.6rem', '2.4rem'], alignItems: 'center' }}>
              {feature.items.length > 1 && (
                <Flex
                  sx={{
                    backgroundColor: 'white10',
                    width: '3.2rem',
                    height: '3.2rem',
                    flexShrink: 0,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                  }}
                >
                  <Text
                    sx={{
                      fontSize: '1.8rem',
                      fontWeight: 'bold',
                      color: 'white50',
                    }}
                  >
                    {index + 1}
                  </Text>
                </Flex>
              )}
              <Text
                variant="text.body2"
                sx={{
                  color: 'white50',
                  lineHeight: '2.7rem',
                  p: { m: 0 },
                }}
              >
                <ReactMarkdown sx={{ whiteSpace: 'pre-wrap' }}>{item}</ReactMarkdown>
              </Text>
            </Flex>
            {/* make sure the item is not the last */}
            {feature.items.length > 1 && index != feature.items.length - 1 && (
              <div
                sx={{ height: '0.1rem', width: '100%', backgroundColor: 'white15', my: '2.4rem' }}
              />
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}
