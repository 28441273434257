import { ContentBlock } from 'components/StaticPageLayout'
import ExternalLink from 'components/UI/ExternalLink'
import InternalLink from 'components/UI/InternalLink'
import Svg from 'components/UI/SVG'
import { graphql, useStaticQuery } from 'gatsby'
import DiscordIcon from 'images/footer/discord-icon.svg'
import RedditIcon from 'images/footer/reddit-icon.svg'
import TelegramIcon from 'images/footer/telegram-icon.svg'
import XIcon from 'images/footer/x-icon.svg'
import YoutubeIcon from 'images/footer/youtube-icon.svg'
import windscribeLogo from 'images/windscribe-logo.svg'
import FooterImage from 'images/footer-image.png'
import { ReactElement } from 'react'
import { Box, Flex, Grid, Text } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'

export default function Footer(): ReactElement {
  const data = useStaticQuery<Queries.FooterQueryQuery>(graphql`
    query FooterQuery {
      directus {
        Footer {
          strings {
            about_us
            apps
            blog
            changelog
            company
            discord
            download
            ethics
            jobs
            open_source
            languages_code {
              code
            }
            pricing
            privacy
            reddit
            refer_a_friend
            status
            support
            terms
            twitter
            vdp
            vpn_for_android
            vpn_for_chrome
            vpn_for_firefox
            vpn_for_firetv
            vpn_for_huawei
            vpn_for_iphone
            vpn_for_linux
            vpn_for_mac
            vpn_for_routers
            vpn_for_windows
            windscribe_dns
            windscribe_limited
            wtf_fest
            youtube
            vpn_for_edge
            vpn_for_fdroid
            features
          }
        }
      }
    }
  `)

  const footerData = getEnglishStrings(data.directus?.Footer?.strings)

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',

        // sort of hacky approach to fix an issue where `RoundedBorder` component is used but
        // the content is very short (for example, the status page in staging)
        zIndex: 2,
      }}
    >
      <Flex
        sx={{
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <img
          src={FooterImage}
          alt=""
          style={{ height: 'auto', maxWidth: '78.2rem', width: '100%' }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '-2px',
            border: '2px solid #232323',
            width: '100vw',
            zIndex: '-10',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '2px',
            width: '100vw',
            border: '2px solid #0A0A0A',
            zIndex: '-10',
          }}
        />
      </Flex>
      <Flex
        sx={{
          zIndex: 0,
          py: '4.8rem',
          justifyContent: 'center',
          backgroundColor: 'darkBlue',
        }}
        data-testid="footer"
      >
        <ContentBlock>
          <Grid
            columns={[1, 4, 7]}
            sx={{
              gap: '3.2rem 6rem',
              width: '100%',
              a: { width: 'fit-content' },
            }}
          >
            <Flex sx={{ gap: '1.6rem', flexDirection: 'column', gridColumn: 'span 2' }}>
              <Text sx={{ fontWeight: 'bold', color: 'white' }}>Windscribe</Text>
              <Grid columns={[1, 2]} sx={{ gap: '2.4rem' }}>
                <Flex sx={{ gap: '1.6rem', flexDirection: 'column' }}>
                  <ExternalLink href="/download">
                    <Text>{footerData?.download}</Text>
                  </ExternalLink>
                  <ExternalLink href="/upgrade">
                    <Text>{footerData?.pricing}</Text>
                  </ExternalLink>
                  <InternalLink href="/knowledge-base">
                    <Text>{footerData?.support}</Text>
                  </InternalLink>
                  <InternalLink href="/features">
                    <Text>{footerData?.features}</Text>
                  </InternalLink>
                  <ExternalLink href="/referafriend">
                    <Text>{footerData?.refer_a_friend}</Text>
                  </ExternalLink>
                  <ExternalLink href="/ethics">
                    <Text>{footerData?.ethics}</Text>
                  </ExternalLink>
                </Flex>
                <Flex sx={{ gap: '1.6rem', flexDirection: 'column' }}>
                  <ExternalLink href="/changelog">
                    <Text>{footerData?.changelog}</Text>
                  </ExternalLink>
                  <ExternalLink href="/wtf">
                    <Text>{footerData?.wtf_fest}</Text>
                  </ExternalLink>
                  <ExternalLink href="/status">
                    <Text>{footerData?.status}</Text>
                  </ExternalLink>
                  <ExternalLink href="https://github.com/windscribe">
                    <Text>{footerData?.open_source}</Text>
                  </ExternalLink>
                  <ExternalLink href="http://controld.com/?cid=ws-footer">
                    <Text>{footerData?.windscribe_dns}</Text>
                  </ExternalLink>
                </Flex>
              </Grid>
            </Flex>
            <Flex sx={{ gap: '1.6rem', flexDirection: 'column', gridColumn: 'span 2' }}>
              <Text sx={{ fontWeight: 'bold', color: 'white' }}>{footerData?.apps}</Text>
              <Grid columns={[1, 2]} sx={{ gap: '2.4rem' }}>
                <Flex sx={{ gap: '1.6rem', flexDirection: 'column' }}>
                  <InternalLink href="/features/windows">
                    <Text>{footerData?.vpn_for_windows}</Text>
                  </InternalLink>
                  <InternalLink href="/features/macos">
                    <Text>{footerData?.vpn_for_mac}</Text>
                  </InternalLink>
                  <InternalLink href="/features/chrome">
                    <Text>{footerData?.vpn_for_chrome}</Text>
                  </InternalLink>
                  <InternalLink href="/features/firefox">
                    <Text>{footerData?.vpn_for_firefox}</Text>
                  </InternalLink>
                  <InternalLink href="/features/edge">
                    <Text>{footerData?.vpn_for_edge}</Text>
                  </InternalLink>
                  <InternalLink href="/features/ios">
                    <Text>{footerData?.vpn_for_iphone}</Text>
                  </InternalLink>
                </Flex>
                <Flex sx={{ gap: '1.6rem', flexDirection: 'column' }}>
                  <InternalLink href="/features/android">
                    <Text>{footerData?.vpn_for_android}</Text>
                  </InternalLink>
                  <InternalLink href="/features/linux">
                    <Text>{footerData?.vpn_for_linux}</Text>
                  </InternalLink>
                  <ExternalLink href="https://www.amazon.com/Windscribe-VPN/dp/B076ZYV6HQ/">
                    <Text>{footerData?.vpn_for_firetv}</Text>
                  </ExternalLink>
                  <ExternalLink href="https://appgallery.huawei.com/#/app/C102125657">
                    <Text>{footerData?.vpn_for_huawei}</Text>
                  </ExternalLink>
                  <InternalLink href="/knowledge-base/articles/what-routers-are-supported-by-windscribe/">
                    <Text>{footerData?.vpn_for_routers}</Text>
                  </InternalLink>
                  <ExternalLink href="https://f-droid.org/en/packages/com.windscribe.vpn/">
                    <Text>{footerData?.vpn_for_fdroid}</Text>
                  </ExternalLink>
                </Flex>
              </Grid>
            </Flex>
            <Flex
              sx={{
                gap: '1.6rem',
                flexDirection: 'column',
                gridColumn: ['span 2', 'span 2', 'span 1'],
              }}
            >
              <Text sx={{ fontWeight: 'bold', color: 'white' }}>{footerData?.company}</Text>
              <Grid sx={{ gap: '1.6rem', flexDirection: 'column' }} columns={[1, 2, 1]}>
                <ExternalLink href="/about">
                  <Text>{footerData?.about_us}</Text>
                </ExternalLink>
                <ExternalLink href="/privacy">
                  <Text>{footerData?.privacy}</Text>
                </ExternalLink>
                <ExternalLink href="/terms">
                  <Text>{footerData?.terms}</Text>
                </ExternalLink>
                <ExternalLink href="/vdp">
                  <Text>{footerData?.vdp}</Text>
                </ExternalLink>
                <ExternalLink href="https://angel.co/company/windscribe">
                  <Text>{footerData?.jobs}</Text>
                </ExternalLink>
                <ExternalLink href="https://blog.windscribe.com/">
                  <Text>{footerData?.blog}</Text>
                </ExternalLink>
              </Grid>
            </Flex>
            <Flex sx={{ gap: '1.6rem', flexDirection: 'column', gridColumn: 'span 2' }}>
              <Svg svg={windscribeLogo} sx={{ width: '4.8rem', height: '4.8rem' }} />
              <Flex sx={{ gap: '1.6rem', width: 'fit-content' }}>
                <ExternalLink href="https://www.youtube.com/c/Windscribe">
                  <Svg svg={YoutubeIcon} />
                </ExternalLink>
                <ExternalLink href="https://www.reddit.com/r/Windscribe/">
                  <Svg svg={RedditIcon} />
                </ExternalLink>
                <ExternalLink href="https://twitter.com/windscribecom">
                  <Svg svg={XIcon} />
                </ExternalLink>
                <ExternalLink href="https://discord.gg/vpn">
                  <Svg svg={DiscordIcon} />
                </ExternalLink>
                <ExternalLink href="https://t.me/WindscribeHQ">
                  <Svg svg={TelegramIcon} />
                </ExternalLink>
              </Flex>
              <Text sx={{ color: 'white50' }}>
                © {new Date().getFullYear()} {footerData?.windscribe_limited}
              </Text>
            </Flex>
          </Grid>
        </ContentBlock>
      </Flex>
    </Flex>
  )
}
