import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import NetworkPageCountries from 'components/features-page/NetworkPageCountries'
import { graphql } from 'gatsby'
import LargeNetworkImage from 'images/large-network.png'
import { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function LargeNetwork({ data }: { data: Queries.LargeNetworkQuery }): ReactElement {
  const largeNetworkStrings = getEnglishStrings(data.directus.large_network_page?.strings)
  const generalTranslations = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()

  return (
    <FeaturePageSkeleton
      metaDescription={largeNetworkStrings?.meta_description ?? ''}
      metaKeywords={largeNetworkStrings?.meta_keywords ?? []}
      title={largeNetworkStrings?.title ?? ''}
      subtitle={largeNetworkStrings?.subtitle ?? ''}
      featureImage={LargeNetworkImage}
      summaryText={largeNetworkStrings?.summary ?? ''}
      gridItems={[
        {
          title: largeNetworkStrings?.amount_of_countries ?? '',
          description: largeNetworkStrings?.amount_of_countries_subtitle ?? '',
        },
        {
          title: largeNetworkStrings?.amount_of_cities ?? '',
          description: largeNetworkStrings?.amount_of_cities_subtitle ?? '',
        },
        {
          title: largeNetworkStrings?.no_virtual ?? '',
          description: largeNetworkStrings?.no_virtual_subtitle ?? '',
        },
        {
          title: largeNetworkStrings?.ram_disk ?? '',
          description: largeNetworkStrings?.ram_disk_subtitle ?? '',
        },
        {
          title: largeNetworkStrings?.gigabit_servers ?? '',
          description: largeNetworkStrings?.gigabit_servers_subtitle ?? '',
        },
        {
          title: largeNetworkStrings?.diverse_hosting ?? '',
          description: largeNetworkStrings?.diverse_hosting_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {largeNetworkStrings?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslations?.by_the_way ?? '',
          items: [
            largeNetworkStrings?.if_you_dont_need ?? '',
            largeNetworkStrings?.whats_in_a_location ?? '',
          ],
        },
      ]}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          my: 'default',
          alignItems: 'center',
          gap: '4.8rem',
        }}
      >
        <Text variant="text.heading1" sx={{ textAlign: 'center' }}>
          {largeNetworkStrings?.our_server_locations}
        </Text>
        <NetworkPageCountries data={data} />
      </Flex>
    </FeaturePageSkeleton>
  )
}

export const Countriesquery = graphql`
  query LargeNetwork {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          austria
          belgium
          bulgaria
          canada
          czech_republic
          denmark
          finland
          france
          germany
          hong_kong
          hungary
          ireland
          italy
          latvia
          lithuania
          moldova
          netherlands
          norway
          poland
          portugal
          romania
          slovakia
          spain
          sweden
          switzerland
          turkey
          united_kingdom
          united_states
          albania
          australia
          argentina
          azerbaijan
          bosnia
          brazil
          cambodia
          chile
          colombia
          croatia
          cyprus
          ecuador
          estonia
          fake_antarctica
          ghana
          greece
          georgia
          iceland
          india
          indonesia
          israel
          japan
          kenya
          luxembourg
          malaysia
          mexico
          new_zealand
          north_macedonia
          panama
          peru
          philippines
          russia
          serbia
          singapore
          south_africa
          south_korea
          taiwan
          thailand
          uae
          ukraine
          vietnam
          show_more
          show_less
          summary
          by_the_way
          your_moms_house
        }
      }
      large_network_page {
        strings {
          languages_code {
            code
          }
          our_server_locations
          title
          subtitle
          amount_of_cities
          amount_of_cities_subtitle
          amount_of_countries
          amount_of_countries_subtitle
          diverse_hosting
          diverse_hosting_subtitle
          gigabit_servers
          gigabit_servers_subtitle
          if_you_dont_need
          no_virtual
          no_virtual_subtitle
          ram_disk
          ram_disk_subtitle
          whats_in_a_location
          summary
          cta
          meta_description
          meta_keywords
        }
      }
    }
  }
`
