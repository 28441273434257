import FeaturesPageTrackedButton from 'components/features-page/FeaturesPageTrackedButton'
import Svg from 'components/UI/SVG'
import androidIcon from 'images/platforms/android.svg'
import iosIcon from 'images/platforms/apple.svg'
import { Flex, Text } from 'theme-ui'

interface OSType {
  icon: React.ElementType
  text: string
  link: string
}

interface MobileButton {
  android: OSType
  ios: OSType
}

export default function MobileDownloadButtons({
  iosText,
  androidText,
  type,
}: {
  androidText: string
  iosText: string
  type: 'android' | 'ios'
}): JSX.Element {
  const mobileButtons: MobileButton = {
    android: {
      icon: androidIcon,
      text: androidText,
      link: '/install/mobile/android',
    },
    ios: {
      icon: iosIcon,
      text: iosText,
      link: '/install/mobile/ios',
    },
  }

  return (
    <FeaturesPageTrackedButton url={mobileButtons[type]?.link} dataTestId="mobile-download-button">
      <Flex sx={{ justifyContent: 'center', alignItems: 'center', gap: '1.6rem' }}>
        <Svg svg={mobileButtons[type].icon} />
        <Text>{mobileButtons[type].text}</Text>
      </Flex>
    </FeaturesPageTrackedButton>
  )
}
