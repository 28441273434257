import { navigate } from 'gatsby'
import { defineBrowser } from 'utils/downloadPageUtils'
import useCurrentPlatform from 'utils/useCurrentPlatform'

export default function InstalledDesktop(): JSX.Element {
  const { browser } = useCurrentPlatform()
  const formattedOs = defineBrowser(browser).toLowerCase()

  const selectedBrowser =
    {
      chrome: 'chrome',
      firefox: 'firefox',
      edge: 'edge',
    }[formattedOs] ?? 'chrome'

  typeof window !== 'undefined' && navigate(`/features/${selectedBrowser}`)
  return <></>
}
