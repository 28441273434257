import React, { ReactElement, forwardRef } from 'react'
import { Link, LinkProps, ThemeUIStyleObject } from 'theme-ui'

interface ExternalLinkProps extends LinkProps {
  sxx?: ThemeUIStyleObject
  dataTestId?: string
}

export default forwardRef<HTMLAnchorElement, ExternalLinkProps>(function ExternalLink(
  props: ExternalLinkProps,
  ref,
): ReactElement {
  const { sxx, children, dataTestId, ...rest } = props
  return (
    <Link
      ref={ref}
      sx={{
        fontFamily: 'IBM Plex Sans',
        textDecoration: 'none',
        ...sxx,
      }}
      data-testid={dataTestId}
      {...rest}
    >
      {props.children}
    </Link>
  )
})
