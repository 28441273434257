import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function ProxyGateway({ data }: { data: Queries.ProxyGatewayQuery }): ReactElement {
  const proxyGatewayData = getEnglishStrings(data.directus.proxy_gateway?.strings)
  const queryParam = useFeaturePageSlug()

  return (
    <FeaturePageSkeleton
      title={proxyGatewayData?.title ?? ''}
      subtitle={proxyGatewayData?.subtitle ?? ''}
      gridItems={[
        {
          title: proxyGatewayData?.proxy_server ?? '',
          description: proxyGatewayData?.proxy_server_subtitle ?? '',
        },
        {
          title: proxyGatewayData?.http_or ?? '',
          description: proxyGatewayData?.http_or_subtitle ?? '',
        },
        {
          title: proxyGatewayData?.connect_to_vpn ?? '',
          description: proxyGatewayData?.connect_to_vpn_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink sx={{ variant: 'buttons.neon' }} href={`/download?${queryParam}`}>
          {proxyGatewayData?.cta}
        </InternalLink>
      }
      summaryText={proxyGatewayData?.summary_text ?? ''}
      aboutTheFeature={[
        {
          title: proxyGatewayData?.how_to_use_it ?? '',
          items: [
            proxyGatewayData?.go_into_windscribe ?? '',
            proxyGatewayData?.choose_the_proxy ?? '',
            proxyGatewayData?.use_the_provided ?? '',
          ],
        },
      ]}
      metaDescription={proxyGatewayData?.meta_description ?? ''}
      metaKeywords={proxyGatewayData?.meta_keywords}
    />
  )
}

export const data = graphql`
  query ProxyGateway {
    directus {
      proxy_gateway {
        strings {
          languages_code {
            code
          }
          choose_the_proxy
          connect_to_vpn
          connect_to_vpn_subtitle
          cta
          go_into_windscribe
          how_to_use_it
          http_or
          http_or_subtitle
          meta_description
          meta_keywords
          proxy_server
          proxy_server_subtitle
          subtitle
          summary_text
          title
          use_the_provided
        }
      }
    }
  }
`
