import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import SplitTunneling from 'images/split-tunneling.png'
import { ReactElement } from 'react'
import { Text } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function SplitTunnelingPage({
  data,
}: {
  data: Queries.SplitTunnelingPageQuery
}): ReactElement {
  const splitTunnelingData = getEnglishStrings(data.directus.split_tunneling?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={splitTunnelingData?.meta_description || ''}
      metaKeywords={splitTunnelingData?.meta_keywords || []}
      title={splitTunnelingData?.title || ''}
      subtitle={splitTunnelingData?.subtitle || ''}
      featureImage={SplitTunneling}
      summaryText={splitTunnelingData?.summary_text || ''}
      gridItems={[
        {
          title: splitTunnelingData?.exclusive_mode || '',
          description: splitTunnelingData?.exclusive_mode_subtitle || '',
        },
        {
          title: splitTunnelingData?.inclusive_mode || '',
          description: splitTunnelingData?.inclusive_mode_subtitle || '',
        },
        {
          title: splitTunnelingData?.app_based || '',
          description: splitTunnelingData?.app_based_subtitle || '',
        },
        {
          title: splitTunnelingData?.ip_address || '',
          description: splitTunnelingData?.ip_address_subtitle || '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          <Text>{splitTunnelingData?.cta_text}</Text>
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way || '',
          items: [
            splitTunnelingData?.some_applications || '',
            splitTunnelingData?.regardless_of_whether || '',
          ],
        },
      ]}
    />
  )
}

export const data = graphql`
  query SplitTunnelingPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      split_tunneling {
        strings {
          title
          subtitle
          summary_text
          exclusive_mode
          exclusive_mode_subtitle
          inclusive_mode
          inclusive_mode_subtitle
          app_based
          app_based_subtitle
          ip_address
          ip_address_subtitle
          cta_text
          some_applications
          regardless_of_whether
          meta_description
          meta_keywords
          languages_code {
            code
          }
        }
      }
    }
  }
`
