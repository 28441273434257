import ExternalLink from 'components/UI/ExternalLink'
import { ReactNode } from 'react'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function FeaturesPageTrackedButton({
  url,
  children,
  variant = 'buttons.neonGreen',
  dataTestId,
}: {
  url: string
  children: ReactNode
  variant?: string
  dataTestId?: string
}): JSX.Element {
  const queryParam = useFeaturePageSlug()

  //Preserves query parameters when appending a new one
  const hasQueryParams = url.includes('?')

  return (
    <>
      <ExternalLink
        href={`${url.replace(/\/$/, '')}${hasQueryParams ? '&' : '?'}${queryParam}`}
        target="_blank"
        sxx={{ textDecoration: 'none', variant: variant, maxWidth: 'max-content' }}
        dataTestId={dataTestId}
      >
        {children}
      </ExternalLink>
    </>
  )
}
