import StaticPageLayout from 'components/StaticPageLayout'
import CircleIconContainer from 'components/UI/CircleIconContainer'
import CollapsableTextPanel from 'components/UI/CollapsableTextPanel'
import ExternalLink from 'components/UI/ExternalLink'
import Svg from 'components/UI/SVG'
import { graphql } from 'gatsby'
import AdTrackers from 'images/ad-trackers-icon.svg'
import BlockAdsAndTrackersIcon from 'images/block-ads-and-trackers-icon.svg'
import BrowseFasterIcon from 'images/browse-faster-icon.svg'
import Competitors from 'images/competitors-icon.svg'
import ConfigureYourRobert from 'images/configure-your-robert.png'
import Crypto from 'images/crypto-icon.svg'
import CustomAccessRulesKeyIcon from 'images/custom-access-rules-key-icon.svg'
import CustomAccessRulesLockIcon from 'images/custom-access-rules-lock-icon.svg'
import CustomAccessRules from 'images/custom-access-rules.svg'
import FakeNewsAndClickbait from 'images/fake-news-and-clickbait-icon.svg'
import Gambling from 'images/gambling-icon.svg'
import HouseBlockListsIcon from 'images/house-block-lists-icon.svg'
import HouseBlockLists from 'images/house-block-lists.svg'
import InstantChanges from 'images/instant-changes.png'
import MalwareIcon from 'images/malware-icon.svg'
import Porn from 'images/porn-icon.svg'
import PreventInfectionsIcon from 'images/prevent-infections-icon.svg'
import ReduceRiskOfInfection from 'images/reduce-risk-of-infection.png'
import ReduceTracking from 'images/reduce-tracking.png'
import Robert from 'images/robert-features-icon.svg'
import SeeFewerAds from 'images/see-fewer-ads.png'
import SocialNetworks from 'images/social-networks-icon.svg'
import TotallyCustomizable from 'images/totally-customizable.png'
import UseLessData from 'images/use-less-data.png'
import { ReactElement, ReactNode } from 'react'
import { Flex, Grid, Text, ThemeUIStyleObject } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

const tableLeftStyle: ThemeUIStyleObject = {
  borderTopLeftRadius: '1.6rem',
  borderBottomLeftRadius: '1.6rem',
}

const tableRightStyle: ThemeUIStyleObject = {
  borderTopRightRadius: '1.6rem',
  borderBottomRightRadius: '1.6rem',
}

export default function RobertPage({ data }: { data: Queries.RobertPageQuery }): ReactElement {
  const robertData = getEnglishStrings(data.directus.robert?.strings)
  const queryParam = useFeaturePageSlug()

  return (
    <StaticPageLayout
      title={`${robertData?.title} | Windscribe`}
      ctaQueryParams={queryParam}
      metaDescription={robertData?.meta_description}
      metaKeywords={robertData?.meta_keywords}
    >
      <Flex
        data-testid="robert"
        sx={{
          width: '100vw',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '10rem',
        }}
      >
        <Flex
          sx={{
            width: 'min-content',
            alignItems: 'center',
            justifyContent: 'center',
            gap: ['2.4rem', '4.8rem', '8rem'],
            marginTop: '3.6rem',
            marginBottom: ['4.8rem', '8rem'],
            px: '1.6rem',
            flexDirection: ['column', 'row'],
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              data-testid="robert-title"
              sx={{
                fontSize: ['3.4rem', '4.8rem'],
                color: 'white',
                fontWeight: 'bold',
                marginBottom: ['2.4rem', '1.6rem'],
                maxWidth: 'max-content',
              }}
              as="p"
            >
              {robertData?.title}
            </Text>
            <Flex>
              <Text
                sx={{
                  fontSize: '1.8rem',
                  color: 'white50',
                }}
              >
                {robertData?.subtitle}
              </Text>
            </Flex>
          </Flex>
          <Svg svg={Robert} sx={{ minWidth: '22.8rem', minHeight: '22.8rem' }} />
        </Flex>
        <Flex
          sx={{
            width: '100%',
            px: ['1.6rem', '6rem'],
            justifyContent: 'center',
          }}
        >
          <Grid
            columns={[1, 2, 4]}
            sx={{
              width: '100%',
              maxWidth: ['100%', '54.6rem', '110rem'],
              gap: ['3.2rem', '4.8rem'],
            }}
          >
            <RobertFeatureWithIcon
              icon={<CircleIconContainer icon={BlockAdsAndTrackersIcon} colour="periwinkle" />}
              title={robertData?.block_ads_and_trackers}
              subtitle={robertData?.block_ads_and_trackers_text}
            />
            <RobertFeatureWithIcon
              icon={<CircleIconContainer icon={PreventInfectionsIcon} colour="periwinkle" />}
              title={robertData?.prevent_infections}
              subtitle={robertData?.prevent_infections_text}
            />
            <RobertFeatureWithIcon
              icon={<CircleIconContainer icon={CustomAccessRulesKeyIcon} colour="periwinkle" />}
              title={robertData?.custom_access_rules}
              subtitle={robertData?.custom_access_rules_text}
            />
            <RobertFeatureWithIcon
              icon={<CircleIconContainer icon={BrowseFasterIcon} colour="periwinkle" />}
              title={robertData?.browse_faster}
              subtitle={robertData?.browse_faster_text}
            />
          </Grid>
        </Flex>
        <Flex
          sx={{
            width: '100%',
            maxWidth: '124.8rem',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: '3.2rem',
            backgroundImage: ({ colors }) =>
              `linear-gradient(to bottom, ${colors?.midnight} 0%, transparent  100%)`,
            px: ['1.6rem', 0],
            paddingTop: ['4.8rem', '8rem'],
            marginTop: ['4.8rem', '8rem'],
          }}
        >
          <Text sx={{ fontSize: ['2.8rem', '4rem'], color: 'white', fontWeight: 'bold' }}>
            {robertData?.how_it_works}
          </Text>
          <Text
            sx={{
              fontSize: ['1.6rem', '1.8rem'],
              color: 'white50',
              marginTop: '1.6rem',
              marginBottom: ['3.2rem', '4.8rem'],
              maxWidth: '56.9rem',
              textAlign: 'center',
            }}
          >
            {robertData?.how_it_works_text}
          </Text>
          <Flex
            sx={{
              flexDirection: ['column', 'row', 'row'],
              gap: ['4.8rem', '8rem'],
              px: ['1.6rem', '1.6rem', 0],
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <RobertFeatureWithIcon
                icon={<CircleIconContainer icon={HouseBlockListsIcon} colour="periwinkle" />}
                title={robertData?.house_block_lists}
                subtitle={robertData?.house_block_lists_text}
                sxx={{ maxWidth: '30rem', marginBottom: ['3.2rem', '4.8rem'] }}
              />
              <Svg svg={HouseBlockLists} sx={{ height: ['29.5rem', '46rem'], maxWidth: '100%' }} />
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <RobertFeatureWithIcon
                icon={<CircleIconContainer icon={CustomAccessRulesLockIcon} colour="periwinkle" />}
                title={robertData?.custom_access_rules}
                subtitle={robertData?.how_it_works_custom_access_text}
                sxx={{ maxWidth: '30rem', marginBottom: ['3.2rem', '4.8rem'] }}
              />
              <Svg
                svg={CustomAccessRules}
                sx={{ height: ['29.5rem', '46rem'], maxWidth: '100%' }}
              />
            </Flex>
          </Flex>
        </Flex>

        <Text
          sx={{
            fontSize: ['2.8rem', '4rem'],
            color: 'white',
            fontWeight: 'bold',
            marginTop: ['6.4rem', '8rem'],
            width: '100%',
            textAlign: 'center',
          }}
        >
          {robertData?.benefits}
        </Text>
        <Flex
          sx={{
            width: '100%',
            justifyContent: 'center',
            mt: ['3.2rem', '4.8rem'],
          }}
        >
          <Grid
            columns={[1, 3, 3]}
            sx={{
              flexDirection: ['column', 'row'],
              maxWidth: 'maxContentWidth',
              px: ['1.6rem', '2.4rem'],
              gap: ['3.2rem', '8rem 4.2rem'],
            }}
          >
            <RobertFeatureWithIcon
              icon={<img src={ReduceTracking} alt="reduce tracking" />}
              title={robertData?.reduce_tracking}
              subtitle={robertData?.reduce_tracking_text}
            />
            <RobertFeatureWithIcon
              icon={<img src={UseLessData} alt="use less data" />}
              title={robertData?.use_less_data}
              subtitle={robertData?.use_less_data_text}
            />
            <RobertFeatureWithIcon
              icon={<img src={SeeFewerAds} alt="see fewer ads" />}
              title={robertData?.see_fewer_ads}
              subtitle={robertData?.see_fewer_ads_text}
            />
            <RobertFeatureWithIcon
              icon={<img src={InstantChanges} alt="instant changes" />}
              title={robertData?.instant_changes}
              subtitle={robertData?.instant_changes_text}
            />
            <RobertFeatureWithIcon
              icon={<img src={TotallyCustomizable} alt="totally customizable" />}
              title={robertData?.totally_customizable}
              subtitle={robertData?.totally_customizable_text}
            />
            <RobertFeatureWithIcon
              icon={<img src={ReduceRiskOfInfection} alt="reduce risk of infection" />}
              title={robertData?.reduce_risk_of_infection}
              subtitle={robertData?.reduce_risk_of_infection_text}
            />
          </Grid>
        </Flex>
        <Flex
          sx={{
            justifyContent: 'center',
            mt: ['3.2rem', '4.8rem'],
          }}
        >
          <ExternalLink
            sx={{
              variant: 'buttons.neonGreen',
              display: 'inline',
              width: 'auto',
            }}
            href={`/myaccount?${queryParam}#robert`}
          >
            {robertData?.configure_your_robert}
          </ExternalLink>
        </Flex>
        <Flex
          sx={{
            width: '100%',
            maxWidth: 'maxContentWidth',
            alignItems: 'center',
            flexDirection: 'column',
            mt: 'default',
            px: ['1.6rem', '1.6rem', 0],
          }}
        >
          <Text
            sx={{
              fontSize: ['2.8rem', '4rem'],
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            {robertData?.plans}
          </Text>
          <table
            sx={{
              mt: ['3.2rem', '4.8rem'],
              width: '100%',
              maxWidth: ['100%', '100%', '93.2rem'],
              tableLayout: 'fixed',
              borderCollapse: 'collapse',
              borderSpacing: '0',
              'td, th': {
                textAlign: 'center',
                fontSize: '1.6rem',
                height: '6.9rem',
              },
            }}
          >
            {/* Desktop and Table Views */}
            <thead sx={{ display: ['none', 'table-row-group'] }}>
              <tr
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: 'white05',
                }}
              >
                <th sx={tableLeftStyle}>{robertData?.plan_type}</th>
                <th sx={{ color: 'neonGreen' }}>{robertData?.free}</th>
                <th sx={{ color: 'neonGreen' }}>{robertData?.build_a_plan}</th>
                <th
                  sx={{
                    ...tableRightStyle,
                    color: 'neonGreen',
                  }}
                >
                  {robertData?.pro}
                </th>
              </tr>
            </thead>
            <tbody
              sx={{
                color: 'white50',
                '& tr>td:first-child': { color: 'white' },
                display: ['none', 'table-row-group'],
              }}
            >
              <tr sx={{ borderBottom: ({ colors }) => `1px solid ${colors?.white10}` }}>
                <td>{robertData?.block_lists}</td>
                <td>{robertData?.malware_ads}</td>
                <td>{robertData?.all}</td>
                <td>{robertData?.all}</td>
              </tr>
              <tr>
                <td>{robertData?.access_rules}</td>
                <td>3</td>
                <td>10</td>
                <td>1000</td>
              </tr>
            </tbody>

            {/* Mobile View */}
            <thead sx={{ display: ['table-row-group', 'none'] }}>
              <tr
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: 'white05',
                }}
              >
                <th sx={tableLeftStyle}>{robertData?.plan_type}</th>
                <th>{robertData?.block_lists}</th>
                <th sx={tableRightStyle}>{robertData?.access_rules}</th>
              </tr>
            </thead>
            <tbody
              sx={{
                display: ['table-row-group', 'none'],
                color: 'white50',
                '& tr>td:first-child': { color: 'neonGreen', fontWeight: 'bold' },
              }}
            >
              <tr sx={{ borderBottom: ({ colors }) => `1px solid ${colors?.white10}` }}>
                <td>{robertData?.free}</td>
                <td>{robertData?.malware_ads}</td>
                <td>3</td>
              </tr>
              <tr sx={{ borderBottom: ({ colors }) => `1px solid ${colors?.white10}` }}>
                <td>{robertData?.build_a_plan}</td>
                <td>{robertData?.all}</td>
                <td>10</td>
              </tr>
              <tr>
                <td>{robertData?.pro}</td>
                <td>{robertData?.all}</td>
                <td>1000</td>
              </tr>
            </tbody>
          </table>
          <Flex sx={{ mt: ['3.2rem', '4.8rem'] }}>
            <ExternalLink href={`/upgrade?${queryParam}`} sx={{ variant: 'buttons.neonGreen' }}>
              {robertData?.see_pricing}
            </ExternalLink>
          </Flex>

          <Text
            sx={{
              fontSize: ['2.8rem', ' 4rem'],
              color: 'white',
              fontWeight: 'bold',
              marginTop: ['6.4rem', '8rem'],
              marginBottom: '1.6rem',
            }}
          >
            {robertData?.block_list_overview}
          </Text>
          <Text
            sx={{
              fontSize: ['1.6rem', '1.8rem'],
              color: 'white50',
              textAlign: 'center',
              maxWidth: ['100%', '71.4rem'],
              marginBottom: '4.8rem',
            }}
          >
            {robertData?.block_list_overview_text}
          </Text>
          <Flex sx={{ width: '100%', flexDirection: 'column', gap: '1.6rem' }}>
            <CollapsableTextPanel
              icon={MalwareIcon}
              title={robertData?.malware}
              innerText={robertData?.malware_text}
            />
            <CollapsableTextPanel
              icon={AdTrackers}
              title={robertData?.ad_trackers}
              innerText={robertData?.ad_trackers_text}
            />
            <CollapsableTextPanel
              icon={SocialNetworks}
              title={robertData?.social_network}
              innerText={robertData?.social_network_text}
            />
            <CollapsableTextPanel
              icon={Porn}
              title={robertData?.porn}
              innerText={robertData?.porn_text}
            />
            <CollapsableTextPanel
              icon={Gambling}
              title={robertData?.gambling}
              innerText={robertData?.gambling_text}
            />
            <CollapsableTextPanel
              icon={FakeNewsAndClickbait}
              title={robertData?.fake_news_and_clickbait}
              innerText={robertData?.fake_news_and_clickbait_text}
            />
            <CollapsableTextPanel
              icon={Competitors}
              title={robertData?.competitors}
              innerText={robertData?.competitors_text}
            />
            <CollapsableTextPanel
              icon={Crypto}
              title={robertData?.crypto}
              innerText={robertData?.crypto_text}
            />
          </Flex>
          <img
            src={ConfigureYourRobert}
            sx={{ height: ['32rem', '50rem'], mt: ['3.2rem', '4.8rem'] }}
            alt="configure your robert"
          />
          <Flex>
            <ExternalLink
              sx={{ marginTop: ['3.2rem', '4.8rem'], variant: 'buttons.neonGreen' }}
              href={`/myaccount?${queryParam}#robert`}
            >
              {robertData?.configure_your_robert}
            </ExternalLink>
          </Flex>
        </Flex>
      </Flex>
    </StaticPageLayout>
  )
}

function RobertFeatureWithIcon({
  icon,
  title,
  subtitle,
  sxx,
}: {
  icon: ReactNode
  title?: string
  subtitle?: string
  isCircularIcon?: boolean
  sxx?: ThemeUIStyleObject
}): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: '1.6rem',
        ...sxx,
      }}
    >
      {icon}
      <Text
        sx={{
          color: 'white',
          fontSize: '2.1rem',
          fontWeight: 'bold',
        }}
      >
        {title}
      </Text>
      <Text sx={{ color: 'white50', fontSize: '1.6rem' }}>{subtitle}</Text>
    </Flex>
  )
}

export const data = graphql`
  query RobertPage {
    directus {
      robert {
        strings {
          ad_trackers
          ad_trackers_text
          access_rules
          all
          benefits
          block_ads_and_trackers
          block_ads_and_trackers_text
          block_list_overview
          block_list_overview_text
          block_lists
          browse_faster
          browse_faster_text
          build_a_plan
          competitors
          competitors_text
          configure_your_robert
          crypto_text
          crypto
          custom_access_rules
          custom_access_rules_text
          fake_news_and_clickbait
          fake_news_and_clickbait_text
          free
          gambling
          gambling_text
          house_block_lists
          house_block_lists_text
          how_it_works
          how_it_works_text
          how_it_works_custom_access_text
          instant_changes
          instant_changes_text
          languages_code {
            code
          }
          malware
          malware_ads
          malware_text
          plan_type
          plans
          porn
          porn_text
          prevent_infections
          prevent_infections_text
          pro
          reduce_risk_of_infection
          reduce_risk_of_infection_text
          reduce_tracking
          reduce_tracking_text
          use_less_data_text
          use_less_data
          totally_customizable_text
          totally_customizable
          title
          subtitle
          social_network_text
          social_network
          see_pricing
          see_fewer_ads_text
          see_fewer_ads
          meta_description
          meta_keywords
        }
      }
    }
  }
`
