import { combineReducers } from '@reduxjs/toolkit'
import { wsApi } from 'redux/api/wsApi'
import { sessionSlice } from 'store/session'

const rootReducer = combineReducers({
  [wsApi.reducerPath]: wsApi.reducer,
  session: sessionSlice.reducer,
})

export default rootReducer
