import { useLocation } from '@reach/router'
import StaticPageLayout from 'components/StaticPageLayout'
import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'
import { gridBaseStyle, otherFeaturesBaseStyle } from 'components/features-page/DesktopFeaturesPage'
import FeatureBox from 'components/features-page/FeatureBox'
import FeatureImage from 'components/features-page/FeatureImage'
import FeatureText from 'components/features-page/FeatureText'
import MobileDownloadButtons from 'components/features-page/MobileDownloadButtons'
import Background from 'components/features-page/background/Background'
import Fade from 'components/features-page/background/Fade'
import RoundedBorder from 'components/features-page/background/RoundedBorder'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import commandLineIcon from 'images/command-line-icon.svg'
import connectivity from 'images/connectivity.svg'
import dataEncryptedIcon from 'images/data-encrypted-icon.svg'
import globeIcon from 'images/globe-icon.svg'
import networkShield from 'images/network-shield.svg'
import noLoggingIcon from 'images/no-logging-icon.svg'
import robertIcon from 'images/robert-icon.svg'
import splitTunneling from 'images/split-tunneling-icon.svg'
import windscribeApp from 'images/windscribe-app.png'
import { ReactElement } from 'react'
import { Box, Flex, Grid, Text, ThemeUIStyleObject } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'

type TitleAndSubtitle = {
  title: string
  subtitle: string
}

const imageBaseStyle: ThemeUIStyleObject = {
  width: ['34.8rem'],
  height: '100%',
}

export default function MobileFeaturePage(): ReactElement {
  const data = useStaticQuery<Queries.MobileFeaturePageQuery>(graphql`
    query MobileFeaturePage {
      directus {
        mobile_page {
          strings {
            android_title
            android_subtitle
            ios_title
            ios_subtitle
            available_for_android_only
            blocks_ads_and_trackers
            blocks_ads_and_trackers_subtitle
            browse_faster
            browse_faster_subtitle
            connect_on_any_network
            connect_or_disconnect
            connect_or_disconnect_second_subtitle
            connect_or_disconnect_subtitle
            custom_access_rules
            custom_access_rules_subtitle
            download_for_android
            download_for_ios
            encrypts_your_data
            ikev2_subtitle
            import_and_connect
            network_rules
            one_of_a_kind
            one_of_a_kind_subtitle
            openvpn_tcp_subtitle
            openvpn_udp_subtitle
            other_features
            prevent_infections
            prevent_infections_subtitle
            robert
            servers_in_over
            split_tunneling
            strict_no_log
            subtitle
            title
            tunnel_what_you_want
            tunnel_what_you_want_subtitle
            unparalleled_connectivity
            windscribe_offers
            wireguard_subtitle
            meta_description
            meta_keywords
            languages_code {
              code
            }
          }
        }
        general_translations {
          strings {
            features
            languages_code {
              code
            }
            ikev2
            wireguard
            openvpn
            tcp
            udp
          }
        }
        ios_app_page {
          strings {
            languages_code {
              code
            }
            meta_description
            meta_keywords
            title
          }
        }
        android_app_page {
          strings {
            languages_code {
              code
            }
            meta_description
            meta_keywords
            title
          }
        }
      }
    }
  `)

  const location = useLocation()
  const isAndroid = location.pathname.includes('android')

  const mobileFeaturesData = getEnglishStrings(data.directus.mobile_page?.strings)
  const generalTranslations = getEnglishStrings(data.directus.general_translations?.strings)

  const metaData = isAndroid
    ? getEnglishStrings(data?.directus?.android_app_page?.strings)
    : getEnglishStrings(data?.directus?.ios_app_page?.strings)

  const titleAndSubtitleRecord: Record<string, TitleAndSubtitle> = {
    '/features/android/': {
      title: mobileFeaturesData?.android_title ?? '',
      subtitle: mobileFeaturesData?.android_subtitle ?? '',
    },
    '/features/ios/': {
      title: mobileFeaturesData?.ios_title ?? '',
      subtitle: mobileFeaturesData?.ios_subtitle ?? '',
    },
  }

  return (
    <StaticPageLayout
      title={`${metaData?.title} | Windscribe`}
      metaDescription={metaData?.meta_description}
      metaKeywords={metaData?.meta_keywords}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          my: ['3.2rem', '4.8rem'],
        }}
      >
        <TitleAndSubtitle
          title={titleAndSubtitleRecord[location.pathname]?.title ?? ''}
          subtitle={titleAndSubtitleRecord[location.pathname]?.subtitle ?? ''}
          dataTestId="mobile-features-title"
        />
      </Flex>
      <MobileDownloadButtons
        iosText={mobileFeaturesData?.download_for_ios ?? ''}
        androidText={mobileFeaturesData?.download_for_android ?? ''}
        type={location.pathname === '/features/android/' ? 'android' : 'ios'}
      />
      <div
        sx={{
          width: '100vw',
          overflow: 'hidden',
          display: ['block', 'none', 'none'],
          mt: '9.6rem',
        }}
      >
        <img
          src={windscribeApp}
          alt=""
          sx={{ maxHeight: '50rem', width: 'auto', overflow: 'hidden', marginLeft: '1.6rem' }}
        />
      </div>

      <StaticImage
        src="../../images/windscribe-app.png"
        alt="Windscribe app"
        sx={{
          width: ['100%', '72rem', '81rem'],
          mt: '4.8rem',
          display: ['none', 'flex', 'flex'],
        }}
      />
      <Flex
        sx={{
          borderRadius: '2.1rem',
          backgroundColor: 'white10',
          alignItems: 'center',
          justifyContent: 'center',
          width: '14.1rem',
          height: '4rem',
          textTransform: 'uppercase',
          mt: ['3.2rem', '4.8rem', '4.8rem'],
        }}
      >
        <Text
          sx={{
            fontSize: '1.8rem',
            fontWeight: 'bold',
            color: 'white50',
          }}
        >
          {generalTranslations?.features}
        </Text>
      </Flex>
      <Background display={['flex', 'flex', 'flex']}>
        <Fade
          height={['300rem', '280rem', '180rem']}
          display={['flex', 'flex', 'flex']}
          backgroundImage={({ colors }): string =>
            `linear-gradient(to bottom, ${colors?.background} 0%, ${colors?.midnight} 20%, ${colors?.midnight} 80%, ${colors?.background} 95%, ${colors?.background})`
          }
        />
        <RoundedBorder minHeight={['50rem', '77.1rem', '50.5rem']} roundedEdge="bottom" />
      </Background>
      <Flex sx={{ mt: ['3.2rem', '4.8rem'] }}>
        <FeatureImage
          imageSide="right"
          title={mobileFeaturesData?.connect_on_any_network ?? ''}
          category={mobileFeaturesData?.unparalleled_connectivity ?? ''}
          firstSubtitle={mobileFeaturesData?.windscribe_offers ?? ''}
          icon={connectivity}
          categoryColour="windscribeBlue"
          imageStyles={{ display: 'flex', justifyContent: ['center', 'center', 'flex-end'] }}
        >
          <StaticImage src="../../images/connection-app.png" alt="" sx={imageBaseStyle} />
        </FeatureImage>
      </Flex>
      <Grid
        columns={[1, 2, 2]}
        sx={{
          ...gridBaseStyle,
          px: 0,
          mt: ['3.2rem', '3.2rem', 0],
        }}
      >
        <FeatureBox
          title={generalTranslations?.wireguard ?? ''}
          subtitle={mobileFeaturesData?.wireguard_subtitle ?? ''}
          colour="windscribeBlue"
        />
        <FeatureBox
          title={generalTranslations?.ikev2 ?? ''}
          subtitle={mobileFeaturesData?.ikev2_subtitle ?? ''}
          colour="windscribeBlue"
        />
        <FeatureBox
          title={`${generalTranslations?.openvpn} (${generalTranslations?.tcp})`}
          subtitle={mobileFeaturesData?.openvpn_tcp_subtitle ?? ''}
          colour="windscribeBlue"
        />
        <FeatureBox
          title={`${generalTranslations?.openvpn} (${generalTranslations?.udp})`}
          subtitle={mobileFeaturesData?.openvpn_udp_subtitle ?? ''}
          colour="windscribeBlue"
        />
      </Grid>
      <MobileContainer>
        <FeatureImage
          imageSide="right"
          title={mobileFeaturesData?.connect_or_disconnect ?? ''}
          category={mobileFeaturesData?.network_rules ?? ''}
          firstSubtitle={mobileFeaturesData?.connect_or_disconnect_subtitle ?? ''}
          secondSubtitle={mobileFeaturesData?.connect_or_disconnect_second_subtitle ?? ''}
          icon={networkShield}
          categoryColour="neonGreen"
          imageStyles={{ display: 'flex', justifyContent: ['center', 'center', 'flex-end'] }}
        >
          <StaticImage src="../../images/network-options-app.png" alt="" sx={imageBaseStyle} />
        </FeatureImage>
      </MobileContainer>
      <MobileContainer />
      <Background display={[isAndroid ? 'flex' : 'none', 'flex', 'flex']}>
        <RoundedBorder
          minHeight={['50rem', '77.1rem', '50.5rem']}
          roundedEdge="top"
          width={['100vw', '100vw', '95%']}
        />
      </Background>
      {isAndroid && (
        <>
          <FeatureImage
            imageSide="left"
            title={mobileFeaturesData?.tunnel_what_you_want ?? ''}
            category={mobileFeaturesData?.split_tunneling ?? ''}
            firstSubtitle={mobileFeaturesData?.tunnel_what_you_want_subtitle ?? ''}
            icon={splitTunneling}
            categoryColour="gold"
          >
            <StaticImage src="../../images/split-tunneling-app.png" alt="" sx={imageBaseStyle} />
          </FeatureImage>
        </>
      )}
      <MobileContainer>
        <FeatureImage
          imageSide="right"
          title={mobileFeaturesData?.one_of_a_kind ?? ''}
          category={mobileFeaturesData?.robert ?? ''}
          firstSubtitle={mobileFeaturesData?.one_of_a_kind_subtitle ?? ''}
          icon={robertIcon}
          categoryColour="periwinkle"
          imageStyles={{ display: 'flex', justifyContent: ['center', 'center', 'flex-end'] }}
        >
          <StaticImage
            src="../../images/robert-app.png"
            alt=""
            sx={{
              width: ['34.8rem'],
              height: '100%',
              mb: '1.6rem',
            }}
          />
        </FeatureImage>
      </MobileContainer>
      <Grid
        columns={[1, 2, 2]}
        sx={{
          ...gridBaseStyle,
          px: 0,
          mt: ['3.2rem', '4.8rem', 0],
        }}
      >
        <FeatureBox
          title={mobileFeaturesData?.blocks_ads_and_trackers ?? ''}
          subtitle={mobileFeaturesData?.blocks_ads_and_trackers_subtitle ?? ''}
          colour="periwinkle"
        />
        <FeatureBox
          title={mobileFeaturesData?.prevent_infections ?? ''}
          subtitle={mobileFeaturesData?.prevent_infections_subtitle ?? ''}
          colour="periwinkle"
        />
        <FeatureBox
          title={mobileFeaturesData?.custom_access_rules ?? ''}
          subtitle={mobileFeaturesData?.custom_access_rules_subtitle ?? ''}
          colour="periwinkle"
        />
        <FeatureBox
          title={mobileFeaturesData?.browse_faster ?? ''}
          subtitle={mobileFeaturesData?.browse_faster_subtitle ?? ''}
          colour="periwinkle"
        />
      </Grid>
      <Flex sx={{ width: ['28.8rem', '72rem', 'maxContentWidth'], flexDirection: 'column' }}></Flex>
      <Flex sx={{ mt: '8rem' }}>
        <Text sx={{ color: 'white', fontSize: '4.8rem', fontWeight: 'bold' }}>
          {mobileFeaturesData?.other_features}
        </Text>
      </Flex>
      <Grid columns={[1, 2, 2]} sx={otherFeaturesBaseStyle}>
        <FeatureText icon={noLoggingIcon} text={mobileFeaturesData?.strict_no_log ?? ''} />
        <FeatureText icon={dataEncryptedIcon} text={mobileFeaturesData?.encrypts_your_data ?? ''} />
        <FeatureText icon={commandLineIcon} text={mobileFeaturesData?.import_and_connect ?? ''} />
        <FeatureText icon={globeIcon} text={mobileFeaturesData?.servers_in_over ?? ''} />
      </Grid>

      <Box sx={{ mt: ['4.8rem', '8rem'], mb: ['11.2rem', '8rem'] }}>
        <MobileDownloadButtons
          iosText={mobileFeaturesData?.download_for_ios ?? ''}
          androidText={mobileFeaturesData?.download_for_android ?? ''}
          type={location.pathname === '/features/android/' ? 'android' : 'ios'}
        />
      </Box>
    </StaticPageLayout>
  )
}

function MobileContainer({ children }: { children?: ReactElement }) {
  return <Box sx={{ mt: ['4.8rem', '8rem'] }}>{children}</Box>
}
