import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import WireGuardDisclaimer from 'components/features-page/WireGuardDisclaimer'
import { graphql } from 'gatsby'
import ConfigGeneratorsImage from 'images/config-generators.png'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function ConfigGenerators({
  data,
}: {
  data: Queries.ConfigGeneratorsQueryQuery
}): ReactElement {
  const configGeneratorsStrings = getEnglishStrings(data.directus.config_generators_page?.strings)
  const generalTranslations = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={configGeneratorsStrings?.meta_description ?? ''}
      metaKeywords={configGeneratorsStrings?.meta_keywords ?? []}
      title={configGeneratorsStrings?.title ?? ''}
      subtitle={configGeneratorsStrings?.subtitle ?? ''}
      featureImage={ConfigGeneratorsImage}
      summaryText={configGeneratorsStrings?.summary_text ?? ''}
      gridItems={[
        {
          title: configGeneratorsStrings?.three_protocols ?? '',
          description: configGeneratorsStrings?.three_protocols_subtitle ?? '',
        },
        {
          title: configGeneratorsStrings?.bypass_our ?? '',
          description: configGeneratorsStrings?.bypass_our_subtitle ?? '',
        },
        {
          title: configGeneratorsStrings?.router_level ?? '',
          description: configGeneratorsStrings?.router_level_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {configGeneratorsStrings?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslations?.by_the_way ?? '',
          items: [
            configGeneratorsStrings?.this_requires ?? '',
            configGeneratorsStrings?.when_you_use ?? '',
          ],
        },
      ]}
    >
      <WireGuardDisclaimer />
    </FeaturePageSkeleton>
  )
}

export const data = graphql`
  query ConfigGeneratorsQuery {
    directus {
      config_generators_page {
        strings {
          languages_code {
            code
          }
          subtitle
          summary_text
          three_protocols
          title
          three_protocols_subtitle
          bypass_our
          bypass_our_subtitle
          router_level
          router_level_subtitle
          this_requires
          when_you_use
          cta
          meta_description
          meta_keywords
        }
      }
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
    }
  }
`
