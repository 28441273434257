import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function DoNotDisturb({
  data,
}: {
  data: Queries.DoNotDisturbPageQuery
}): ReactElement {
  const doNotDisturbData = getEnglishStrings(data.directus.do_not_disturb?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={doNotDisturbData?.meta_description ?? ''}
      metaKeywords={doNotDisturbData?.meta_keywords ?? []}
      title={doNotDisturbData?.title ?? ''}
      subtitle={doNotDisturbData?.subtitle ?? ''}
      gridItems={[
        {
          title: doNotDisturbData?.stop_in ?? '',
          description: doNotDisturbData?.stop_in_subtitle ?? '',
        },
        {
          title: doNotDisturbData?.keep_in_mind ?? '',
          description: doNotDisturbData?.keep_in_mind_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {doNotDisturbData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[]}
    />
  )
}

export const data = graphql`
  query DoNotDisturbPage {
    directus {
      do_not_disturb {
        strings {
          cta
          keep_in_mind
          keep_in_mind_subtitle
          languages_code {
            code
          }
          meta_description
          meta_keywords
          stop_in
          stop_in_subtitle
          subtitle
          title
        }
      }
    }
  }
`
