import React, { forwardRef, ReactElement, ReactNode } from 'react'
import {
  Flex,
  Input as ThemeUIInput,
  InputProps as ThemeUIProps,
  ThemeUIStyleObject,
} from 'theme-ui'

export interface InputProps extends ThemeUIProps {
  rightContent?: ReactNode
  inputSx?: ThemeUIStyleObject
}

export default forwardRef<HTMLInputElement, InputProps>(function Input(
  props: InputProps,
  ref,
): ReactElement {
  const { rightContent, inputSx, ...containerProps } = props
  return (
    <Flex
      {...containerProps}
      sx={{
        flexDirection: 'row',
        position: 'relative',
        width: '100%',
        alignItems: 'center',
        height: '4.8rem',
        borderRadius: '3.2rem',
        backgroundColor: 'white10',
        ...containerProps.sx,
      }}
    >
      <ThemeUIInput
        ref={ref}
        sx={{
          fontFamily: 'IBM Plex Sans',
          flex: 1,
          border: 'none',
          height: '4.8rem',
          color: 'white',
          px: '2.4rem',
          fontSize: '1.8rem',
          outline: 'none',
          position: 'relative',
          ...inputSx,
        }}
      />
      {rightContent && (
        <Flex
          sx={{
            marginRight: '1.9rem',
            color: 'white50',
            flexShrink: 0,
          }}
        >
          {rightContent}
        </Flex>
      )}
    </Flex>
  )
})
