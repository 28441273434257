import { alpha } from '@theme-ui/color'
import StaticContentSkeleton from 'components/StaticContentSkeleton'
import ExternalLink from 'components/UI/ExternalLink'
import Svg from 'components/UI/SVG'
import Background from 'components/features-page/background/Background'
import RoundedBorder from 'components/features-page/background/RoundedBorder'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Copy from 'images/copy.svg'
import GreenCheck from 'images/green-check.svg'
import GreenGiftBox from 'images/green-gift-box.svg'
import MobileIcon from 'images/mobile-icon.svg'
import PurpleGiftBox from 'images/purple-gift-box.svg'
import WindscribeCoinsMobile from 'images/windscribe-coins-mobile.svg'
import WindscribeCoinsTablet from 'images/windscribe-coins-tablet.svg'
import WindscribeCoins from 'images/windscribe-coins.svg'
import { SessionData } from 'network/session'
import { ReactElement, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useAppSelector } from 'store/hooks'
import { Flex, Grid, Text, ThemeUICSSObject, get } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import transformProductionApiUrlTld from 'utils/transformProductionApiUrlTld'
import useHasMounted from 'utils/useHasMounted'

export default function ReferAFriendPage({
  data,
}: {
  data: Queries.ReferAFriendPageQuery
}): ReactElement | null {
  const hasMounted = useHasMounted()
  const { data: sessionData } = useAppSelector(state => state.session)
  const referAFriendPageData = getEnglishStrings(data.directus.refer_a_friend?.strings)

  if (!hasMounted) {
    return null
  }

  return (
    <>
      <StaticContentSkeleton
        title={referAFriendPageData?.title ?? ''}
        metaDescription={referAFriendPageData?.meta_description ?? ''}
        metaKeywords={referAFriendPageData?.meta_keywords ?? []}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            gap: ['1.4rem', '2.4rem'],
            width: '100%',
          }}
        >
          <Flex sx={{ flexDirection: 'column', gap: '3.2rem' }}>
            <Flex
              sx={{
                flex: '1 1 auto',
                maxWidth: 'maxContentWidth',
                justifyContent: 'space-between',
                width: ['100%', '100%', 'maxContentWidth'],
                flexDirection: ['column', 'row', 'row'],
                alignItems: 'center',
              }}
            >
              <Flex
                sx={{
                  flexDirection: 'column',
                  gap: '2.4rem',
                  maxWidth: '41.6rem',
                  textAlign: ['center', 'left'],
                }}
              >
                <Flex sx={{ flexDirection: 'column', gap: '1.6rem' }}>
                  <Text variant="text.heading1">{referAFriendPageData?.title}</Text>
                  <Text variant="text.body2" color="white50">
                    {referAFriendPageData?.subtitle}
                  </Text>
                  <StaticImage
                    src="../images/refer-a-friend.png"
                    alt={''}
                    sx={{ maxWidth: '40rem', display: ['flex', 'none'], my: '4.8rem' }}
                  />
                </Flex>
                <Flex sx={{ gap: '0.8rem', flexDirection: 'column' }}>
                  <BulletPoint text={referAFriendPageData?.one_gig_of_data ?? ''} />
                  <BulletPoint text={referAFriendPageData?.they_get_one_gig ?? ''} />
                  <BulletPoint text={referAFriendPageData?.get_pro_if ?? ''} />
                </Flex>
              </Flex>
              <StaticImage
                src="../images/refer-a-friend.png"
                alt={''}
                sx={{ maxWidth: '40rem', display: ['none', 'flex'] }}
              />
            </Flex>
            <div>
              {sessionData?.username && <LoggedInSection sessionData={sessionData} data={data} />}
            </div>
            {!sessionData?.username && (
              <Flex
                sx={{
                  gap: ['0.8rem', '1.6rem'],
                  width: '100%',
                  maxWidth: 'maxContentWidth',
                  alignItems: 'center',
                }}
              >
                <LoggedOutSection data={data} />
                <Text color="white50" sx={{ fontWeight: 'bold', fontSize: '1.8rem' }}>
                  {referAFriendPageData?.to_start}
                </Text>
              </Flex>
            )}
          </Flex>

          {/* Coins section */}
          <div sx={{ mt: '2rem' }}>
            <Flex sx={{ justifyContent: 'center' }}>
              <Svg
                svg={WindscribeCoins}
                sx={{
                  width: '100%',
                  display: ['none', 'none', 'flex'],
                  maxWidth: 'maxContentWidth',
                  mb: '-1.2rem',
                }}
              />
              <Svg
                svg={WindscribeCoinsTablet}
                sx={{
                  width: '100%',
                  display: ['none', 'flex', 'none'],
                  maxWidth: 'maxContentWidth',
                  mb: '-0.2rem',
                }}
              />
              <Svg
                svg={WindscribeCoinsMobile}
                sx={{
                  width: '100%',
                  display: ['flex', 'none'],
                  maxWidth: 'maxContentWidth',
                  mb: '-1.2rem',
                }}
              />
            </Flex>
            <Background display={['flex', 'flex', 'flex']}>
              <RoundedBorder
                maxWidth="124.8rem"
                width={['100vw', '100vw', '100vw']}
                minHeight={['81.2rem', '100rem', '100rem']}
                roundedEdge="top"
                backgroundImage={({ colors }): string =>
                  `linear-gradient(180deg, ${colors?.kuretakeBlack} 32.81%, ${colors?.transparent} 100%)`
                }
              />
            </Background>
          </div>
        </Flex>
        {/* Rewards Section */}
        <Flex sx={{ flexDirection: 'column', alignItems: 'center', gap: '4.8rem' }}>
          <Flex sx={{ flexDirection: 'column', gap: '1.6rem', alignItems: 'center' }}>
            <Text variant="text.heading1">{referAFriendPageData?.rewards}</Text>
            <Text variant="text.body2" color="white50" sx={{ textAlign: 'center' }}>
              {referAFriendPageData?.get_these_tasty}
            </Text>
          </Flex>
          <Grid columns={[1, 2]} sx={{ gap: '4.8rem' }}>
            <Reward
              rewardTitle={referAFriendPageData?.first_reward ?? ''}
              icon={GreenGiftBox}
              title={referAFriendPageData?.one_gig_of_data_every_month ?? ''}
              when={referAFriendPageData?.when ?? ''}
              subtitle={referAFriendPageData?.when_your_friend_creates ?? ''}
              variant="green"
            />
            <Reward
              rewardTitle={referAFriendPageData?.second_reward ?? '' ?? ''}
              icon={PurpleGiftBox}
              title={referAFriendPageData?.one_month_or_one_year ?? ''}
              when={referAFriendPageData?.when ?? ''}
              subtitle={referAFriendPageData?.when_your_friend_pays ?? ''}
              variant="purple"
            />
          </Grid>
        </Flex>
        {/* How it works section */}
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '4.8rem',
            alignItems: 'center',
            maxWidth: '86.4rem',
            width: '100%',
          }}
        >
          <Text variant="text.heading1">{referAFriendPageData?.how_it_works}</Text>
          <Grid columns={[1, 3]} sx={{ gap: '4.8rem' }}>
            <Step
              step={referAFriendPageData?.step_one ?? ''}
              title={referAFriendPageData?.share_referral_link ?? ''}
              subtitle={referAFriendPageData?.share_referral_link_subtitle ?? ''}
            />
            <Step
              step={referAFriendPageData?.step_two ?? ''}
              title={referAFriendPageData?.friend_joins_windscribe ?? ''}
              subtitle={referAFriendPageData?.your_friend_will_have ?? ''}
            />
            <Step
              step={referAFriendPageData?.step_three ?? ''}
              title={referAFriendPageData?.you_both_get_rewarded ?? ''}
              subtitle={referAFriendPageData?.you_will_both_get_an ?? ''}
            />
          </Grid>
        </Flex>
        {/* Get Started section */}
        <Flex sx={{ gap: '4.8rem', flexDirection: 'column', alignItems: 'center' }}>
          <Flex sx={{ gap: '1.6rem', flexDirection: 'column', alignItems: 'center' }}>
            <Text variant="text.heading1">{referAFriendPageData?.get_started}</Text>
            <Text
              variant="text.body2"
              color="white50"
              sx={{ maxWidth: '52.4rem', textAlign: 'center' }}
            >
              {referAFriendPageData?.login_or_signup_first}
            </Text>
          </Flex>
          {sessionData?.username ? (
            <LoggedInSection sessionData={sessionData} data={data} />
          ) : (
            <LoggedOutSection data={data} />
          )}
        </Flex>
        <Text
          variant="text.body2"
          color="white50"
          sx={{ textAlign: 'center', maxWidth: 'maxContentWidth' }}
        >
          {referAFriendPageData?.this_policy_is_only}
        </Text>
      </StaticContentSkeleton>
    </>
  )
}

export const data = graphql`
  query ReferAFriendPage {
    directus {
      refer_a_friend {
        strings {
          languages_code {
            code
          }
          first_reward
          friend_joins_windscribe
          get_pro_if
          get_started
          get_these_tasty
          how_it_works
          login
          login_or_signup_first
          mobile_only
          one_gig_of_data
          one_gig_of_data_every_month
          one_month_or_one_year
          or
          rewards
          second_reward
          share_referral_link
          share_referral_link_subtitle
          share_referral_link_title
          share_your_username
          sign_up
          step_one
          step_three
          step_two
          subtitle
          they_get_one_gig
          this_policy_is_only
          title
          to_start
          when
          when_your_friend_creates
          when_your_friend_pays
          you_both_get_rewarded
          you_will_both_get_an
          your_friend_will_have
          meta_description
          meta_keywords
        }
      }
    }
  }
`

function LoggedOutSection({ data }: { data: Queries.ReferAFriendPageQuery }) {
  const referAFriendPageData = getEnglishStrings(data.directus.refer_a_friend?.strings)
  return (
    <Flex
      sx={{
        alignItems: 'center',
        gap: ['0.8rem', '1.6rem'],
        justifyContent: 'flex-start',

        color: 'neonGreen',
      }}
    >
      <ExternalLink href="/login" variant="buttons.neon">
        {referAFriendPageData?.login}
      </ExternalLink>
      <Text variant="text.body2" color="white50">
        {referAFriendPageData?.or}
      </Text>
      <ExternalLink href="/signup" variant="buttons.neon">
        {referAFriendPageData?.sign_up}
      </ExternalLink>
    </Flex>
  )
}

const loggedInSectionStyle: ThemeUICSSObject = {
  position: 'relative',
  svg: {
    transition: theme => `${get(theme, 'transitions.default')}`,
    '& rect': {
      transition: theme => `${get(theme, 'transitions.default')}`,
    },
    '& path': {
      transition: theme => `${get(theme, 'transitions.default')}`,
    },
  },
  '&:hover': {
    cursor: 'pointer',
    '& input': {
      backgroundColor: 'transparent',
      color: 'neonGreen',
    },
    svg: {
      '& rect': {
        fill: 'neonGreen',
        fillOpacity: 1,
      },
      '& path': {
        fill: 'background',
      },
    },
  },
}

function LoggedInSection({
  sessionData,
  data,
}: {
  sessionData: SessionData
  data: Queries.ReferAFriendPageQuery
}) {
  const transformedLocation = transformProductionApiUrlTld(window.location.origin)
  const referAFriendPageData = getEnglishStrings(data.directus.refer_a_friend?.strings)
  const userUrl =
    typeof window !== 'undefined' ? `${transformedLocation}/yo/${sessionData?.user_id}` : ''
  const [hasCopiedUrl, setHasCopiedUrl] = useState(false)
  const [hasCopiedUsername, setHasCopiedUsername] = useState(false)

  return (
    <Grid
      sx={{
        gap: ['1.6rem', '4.8rem'],
        alignItems: 'stretch',
        maxWidth: 'maxContentWidth',
      }}
      columns={[1, '1fr auto 1fr']}
    >
      <Flex sx={{ flexDirection: 'column', gap: '0.8rem' }}>
        <Text color="white50" sx={{ fontWeight: 'bold' }}>
          {referAFriendPageData?.share_referral_link}
        </Text>
        <Flex sx={loggedInSectionStyle}>
          <input
            readOnly
            sx={{
              width: '100%',
              variant: 'buttons.neon',
              border: ({ colors }) => `1px solid ${colors?.neonGreen25}`,
              backgroundColor: 'transparent',
              color: 'neonGreen',
              paddingRight: '5rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            data-testid="referral-link"
            value={userUrl}
            onClick={() => {
              setHasCopiedUrl(true)
              navigator.clipboard.writeText(userUrl)

              setTimeout(() => {
                setHasCopiedUrl(false)
              }, 600)
            }}
          />
          {hasCopiedUrl ? (
            <Svg
              svg={GreenCheck}
              sx={{
                position: 'absolute',
                top: '0.37rem',
                right: '0.4rem',
                pointerEvents: 'none',
                width: '4rem',
                height: '4rem',
                '& circle': {
                  fill: 'neonGreen',
                  fillOpacity: 1,
                },
                '& path': {
                  fill: 'background',
                },
              }}
            />
          ) : (
            <Svg
              svg={Copy}
              sx={{ position: 'absolute', top: '0.35rem', right: '0.35rem', pointerEvents: 'none' }}
            />
          )}
        </Flex>
      </Flex>
      <Flex sx={{ flexDirection: 'column', gap: '0.8rem', alignItems: 'center' }}>
        <div
          sx={{
            height: '3.7rem',
            width: '0.1rem',
            backgroundColor: 'white10',
            display: ['none', 'flex'],
          }}
        />
        <Text color="white50" variant="text.body2">
          {referAFriendPageData?.or}
        </Text>
        <div
          sx={{
            height: '3.7rem',
            width: '0.1rem',
            backgroundColor: 'white10',
            display: ['none', 'flex'],
          }}
        />
      </Flex>
      <Flex sx={{ flexDirection: 'column', gap: '0.8rem' }}>
        <Text color="white50" sx={{ fontWeight: 'bold' }}>
          {referAFriendPageData?.share_your_username}
        </Text>
        <Flex sx={loggedInSectionStyle}>
          <input
            readOnly
            sx={{
              width: '100%',
              variant: 'buttons.neon',
              border: ({ colors }) => `1px solid ${colors?.neonGreen25}`,
              backgroundColor: 'transparent',
              color: 'neonGreen',
            }}
            value={sessionData?.username}
            onClick={() => {
              setHasCopiedUsername(true)
              navigator.clipboard.writeText(sessionData?.username)

              setTimeout(() => {
                setHasCopiedUsername(false)
              }, 600)
            }}
          />
          {hasCopiedUsername ? (
            <Svg
              svg={GreenCheck}
              sx={{
                position: 'absolute',
                top: '0.37rem',
                right: '0.4rem',
                pointerEvents: 'none',
                width: '4rem',
                height: '4rem',
                '& circle': {
                  fill: 'neonGreen',
                  fillOpacity: 1,
                },
                '& path': {
                  fill: 'background',
                },
              }}
            />
          ) : (
            <Svg
              svg={Copy}
              sx={{ position: 'absolute', top: '0.35rem', right: '0.35rem', pointerEvents: 'none' }}
            />
          )}
        </Flex>
        <Flex sx={{ gap: '0.8rem', alignItems: 'center', justifyItems: 'center' }}>
          <Svg svg={MobileIcon} sx={{ minWidth: '2.4rem', minHeight: '2.4rem' }} />
          <Text color="white50">{referAFriendPageData?.mobile_only}</Text>
        </Flex>
      </Flex>
    </Grid>
  )
}

function BulletPoint({ text }: { text: string }) {
  return (
    <Flex sx={{ gap: '1.6rem', alignItems: 'center', textAlign: 'start' }}>
      <Svg svg={GreenCheck} width={'2.4rem'} height={'2.4rem'} sx={{ maxWidth: 'max-content' }} />
      <ReactMarkdown>{text}</ReactMarkdown>
    </Flex>
  )
}

function Reward({
  rewardTitle,
  icon,
  title,
  when,
  subtitle,
  variant,
}: {
  rewardTitle: string
  icon: React.ElementType
  title: string
  when: string
  subtitle: string
  variant: 'green' | 'purple'
}) {
  const style = {
    green: {
      gradient: `linear-gradient(180deg, rgba(85, 255, 138, 0.15) 0%, rgba(85, 255, 138, 0.00) 100%)`,
      border: '2px solid rgba(85, 255, 138, 0.25);',
      background: '#55FF8A',
      color: 'neonGreen',
      backgroundColor: 'neonGreen15',
    },
    purple: {
      gradient:
        'linear-gradient(180deg, rgba(165, 76, 255, 0.15) 0%, rgba(165, 76, 255, 0.00) 100%)',
      border: '2px solid rgba(165, 76, 255, 0.25)',
      background: '#A54CFF',
      color: 'neonGreen',
      backgroundColor: alpha('#A54CFF26', 0.15),
    },
  }[variant]

  return (
    <Flex sx={{ flexDirection: 'column', gap: '3.2rem', maxWidth: '24.6rem' }}>
      <Flex sx={{ flexDirection: 'column', gap: '1.6rem', alignItems: 'center' }}>
        <Text
          sx={{
            p: '0.4rem 1.2rem',
            color: style.background,
            backgroundColor: style.backgroundColor,
            borderRadius: '10rem',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            width: 'fit-content',
          }}
        >
          {rewardTitle}
        </Text>
        <Flex
          sx={{
            p: '1.6rem',
            gap: '2.4rem',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '1.6rem',
            border: style.border,
            background: style.gradient,
          }}
        >
          <Svg svg={icon} />
          <Text sx={{ fontWeight: 'bold', fontSize: '2.1rem' }}>{title}</Text>
        </Flex>
      </Flex>
      <Flex sx={{ gap: '1.6rem', flexDirection: 'column', alignItems: 'center' }}>
        <Text
          sx={{
            p: '0.4rem 1.2rem',
            backgroundColor: style.backgroundColor,
            width: 'fit-content',
            borderRadius: '10rem',
            fontWeight: 'bold',
          }}
          color={style.background}
        >
          {when}
        </Text>
        <Text variant="text.body2" color="white50" sx={{ textAlign: 'center' }}>
          {subtitle}
        </Text>
      </Flex>
    </Flex>
  )
}

function Step({ step, title, subtitle }: { step: string; title: string; subtitle: string }) {
  return (
    <Flex sx={{ flexDirection: 'column', gap: '0.8rem' }}>
      <Flex sx={{ flexDirection: 'column', gap: '2.4rem' }}>
        <Text
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: '#00B2FF',
            p: '0.4rem 1.2rem',
            backgroundColor: alpha('#00B2FF26', 0.15),
            borderRadius: '10rem',
            width: 'fit-content',
          }}
        >
          {step}
        </Text>
        <Text sx={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{title}</Text>
      </Flex>
      <Text variant="text.body3" color="white50">
        <ReactMarkdown>{subtitle}</ReactMarkdown>
      </Text>
    </Flex>
  )
}
