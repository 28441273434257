import { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'

export default function Tag({
  backgroundColour,
  title,
  textColour = 'white50',
  padding = '1rem 2rem',
}: {
  title: string
  backgroundColour: string
  textColour?: string
  padding?: string
}): ReactElement {
  return (
    <Flex
      sx={{
        padding,
        backgroundColor: backgroundColour,
        borderRadius: '2.1rem',
        flexShrink: 0,
      }}
    >
      <Text
        variant="text.heading3"
        sx={{
          color: textColour,
          textAlign: 'center',
        }}
      >
        {title}
      </Text>
    </Flex>
  )
}
