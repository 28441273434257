import Breadcrumbs, { Breadcrumb } from 'components/knowledge-base/breadcrumbs/Breadcrumbs'
import { graphql, useStaticQuery } from 'gatsby'
import { Dispatch, ReactElement, SetStateAction, useEffect, useRef, useState } from 'react'
import { connectHits, InstantSearch, SearchBox } from 'react-instantsearch-dom'
import { Flex } from 'theme-ui'
import useBreakpointIndex from 'theme/useBreakpointIndex'
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'
import { StringParam, useQueryParam } from 'use-query-params'
import getEnglishStrings from 'utils/getEnglishStrings'
import Hits from './Hits'
import './instant-search.css'
import SearchClearIcon from 'images/search-clear-icon.png'
import transformProductionApiUrlTld from 'utils/transformProductionApiUrlTld'

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: process.env.GATSBY_TYPESENSE_SEARCH_API_KEY ?? '',
    nodes: [
      {
        host: transformProductionApiUrlTld(process.env.GATSBY_TYPESENSE_API_URL ?? ''),
        port: 443,
        protocol: 'https',
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    query_by: 'title, body',
  },
})
const searchClient = typesenseInstantsearchAdapter.searchClient

export default function SearchInterface({
  hasInputValue,
  setHasInputValue,
  breadcrumbs,
}: {
  hasInputValue: boolean
  setHasInputValue: Dispatch<SetStateAction<boolean>>
  breadcrumbs: Breadcrumb[]
}): ReactElement {
  const data = useStaticQuery<Queries.SearchPlaceholderQuery>(graphql`
    query SearchPlaceholder {
      directus {
        knowledge_base_page {
          strings {
            languages_code {
              code
            }
            search_placeholder_mobile
            search_placeholder_desktop_tablet
          }
        }
      }
    }
  `)

  const strings = getEnglishStrings(data?.directus?.knowledge_base_page?.strings)

  const CustomHits = connectHits(Hits)
  const searchingBreadcrubs: Breadcrumb[] = [
    { title: 'HELP', href: '/knowledge-base', shouldUseExternalLink: true },
    { title: 'SEARCH' },
  ]
  const [searchQuery] = useQueryParam('search', StringParam)
  const inputRef = useRef<HTMLInputElement>(null)

  const breakpointIndex = useBreakpointIndex()
  const placeholderText = (() => {
    if (breakpointIndex === 0) {
      return strings?.search_placeholder_mobile ?? ''
    } else if (breakpointIndex > 0) {
      return strings?.search_placeholder_desktop_tablet ?? ''
    } else {
      return ''
    }
  })()

  const isMobileOrTablet = breakpointIndex === 0 || breakpointIndex === 1

  useEffect(() => {
    setHasInputValue(!!searchQuery && searchQuery !== '')
  }, [searchQuery, setHasInputValue])

  // unfortunately this is actually the type that React InstantSearch uses for its search state
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchState, setSearchState] = useState<any>({ query: '' })

  useEffect(() => {
    // we need to actually trigger a change from a `useEffect` to initialize the search
    // state from the query string because in SSR'd builds it will not otherwise toggle out
    // of search mode on clearing the input
    setSearchState({ query: searchQuery })

    // we only want to do this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="articles"
      searchState={searchState}
      onSearchStateChange={args => {
        setSearchState(args)
        setHasInputValue(!!args.query && args.query !== '')
      }}
    >
      <Flex
        sx={{ width: '100%' }}
        onClick={() => {
          isMobileOrTablet &&
            document.getElementById('instantSearch_input')?.scrollIntoView({ behavior: 'smooth' })
        }}
      >
        <SearchBox
          translations={{
            placeholder: placeholderText,
          }}
          inputId="instantSearch_input"
          inputRef={inputRef}
          reset={<img src={SearchClearIcon} alt="clear search icon" />}
          onChange={event => setHasInputValue((event.target as HTMLInputElement).value !== '')}
          onReset={event => setHasInputValue((event.target as HTMLInputElement).value !== '')}
          sx={{
            'input[type="search"]': {
              scrollMarginTop: '1.6rem',
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'input[type="search"]::-webkit-search-cancel-button': {
              display: 'none',
            },
            '.ais-SearchBox-reset': {
              border: 'none',
              backgroundColor: 'white',
              borderRadius: '1.6rem',
            },
            mb: '4.8rem',
          }}
        />
      </Flex>
      <Breadcrumbs breadcrumbs={hasInputValue ? searchingBreadcrubs : breadcrumbs} />
      {hasInputValue && <CustomHits />}
    </InstantSearch>
  )
}
