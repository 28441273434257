import { navigate } from 'gatsby'
import { defineOS } from 'utils/downloadPageUtils'
import useCurrentPlatform from 'utils/useCurrentPlatform'

export default function InstalledExtension(): JSX.Element {
  const { osName } = useCurrentPlatform()
  const formattedOs = defineOS(osName).toLowerCase()

  const selectedOS =
    {
      windows: 'windows',
      macos: 'macos',
      linux: 'linux',
    }[formattedOs] ?? 'windows'

  typeof window !== 'undefined' && navigate(`/features/${selectedOS}`)
  return <></>
}
