import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function TimeWarpPage({ data }: { data: Queries.TimeWarpPageQuery }): ReactElement {
  const timeWarpData = getEnglishStrings(data.directus.time_warp?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={timeWarpData?.meta_description ?? ''}
      metaKeywords={timeWarpData?.meta_keywords ?? []}
      title={timeWarpData?.title ?? ''}
      subtitle={timeWarpData?.subtitle ?? ''}
      gridItems={[
        {
          title: timeWarpData?.time_zone ?? '',
          description: timeWarpData?.time_zone_subtitle ?? '',
        },
        {
          title: timeWarpData?.a_better ?? '',
          description: timeWarpData?.a_better_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {timeWarpData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[]}
    />
  )
}

export const data = graphql`
  query TimeWarpPage {
    directus {
      time_warp {
        strings {
          a_better
          a_better_subtitle
          cta
          languages_code {
            code
          }
          meta_description
          meta_keywords
          subtitle
          time_zone
          time_zone_subtitle
          title
        }
      }
    }
  }
`
