import StaticPageLayout from 'components/StaticPageLayout'
import { ReactElement, ReactNode } from 'react'
import ReactMarkdown from 'react-markdown'
import { Flex, Text } from 'theme-ui'

export default function StaticContentSkeleton({
  metaDescription,
  metaKeywords,
  title,
  children,
}: {
  title: string
  metaDescription: string
  metaKeywords: string[]
  children: ReactNode
}): ReactElement {
  return (
    <StaticPageLayout
      title={`Windscribe | ${title}`}
      metaDescription={metaDescription}
      metaKeywords={metaKeywords}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: '8rem',
          maxWidth: '100%',
          mb: ['6.4rem', '8rem'],
          mt: ['2.4rem', '4.8rem', '6.4rem'],
          //adds 2.4 on top of the already existing 2.4rem in the StaticPageLayout
          px: [0, '2.4rem', 0],
        }}
      >
        {children}
      </Flex>
    </StaticPageLayout>
  )
}
export function TitledContent({
  title,
  content,
}: {
  title?: string
  content: string
}): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '1.6rem',
        width: '100%',
        maxWidth: ['100%', 'maxContentWidth'],
      }}
    >
      {title && <Text variant="text.heading2">{title}</Text>}
      <ReactMarkdown sx={{ variant: 'text.body2', color: 'white50', p: { p: 0, m: 0 } }}>
        {content}
      </ReactMarkdown>
    </Flex>
  )
}
