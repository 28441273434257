import GetStartedIcon from 'images/get-started-icon.svg'
import FaqIcon from 'images/faq-icon.svg'
import TroubleshootIcon from 'images/troubleshoot-icon.svg'
import OtherIcon from 'images/other-icon.svg'
import AccountsAndBillingIcon from 'images/abicon.svg'

export default {
  'Accounts and Billing': AccountsAndBillingIcon,
  'Technical Issues': TroubleshootIcon,
  About: OtherIcon,
  Guides: GetStartedIcon,
  'Popular Articles': FaqIcon,
}
