import StaticContentSkeleton from 'components/StaticContentSkeleton'
import ExternalLink from 'components/UI/ExternalLink'
import Svg from 'components/UI/SVG'
import Background from 'components/features-page/background/Background'
import RoundedBorder from 'components/features-page/background/RoundedBorder'
import { graphql } from 'gatsby'
import OpenDropdownIcon from 'images/open-dropdown.svg'
import { ReactElement, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Flex, Grid, Text, get } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import LinkIcon from 'images/link-icon.svg'
import Tag from 'components/Tag'
import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'

export default function OSS({ data }: { data: Queries.OSSPageQuery }): ReactElement {
  const openSourceData = getEnglishStrings(data.directus.open_source_software_attributions?.strings)

  return (
    <StaticContentSkeleton
      title={openSourceData?.title ?? ''}
      metaDescription={openSourceData?.meta_description ?? ''}
      metaKeywords={openSourceData?.meta_keywords ?? []}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: '8rem',
          width: '100%',
        }}
      >
        <TitleAndSubtitle title={openSourceData?.title} subtitle={openSourceData?.subtitle} />
        <Background display={['flex', 'flex', 'flex']}>
          <RoundedBorder
            width={['100vw', '100vw', '100vw']}
            minHeight={['81.2rem', '81.2rem', '100rem']}
            roundedEdge="top"
            backgroundImage={({ colors }): string =>
              `linear-gradient(180deg, ${colors?.kuretakeBlack} 32.81%, ${colors?.kuretakeBlack0} 100%)`
            }
            maxWidth="128rem"
          />
        </Background>
        <Flex sx={{ flexDirection: 'column', gap: '4.8rem' }}>
          <Flex sx={{ justifyContent: 'center' }}>
            <Tag title={openSourceData?.software_we_use ?? ''} backgroundColour="white10" />
          </Flex>
          <Flex
            sx={{
              flexDirection: 'column',
              gap: '1.6rem',
              maxWidth: 'maxContentWidth',
              width: '100%',
            }}
          >
            <Item
              title={openSourceData?.markus_f_x_j_title ?? ''}
              content={openSourceData?.markus_f_x_j ?? ''}
              projectLink={openSourceData?.project_link ?? ''}
              url="http://www.oberhumer.com/opensource/lzo/"
            />
            <Item
              title={openSourceData?.qt_framework_title ?? ''}
              content={openSourceData?.qt_framework ?? ''}
              projectLink={openSourceData?.project_link ?? ''}
              url="https://www.qt.io/"
            />
            <Item
              title={openSourceData?.michal_trojnara_title ?? ''}
              content={openSourceData?.michal_trojnara ?? ''}
              projectLink={openSourceData?.project_link ?? ''}
              url="https://www.stunnel.org/"
            />
            <Item
              title={openSourceData?.daniel_stenberg_title ?? ''}
              content={openSourceData?.daniel_stenberg ?? ''}
              projectLink={openSourceData?.project_link ?? ''}
              url="https://github.com/c-ares/c-ares"
            />
            <Item
              title={openSourceData?.jason_a_wireguard_title ?? ''}
              content={openSourceData?.jason_a_wireguard ?? ''}
              projectLink={openSourceData?.project_link ?? ''}
              url="https://git.zx2c4.com/wireguard-go/about/"
            />
            <Item
              title={openSourceData?.jason_a_wintun_title ?? ''}
              content={openSourceData?.jason_a_wintun ?? ''}
              projectLink={openSourceData?.project_link ?? ''}
              url="https://www.wintun.net/"
            />
            <Item
              title={openSourceData?.openvpn_title ?? ''}
              content={openSourceData?.openvpn ?? ''}
              projectLink={openSourceData?.project_link ?? ''}
              url="https://github.com/OpenVPN/openvpn"
            />
            <Item
              title={openSourceData?.open_vpn_technologies_title ?? ''}
              content={openSourceData?.openvpn_technologies ?? ''}
              projectLink={openSourceData?.project_link ?? ''}
              url="https://github.com/OpenVPN/tap-windows6"
            />
            <Item
              title={openSourceData?.nsis_title ?? ''}
              content={openSourceData?.nsis ?? ''}
              projectLink={openSourceData?.project_link ?? ''}
              url="https://nsis.sourceforge.io/License"
            />
            <Item
              title={openSourceData?.openssl_title ?? ''}
              content={openSourceData?.openssl ?? ''}
              projectLink={openSourceData?.project_link ?? ''}
              url="https://www.openssl.org/"
            />
            <Item
              title={openSourceData?.ublock_title ?? ''}
              content={openSourceData?.ublock ?? ''}
              projectLink={openSourceData?.project_link ?? ''}
              url="https://github.com/gorhill/uBlock"
            />
            <Item
              title={openSourceData?.realm_title ?? ''}
              content={openSourceData?.realm ?? ''}
              projectLink={openSourceData?.project_link ?? ''}
              url="https://github.com/realm/realm-swift"
            />
          </Flex>
        </Flex>
      </Flex>
    </StaticContentSkeleton>
  )
}

function Item({
  title,
  content,
  projectLink,
  url,
}: {
  title: string
  content: string
  projectLink: string
  url: string
}): ReactElement {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '3.2rem',
        justifyContent: 'center',
        backgroundColor: 'white10',
        p: '2.4rem',
        borderRadius: '2.4rem',
        ':hover': {
          cursor: 'pointer',
        },
        width: ['100%', '100%', 'maxContentWidth'],
      }}
      onClick={() => setIsOpen(!isOpen)}
    >
      <Grid
        sx={{
          justifyContent: 'space-between',
          flexDirection: ['column', 'row'],
          gap: ['1.6rem', '2rem'],
        }}
        columns={['1fr', '1fr auto']}
      >
        <Text variant="heading2">{title}</Text>
        <Flex
          sx={{
            gap: '1.6rem',
            alignItems: 'center',
            justifyContent: 'space-between',
            a: {
              color: 'neonGreen',
              ':hover': {
                color: 'neonGreen',
              },
            },
          }}
        >
          <ExternalLink
            href={url}
            sx={{
              display: 'flex',
              gap: '0.8rem',
              alignItems: 'center',
              variant: 'text.button',
              width: 'fit-content',
            }}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            {projectLink}
            <Svg svg={LinkIcon} />
          </ExternalLink>
          <div
            sx={{
              height: '100%',
              width: '2px',
              backgroundColor: 'white10',
              borderRadius: '2.1rem',
              display: ['none', 'block'],
            }}
          />
          <Svg
            svg={OpenDropdownIcon}
            sx={{
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
              transition: theme => `${get(theme, 'transitions.default')}`,
            }}
          />
        </Flex>
      </Grid>
      {isOpen && (
        <ReactMarkdown
          sx={{
            color: 'white50',
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
            code: {
              whiteSpace: 'break-spaces',
            },
          }}
        >
          {content}
        </ReactMarkdown>
      )}
    </Flex>
  )
}

export const data = graphql`
  query OSSPage {
    directus {
      open_source_software_attributions {
        strings {
          languages_code {
            code
          }
          daniel_stenberg
          jason_a_wintun
          jason_a_wireguard
          markus_f_x_j
          meta_description
          meta_keywords
          michal_trojnara
          nsis
          openssl
          openvpn
          openvpn_technologies
          project_link
          qt_framework
          realm
          subtitle
          title
          ublock
          daniel_stenberg_title
          jason_a_wintun_title
          jason_a_wireguard_title
          markus_f_x_j_title
          michal_trojnara_title
          nsis_title
          open_vpn_technologies_title
          openssl_title
          openvpn_title
          qt_framework_title
          realm_title
          ublock_title
          software_we_use
        }
      }
    }
  }
`
