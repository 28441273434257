import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function WebRTCSlayer({
  data,
}: {
  data: Queries.WebRTCSlayerPageQuery
}): ReactElement {
  const webRTCSlayerData = getEnglishStrings(data?.directus?.webrtc_slayer?.strings)
  const generalTranslationsData = getEnglishStrings(data?.directus?.general_translations?.strings)
  const queryParam = useFeaturePageSlug()

  return (
    <FeaturePageSkeleton
      title={webRTCSlayerData?.title ?? ''}
      subtitle={webRTCSlayerData?.subtitle ?? ''}
      gridItems={[
        {
          title: webRTCSlayerData?.what_is_webrtc ?? '',
          description: webRTCSlayerData?.what_is_webrtc_subtitle ?? '',
        },
        {
          title: webRTCSlayerData?.why_should_i ?? '',
          description: webRTCSlayerData?.why_should_i_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {webRTCSlayerData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [webRTCSlayerData?.by_enabling ?? ''],
        },
      ]}
      metaDescription={webRTCSlayerData?.meta_description ?? ''}
      metaKeywords={webRTCSlayerData?.meta_keywords ?? []}
    />
  )
}

export const data = graphql`
  query WebRTCSlayerPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      webrtc_slayer {
        strings {
          by_enabling
          cta
          languages_code {
            code
          }
          subtitle
          title
          what_is_webrtc
          what_is_webrtc_subtitle
          why_should_i
          why_should_i_subtitle
          meta_description
          meta_keywords
        }
      }
    }
  }
`
