import { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'

export default function TransparencyReportChartScale({
  size,
  multiplier,
}: {
  size: number
  multiplier: number
}): ReactElement {
  return (
    <>
      <Flex
        sx={{
          flexDirection: 'column-reverse',
          justifyContent: 'flex-end',
          gap: '2.8rem',
          alignItems: 'flex-end',
          color: 'white50',
          textAlign: 'end',
          fontSize: '1rem',
          pb: '4rem',
          span: {
            m: 0,
            p: 0,
          },
          width: '3.2rem',
        }}
      >
        <Text key="0">0</Text>
        {Array.from({ length: size }, (_, index) => {
          return <Text key={index}> {(index + 1) * multiplier}</Text>
        })}
      </Flex>
    </>
  )
}
