import CreateAccountButtons from 'components/knowledge-base/signup-page/CreateAccountButtons'
import RobertCheckbox from 'components/knowledge-base/signup-page/RobertCheckbox'
import SignupPageTextInput from 'components/knowledge-base/signup-page/SignupPageTextInput'
import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'
import StaticPageLayout from 'components/StaticPageLayout'
import { graphql } from 'gatsby'
import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'

export default function Signup({ data }: { data: Queries.SignupPageQuery }): ReactElement {
  const dataStrings = getEnglishStrings(data.directus.billing_page?.strings)
  return (
    <StaticPageLayout>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          fontFamily: 'IBM Plex Sans',
          width: '100%',
        }}
      >
        <TitleAndSubtitle title={dataStrings?.title} subtitle={dataStrings?.subtitle} />
        <SignupPageTextInput
          username={dataStrings?.username}
          password={dataStrings?.password}
          confirmPassword={dataStrings?.confirm_password}
          email={dataStrings?.email}
          emailDescription={dataStrings?.email_description}
        />
        <RobertCheckbox
          robertTitle={dataStrings?.robert_title}
          robertDescription={dataStrings?.robert_description}
        />
        <CreateAccountButtons
          createAccountButton={dataStrings?.create_account_button}
          createAccountDescription={dataStrings?.create_account_description}
          voucher={dataStrings?.voucher}
          add={dataStrings?.add}
          haveAccount={dataStrings?.have_account}
          login={dataStrings?.login}
        />
      </Flex>
    </StaticPageLayout>
  )
}

export const pageQuery = graphql`
  query SignupPage {
    directus {
      billing_page {
        strings {
          languages_code {
            code
          }
          add
          confirm_password
          create_account_button
          create_account_description
          email
          email_description
          have_account
          login
          password
          robert_description
          robert_title
          subtitle
          title
          voucher
          username
        }
      }
    }
  }
`
