import { StylePropertyValue } from 'theme-ui'

export default function RoundedBorder({
  minHeight,
  roundedEdge,
  backgroundColor = 'background',
  backgroundImage,
  width = [0, '100vw', '95%'],
  zIndex = -1,
  maxWidth = '138rem',
}: {
  minHeight?: (string | number)[]
  roundedEdge: 'top' | 'bottom'
  backgroundColor?: string
  backgroundImage?: StylePropertyValue<string>
  zIndex?: number
  width?: (string | number)[]
  maxWidth?: string
}): JSX.Element {
  return (
    <div
      sx={{
        position: 'absolute',
        width,
        maxWidth: maxWidth,
        left: '50%',
        transform: 'translateX(-50%)',
        minHeight,
        backgroundColor: backgroundColor,
        borderRadius: roundedEdge === 'top' ? '3.6rem 3.6rem 0 0' : '0 0 3.6rem 3.6rem',
        borderColor: 'transparent',
        zIndex: zIndex,
        backgroundImage: backgroundImage,
      }}
    />
  )
}
