import platform from 'platform'

export enum OsFamily {
  WINDOWS = 'windows',
  MAC_OS_X = 'mac',
  IOS = 'ios',
  ANDROID = 'android',
  UNKNOWN = 'unknown',
}

export enum Browser {
  CHROME = 'chrome',
  FIREFOX = 'firefox',
  SAFARI = 'safari',
  OPERA = 'opera',
  MSEDGE = 'edge',
  OTHER = 'other',
}

export function getBrowser(): Browser {
  const { name, ua } = platform

  if (name === 'Chrome') {
    // Since Brave does not have a unique UA, this prevents users on Brave from seeing 'Chrome' highlighted in the setup guide
    //@ts-ignore
    if (navigator?.brave) {
      return Browser.OTHER
    }
    if (ua?.includes('Edg')) {
      return Browser.MSEDGE
    } else {
      return Browser.CHROME
    }
  } else if (name === 'Microsoft Edge') {
    return Browser.MSEDGE
  } else if (name === 'Firefox') {
    return Browser.FIREFOX
  } else if (name === 'Safari') {
    return Browser.SAFARI
  } else if (name === 'Opera') {
    return Browser.OPERA
  } else {
    return Browser.OTHER
  }
}

export function getOsFamily(): OsFamily {
  const os: { family: string } = platform.os

  if (!os) {
    return OsFamily.UNKNOWN
  }

  if (os.family.startsWith('Windows')) {
    return OsFamily.WINDOWS
  }

  if (os.family === 'OS X') {
    // HACK: since iOS 13, iPads report themselves as Intel Macs running OS X, so this
    // appears to be the best way to distinguish between the two, although it might
    // not be safe forever (for example if a touchscreen Mac were released)
    //
    // more info: https://developer.apple.com/forums/thread/119186)
    if (window.navigator.maxTouchPoints > 0) {
      return OsFamily.IOS
    } else {
      return OsFamily.MAC_OS_X
    }
  }

  if (os.family === 'iOS') {
    return OsFamily.IOS
  }

  if (os.family === 'Android') {
    return OsFamily.ANDROID
  }

  return OsFamily.UNKNOWN
}

export const getPlatformInformation = (): { browserName?: string; platformName?: string } => {
  //   console.log(getBrowser(), getOsFamily())
  return { browserName: getBrowser(), platformName: getOsFamily() }
}
