import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { Flex } from 'theme-ui'

export default function FeatureHeader({
  title,
  subtitle,
  imageSource,
}: {
  title: string
  subtitle: string
  imageSource?: string
}): ReactElement {
  return (
    <Flex
      sx={{
        mt: ['3.2rem', '5.6rem'],
        alignItems: 'center',
        justifyContent: 'center',
        gap: ['2.4rem', '4.8rem', '8rem'],
        flexDirection: ['column', 'row', 'row'],
        maxWidth: ['100%', '90%', '83.3rem'],
      }}
    >
      <TitleAndSubtitle
        title={title}
        subtitle={subtitle}
        dataTestId={`${title?.toLowerCase().replaceAll(' ', '-')}-title`}
      />
      {imageSource && (
        <img
          alt=""
          src={imageSource}
          sx={{ width: '20.8rem', height: '22.8rem', objectFit: 'scale-down' }}
        />
      )}
    </Flex>
  )
}
