import StaticPageLayout from 'components/StaticPageLayout'
import ExternalLink from 'components/UI/ExternalLink'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'

const NotFoundPage = ({ data }: { data: Queries.NotFoundPageQuery }): ReactElement => {
  const notFoundTranslatedStrings = getEnglishStrings(data.directus.not_found_page?.strings)
  const notFoundImage = getImage(data.allFile.edges[0].node.childImageSharp)

  return (
    <StaticPageLayout title={`${notFoundTranslatedStrings?.title} | Windscribe`}>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2.4rem',
          mb: ['12rem', '9rem', '14.3rem'],
        }}
      >
        {notFoundImage && (
          <GatsbyImage
            alt={''}
            image={notFoundImage}
            sx={{
              mt: '19rem',
              maxWidth: '56.8rem',
              width: '100%',
              height: 'auto',
            }}
          />
        )}
        <Text sx={{ fontSize: '1.8rem', textAlign: 'center' }}>
          {notFoundTranslatedStrings?.nothing_to_see}
        </Text>
        <ExternalLink variant="buttons.neon" href="/">
          {notFoundTranslatedStrings?.go_home}
        </ExternalLink>
      </Flex>
    </StaticPageLayout>
  )
}

export const query = graphql`
  query NotFoundPage {
    directus {
      not_found_page {
        strings {
          languages_code {
            code
          }
          title
          nothing_to_see
          go_home
        }
      }
    }
    allFile(filter: { base: { in: ["not-found.png"] }, sourceInstanceName: { eq: "images" } }) {
      edges {
        node {
          base
          childImageSharp {
            gatsbyImageData(width: 750, placeholder: NONE)
          }
        }
      }
    }
  }
`

export default NotFoundPage
