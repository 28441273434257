import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import WireGuardDisclaimer from 'components/features-page/WireGuardDisclaimer'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'
import CircumventCensorshipImage from 'images/circumvent-censorship.png'

export default function CircumventCensorship({
  data,
}: {
  data: Queries.CircumventCensorshipPageQuery
}): ReactElement {
  const circumventCensorshipData = getEnglishStrings(data.directus.circumvent_censorship?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()

  return (
    <FeaturePageSkeleton
      featureImage={CircumventCensorshipImage}
      title={circumventCensorshipData?.title ?? ''}
      subtitle={circumventCensorshipData?.subtitle ?? ''}
      gridItems={[
        {
          title: circumventCensorshipData?.better_bypassing ?? '',
          description: circumventCensorshipData?.better_bypassing_subtitle ?? '',
        },
        {
          title: circumventCensorshipData?.four_protocols ?? '',
          description: circumventCensorshipData?.four_protocols_subtitle ?? '',
        },
        {
          title: circumventCensorshipData?.how_does_it_work ?? '',
          description: circumventCensorshipData?.how_does_it_work_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {circumventCensorshipData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [circumventCensorshipData?.if_youre_having ?? ''],
        },
      ]}
      metaDescription={circumventCensorshipData?.meta_description ?? ''}
      metaKeywords={circumventCensorshipData?.meta_keywords}
    >
      <WireGuardDisclaimer />
    </FeaturePageSkeleton>
  )
}

export const data = graphql`
  query CircumventCensorshipPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      circumvent_censorship {
        strings {
          languages_code {
            code
          }
          better_bypassing
          better_bypassing_subtitle
          cta
          four_protocols
          four_protocols_subtitle
          how_does_it_work
          how_does_it_work_subtitle
          if_youre_having
          meta_description
          meta_keywords
          subtitle
          title
        }
      }
    }
  }
`
