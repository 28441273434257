import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function AdBlockingPage({
  data,
}: {
  data: Queries.AdBlockingPageQuery
}): ReactElement {
  const blockerFeaturesData = getEnglishStrings(data.directus.blocker_features?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={blockerFeaturesData?.meta_description ?? ''}
      metaKeywords={blockerFeaturesData?.meta_keywords ?? []}
      title={blockerFeaturesData?.title ?? ''}
      subtitle={blockerFeaturesData?.subtitle ?? ''}
      summaryText={blockerFeaturesData?.summary_text ?? ''}
      gridItems={[
        {
          title: blockerFeaturesData?.ad_crusher ?? '',
          description: blockerFeaturesData?.ad_crusher_subtitle ?? '',
        },
        {
          title: blockerFeaturesData?.tracker_eradicator ?? '',
          description: blockerFeaturesData?.tracker_eradicator_subtitle ?? '',
        },
        {
          title: blockerFeaturesData?.malware_evader ?? '',
          description: blockerFeaturesData?.malware_evader_subtitle ?? '',
        },
        {
          title: blockerFeaturesData?.social_distancing ?? '',
          description: blockerFeaturesData?.social_distancing_subtitle ?? '',
        },
        {
          title: blockerFeaturesData?.cookie_go ?? '',
          description: blockerFeaturesData?.cookie_go_subtitle ?? '',
        },
        {
          title: blockerFeaturesData?.advanced_mode ?? '',
          description: blockerFeaturesData?.advanced_mode_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {blockerFeaturesData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [blockerFeaturesData?.think_your_privacy ?? ''],
        },
      ]}
    />
  )
}

export const data = graphql`
  query AdBlockingPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      blocker_features {
        strings {
          ad_crusher
          ad_crusher_subtitle
          advanced_mode
          advanced_mode_subtitle
          cookie_go
          cookie_go_subtitle
          cta
          languages_code {
            code
          }
          malware_evader
          malware_evader_subtitle
          meta_description
          meta_keywords
          social_distancing
          social_distancing_subtitle
          subtitle
          summary_text
          think_your_privacy
          title
          tracker_eradicator
          tracker_eradicator_subtitle
        }
      }
    }
  }
`
