import { useLocation } from '@reach/router'
import StaticPageLayout from 'components/StaticPageLayout'
import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'
import BrowserDownloadButtons from 'components/features-page/BrowserDownloadButtons'
import { gridBaseStyle, otherFeaturesBaseStyle } from 'components/features-page/DesktopFeaturesPage'
import FeatureBox from 'components/features-page/FeatureBox'
import FeatureImage from 'components/features-page/FeatureImage'
import FeatureText from 'components/features-page/FeatureText'
import Background from 'components/features-page/background/Background'
import Fade from 'components/features-page/background/Fade'
import RoundedBorder from 'components/features-page/background/RoundedBorder'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import blockerIcon from 'images/blocker.svg'
import checkIcon from 'images/check-icon.svg'
import connectivity from 'images/connectivity.svg'
import dataEncryptedIcon from 'images/data-encrypted-icon.svg'
import globeIcon from 'images/globe-icon.svg'
import privacyIcon from 'images/privacy-dark.svg'
import unlockContentIcon from 'images/unlock-content-icon.svg'
import { Box, Flex, Grid, Text } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import FirefoxExtension from 'images/windscribe-firefox-extension.png'
import ChromeExtension from 'images/windscribe-chrome-extension.png'
import EdgeExtension from 'images/windscribe-edge-extension.png'

type Header = {
  title: string
  subtitle: string
  image: string
}

export default function BrowserFeaturesPage(): JSX.Element {
  const data = useStaticQuery<Queries.ExtensionFeaturesPageQuery>(graphql`
    query ExtensionFeaturesPage {
      directus {
        general_translations {
          strings {
            features
            languages_code {
              code
            }
          }
        }
        browser_page {
          strings {
            ad_crusher
            ad_crusher_subtitle
            advanced_blocking
            advanced_blocking_subtitle
            advanced_blocking_title
            advanced_mode
            advanced_privacy
            advanced_mode_subtitle
            advanced_privacy_first_subtitle
            advanced_privacy_second_subtitle
            advanced_privacy_title
            cookie_go
            cookie_go_subtitle
            cookie_monster
            cookie_monster_subtitle
            custom_whitelisting
            do_not_disturb
            do_not_disturb_subtitle
            download_for_chrome
            download_for_edge
            failover
            download_for_firefox
            failover_subtitle
            language_warp
            language_warp_subtitle
            location_warp
            languages_code {
              code
            }
            location_warp_subtitle
            malware_evader
            other_features
            malware_evader_subtitle
            proxy_port
            smokewall
            proxy_port_subtitle
            servers_in_over
            smokewall_subtitle
            social_distancing
            social_distancing_subtitle
            split_personality
            split_personality_subtitle
            strict_no_logging
            subtitle
            time_warp
            time_warp_subtitle
            title
            tracker_eradicator
            tracker_eradicator_subtitle
            unlock_content
            unparalleled_connectivity
            unparalleled_connectivity_subtitle
            unparalleled_connectivity_title
            webrtc_subtitle
            webrtc
            workers_block
            workers_block_subtitle
            meta_description
            meta_keywords
            chrome_title
            chrome_subtitle
            firefox_title
            firefox_subtitle
            edge_title
            edge_subtitle
          }
        }
        chrome_app_page {
          strings {
            languages_code {
              code
            }
            meta_description
            meta_keywords
            title
          }
        }
        firefox_app_page {
          strings {
            languages_code {
              code
            }
            meta_description
            meta_keywords
            title
          }
        }
        edge_app_page {
          strings {
            languages_code {
              code
            }
            meta_description
            meta_keywords
            title
          }
        }
      }
    }
  `)

  const browserFeaturesData = getEnglishStrings(data.directus.browser_page?.strings)
  const generalTranslations = getEnglishStrings(data.directus.general_translations?.strings)
  const location = useLocation()

  const osType = (() => {
    switch (location.pathname) {
      case '/features/chrome/':
        return 'chrome'
      case '/features/firefox/':
        return 'firefox'
      case '/features/edge/':
        return 'edge'
      default:
        return 'chrome'
    }
  })()

  const metaData = {
    firefox: getEnglishStrings(data?.directus?.firefox_app_page?.strings),
    chrome: getEnglishStrings(data?.directus?.chrome_app_page?.strings),
    edge: getEnglishStrings(data?.directus?.edge_app_page?.strings),
  }[osType]

  const headerRecord: Record<string, Header> = {
    '/features/chrome/': {
      title: browserFeaturesData?.chrome_title ?? '',
      subtitle: browserFeaturesData?.chrome_subtitle ?? '',
      image: '../../images/windscribe-chrome-app.png',
    },
    '/features/firefox/': {
      title: browserFeaturesData?.firefox_title ?? '',
      subtitle: browserFeaturesData?.firefox_subtitle ?? '',
      image: '../../images/windscribe-firefox-app.png',
    },
    '/features/edge/': {
      title: browserFeaturesData?.edge_title ?? '',
      subtitle: browserFeaturesData?.edge_subtitle ?? '',
      image: '../../images/windscribe-edge-app.png',
    },
  }

  return (
    <StaticPageLayout
      title={`${metaData?.title} | Windscribe`}
      metaDescription={metaData?.meta_description}
      metaKeywords={metaData?.meta_keywords}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          my: ['3.2rem', '4.8rem'],
        }}
      >
        <TitleAndSubtitle
          title={headerRecord[location.pathname]?.title ?? ''}
          subtitle={headerRecord[location.pathname]?.subtitle ?? ''}
          dataTestId={`${osType}-features-title`}
        />
      </Flex>
      <BrowserDownloadButtons
        chromeText={browserFeaturesData?.download_for_chrome ?? ''}
        firefoxText={browserFeaturesData?.download_for_firefox ?? ''}
        edgeText={browserFeaturesData?.download_for_edge ?? ''}
        type={osType}
      />

      <img
        src={
          {
            chrome: ChromeExtension,
            firefox: FirefoxExtension,
            edge: EdgeExtension,
          }[osType]
        }
        alt="Windscribe extension app"
        sx={{
          width: ['100%', '72rem', '68.8rem'],
          mt: '4.8rem',
        }}
      />

      <Flex
        sx={{
          borderRadius: '2.1rem',
          backgroundColor: 'white10',
          alignItems: 'center',
          justifyContent: 'center',
          width: '14.1rem',
          height: '4rem',
          textTransform: 'uppercase',
          mt: ['4.8rem', '8rem', '8rem'],
        }}
      >
        <Text
          sx={{
            fontSize: '1.8rem',
            fontWeight: 'bold',
            color: 'white50',
          }}
        >
          {generalTranslations?.features}
        </Text>
      </Flex>
      <Background display={['flex', 'flex', 'flex']}>
        <Fade
          height={['455rem', '450rem', '220rem']}
          display={['flex', 'flex', 'flex']}
          backgroundImage={({ colors }): string =>
            `linear-gradient(to bottom, ${colors?.background} 0%, ${colors?.midnight} 20%, ${colors?.midnight})`
          }
        />
        <RoundedBorder minHeight={[0, '81.2rem', '50rem']} roundedEdge="bottom" />
      </Background>
      <Box
        sx={{
          mt: ['3.2rem', '4.8rem', '4.8rem'],
        }}
      >
        <FeatureImage
          imageSide="right"
          title={browserFeaturesData?.advanced_privacy_title ?? ''}
          category={browserFeaturesData?.advanced_privacy ?? ''}
          firstSubtitle={browserFeaturesData?.advanced_privacy_first_subtitle ?? ''}
          secondSubtitle={browserFeaturesData?.advanced_privacy_second_subtitle ?? ''}
          icon={privacyIcon}
          categoryColour="periwinkle"
          imageStyles={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <StaticImage
            src="../../images/privacy-app.png"
            alt=""
            sx={{ width: '32rem', height: '100%' }}
          />
        </FeatureImage>
      </Box>
      <Background display={['flex', 'flex', 'none']}>
        <Flex
          sx={{
            marginTop: ['-75rem', 0, 0],
            height: ['75rem', 0],
            width: '100%',
            backgroundColor: ({ colors }) => colors?.background,
            zIndex: -1,
          }}
        />
        <RoundedBorder minHeight={['9rem', '10.5rem', 0]} roundedEdge="bottom" />
      </Background>
      <Grid
        columns={[1, 4, 6]}
        sx={{
          ...gridBaseStyle,
          px: 0,
          mt: ['3.2rem', '4.8rem', 0],
          '& > div': {
            gridColumn: ['span 2', 'span 2', 'span 2'],
          },
          '& > div:nth-child(7), div:nth-child(8)': {
            gridColumn: ['span 2', 'span 2', 'span 3'],
          },
        }}
      >
        <FeatureBox
          title={browserFeaturesData?.cookie_monster ?? ''}
          subtitle={browserFeaturesData?.cookie_monster_subtitle ?? ''}
          colour="periwinkle"
        />
        <FeatureBox
          title={browserFeaturesData?.do_not_disturb ?? ''}
          subtitle={browserFeaturesData?.do_not_disturb_subtitle ?? ''}
          colour="periwinkle"
        />
        <FeatureBox
          title={browserFeaturesData?.webrtc ?? ''}
          subtitle={browserFeaturesData?.webrtc_subtitle ?? ''}
          colour="periwinkle"
        />
        <FeatureBox
          title={browserFeaturesData?.location_warp ?? ''}
          subtitle={browserFeaturesData?.location_warp_subtitle ?? ''}
          colour="periwinkle"
        />
        <FeatureBox
          title={browserFeaturesData?.time_warp ?? ''}
          subtitle={browserFeaturesData?.time_warp_subtitle ?? ''}
          colour="periwinkle"
        />
        <FeatureBox
          title={browserFeaturesData?.language_warp ?? ''}
          subtitle={browserFeaturesData?.language_warp_subtitle ?? ''}
          colour="periwinkle"
        />
        <FeatureBox
          title={browserFeaturesData?.split_personality ?? ''}
          subtitle={browserFeaturesData?.split_personality_subtitle ?? ''}
          colour="periwinkle"
        />
        <FeatureBox
          title={browserFeaturesData?.workers_block ?? ''}
          subtitle={browserFeaturesData?.workers_block_subtitle ?? ''}
          colour="periwinkle"
        />
      </Grid>
      <Flex sx={{ mt: ['4.8rem', '6.2rem', '8rem'] }}>
        <FeatureImage
          imageSide="left"
          title={browserFeaturesData?.advanced_blocking_title ?? ''}
          category={browserFeaturesData?.advanced_blocking ?? ''}
          firstSubtitle={browserFeaturesData?.advanced_blocking_subtitle ?? ''}
          icon={blockerIcon}
          categoryColour="neonGreen"
        >
          <StaticImage
            src="../../images/blocker-app.png"
            alt=""
            sx={{ width: '32rem', height: '100%' }}
          />
        </FeatureImage>
      </Flex>
      <Grid
        columns={[1, 2, 3]}
        sx={{
          ...gridBaseStyle,
          px: 0,
          mt: ['3.2rem', '4.8rem', '0'],
          mb: ['4.8rem', '8rem', '8rem'],
        }}
      >
        <FeatureBox
          title={browserFeaturesData?.ad_crusher ?? ''}
          subtitle={browserFeaturesData?.ad_crusher_subtitle ?? ''}
          colour="neonGreen"
        />
        <FeatureBox
          title={browserFeaturesData?.tracker_eradicator ?? ''}
          subtitle={browserFeaturesData?.tracker_eradicator_subtitle ?? ''}
          colour="neonGreen"
        />
        <FeatureBox
          title={browserFeaturesData?.malware_evader ?? ''}
          subtitle={browserFeaturesData?.malware_evader_subtitle ?? ''}
          colour="neonGreen"
        />
        <FeatureBox
          title={browserFeaturesData?.social_distancing ?? ''}
          subtitle={browserFeaturesData?.social_distancing_subtitle ?? ''}
          colour="neonGreen"
        />
        <FeatureBox
          title={browserFeaturesData?.cookie_go ?? ''}
          subtitle={browserFeaturesData?.cookie_go_subtitle ?? ''}
          colour="neonGreen"
        />
        <FeatureBox
          title={browserFeaturesData?.advanced_mode ?? ''}
          subtitle={browserFeaturesData?.advanced_mode_subtitle ?? ''}
          colour="neonGreen"
        />
      </Grid>

      <Background display={['block', 'block', 'block']}>
        <RoundedBorder minHeight={['50rem', '81.3rem', '47.8rem']} roundedEdge="top" />
        <Flex sx={{ marginTop: ['-18rem', 0, 0] }}>
          <Fade
            height={['100rem', '180rem', '100rem']}
            display={['flex', 'flex', 'flex']}
            backgroundImage={({ colors }): string =>
              `linear-gradient(to bottom, ${colors?.midnight}0%, ${colors?.background} 40%, ${colors?.background} 88%, ${colors?.background} 100%)`
            }
          />
        </Flex>
      </Background>
      <Flex sx={{ mt: ['4.8rem', '8rem', '8rem'] }}>
        <FeatureImage
          imageSide="right"
          title={browserFeaturesData?.unparalleled_connectivity_title ?? ''}
          category={browserFeaturesData?.unparalleled_connectivity ?? ''}
          firstSubtitle={browserFeaturesData?.unparalleled_connectivity_subtitle ?? ''}
          icon={connectivity}
          categoryColour="windscribeBlue"
          imageStyles={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <StaticImage
            src="../../images/smokewall-app.png"
            alt=""
            sx={{ width: '32rem', height: '100%' }}
          />
        </FeatureImage>
      </Flex>
      <Grid
        columns={[1, 2, 3]}
        sx={{
          ...gridBaseStyle,
          px: 0,
          width: '100%',
          mt: ['3.2rem', '4.8rem', '0'],
          '& > div': {
            gridColumn: ['span 1', 'span 1', 'span 1'],
          },
          '& > div:nth-child(3)': {
            gridColumn: ['span 1', 'span 2', 'span 1'],
          },
        }}
      >
        <FeatureBox
          title={browserFeaturesData?.smokewall ?? ''}
          subtitle={browserFeaturesData?.smokewall_subtitle ?? ''}
          colour="windscribeBlue"
        />
        <FeatureBox
          title={browserFeaturesData?.failover ?? ''}
          subtitle={browserFeaturesData?.failover_subtitle ?? ''}
          colour="windscribeBlue"
        />
        <FeatureBox
          title={browserFeaturesData?.proxy_port ?? ''}
          subtitle={browserFeaturesData?.proxy_port_subtitle ?? ''}
          colour="windscribeBlue"
        />
      </Grid>
      <Flex sx={{ mt: ['4.8rem', '8rem', '8rem'] }}>
        <Text sx={{ color: 'white', fontSize: '4.8rem', fontWeight: 'bold' }}>
          {browserFeaturesData?.other_features}
        </Text>
      </Flex>
      <Grid columns={[1, 2, 2]} sx={otherFeaturesBaseStyle}>
        <FeatureText icon={unlockContentIcon} text={browserFeaturesData?.unlock_content ?? ''} />
        <FeatureText icon={dataEncryptedIcon} text={browserFeaturesData?.strict_no_logging ?? ''} />
        <FeatureText icon={checkIcon} text={browserFeaturesData?.custom_whitelisting ?? ''} />
        <FeatureText icon={globeIcon} text={browserFeaturesData?.servers_in_over ?? ''} />
      </Grid>
      <Flex
        sx={{
          justifyContent: 'center',
          width: '100%',
          mb: [0, '3.2rem'],
          mt: ['4.8rem', '4.8rem', '8rem'],
        }}
      >
        <BrowserDownloadButtons
          chromeText={browserFeaturesData?.download_for_chrome ?? ''}
          firefoxText={browserFeaturesData?.download_for_firefox ?? ''}
          edgeText={browserFeaturesData?.download_for_edge ?? ''}
          type={osType}
        />
      </Flex>
    </StaticPageLayout>
  )
}
