import ExternalLink from 'components/UI/ExternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import { Text } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function CookieMonster({
  data,
}: {
  data: Queries.CookieMonsterPageQuery
}): ReactElement {
  const cookieMonsterData = getEnglishStrings(data.directus.cookie_monster?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={cookieMonsterData?.meta_description || ''}
      metaKeywords={cookieMonsterData?.meta_keywords || []}
      title={cookieMonsterData?.title || ''}
      subtitle={cookieMonsterData?.subtitle || ''}
      gridItems={[
        {
          title: cookieMonsterData?.deletes_your || '',
          description: cookieMonsterData?.deletes_your_subtitle || '',
        },
        {
          title: cookieMonsterData?.pick_your || '',
          description: cookieMonsterData?.pick_your_party || '',
        },
      ]}
      cta={
        <ExternalLink variant="buttons.neon" href={`/download?${queryParam}`}>
          <Text>{cookieMonsterData?.cta}</Text>
        </ExternalLink>
      }
      aboutTheFeature={[]}
    />
  )
}

export const data = graphql`
  query CookieMonsterPage {
    directus {
      cookie_monster {
        strings {
          languages_code {
            code
          }
          cta
          deletes_your
          deletes_your_subtitle
          meta_description
          meta_keywords
          pick_your
          pick_your_party
          subtitle
          title
        }
      }
    }
  }
`
