import FeatureInfo from 'components/FeatureInfo'
import StaticPageLayout from 'components/StaticPageLayout'
import FeatureHeader from 'components/features-page/FeatureHeader'
import FeatureItem from 'components/features-page/FeatureItem'
import FeatureSummary from 'components/features-page/FeatureSummary'
import { graphql, useStaticQuery } from 'gatsby'
import Garry1 from 'images/features/garry-1.svg'
import Garry2 from 'images/features/garry-2.svg'
import Garry3 from 'images/features/garry-3.svg'
import Garry4 from 'images/features/garry-4.svg'
import Garry5 from 'images/features/garry-5.svg'
import Garry6 from 'images/features/garry-6.svg'
import { ReactElement } from 'react'
import { Flex, Grid } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

interface GridFeatureItem {
  title: string
  description: string
}

export interface AboutTheFeature {
  title: string
  items: string[]
}

const featureIcons: React.ElementType[] = [Garry1, Garry2, Garry3, Garry4, Garry5, Garry6]

interface VideoFeature {
  src: string
  title: string
}

export default function FeaturePageSkeleton({
  title,
  subtitle,
  featureImage,
  summaryText,
  gridItems,
  cta,
  aboutTheFeature,
  videoFeature,
  children,
  metaDescription,
  metaKeywords,
  midSection,
}: {
  title: string
  subtitle: string
  featureImage?: string
  summaryText?: string
  gridItems: GridFeatureItem[]
  cta: ReactElement
  aboutTheFeature: AboutTheFeature[]
  videoFeature?: VideoFeature
  children?: ReactElement
  metaDescription: string
  metaKeywords: string[]
  midSection?: ReactElement
}): ReactElement {
  const data = useStaticQuery<Queries.FeaturePageSkeletonQuery>(graphql`
    query FeaturePageSkeleton {
      directus {
        general_translations {
          strings {
            languages_code {
              code
            }
            show_more
            show_less
            summary
            by_the_way
          }
        }
      }
    }
  `)

  const generalTranslations = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <StaticPageLayout
      title={`${title} | Windscribe`}
      metaDescription={metaDescription}
      metaKeywords={metaKeywords}
      ctaQueryParams={queryParam}
    >
      <FeatureHeader title={title} subtitle={subtitle} imageSource={featureImage} />
      {summaryText && (
        <Flex sx={{ mt: 'default' }}>
          <FeatureSummary
            text={summaryText}
            summaryTranslation={generalTranslations?.summary ?? ''}
          />
        </Flex>
      )}
      {videoFeature && (
        <iframe
          sx={{
            mt: 'default',
            aspectRatio: '16 / 9',
            width: '100%',
            maxWidth: '68rem',
            border: 0,
          }}
          src={videoFeature.src}
          title={videoFeature.title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      )}
      {midSection}
      <Grid
        columns={[1, 2, 2]}
        sx={{
          gap: ['3.2rem', '4.8rem 1.6rem', '4.8rem 8rem'],
          mt: 'default',
          maxWidth: ['100%', 'inherit', 'maxContentWidth'],
          width: ['inherit', '100%', 'inherit'],
        }}
      >
        {gridItems?.map((item, index) => (
          <FeatureItem
            key={item.title}
            icon={featureIcons[index]}
            title={item.title}
            description={item.description}
          />
        ))}
        {gridItems.length % 2 !== 0 && (
          <Flex sx={{ '& > *': { height: 'fit-content' }, margin: '0 auto', alignItems: 'center' }}>
            {cta}
          </Flex>
        )}
      </Grid>
      {gridItems.length % 2 === 0 && (
        <Flex sx={{ mt: ['3.2rem', '4.8rem'] }}>
          <Flex sx={{ flexGrow: 0 }}>{cta}</Flex>
        </Flex>
      )}
      {aboutTheFeature.map(feature => (
        <Flex sx={{ mt: 'default' }} key={feature.title}>
          <FeatureInfo feature={feature} />
        </Flex>
      ))}
      {children ? children : <Flex sx={{ mt: 'default' }} />}
    </StaticPageLayout>
  )
}
