import React, { forwardRef, ReactElement } from 'react'
import { SelectProps, Flex } from 'theme-ui'
import DropdownArrow from 'images/dropdown-arrow.svg'
import Svg from './SVG'

export default forwardRef<HTMLSelectElement, SelectProps>(function Select(
  props: SelectProps,
  ref,
): ReactElement {
  return (
    <Flex sx={{ width: '100%', justifyContent: 'center' }}>
      <select
        ref={ref}
        {...props}
        sx={{
          cursor: 'pointer',
          fontFamily: 'IBM Plex Sans',
          height: '4.8rem',
          border: 'none',
          borderRadius: '3.2rem',
          backgroundColor: 'white10',
          color: 'white',
          px: '2.4rem',
          fontSize: '1.8rem',
          appearance: 'none',
          whiteSpace: 'nowrap',
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
          option: {
            color: 'initial',
          },
          ...props.sx,
        }}
      />
      <Flex
        sx={{
          position: 'relative',
          right: '4rem',
          width: '2.4rem',
          height: '4.8rem',
          pointerEvents: 'none',
          backgroundColor: 'transparent',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Svg svg={DropdownArrow} />
      </Flex>
    </Flex>
  )
})
