import BrowserFeatures, { FeatureDivider } from 'components/features-page/BrowserFeatures'
import { ReactElement, useState } from 'react'
import { Box, Button, Flex, ThemeUIStyleObject } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import FlexibleDNSIcon from 'images/flexible-dns.svg'
import { Feature, FeatureWrapper } from 'components/features-page/Features'
import MacSpoofingIcon from 'images/mac-spoofing.svg'
import TCPSocketTerminationIcon from 'images/tcp-socket-termination.svg'
import ProxyGatewatIcon from 'images/proxy-gateway.svg'
import ProxySettingsIcon from 'images/proxy-settings.svg'
import SecureHotspotIcon from 'images/secure-hotspot.svg'
import DoubleHopIcon from 'images/double-hop.svg'
import FirewallIcon from 'images/firewall-icon.svg'
import UpdateChannels from 'images/update-channels.svg'
import BlockerFeaturesIcon from 'images/blocker-features.svg'
import CookieMonsterIcon from 'images/cookie-monster.svg'
import AdPrivacyIcon from 'images/ad-privacy.svg'
import TimeWarpIcon from 'images/time-warp.svg'
import LocationWarpIcon from 'images/location-warp.svg'
import SplitPersonalityIcon from 'images/split-personality.svg'
import WebRTCIcon from 'images/webrtc-slayer.svg'
import DoNotDisturbIcon from 'images/do-not-disturb.svg'
import LanguageWarpIcon from 'images/language-warp.svg'
import AutoPilotIcon from 'images/auto-pilot.svg'
import WorkerBlockIcon from 'images/worker-block.svg'
import GPSSpoofingIcon from 'images/gps-spoofing.svg'
import DecoyTrafficIcon from 'images/decoy-traffic.svg'
import Svg from 'components/UI/SVG'
import DesktopIcon from 'images/desktop-features.svg'
import BrowserIcon from 'images/browser-extension.svg'
import MobileIcon from 'images/mobile-extension.svg'

export interface FeatureSelectorList {
  [key: string]: FeatureSelectorListItem[]
}

interface FeatureSelectorListItem {
  title?: string | null
  description?: string | null
  icon: React.ElementType
}

const buttonStyles: ThemeUIStyleObject = {
  display: 'flex',
  gap: '1.6rem',
  backgroundColor: 'transparent',
  position: 'relative',
  alignItems: 'center',
  fontSize: '1.8rem',
  fontWeight: 'bold',
}

export default function FeatureSelector({
  data,
}: {
  data: Queries.KnowledgeBaseFeaturesPageQuery
}): ReactElement {
  const [selectedFeatureSet, setSelectedFeatureSet] =
    useState<'desktop' | 'extension' | 'mobile'>('desktop')
  const featuresPageStrings = getEnglishStrings(data.directus.features_page?.strings)
  const generalTranslations = getEnglishStrings(data.directus.general_translations?.strings)

  const featureList: FeatureSelectorList = {
    desktop: [
      {
        title: featuresPageStrings?.flexible_dns,
        description: featuresPageStrings?.flexible_dns_subtitle,
        icon: FlexibleDNSIcon,
      },
      {
        title: featuresPageStrings?.mac_spoofing,
        description: featuresPageStrings?.mac_spoofing_subtitle,
        icon: MacSpoofingIcon,
      },
      {
        title: featuresPageStrings?.tcp_socket_termination,
        description: featuresPageStrings?.tcp_socket_termination_subtitle,
        icon: TCPSocketTerminationIcon,
      },
      {
        title: featuresPageStrings?.auto_pilot,
        description: featuresPageStrings?.auto_pilot_subtitle,
        icon: AutoPilotIcon,
      },
      {
        title: featuresPageStrings?.proxy_gateway,
        description: featuresPageStrings?.proxy_gateway_subtitle,
        icon: ProxyGatewatIcon,
      },
      {
        title: featuresPageStrings?.proxy_settings,
        description: featuresPageStrings?.proxy_settings_subtitle,
        icon: ProxySettingsIcon,
      },
      {
        title: featuresPageStrings?.secure_hotspot,
        description: featuresPageStrings?.secure_hotspot_subtitle,
        icon: SecureHotspotIcon,
      },
      {
        title: featuresPageStrings?.double_hop,
        description: featuresPageStrings?.double_hop_subtitle,
        icon: DoubleHopIcon,
      },
      {
        title: featuresPageStrings?.firewall,
        description: featuresPageStrings?.firewall_subtitle,
        icon: FirewallIcon,
      },
      {
        title: featuresPageStrings?.update_channels,
        description: featuresPageStrings?.update_channels_subtitle,
        icon: UpdateChannels,
      },
    ],
    extension: [
      {
        title: featuresPageStrings?.blocker_features,
        description: featuresPageStrings?.blocker_features_subtitle,
        icon: BlockerFeaturesIcon,
      },
      {
        title: featuresPageStrings?.cookie_monster,
        description: featuresPageStrings?.cookie_monster_subtitle,
        icon: CookieMonsterIcon,
      },
      {
        title: featuresPageStrings?.ad_privacy,
        description: featuresPageStrings?.ad_privacy_subtitle,
        icon: AdPrivacyIcon,
      },
      {
        title: featuresPageStrings?.tcp_socket_termination,
        description: featuresPageStrings?.tcp_socket_termination_subtitle,
        icon: TCPSocketTerminationIcon,
      },
      {
        title: featuresPageStrings?.time_warp,
        description: featuresPageStrings?.time_warp_subtitle,
        icon: TimeWarpIcon,
      },
      {
        title: featuresPageStrings?.location_warp,
        description: featuresPageStrings?.location_warp_subtitle,
        icon: LocationWarpIcon,
      },
      {
        title: featuresPageStrings?.split_personality,
        description: featuresPageStrings?.split_personality_subtitle,
        icon: SplitPersonalityIcon,
      },
      {
        title: featuresPageStrings?.double_hop,
        description: featuresPageStrings?.double_hop_subtitle,
        icon: DoubleHopIcon,
      },
      {
        title: featuresPageStrings?.webrtc_slayer,
        description: featuresPageStrings?.webrtc_slayer_subtitle,
        icon: WebRTCIcon,
      },
      {
        title: featuresPageStrings?.do_not_disturb,
        description: featuresPageStrings?.do_not_disturb_subtitle,
        icon: DoNotDisturbIcon,
      },
      {
        title: featuresPageStrings?.language_warp,
        description: featuresPageStrings?.language_warp_subtitle,
        icon: LanguageWarpIcon,
      },
      {
        title: featuresPageStrings?.auto_pilot,
        description: featuresPageStrings?.auto_pilot_subtitle,
        icon: AutoPilotIcon,
      },
      {
        title: featuresPageStrings?.worker_block,
        description: featuresPageStrings?.worker_block_subtitle,
        icon: WorkerBlockIcon,
      },
    ],
    mobile: [
      {
        title: featuresPageStrings?.tcp_socket_termination,
        description: featuresPageStrings?.tcp_socket_termination_subtitle,
        icon: TCPSocketTerminationIcon,
      },
      {
        title: featuresPageStrings?.gps_spoofing,
        description: featuresPageStrings?.gps_spoofing_subtitle,
        icon: GPSSpoofingIcon,
      },
      {
        title: featuresPageStrings?.auto_pilot,
        description: featuresPageStrings?.auto_pilot_subtitle,
        icon: AutoPilotIcon,
      },
      {
        title: featuresPageStrings?.decoy_traffic,
        description: featuresPageStrings?.decoy_traffic_subtitle,
        icon: DecoyTrafficIcon,
      },
    ],
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        gap: '4.8rem',
      }}
    >
      <Flex sx={{ flexDirection: 'column', gap: '1.6rem' }}>
        <Flex
          sx={{
            button: { width: 'max-content' },
            width: '100vw',
            maxWidth: '98rem',
            gap: ['2.4rem', '4rem'],
            justifyContent: 'center',
            span: {
              display: ['none', 'block'],
            },
          }}
        >
          <Button
            onClick={() => setSelectedFeatureSet('desktop')}
            sx={{
              ...buttonStyles,
              svg: {
                'g > path': {
                  fill: selectedFeatureSet === 'desktop' ? 'white' : 'white50',
                  fillOpacity: 1,
                },
              },
              color: selectedFeatureSet === 'desktop' ? 'white' : 'white50',
            }}
          >
            <Svg svg={DesktopIcon} width="2.4rem" height="2.4rem" />
            <span>{generalTranslations?.desktop}</span>
            {selectedFeatureSet === 'desktop' && <FeatureListitemUnderline />}
          </Button>
          <Button
            onClick={() => setSelectedFeatureSet('extension')}
            sx={{
              ...buttonStyles,
              svg: {
                path: {
                  fill: selectedFeatureSet === 'extension' ? 'white' : 'white50',
                  fillOpacity: 1,
                },
              },
              color: selectedFeatureSet === 'extension' ? 'white' : 'white50',
            }}
          >
            <Svg svg={BrowserIcon} width="2.4rem" height="2.4rem" />
            <span>{generalTranslations?.extension}</span>
            {selectedFeatureSet === 'extension' && <FeatureListitemUnderline />}
          </Button>
          <Button
            onClick={() => setSelectedFeatureSet('mobile')}
            sx={{
              ...buttonStyles,
              svg: {
                path: {
                  fill: selectedFeatureSet === 'mobile' ? 'white' : 'white50',
                  fillOpacity: 1,
                },
              },
              color: selectedFeatureSet === 'mobile' ? 'white' : 'white50',
            }}
          >
            <Svg svg={MobileIcon} width="2.4rem" height="2.4rem" />
            <span>{generalTranslations?.mobile}</span>
            {selectedFeatureSet === 'mobile' && <FeatureListitemUnderline />}
          </Button>
        </Flex>
        <FeatureDivider />
      </Flex>

      <FeatureWrapper>
        {featureList[selectedFeatureSet].map((feature, index) => (
          <Feature
            key={index}
            title={feature.title}
            description={feature.description}
            icon={feature.icon}
          />
        ))}
      </FeatureWrapper>
      <BrowserFeatures
        browserExtensionFeaturesText={featuresPageStrings?.browser_extension_features}
        desktopAppFeaturesText={featuresPageStrings?.desktop_app_features}
        mobileAppFeaturesText={featuresPageStrings?.mobile_features}
        activeGroup={selectedFeatureSet}
      />
    </Flex>
  )
}

function FeatureListitemUnderline() {
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '-1.8rem',
        left: 0,
        width: '100%',
        height: '0.2rem',
        backgroundColor: 'white',
      }}
    />
  )
}
