import { navigate } from 'gatsby'
import { PushReplaceHistory } from 'use-query-params'

function generatePath(location: Location) {
  return location.pathname + location.search + location.hash
}

export const history: PushReplaceHistory = {
  push: location => {
    navigate(generatePath(location), {
      replace: false,
      state: location.hash,
    })
  },
  replace: location => {
    navigate(generatePath(location), {
      replace: true,
      state: location.hash,
    })
  },
}
