export type DesktopPlaforms = 'Windows' | 'Linux' | 'MacOS'
export type ExtensionPlatforms = 'Chrome' | 'Firefox' | 'Edge'
export type MobilePlatforms = 'iOS' | 'Android'
export type TVPlatforms = 'FireTV' | 'AndroidTV'

//List was taken from here:
//https://github.com/etienne-martin/device-detector-js/blob/master/src/parsers/operating-system/fixtures/operating-system.json
export const defineOS = (osName: string): DesktopPlaforms => {
  switch (osName) {
    case 'Windows':
    case 'windows':
      return 'Windows'
    case 'Arch Linux':
    case 'CentOS':
    case 'Debian':
    case 'Fedora':
    case 'FreeBSD':
    case 'Gentoo':
    case 'Kubuntu':
    case 'GNU/Linux':
    case 'Lubuntu':
    case 'Mandriva':
    case 'Mint':
    case 'OpenBSD':
    case 'Red Hat':
    case 'SUSE':
    case 'Slackware':
    case 'Ubuntu':
    case 'Xubuntu':
    case 'Linux':
    case 'linux':
      return 'Linux'
    case 'Mac OS':
    case 'Mac':
    case 'macOS':
    case 'macos':
      return 'MacOS'
    default:
      return 'Windows'
  }
}

//List was taken from here:
//https:github.com/etienne-martin/device-detector-js/blob/master/src/parsers/client/fixtures/available-browsers.json
export const defineBrowser = (browser: string): ExtensionPlatforms => {
  switch (browser) {
    case 'Chrome':
    case 'Chrome Frame':
    case 'Chrome Mobile iOS':
    case 'Chrome Mobile':
    case 'ChromePlus':
    case 'Chrome Webview':
    case 'Headless Chrome':
    case 'chrome':
      return 'Chrome'
    case 'Firefox':
    case 'Firefox Mobile iOS':
    case 'Firefox Focus':
    case 'Firefox Reality':
    case 'Firefox Rocket':
    case 'Firefox Mobile':
    case 'firefox':
      return 'Firefox'
    case 'Microsoft Edge':
    case 'edge':
      return 'Edge'
    default:
      return 'Chrome'
  }
}

export const defineTV = (osName: string): TVPlatforms => {
  switch (osName) {
    case 'Google TV':
      return 'AndroidTV'
    case 'Fire OS':
      return 'FireTV'
    default:
      return 'FireTV'
  }
}

export const defineMobilePlatform = (osName: string): MobilePlatforms => {
  switch (osName) {
    case 'iOS':
      return 'iOS'
    case 'Android':
      return 'Android'
    default:
      return 'iOS'
  }
}

export type AvailablePlatforms =
  | 'Windows'
  | 'Linux'
  | 'MacOS'
  | 'Chrome'
  | 'Firefox'
  | 'Edge'
  | 'iOS'
  | 'Android'
  | 'FireTV'
  | 'AndroidTV'
