import { StylePropertyValue } from 'theme-ui'

export default function Fade({
  height,
  display,
  backgroundImage,
  width,
}: {
  height: (string | number)[]
  display: string[]
  backgroundImage: StylePropertyValue<string>
  width?: (string | number)[]
}): JSX.Element {
  return (
    <div
      sx={{
        width: width ?? '100vw',
        height,
        left: '0',
        backgroundColor: 'transparent',
        backgroundImage,
        position: 'absolute',
        zIndex: -2,
        display,
      }}
    />
  )
}
