import React, { ReactElement } from 'react'
import { Flex, Text, get } from 'theme-ui'
import Svg from 'components/UI/SVG'
import ArticlesArrow from 'images/articles-arrow.svg'
import { HitsProvided } from 'react-instantsearch-core'
import InternalLink from 'components/UI/InternalLink'

interface Hit {
  title: string
  body: string
}

type HitsProps = HitsProvided<Hit>

export default function Hits({ hits }: HitsProps): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        maxWidth: '133rem',
        marginTop: '8rem',
        backgroundColor: 'bodyBG',
        borderRadius: '3.2rem',
        flexDirection: 'column',
        padding: ['2.4rem 1.6rem 4.8rem', '5.4rem 4.8rem 8rem', '5.4rem 10rem 8rem'],
        fontFamily: 'IBM Plex Sans',
      }}
    >
      <Text
        sx={{
          fontSize: ['2.8rem', '4.8rem', '4.8rem'],
          fontWeight: 'bold',
          color: 'white50',
          py: '2.4rem',
          textAlign: ['center', 'left', 'left'],
        }}
        data-testid="Hits_searchResult"
      >{`Search Results (${hits.length})`}</Text>
      {hits.map(hit => (
        <Flex
          data-testid={hit.page_path}
          key={hit.objectID}
          sx={{
            py: '2.4rem',
            borderBottom: '0.1rem solid',
            borderBottomColor: 'white15',
            transition: theme => `${get(theme, 'transitions.default')}`,
            '.articles-arrow': {
              transition: theme => `${get(theme, 'transitions.default')}`,
            },
            ':hover': {
              '.articles-arrow': {
                transform: 'translateX(1rem)',
                opacity: 1,
              },
              borderBottomColor: 'white',
            },
          }}
        >
          <InternalLink href={hit.page_path}>
            <Flex
              sx={{
                display: 'flex',
                textDecoration: 'none',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Flex sx={{ flexDirection: 'column' }}>
                <Text
                  sx={{
                    fontSize: ['1.8rem', '2.4rem', '2.4rem'],
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >
                  {hit.title}
                </Text>
              </Flex>
              <Svg
                svg={ArticlesArrow}
                className="articles-arrow"
                sx={{
                  opacity: 0.25,
                  marginLeft: ['1.6rem', '2.4rem', '2.4rem'],
                  minWidth: '2.4rem',
                  minHeight: '2.4rem',
                }}
              />
            </Flex>
          </InternalLink>
        </Flex>
      ))}
    </Flex>
  )
}
