import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function FlexibleDNS({
  data,
}: {
  data: Queries.FlexibleDNSPageQuery
}): ReactElement {
  const flexibleDNSData = getEnglishStrings(data.directus.flexible_dns?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={flexibleDNSData?.meta_description ?? ''}
      metaKeywords={flexibleDNSData?.meta_keywords ?? []}
      title={flexibleDNSData?.title ?? ''}
      subtitle={flexibleDNSData?.subtitle ?? ''}
      gridItems={[
        {
          title: flexibleDNSData?.auto_robert ?? '',
          description: flexibleDNSData?.auto_robert_subtitle ?? '',
        },
        {
          title: flexibleDNSData?.custom_mode ?? '',
          description: flexibleDNSData?.custom_mode_subtitle ?? '',
        },
        {
          title: flexibleDNSData?.secure_protocols ?? '',
          description: flexibleDNSData?.secure_protocols_subtitle ?? '',
        },
        {
          title: flexibleDNSData?.split_dns ?? '',
          description: flexibleDNSData?.split_dns_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {flexibleDNSData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [flexibleDNSData?.psst ?? '', flexibleDNSData?.forced_mode ?? ''],
        },
      ]}
    />
  )
}

export const data = graphql`
  query FlexibleDNSPage {
    directus {
      flexible_dns {
        strings {
          languages_code {
            code
          }
          auto_robert
          auto_robert_subtitle
          cta
          custom_mode
          custom_mode_subtitle
          forced_mode
          meta_description
          meta_keywords
          psst
          secure_protocols
          secure_protocols_subtitle
          split_dns
          split_dns_subtitle
          subtitle
          title
        }
      }
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
    }
  }
`
