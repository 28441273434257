import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function TCPSocketTermination({
  data,
}: {
  data: Queries.TCPSocketPageQuery
}): ReactElement {
  const tcpSocketData = getEnglishStrings(data?.directus?.tcp_socket_termination?.strings)
  const queryParam = useFeaturePageSlug()

  return (
    <FeaturePageSkeleton
      title={tcpSocketData?.title ?? ''}
      subtitle={tcpSocketData?.subtitle ?? ''}
      gridItems={[
        {
          title: tcpSocketData?.what_is_it ?? '',
          description: tcpSocketData?.what_is_it_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {tcpSocketData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: tcpSocketData?.why_should_i ?? '',
          items: [tcpSocketData?.some_software ?? ''],
        },
      ]}
      metaDescription={tcpSocketData?.meta_description ?? ''}
      metaKeywords={tcpSocketData?.meta_keywords ?? []}
    />
  )
}

export const data = graphql`
  query TCPSocketPage {
    directus {
      tcp_socket_termination {
        strings {
          languages_code {
            code
          }
          meta_description
          meta_keywords
          some_software
          subtitle
          title
          what_is_it
          what_is_it_subtitle
          why_should_i
          cta
        }
      }
    }
  }
`
