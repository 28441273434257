import ExternalLink from 'components/UI/ExternalLink'
import BasePlatform, { DownloadPlatforms } from 'components/download-page/BasePlatform'
import DownloadPlatformButton from 'components/download-page/DownloadPlatformButton'
import PlatformTitle from 'components/download-page/PlatformTitle'
import DownloadForIos from 'images/download-for-ios.png'
import GetOnGooglePlay from 'images/get-on-google-play.png'
import AndroidIcon from 'images/platforms/android.svg'
import IosIcon from 'images/platforms/apple.svg'
import { Dispatch, SetStateAction } from 'react'
import { Flex, Text } from 'theme-ui'
import { AvailablePlatforms } from 'utils/downloadPageUtils'
import getEnglishStrings from 'utils/getEnglishStrings'
import GetOnFDroid from 'images/get-it-on-fdroid.png'

const platforms: DownloadPlatforms = {
  Android: {
    icon: AndroidIcon,
    name: 'Android',
    learnMoreUrl: '/features/android',
    installGuideUrl: '/knowledge-base/articles/how-to-install-windscribe-on-android',
    sourceCodeUrl: 'https://github.com/Windscribe/Android-App',
  },
  iOS: {
    icon: IosIcon,
    name: 'iOS',
    learnMoreUrl: '/features/ios',
    installGuideUrl: '/knowledge-base/articles/getting-started-with-windscribe-on-ios',
    sourceCodeUrl: '',
  },
}

export default function MobilePlatform({
  platformName,
  setPlatform,
  translations,
  featuresList,
  image,
}: {
  platformName: AvailablePlatforms
  setPlatform: Dispatch<SetStateAction<AvailablePlatforms>>
  translations: Queries.DownloadPageQuery
  featuresList: string[]
  image: React.ReactNode
}): JSX.Element {
  const downloadPageStrings = getEnglishStrings(translations.directus.download_page?.strings)
  const generalTranslationStrings = getEnglishStrings(
    translations.directus.general_translations?.strings,
  )

  return (
    <BasePlatform
      platformName={platformName}
      setPlatform={setPlatform}
      featuresList={featuresList}
      image={image}
      title={
        platformName === 'iOS'
          ? downloadPageStrings?.windscribe_for_ios ?? ''
          : downloadPageStrings?.windscribe_for_android ?? ''
      }
      downloadSection={
        {
          iOS: (
            <Flex sx={{ flexDirection: 'column' }}>
              <ExternalLink href="/install/mobile/ios">
                <img
                  src={DownloadForIos}
                  alt=""
                  sx={{
                    width: ['19.7rem', '15.9rem', '16.2rem'],
                    height: ['6.2rem', '5.2rem', '5.4rem'],
                  }}
                />
              </ExternalLink>
            </Flex>
          ),
          Android: (
            <>
              <Flex
                sx={{
                  flexWrap: 'wrap',
                  a: { width: 'auto' },
                  justifyContent: ['center', 'flex-start', 'flex-start'],
                }}
              >
                <ExternalLink href="/install/mobile/android">
                  <img src={GetOnGooglePlay} alt="" sx={{ width: '24.8rem', height: '100%' }} />
                </ExternalLink>
                <ExternalLink href="https://f-droid.org/en/packages/com.windscribe.vpn/">
                  <img src={GetOnFDroid} alt="" sx={{ width: '24.8rem', height: '100%' }} />
                </ExternalLink>
              </Flex>
              <Flex sx={{ flexDirection: 'column' }}>
                <Flex
                  sx={{
                    position: 'relative',
                    justifyContent: 'center',
                    minHeight: '2rem',
                    my: '2.6rem',
                  }}
                >
                  <Text
                    sx={{
                      backgroundColor: 'background',
                      fontWeight: 'bold',
                      fontSize: '1.8rem',
                      textTransform: 'uppercase',
                      minWidth: '3.9rem',
                      textAlign: 'center',
                    }}
                  >
                    {generalTranslationStrings?.or}
                  </Text>
                  <div
                    sx={{
                      position: 'absolute',
                      width: '100%',
                      height: '0.2rem',
                      backgroundColor: 'white15',
                      bottom: '50%',
                      zIndex: '-1',
                    }}
                  />
                </Flex>
                <DownloadPlatformButton
                  platformIcon={platforms[platformName].icon}
                  downloadText={generalTranslationStrings?.download ?? ''}
                  downloadUrl="/install/mobile/android-direct-phone"
                  platformType="desktop"
                />
              </Flex>
            </>
          ),
        }[platformName]
      }
      header={<PlatformTitle title={generalTranslationStrings?.mobile ?? ''} />}
      translations={translations}
      platforms={platforms}
    />
  )
}
