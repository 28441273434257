import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import NoidentifyingLogs from 'images/no-identifying-logs.png'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function NoIdentifyingLogsPage({
  data,
}: {
  data: Queries.NoIdentifyingLogsPageQuery
}): ReactElement {
  const noIdentifyingLogsData = getEnglishStrings(data.directus.no_identifying_logs?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={noIdentifyingLogsData?.meta_description ?? ''}
      metaKeywords={noIdentifyingLogsData?.meta_keywords ?? []}
      title={noIdentifyingLogsData?.title ?? ''}
      subtitle={noIdentifyingLogsData?.subtitle ?? ''}
      featureImage={NoidentifyingLogs}
      summaryText={noIdentifyingLogsData?.summary_text ?? ''}
      gridItems={[
        {
          title: noIdentifyingLogsData?.no_personal_data ?? '',
          description: noIdentifyingLogsData?.no_personal_data_subtitle ?? '',
        },
        {
          title: noIdentifyingLogsData?.no_monitoring ?? '',
          description: noIdentifyingLogsData?.no_monitoring_subtitle ?? '',
        },
        {
          title: noIdentifyingLogsData?.transparency_report ?? '',
          description: noIdentifyingLogsData?.transparency_report_subtitle ?? '',
        },
        {
          title: noIdentifyingLogsData?.privacy_policy ?? '',
          description: noIdentifyingLogsData?.privacy_policy_subtitle ?? '',
        },
        {
          title: noIdentifyingLogsData?.mature_jurisdiction ?? '',
          description: noIdentifyingLogsData?.mature_jurisdiction_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {noIdentifyingLogsData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [noIdentifyingLogsData?.being_based ?? ''],
        },
      ]}
    />
  )
}

export const data = graphql`
  query NoIdentifyingLogsPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      no_identifying_logs {
        strings {
          subtitle
          title
          languages_code {
            code
          }
          summary_text
          cta
          being_based
          no_personal_data
          no_personal_data_subtitle
          no_monitoring
          no_monitoring_subtitle
          transparency_report
          transparency_report_subtitle
          privacy_policy
          privacy_policy_subtitle
          mature_jurisdiction
          mature_jurisdiction_subtitle
          meta_description
          meta_keywords
        }
      }
    }
  }
`
