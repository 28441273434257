import slugify from './slugify'

import { useLocation } from '@reach/router'

export function useFeaturePageSlug(): string {
  const location = useLocation()

  const { pathname } = location

  const feature = pathname.split('/features/')[1]

  const slug = feature ? `feat-${slugify(feature)}` : 'features'

  return `cpid=${slug}&pcpid=${slug}`
}
