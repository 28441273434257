import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const WS_API = process.env.GATSBY_WINDSCRIBE_API_BASE_URL ?? ''

export interface Pop {
  status: number
  name: string
  usage: number
  link_speed: string
}

export interface Location {
  name: string
  country_code: string
  p2p: number
  premium_only: number
  pops_enabled: number
  pops: Pop[]
}

export interface Locations {
  [key: string]: Location
}

interface Totals {
  connections: number
  usage: string
  users: string
}

export interface WebsiteStatusResponse {
  data: {
    totals: Totals
    locations: Locations
  }
}

export interface TransparencyResponse {
  data: {
    stats_dmca: Record<string, string>[]
    stats_data: Record<string, string>[]
    success: boolean
  }
}

// eslint-disable-next-line
export enum SupportCategory {
  NOT_SELECTED = 0,
  ACCOUNT_ISSUE = 1,
  TECHNICAL_PROBLEM = 2,
  SALES_QUESTION = 3,
  FEEDBACK = 4,
}

export interface SubmitSupportTicketRequestBody {
  support_name?: string
  support_email: string
  support_subject: string
  support_message: string
  support_category: SupportCategory
  issue_metadata?: Record<string, unknown>
  session_auth_hash?: string
}

export interface SubmitSupportTicketResponse {
  data: {
    ticket_id: number
  }
  errorMessage: string
  errorDescription: string
}

export const wsApi = createApi({
  reducerPath: 'wsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: WS_API,
  }),
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    transparencyReport: builder.query<TransparencyResponse, string>({
      keepUnusedDataFor: 0,
      query: () => ({
        url: `/TransparencyStats`,
        method: 'GET',
        headers: {
          Authorization: 'Bearer 1234',
        },
      }),
    }),
    sendSupportTicket: builder.mutation<
      SubmitSupportTicketResponse,
      SubmitSupportTicketRequestBody
    >({
      query: body => ({
        url: '/SupportTicket',
        method: 'POST',
        body,
        headers: {
          Authorization: 'Bearer 1234',
        },
      }),
    }),
    statusReport: builder.query<WebsiteStatusResponse, string>({
      keepUnusedDataFor: 0,
      query: () => ({
        url: '/WebsiteStatus',
        method: 'GET',
        headers: {
          Authorization: 'Bearer 1234',
        },
      }),
    }),
  }),
})
