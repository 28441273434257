import Svg from 'components/UI/SVG'
import { Flex, Grid, Text } from 'theme-ui'
import { ReactElement } from 'react'
import ReactMarkdown from 'react-markdown'

export default function FeatureItem({
  description,
  icon,
  title,
}: {
  icon: React.ElementType
  title: string
  description: string
}): ReactElement {
  return (
    <Grid
      sx={{ gap: ['1.6rem', '2.4rem'], columns: [1, 2], gridTemplateColumns: 'repeat(12,1fr)' }}
    >
      <Svg
        svg={icon}
        sx={{ minWidth: ['4.8rem', '6rem'], minHeight: ['4.8rem', '6rem'], gridColumn: 'span 3' }}
      />
      <Flex sx={{ flexDirection: 'column', gap: '0.8rem', gridColumn: 'span 9' }}>
        <Text sx={{ color: 'white', fontWeight: 'bold' }}>{title}</Text>
        <Text
          sx={{
            color: 'white50',
            lineHeight: '2.7rem',
            p: { m: 0 },
          }}
        >
          <ReactMarkdown>{description}</ReactMarkdown>
        </Text>
      </Flex>
    </Grid>
  )
}
