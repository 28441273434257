import Features from 'components/features-page/Features'
import FeatureSelector from 'components/features-page/FeatureSelector'
import StaticPageLayout from 'components/StaticPageLayout'
import Tag from 'components/Tag'
import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function FeaturesPage({
  data,
}: {
  data: Queries.KnowledgeBaseFeaturesPageQuery
}): ReactElement {
  const featuresPageStrings = getEnglishStrings(data.directus.features_page?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <StaticPageLayout
      title={`${featuresPageStrings?.head_title} | Windscribe`}
      ctaQueryParams={queryParam}
      metaDescription={featuresPageStrings?.meta_description}
      metaKeywords={featuresPageStrings?.meta_keywords}
    >
      <Flex sx={{ flexDirection: 'column', alignItems: 'center', width: '100%', gap: '4.8rem' }}>
        <TitleAndSubtitle
          title={featuresPageStrings?.title}
          subtitle={featuresPageStrings?.subtitle}
        />
        <Features featuresData={data.directus.features_page} />
        <Tag title={featuresPageStrings?.more_features} backgroundColour="white10" />

        <FeatureSelector data={data} />
      </Flex>
    </StaticPageLayout>
  )
}

export const pageQuery = graphql`
  query KnowledgeBaseFeaturesPage {
    directus {
      features_page {
        strings {
          browser_extension_features
          mobile_features
          config_generators_subtitle
          config_generators_title
          desktop_app_features
          feature_list_title
          identifying_logs_subtitle
          identifying_logs_title
          languages_code {
            code
          }
          large_network_subtitle
          large_network_title
          port_forwarding_subtitle
          port_forwarding_title
          robert_subtitle
          robert_title
          scribeforce_subtitle
          scribeforce_title
          simple_clients_subtitle
          simple_clients_title
          split_tunneling_subtitle
          split_tunneling_title
          static_ips_subtitle
          static_ips_title
          subtitle
          strongest_encryption_title
          strongest_encryption_subtitle
          title
          use_for_free_subtitle
          use_for_free_title
          head_title
          mobile_features
          meta_keywords
          meta_description

          more_features
          desktop_download
          flexible_dns
          flexible_dns_subtitle
          mac_spoofing
          mac_spoofing_subtitle
          tcp_socket_termination
          tcp_socket_termination_subtitle
          auto_pilot
          auto_pilot_subtitle
          proxy_gateway
          proxy_gateway_subtitle
          proxy_settings
          proxy_settings_subtitle
          secure_hotspot
          secure_hotspot_subtitle
          double_hop
          double_hop_subtitle
          firewall
          firewall_subtitle
          update_channels
          update_channels_subtitle

          blocker_features
          blocker_features_subtitle
          cookie_monster
          cookie_monster_subtitle
          ad_privacy
          ad_privacy_subtitle
          time_warp
          time_warp_subtitle
          location_warp
          location_warp_subtitle
          split_personality
          split_personality_subtitle
          webrtc_slayer
          webrtc_slayer_subtitle
          do_not_disturb
          do_not_disturb_subtitle
          language_warp
          language_warp_subtitle
          auto_pilot
          auto_pilot_subtitle
          worker_block
          worker_block_subtitle

          gps_spoofing
          gps_spoofing_subtitle
          decoy_traffic
          decoy_traffic_subtitle

          circumvent_censorship
          circumvent_censorship_subtitle
        }
      }
      general_translations {
        strings {
          languages_code {
            code
          }
          extension
          desktop
          mobile
        }
      }
    }
  }
`
