import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function UpdateChannels({
  data,
}: {
  data: Queries.UpdateChannelsPageQuery
}): ReactElement {
  const updateChannelsData = getEnglishStrings(data?.directus?.update_channels?.strings)
  const queryParam = useFeaturePageSlug()

  return (
    <FeaturePageSkeleton
      title={updateChannelsData?.title ?? ''}
      subtitle={updateChannelsData?.subtitle ?? ''}
      gridItems={[
        {
          title: updateChannelsData?.what_is_it ?? '',
          description: updateChannelsData?.what_is_it_subtitle ?? '',
        },
        {
          title: updateChannelsData?.release ?? '',
          description: updateChannelsData?.release_subtitle ?? '',
        },
        {
          title: updateChannelsData?.beta ?? '',
          description: updateChannelsData?.beta_subtitle ?? '',
        },
        {
          title: updateChannelsData?.guinea_pig ?? '',
          description: updateChannelsData?.guinea_pig_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {updateChannelsData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: updateChannelsData?.why_should_i ?? '',
          items: [updateChannelsData?.if_you_report ?? ''],
        },
      ]}
      metaDescription={updateChannelsData?.meta_description ?? ''}
      metaKeywords={updateChannelsData?.meta_keywords ?? []}
    />
  )
}

export const data = graphql`
  query UpdateChannelsPage {
    directus {
      update_channels {
        strings {
          languages_code {
            code
          }
          cta
          if_you_report
          meta_description
          meta_keywords
          subtitle
          title
          what_is_it
          what_is_it_subtitle
          why_should_i
          release
          release_subtitle
          beta
          beta_subtitle
          guinea_pig
          guinea_pig_subtitle
        }
      }
    }
  }
`
