import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function SecureHotspot({
  data,
}: {
  data: Queries.SecureHotspotPageQuery
}): ReactElement {
  const secureHotspotData = getEnglishStrings(data.directus.secure_hotspot?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={secureHotspotData?.meta_description ?? ''}
      metaKeywords={secureHotspotData?.meta_keywords ?? []}
      title={secureHotspotData?.title ?? ''}
      subtitle={secureHotspotData?.subtitle ?? ''}
      summaryText={secureHotspotData?.summary_text ?? ''}
      gridItems={[
        {
          title: secureHotspotData?.create_a_secure ?? '',
          description: secureHotspotData?.create_a_secure_subtitle ?? '',
        },
        {
          title: secureHotspotData?.helpful_on_restrictive ?? '',
          description: secureHotspotData?.helpful_on_restrictive_subtitle ?? '',
        },
        {
          title: secureHotspotData?.windows_only ?? '',
          description: secureHotspotData?.windows_only_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {secureHotspotData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: secureHotspotData?.how_to_use_it ?? '',
          items: [
            secureHotspotData?.go_into_windscribe ?? '',
            secureHotspotData?.choose_the_network ?? '',
            secureHotspotData?.find_the_wifi ?? '',
            secureHotspotData?.all_the_activity ?? '',
          ],
        },
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [secureHotspotData?.secure_hotspot_is ?? ''],
        },
      ]}
    />
  )
}

export const data = graphql`
  query SecureHotspotPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      secure_hotspot {
        strings {
          all_the_activity
          choose_the_network
          create_a_secure
          create_a_secure_subtitle
          cta
          find_the_wifi
          go_into_windscribe
          helpful_on_restrictive
          helpful_on_restrictive_subtitle
          how_to_use_it
          languages_code {
            code
          }
          meta_description
          meta_keywords
          secure_hotspot_is
          subtitle
          summary_text
          title
          windows_only
          windows_only_subtitle
        }
      }
    }
  }
`
