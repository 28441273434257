import React, { Dispatch, ReactElement, ReactNode, SetStateAction } from 'react'
import { Button, Flex, get } from 'theme-ui'

export interface TagData {
  id: string
  label: ReactNode
  isDisabled?: boolean
}

export default function Tags({
  tags,
  selectedTagId,
  setSelectedTagId,
  shouldAllowNoSelection,
}: {
  tags: TagData[]
  selectedTagId?: string | null
  setSelectedTagId: Dispatch<SetStateAction<string | undefined>>
  shouldAllowNoSelection: boolean
}): ReactElement {
  return (
    <Flex
      sx={{
        flexWrap: 'wrap',
        gap: '2.4rem',
        mb: '4rem',
        px: ['1.2rem', '2.4rem', 0],
        justifyContent: shouldAllowNoSelection ? 'flex-start' : 'center',
      }}
      data-testid="Tags_container"
    >
      {tags.map(tag => (
        <Tag
          key={tag.id}
          isSelected={tag.id === selectedTagId}
          onClick={() =>
            setSelectedTagId(selected => {
              if (shouldAllowNoSelection) {
                return tag.id === selected ? undefined : tag.id
              } else {
                return tag.id
              }
            })
          }
          isDisabled={tag.isDisabled}
          id={tag.id}
        >
          {tag.label}
        </Tag>
      ))}
    </Flex>
  )
}

const Tag = ({
  children,
  isSelected,
  onClick,
  isDisabled,
  id,
}: {
  children?: ReactNode
  isSelected: boolean
  onClick: () => void
  isDisabled?: boolean
  id: string
}) => {
  return (
    <Button
      data-testid={`Tag_${id}`}
      aria-pressed={isSelected}
      sx={{
        cursor: 'pointer',
        lineHeight: 0,
        paddingY: '1.6rem',
        paddingX: '1.6rem',
        color: isSelected ? 'midnight' : 'white50',
        border: '0.2rem solid transparent',
        transition: theme => `${get(theme, 'transitions.default')}`,
        backgroundColor: isSelected ? 'white' : 'white10',
        '& svg > path,g': {
          fill: isSelected ? 'midnight' : 'white50',
          transition: theme => `${get(theme, 'transitions.default')}`,
        },
        borderRadius: 'rounded',
        ':disabled': {
          cursor: 'auto',
          border: ({ colors }) => `0.2rem solid ${colors?.white15}`,
          backgroundColor: 'transparent',
          borderColor: 'white10',
          color: 'white25',
          '& svg > path,g': { fill: 'white25' },
          ':hover': {
            backgroundColor: 'transparent',
          },
          transition: '0s',
        },
        ':hover': {
          backgroundColor: isSelected ? 'white' : 'white25',
          '& svg > path,g': { fill: isSelected ? 'midnight' : 'white' },
        },
      }}
      disabled={isDisabled}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
