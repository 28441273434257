import ExternalLink from 'components/UI/ExternalLink'
import InternalLink from 'components/UI/InternalLink'
import Svg from 'components/UI/SVG'
import CheckIcon from 'images/checkbox.svg'
import { Dispatch, SetStateAction } from 'react'
import ReactMarkdown from 'react-markdown'
import { Flex, Text, get } from 'theme-ui'
import useBreakpointIndex from 'theme/useBreakpointIndex'
import { AvailablePlatforms } from 'utils/downloadPageUtils'
import getEnglishStrings from 'utils/getEnglishStrings'

export interface DownloadPlatforms {
  [key: string]: {
    icon: React.ElementType
    name: string
    learnMoreUrl: string
    installGuideUrl: string
    sourceCodeUrl: string
    downloadOptions?: {
      name: string
      downloadUrl: string
    }[]
  }
}

export default function BasePlatform({
  platformName,
  setPlatform,
  featuresList,
  image,
  title,
  downloadSection,
  header,
  translations,
  platforms,
  dataTestId,
}: {
  platformName: AvailablePlatforms
  setPlatform: Dispatch<SetStateAction<AvailablePlatforms>>
  featuresList: string[]
  image: React.ReactNode
  title: string
  downloadSection: React.ReactNode
  header: React.ReactNode
  translations: Queries.DownloadPageQuery
  platforms: DownloadPlatforms
  dataTestId?: string
}): JSX.Element {
  const downloadPageStrings = getEnglishStrings(translations.directus.download_page?.strings)
  const breakpointIndex = useBreakpointIndex()
  const isMobile = breakpointIndex === 0
  return (
    <Flex
      sx={{ mt: ['4.8rem', '8rem', '8rem'], flexDirection: 'column', '& > *': { flex: '1 0 0px' } }}
    >
      <Flex sx={{ justifyContent: 'center', alignItems: 'center', mb: ['3.2rem', 0, 0] }}>
        {header}
      </Flex>
      <Flex
        sx={{
          mt: '4.8rem',
          flexDirection: ['column', 'row', 'row'],
          justifyContent: 'center',
          display: ['none', 'flex', 'flex'],
        }}
      >
        {!isMobile && (
          <PlatformSelector
            platforms={platforms}
            platformName={platformName}
            setPlatform={setPlatform}
          />
        )}
      </Flex>
      <Flex
        sx={{
          alignItems: ['center', 'center'],
          justifyContent: ['center', 'space-between', 'space-between'],
          flexDirection: ['column-reverse', 'row', 'row'],
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: ['center', 'flex-start', 'flex-start'],
          }}
        >
          {isMobile && (
            <Flex sx={{ my: ['3.2rem', 0, 0] }}>
              <PlatformSelector
                platforms={platforms}
                platformName={platformName}
                setPlatform={setPlatform}
              />
            </Flex>
          )}
          <Text
            sx={{
              fontWeight: 'bold',
              fontSize: ['2.4rem', '3.2rem', '3.2rem'],
              mb: ['3.2rem', '4.8rem', '4.8rem'],
              mt: [0, '1.6rem', '1.6rem'],
            }}
            data-testid={dataTestId}
          >
            {title}
          </Text>

          <Flex
            sx={{
              position: 'relative',
              flexDirection: 'column',
              alignItems: ['center', 'flex-start', 'flex-start'],
              justifyContent: 'center',
            }}
          >
            {downloadSection}
          </Flex>
          <Flex
            sx={{
              flexDirection: 'column',
              mt: ['3.2rem', '4.8rem', '4rem'],
              p: { my: 0 },
              gap: '1.8rem',
            }}
          >
            <Flex sx={{ alignItems: 'flex-start', gap: '1.6rem', flexDirection: 'column' }}>
              {featuresList.map(feature => (
                <Flex key={feature} sx={{ gap: '1.6rem' }}>
                  <Svg svg={CheckIcon} sx={{ width: '2.4rem', height: '2.4rem' }} />
                  <Text sx={{ maxWidth: ['54rem', '30.6rem', '37rem'] }}>
                    <ReactMarkdown>{feature}</ReactMarkdown>
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Flex>
          <Flex
            sx={{
              a: { textDecoration: 'underline' },
              gap: '1.6rem',
              mt: '2.4rem',
              width: '100%',
              justifyContent: ['center', 'flex-start', 'flex-start'],
              flexWrap: 'wrap',
            }}
          >
            {platforms[platformName].learnMoreUrl && (
              <>
                <InternalLink
                  href={platforms[platformName].learnMoreUrl}
                  sx={{ width: 'max-content' }}
                >
                  {downloadPageStrings?.learn_more}
                </InternalLink>
                <Text sx={{ color: 'white10' }}>•</Text>
              </>
            )}
            {platforms[platformName].installGuideUrl && (
              <>
                <InternalLink
                  href={platforms[platformName].installGuideUrl}
                  sx={{ width: 'max-content' }}
                >
                  {downloadPageStrings?.install_guide}
                </InternalLink>
                <Text sx={{ color: 'white10' }}>•</Text>
              </>
            )}
            {platforms[platformName].sourceCodeUrl && (
              <ExternalLink
                href={platforms[platformName].sourceCodeUrl}
                sx={{ width: 'max-content' }}
              >
                {downloadPageStrings?.source_code}
              </ExternalLink>
            )}
          </Flex>
        </Flex>
        {image}
      </Flex>
    </Flex>
  )
}

export function PlatformSelector({
  platformName,
  platforms,
  setPlatform,
}: {
  platforms: DownloadPlatforms
  platformName: AvailablePlatforms
  setPlatform: Dispatch<SetStateAction<AvailablePlatforms>>
}): JSX.Element {
  return (
    <Flex sx={{ flexDirection: 'column', mb: '3.2rem' }}>
      <Flex
        sx={{
          gap: '4rem',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {Object.values(platforms).map(platform => (
          <Flex
            key={platform.name}
            sx={{
              position: 'relative',
              alignItems: 'flex-start',
              justifyContent: 'center',
              'span, svg path': {
                transition: theme => `${get(theme, 'transitions.default')}`,
              },
              ':hover': {
                cursor: 'pointer',
                '& span': {
                  color: 'white',
                },
                '& svg path': {
                  fill: 'white',
                },
              },
              gap: '1.6rem',
              minHeight: '4.8rem',
            }}
            onClick={() => {
              setPlatform(platform.name as AvailablePlatforms)
            }}
            data-testid={`${platform.name}-platform-option`}
          >
            <Flex sx={{ gap: '1.6rem', alignItems: 'center' }}>
              <Svg
                svg={platform.icon}
                sx={{
                  width: '3.2rem',
                  height: '3.2rem',
                  '& path': {
                    fill: platforms[platformName].name === platform.name ? 'white' : 'white50',
                  },
                }}
              />
              <Text
                sx={{
                  color: platforms[platformName].name === platform.name ? 'white' : 'white50',
                  fontWeight: 'bold',
                  display: ['none', 'block', 'block'],
                }}
              >
                {platform.name}
              </Text>
            </Flex>
            {platforms[platformName].name === platform.name && (
              <div
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '0.2rem',
                  backgroundColor: 'white',
                  bottom: '-0.2rem',
                  right: 0,
                }}
              />
            )}
          </Flex>
        ))}
      </Flex>
      <Flex>
        <div
          sx={{
            width: ['100%', '72rem', 'maxContentWidth'],
            height: '0.2rem',
            background: ({ colors }) =>
              `linear-gradient(to left, ${colors?.transparent}, ${colors?.white10} 50%, ${colors?.transparent})`,
          }}
        />
      </Flex>
    </Flex>
  )
}
