import ExternalLink from 'components/UI/ExternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import { Text } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function DoubleHop({ data }: { data: Queries.DoubleHopPageQuery }): ReactElement {
  const doubleHopData = getEnglishStrings(data.directus.double_hop?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={doubleHopData?.meta_description || ''}
      metaKeywords={doubleHopData?.meta_keywords || []}
      title={doubleHopData?.title || ''}
      subtitle={doubleHopData?.subtitle || ''}
      gridItems={[
        {
          title: doubleHopData?.enhanced_privacy || '',
          description: doubleHopData?.enhanced_privacy_subtitle || '',
        },
        {
          title: doubleHopData?.improve_connectivity || '',
          description: doubleHopData?.improve_connectivity_subtitle || '',
        },
      ]}
      cta={
        <ExternalLink variant="buttons.neon" href={`/download?${queryParam}`}>
          <Text>{doubleHopData?.cta}</Text>
        </ExternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way || '',
          items: [doubleHopData?.find_out_why || '', doubleHopData?.when_connected || ''],
        },
      ]}
    />
  )
}

export const data = graphql`
  query DoubleHopPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      double_hop {
        strings {
          cta
          enhanced_privacy
          enhanced_privacy_subtitle
          find_out_why
          improve_connectivity
          improve_connectivity_subtitle
          languages_code {
            code
          }
          meta_description
          meta_keywords
          subtitle
          summary_text
          title
          when_connected
        }
      }
    }
  }
`
