import { TagData } from 'components/knowledge-base/Tags'
import Svg from 'components/UI/SVG'
import AllPlatformsIcon from 'images/platforms/all-platforms.svg'
import AmazonIcon from 'images/platforms/amazon.svg'
import AndroidIcon from 'images/platforms/android.svg'
import MacOSIcon from 'images/platforms/apple.svg'
import ChromeIcon from 'images/platforms/chrome.svg'
import FirefoxIcon from 'images/platforms/firefox.svg'
import IOSIcon from 'images/platforms/ios.svg'
import LinuxIcon from 'images/platforms/linux.svg'
import RouterIcon from 'images/platforms/router.svg'
import WindowsIcon from 'images/platforms/windows.svg'
import React from 'react'

export const allPlatformsId = '__all__'
const iconsForPlatformTagIds = {
  [allPlatformsId]: AllPlatformsIcon,
  amazon: AmazonIcon,
  android: AndroidIcon,
  macos: MacOSIcon,
  chrome: ChromeIcon,
  firefox: FirefoxIcon,
  ios: IOSIcon,
  router: RouterIcon,
  windows: WindowsIcon,
  linux: LinuxIcon,
} as const

export type PlatformTagId = keyof typeof iconsForPlatformTagIds

export const orderedPlatforms: PlatformTagId[] = [
  allPlatformsId,
  'windows',
  'macos',
  'linux',
  'ios',
  'android',
  'amazon',
  'router',
  'chrome',
  'firefox',
]

export const getPlatformCategoryTags = (
  articlesInCategory: Queries.DirectusData_knowledge_base_articles[],
): TagData[] => {
  if (!articlesInCategory) {
    return []
  }

  return orderedPlatforms.map(platformTagId => {
    return {
      id: platformTagId,
      label: <Svg svg={iconsForPlatformTagIds[platformTagId]} />,
      isDisabled:
        platformTagId !== allPlatformsId &&
        !articlesInCategory.find(article =>
          article?.tags?.find(tag => tag?.knowledge_base_tags_id?.slug === platformTagId),
        ),
    }
  })
}
