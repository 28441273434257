import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function LanguageWarp({
  data,
}: {
  data: Queries.LanguageWarpPageQuery
}): ReactElement {
  const languageWarpData = getEnglishStrings(data?.directus?.language_warp?.strings)
  const queryParam = useFeaturePageSlug()

  return (
    <FeaturePageSkeleton
      title={languageWarpData?.title ?? ''}
      subtitle={languageWarpData?.subtitle ?? ''}
      gridItems={[
        {
          title: languageWarpData?.language_match ?? '',
          description: languageWarpData?.language_match_subtitle ?? '',
        },
        {
          title: languageWarpData?.deep_cover ?? '',
          description: languageWarpData?.deep_cover_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {languageWarpData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[]}
      metaDescription={languageWarpData?.meta_description ?? ''}
      metaKeywords={languageWarpData?.meta_keywords ?? []}
    />
  )
}

export const data = graphql`
  query LanguageWarpPage {
    directus {
      language_warp {
        strings {
          languages_code {
            code
          }
          subtitle
          title
          cta
          deep_cover
          deep_cover_subtitle
          language_match
          language_match_subtitle
          meta_description
          meta_keywords
        }
      }
    }
  }
`
