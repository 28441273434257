import React, { Dispatch, ReactElement, SetStateAction } from 'react'
import { Flex } from 'theme-ui'
import GarryHandshake from 'images/garry-handshake.svg'
import Svg from 'components/UI/SVG'
import { Breadcrumb } from 'components/knowledge-base/breadcrumbs/Breadcrumbs'
import { categoriesYOffset } from 'components/knowledge-base/Categories'
import SearchInterface from 'components/knowledge-base/search/InstantSearch'

export default function GarrySearchBar({
  hasInputValue,
  setHasInputValue,
  breadcrumbs,
}: {
  hasInputValue: boolean
  setHasInputValue: Dispatch<SetStateAction<boolean>>
  breadcrumbs: Breadcrumb[]
}): ReactElement {
  return (
    <Flex
      data-testid="search-bar"
      sx={{
        marginBottom: `${categoriesYOffset}rem`,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Svg
        descriptiveText="Image of Garry"
        svg={GarryHandshake}
        sx={{ width: ['29.2rem', '42.3rem', '42.3rem'], height: ['17.9rem', '22.8rem', '22.8rem'] }}
      />
      <SearchInterface
        data-testid="search-interface"
        hasInputValue={hasInputValue}
        setHasInputValue={setHasInputValue}
        breadcrumbs={breadcrumbs}
      />
    </Flex>
  )
}
