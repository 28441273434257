import { useLocation } from '@reach/router'
import StaticPageLayout from 'components/StaticPageLayout'
import Svg from 'components/UI/SVG'
import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'
import DesktopDownloadButtons from 'components/features-page/DesktopDownloadButtons'
import DesktopFeatureIcon from 'components/features-page/DesktopFeatureIcon'
import FeatureBox from 'components/features-page/FeatureBox'
import FeatureImage from 'components/features-page/FeatureImage'
import FeatureText from 'components/features-page/FeatureText'
import ImageEdgesFeatures from 'components/features-page/ImageEdgesFeature'
import Background from 'components/features-page/background/Background'
import Fade from 'components/features-page/background/Fade'
import RoundedBorder from 'components/features-page/background/RoundedBorder'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import commandLineIcon from 'images/command-line.svg'
import connectivityIcon from 'images/connectivity.svg'
import windscribeAppMobile from 'images/desktop-app-mobile.png'
import firewallIcon from 'images/firewall.svg'
import gaugeIcon from 'images/gauge-icon.svg'
import ghostIcon from 'images/ghost-icon.svg'
import globeIcon from 'images/globe-icon.svg'
import hotspotIcon from 'images/hotspot-icon.svg'
import memeIcon from 'images/meme-icon.svg'
import networkShield from 'images/network-shield.svg'
import protocolsIcon from 'images/protocols.svg'
import proxyIcon from 'images/proxy-icon.svg'
import refreshIcon from 'images/refresh-icon.svg'
import splitArrowIcon from 'images/split-arrow.svg'
import splitTunnelingIcon from 'images/split-tunneling-icon.svg'
import spoofingIcon from 'images/spoofing.svg'
import targetIcon from 'images/target-icon.svg'
import toolsIcon from 'images/tools-icon.svg'
import { Flex, Grid, Text, ThemeUIStyleObject } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'

type TitleAndSubtitle = {
  title: string
  subtitle: string
}

export const gridBaseStyle: ThemeUIStyleObject = {
  gap: '2.4rem',
  gridAutoRows: '1fr',
  px: ['2.4rem', '2.4rem', 0],
  maxWidth: theme => ['100%', '100%', `calc(${theme.sizes?.['maxContentWidth']} + 0.8rem)`],
}

export const imageBaseStyle: ThemeUIStyleObject = {
  width: ['unset', '50rem', '50rem'],
  height: '100%',
}

export const otherFeaturesBaseStyle: ThemeUIStyleObject = {
  gap: ['3.2rem', '8rem 2.4rem'],
  mt: ['3.2rem', '4.8rem'],
  maxWidth: ['100%', '100%', 'maxContentWidth'],
  px: ['2.4rem', '2.4rem', 0],
}

export default function DesktopFeaturesPage(): JSX.Element {
  const data = useStaticQuery<Queries.DesktopFeaturesPageQuery>(graphql`
    query DesktopFeaturesPage {
      directus {
        general_translations {
          strings {
            ikev2
            wireguard
            openvpn
            features
            watch_a_video
            languages_code {
              code
            }
            tcp
            udp
          }
        }
        desktop_page {
          strings {
            languages_code {
              code
            }
            automatic_updating
            automatic_updating_subtitle
            connect_on_any_network
            custom_config
            custom_config_first_subtitle
            custom_config_second_subtitle
            custom_config_support
            custom_config_title
            custom_dns_support
            custom_dns_support_subtitle
            different_protocols
            download_for_macos
            download_for_windows
            features
            firewall
            firewall_first_subtitle
            firewall_second_subtitle
            firewall_title
            ikev2_subtitle
            mac_address_first_subtitle
            mac_address_second_subtitle
            mac_address_spoofing
            mac_address_title
            meme_friendly
            meme_friendly_subtitle
            meta_description
            meta_keywords
            network_and_per_app
            network_level
            network_level_subtitle
            network_rules
            network_rules_first_subtitle
            network_rules_second_subtitle
            network_rules_title
            openvpn_tcp_subtitle
            openvpn_udp_subtitle
            other_features
            packet_size
            packet_size_subtitle
            pre_app_tunneling
            pre_app_tunneling_subtitle
            proxy_gateway
            proxy_gateway_subtitle
            proxy_gateway_title
            secure_hotspot
            secure_hotspot_subtitle
            split_tunneling
            split_tunneling_title
            split_tunneling_subtitle
            stealth
            subtitle
            stealth_subtitle
            tcp_socket_subtitle
            tcp_socket_termination
            title
            tldr_subtitle
            unparalleled_connectivity
            windscribe_offers
            wireguard_subtitle
            wstunnel
            wstunnel_subtitle
            windows_title
            windows_subtitle
            linux_title
            linux_subtitle
            macos_title
            macos_subtitle
            download_for_linux
          }
        }
        linux_app_page {
          strings {
            languages_code {
              code
            }
            meta_description
            meta_keywords
            title
          }
        }
        windows_app_page {
          strings {
            languages_code {
              code
            }
            meta_description
            meta_keywords
            title
          }
          id
        }
        macos_app_page {
          strings {
            languages_code {
              code
            }
            id
            meta_description
            meta_keywords
            title
          }
        }
      }
    }
  `)

  const desktopFeaturesData = getEnglishStrings(data.directus?.desktop_page?.strings)
  const generalTranslations = getEnglishStrings(data.directus?.general_translations?.strings)
  const location = useLocation()

  const osType = (() => {
    switch (location.pathname) {
      case '/features/windows/':
        return 'windows'
      case '/features/macos/':
        return 'macos'
      case '/features/linux/':
        return 'linux'
      default:
        return 'windows'
    }
  })()

  const metaData = {
    windows: getEnglishStrings(data?.directus?.windows_app_page?.strings),
    macos: getEnglishStrings(data?.directus?.macos_app_page?.strings),
    linux: getEnglishStrings(data?.directus?.linux_app_page?.strings),
  }[osType]

  const titleAndSubtitleRecord: Record<string, TitleAndSubtitle> = {
    '/features/windows/': {
      title: desktopFeaturesData?.windows_title ?? '',
      subtitle: desktopFeaturesData?.windows_subtitle ?? '',
    },
    '/features/macos/': {
      title: desktopFeaturesData?.macos_title ?? '',
      subtitle: desktopFeaturesData?.macos_subtitle ?? '',
    },
    '/features/linux/': {
      title: desktopFeaturesData?.linux_title ?? '',
      subtitle: desktopFeaturesData?.linux_subtitle ?? '',
    },
  }

  return (
    <StaticPageLayout
      title={`${metaData?.title} | Windscribe`}
      metaDescription={metaData?.meta_description ?? ''}
      metaKeywords={metaData?.meta_keywords ?? ''}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: ['hidden', 'hidden', 'visible'],
          width: '100vw',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            my: ['3.2rem', '4.8rem'],
          }}
        >
          <TitleAndSubtitle
            title={titleAndSubtitleRecord[location.pathname]?.title}
            subtitle={titleAndSubtitleRecord[location.pathname]?.subtitle}
            dataTestId={`${osType}-features-title`}
          />
        </Flex>
        <Flex sx={{ mb: ['4rem', '4.8rem'] }}>
          <DesktopDownloadButtons
            windowsText={desktopFeaturesData?.download_for_windows ?? ''}
            macOSText={desktopFeaturesData?.download_for_macos ?? ''}
            linuxText={desktopFeaturesData?.download_for_linux ?? ''}
            osType={osType}
          />
        </Flex>
        <StaticImage
          src="../../images/desktop-app-desktop.png"
          alt=""
          sx={{
            display: ['none', 'block', 'block'],
            maxWidth: ['100%', '76.8rem', 'maxContentWidth'],
          }}
        />
        <div
          sx={{
            width: '100%',
            overflow: 'hidden',
            display: ['block', 'none', 'none'],
          }}
        >
          <img
            src={windscribeAppMobile}
            alt=""
            sx={{ width: '100%', height: 'auto', overflow: 'hidden' }}
          />
        </div>
        <Flex
          sx={{
            gap: ['2.8rem', '3.2rem', '2.4rem'],
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: ['column', 'row', 'row'],
            '& > *': {
              flex: '1 0 0px',
            },
            px: ['2.4rem', '7.2rem', 0],
          }}
        >
          <DesktopFeatureIcon
            icon={protocolsIcon}
            text={desktopFeaturesData?.different_protocols ?? ''}
          />
          <DesktopFeatureIcon
            icon={splitArrowIcon}
            text={desktopFeaturesData?.network_and_per_app ?? ''}
          />
          <DesktopFeatureIcon
            icon={spoofingIcon}
            text={desktopFeaturesData?.mac_address_spoofing ?? ''}
          />
          <DesktopFeatureIcon
            icon={commandLineIcon}
            text={desktopFeaturesData?.custom_config ?? ''}
          />
        </Flex>
        <Flex
          sx={{
            borderRadius: '2.1rem',
            backgroundColor: 'white10',
            alignItems: 'center',
            justifyContent: 'center',
            width: '14.1rem',
            height: '4rem',
            textTransform: 'uppercase',
            my: ['3.2rem', '4.8rem', '4.8rem'],
          }}
        >
          <Text
            sx={{
              fontSize: '1.8rem',
              fontWeight: 'bold',
              color: 'white50',
            }}
          >
            {generalTranslations?.features}
          </Text>
        </Flex>
        <Background display={['none', 'flex', 'flex']}>
          <Fade
            height={[0, '450rem', '287.6rem']}
            display={['none', 'flex', 'flex']}
            backgroundImage={({ colors }): string =>
              `linear-gradient(to bottom, ${colors?.background} 0%, ${colors?.midnight} 20%, ${colors?.midnight})`
            }
          />
          <RoundedBorder minHeight={[0, '75.8rem', '46rem']} roundedEdge="bottom" />
        </Background>
        <FeatureImage
          title={desktopFeaturesData?.connect_on_any_network ?? ''}
          category={desktopFeaturesData?.unparalleled_connectivity ?? ''}
          firstSubtitle={desktopFeaturesData?.windscribe_offers ?? ''}
          icon={connectivityIcon}
          categoryColour="windscribeBlue"
          imageSide="left"
        >
          <StaticImage src={`../../images/firewall-mode.png`} alt="" sx={imageBaseStyle} />
        </FeatureImage>
        <Grid
          columns={[1, 2, 2]}
          sx={{
            ...gridBaseStyle,
            mt: ['4.8rem', '4.8rem', 0],
            mb: ['4.8rem', '8rem', '10rem'],
          }}
        >
          <FeatureBox
            title={generalTranslations?.wireguard ?? ''}
            subtitle={desktopFeaturesData?.wireguard_subtitle ?? ''}
            colour="windscribeBlue"
          />
          <FeatureBox
            title={generalTranslations?.ikev2 ?? ''}
            subtitle={desktopFeaturesData?.ikev2_subtitle ?? ''}
            colour="windscribeBlue"
          />
          <FeatureBox
            title={`${generalTranslations?.openvpn} (${generalTranslations?.tcp})`}
            subtitle={desktopFeaturesData?.openvpn_tcp_subtitle ?? ''}
            colour="windscribeBlue"
          />
          <FeatureBox
            title={`${generalTranslations?.openvpn} (${generalTranslations?.udp})`}
            subtitle={desktopFeaturesData?.openvpn_udp_subtitle ?? ''}
            colour="windscribeBlue"
          />
          <FeatureBox
            title={desktopFeaturesData?.stealth ?? ''}
            subtitle={desktopFeaturesData?.stealth_subtitle ?? ''}
            colour="windscribeBlue"
          />
          <FeatureBox
            title={desktopFeaturesData?.wstunnel ?? ''}
            subtitle={desktopFeaturesData?.wstunnel_subtitle ?? ''}
            colour="windscribeBlue"
          />
        </Grid>
        <FeatureImage
          title={desktopFeaturesData?.firewall_title ?? ''}
          category={desktopFeaturesData?.firewall ?? ''}
          firstSubtitle={desktopFeaturesData?.firewall_first_subtitle ?? ''}
          secondSubtitle={desktopFeaturesData?.firewall_second_subtitle ?? ''}
          icon={firewallIcon}
          categoryColour="neonGreen"
          videoButton={{
            href: 'https://www.youtube.com/watch?v=fUlAmYH8c4k',
            text: generalTranslations?.watch_a_video ?? '',
          }}
          imageSide="right"
        >
          <StaticImage src="../../images/connection-desktop.png" alt="" sx={imageBaseStyle} />
        </FeatureImage>
        <Flex
          sx={{
            maxWidth: theme => `calc(${theme.sizes?.['maxContentWidth']} + 0.8rem)`,
            mt: '8rem',
          }}
        >
          <Flex sx={{ display: ['flex', 'flex', 'none'], width: '100%' }}>
            <ImageEdgesFeatures
              title={desktopFeaturesData?.split_tunneling_title ?? ''}
              category={desktopFeaturesData?.split_tunneling ?? ''}
              firstSubtitle={desktopFeaturesData?.split_tunneling_subtitle ?? ''}
              icon={splitTunnelingIcon}
              categoryColour={'gold'}
              imageLeft={
                <StaticImage
                  src="../../images/vertical-split-tunneling-top.png"
                  alt=""
                  sx={{
                    ...imageBaseStyle,
                    maxWidth: 'calc(100% - 3.2rem)',
                  }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              }
              imageRight={
                <StaticImage
                  src="../../images/vertical-split-tunneling-bottom.png"
                  alt=""
                  sx={{
                    ...imageBaseStyle,
                    minHeight: '24.4rem',
                    maxWidth: 'calc(100% - 3.2rem)',
                  }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              }
              {...(osType === 'windows' && {
                videoButton: {
                  href: 'https://www.youtube.com/watch?v=WvP7wwNNyRo',
                  text: generalTranslations?.watch_a_video ?? '',
                },
              })}
            />
          </Flex>
          <Flex sx={{ display: ['none', 'none', 'flex'] }}>
            <ImageEdgesFeatures
              title={desktopFeaturesData?.split_tunneling_title ?? ''}
              category={desktopFeaturesData?.split_tunneling ?? ''}
              firstSubtitle={desktopFeaturesData?.split_tunneling_subtitle ?? ''}
              icon={splitTunnelingIcon}
              categoryColour={'gold'}
              imageLeft={
                <StaticImage
                  src="../../images/horizontal-split-tunneling-left.png"
                  alt=""
                  sx={{ ...imageBaseStyle, mr: '4.8rem', maxHeight: '47rem' }}
                />
              }
              imageRight={
                <StaticImage
                  src="../../images/horizontal-split-tunneling-right.png"
                  alt=""
                  sx={{ ...imageBaseStyle, ml: '4.8rem', maxHeight: '47rem' }}
                />
              }
              {...(osType !== 'linux' && {
                videoButton: {
                  href: 'https://www.youtube.com/watch?v=WvP7wwNNyRo',
                  text: generalTranslations?.watch_a_video ?? '',
                },
              })}
            />
          </Flex>
        </Flex>
        <Grid
          columns={[1, 2, 2]}
          sx={{
            ...gridBaseStyle,
            mt: ['4.8rem', '4.8rem', '4.8rem'],
            mb: ['4.8rem', '4.8rem', '8rem'],
          }}
        >
          <FeatureBox
            title={desktopFeaturesData?.pre_app_tunneling ?? ''}
            subtitle={desktopFeaturesData?.pre_app_tunneling_subtitle ?? ''}
            colour="gold"
          />
          <FeatureBox
            title={desktopFeaturesData?.network_level ?? ''}
            subtitle={desktopFeaturesData?.network_level_subtitle ?? ''}
            colour="gold"
          />
        </Grid>
        <Background display={['none', 'block', 'block']}>
          <RoundedBorder minHeight={[0, '81.3rem', '47.8rem']} roundedEdge="top" />
          <Fade
            height={['100rem', '120rem', '100rem']}
            display={['flex', 'flex', 'flex']}
            backgroundImage={({ colors }): string =>
              `linear-gradient(to bottom, ${colors?.midnight}0%, ${colors?.background} 30%, ${colors?.background} 88%, ${colors?.background} 100%)`
            }
          />
        </Background>
        <StaticImage
          src="../../images/mac-spoofing-desktop.png"
          alt=""
          sx={{
            maxWidth: ['0rem', '72rem', 'maxContentWidth'],
            height: '100%',
            display: ['none', 'none', 'block'],
          }}
        />
        <StaticImage
          src="../../images/mac-spoofing-tablet.png"
          alt=""
          sx={{
            maxWidth: ['0rem', '100%', 'maxContentWidth'],
            height: '100%',
            display: ['none', 'block', 'none'],
          }}
        />
        <StaticImage
          src="../../images/mac-spoofing-mobile.png"
          alt=""
          sx={{
            maxWidth: ['unset', '72rem', 'maxContentWidth'],
            height: '100%',
            display: ['block', 'none', 'none'],
            mb: '1.6rem',
          }}
        />
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: ['center', 'center', 'flex-start'],
            mb: ['5.2rem', '8rem', '8rem'],
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: ['center', 'center', 'flex-start'],
              textAlign: 'center',
            }}
          >
            <Flex
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                gap: '2.4rem',
                flexDirection: ['column', 'row', 'row'],
              }}
            >
              <Svg svg={ghostIcon} />
              <Text
                color="periwinkle"
                sx={{
                  fontSize: '2.8rem',
                  fontWeight: 'bold',
                }}
              >
                {desktopFeaturesData?.mac_address_spoofing}
              </Text>
            </Flex>
            <Text
              sx={{
                fontWeight: 'bold',
                fontSize: '2.8rem',
                mt: '2.4rem',
                maxWidth: ['60%', '60%', '100%'],
              }}
            >
              {desktopFeaturesData?.mac_address_title}
            </Text>
          </Flex>
          <Flex
            sx={{
              mt: '2.4rem',
              flexDirection: ['column', 'column', 'row'],
              gap: ['2.4rem', '2.4rem', '2rem'],
              textAlign: ['center', 'center', 'left'],
              alignItems: ['center', 'center', 'flex-start'],
              maxWidth: ['75%', '65%', 'maxContentWidth'],
              '& > *': {
                flex: '1 0 0px',
              },
            }}
          >
            <Text color="white50">{desktopFeaturesData?.mac_address_first_subtitle}</Text>
            <Text color="white50">{desktopFeaturesData?.mac_address_second_subtitle}</Text>
          </Flex>
        </Flex>
        <FeatureImage
          category={desktopFeaturesData?.network_rules ?? ''}
          title={desktopFeaturesData?.network_rules_title ?? ''}
          firstSubtitle={desktopFeaturesData?.network_rules_first_subtitle ?? ''}
          secondSubtitle={desktopFeaturesData?.network_rules_second_subtitle ?? ''}
          icon={networkShield}
          categoryColour={'neonGreen'}
          imageSide={'left'}
        >
          <StaticImage src="../../images/network-whitelist.png" alt="" sx={imageBaseStyle} />
        </FeatureImage>
        <Background display={['none', 'flex', 'flex']}>
          <RoundedBorder minHeight={[0, '108rem', '68.3rem']} roundedEdge="bottom" />
          <Fade
            height={[0, '200rem', '130rem']}
            display={['flex', 'flex', 'flex']}
            backgroundImage={({ colors }): string =>
              `linear-gradient(to bottom, ${colors?.background}0%, ${colors?.midnight} 20%, ${colors?.midnight} 88%, ${colors?.background} 100%)`
            }
          />
        </Background>
        <Flex sx={{ mt: ['5.2rem', '8rem', '8rem'] }}>
          <FeatureImage
            title={desktopFeaturesData?.custom_config_title ?? ''}
            category={desktopFeaturesData?.custom_config ?? ''}
            firstSubtitle={desktopFeaturesData?.custom_config_first_subtitle ?? ''}
            secondSubtitle={desktopFeaturesData?.custom_config_second_subtitle ?? ''}
            icon={toolsIcon}
            categoryColour={'windscribeBlue'}
            imageSide="right"
          >
            <>
              <StaticImage
                src="../../images/custom-config-support-desktop.png"
                alt=""
                sx={{
                  ...imageBaseStyle,
                  display: ['none', 'none', 'block'],
                }}
              />
              <StaticImage
                src="../../images/custom-config-support-tablet.png"
                alt=""
                sx={{
                  ...imageBaseStyle,
                  display: ['block', 'block', 'none'],
                }}
              />
            </>
          </FeatureImage>
        </Flex>
        <Background display={['none', 'flex', 'none']}>
          <div sx={{ mt: '-21rem' }}>
            <RoundedBorder minHeight={[0, '30rem', 0]} roundedEdge="bottom" />
          </div>
        </Background>
        <Flex sx={{ mt: ['4.8rem', '16rem', '8rem'] }}>
          <FeatureImage
            title={desktopFeaturesData?.proxy_gateway_title ?? ''}
            category={desktopFeaturesData?.proxy_gateway ?? ''}
            firstSubtitle={desktopFeaturesData?.proxy_gateway_subtitle ?? ''}
            icon={proxyIcon}
            categoryColour={'gold'}
            imageSide="left"
          >
            <StaticImage src="../../images/share-app.png" alt="" sx={imageBaseStyle} />
          </FeatureImage>
        </Flex>
        <Text
          sx={{
            color: 'white',
            fontSize: '4.8rem',
            fontWeight: 'bold',
            mt: ['4.8rem', '8rem', '12.8rem'],
          }}
        >
          {desktopFeaturesData?.other_features}
        </Text>
        <Grid columns={[1, 2, 2]} sx={otherFeaturesBaseStyle}>
          <FeatureText
            icon={targetIcon}
            text={desktopFeaturesData?.tcp_socket_termination ?? ''}
            subtitle={desktopFeaturesData?.tcp_socket_subtitle}
          />
          <FeatureText
            icon={gaugeIcon}
            text={desktopFeaturesData?.packet_size ?? ''}
            subtitle={desktopFeaturesData?.packet_size_subtitle}
          />
          <FeatureText
            icon={hotspotIcon}
            text={desktopFeaturesData?.secure_hotspot ?? ''}
            subtitle={desktopFeaturesData?.secure_hotspot_subtitle}
          />
          <FeatureText
            icon={refreshIcon}
            text={desktopFeaturesData?.automatic_updating ?? ''}
            subtitle={desktopFeaturesData?.automatic_updating_subtitle}
          />
          <FeatureText
            icon={globeIcon}
            text={desktopFeaturesData?.custom_dns_support ?? ''}
            subtitle={desktopFeaturesData?.custom_dns_support_subtitle}
          />
          <FeatureText
            icon={memeIcon}
            text={desktopFeaturesData?.meme_friendly ?? ''}
            subtitle={desktopFeaturesData?.meme_friendly_subtitle}
          />
        </Grid>
        <Flex
          sx={{
            px: '1.6rem',
            maxWidth: ['75%', '65%', '90%'],
            mt: ['3.2rem', '8rem', '8rem'],
          }}
        >
          <Text
            sx={{
              fontSize: '1.4rem',
              color: 'white50',
              textAlign: 'center',
            }}
          >
            {desktopFeaturesData?.tldr_subtitle}
          </Text>
        </Flex>
        <Flex sx={{ mb: ['6.4rem', '8rem', '8rem'], mt: ['3.2rem', '4.8rem', '8rem'] }}>
          <DesktopDownloadButtons
            windowsText={desktopFeaturesData?.download_for_windows ?? ''}
            macOSText={desktopFeaturesData?.download_for_macos ?? ''}
            linuxText={desktopFeaturesData?.download_for_linux ?? ''}
            osType={osType}
          />
        </Flex>
      </Flex>
    </StaticPageLayout>
  )
}
