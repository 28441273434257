import { Dispatch, SetStateAction } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { allPlatformsId } from 'utils/getPlatformCategoryTags'

export default function usePlatformTagId(): {
  selectedPlatformTagId?: string | null
  setSelectedPlatformTagId: Dispatch<SetStateAction<string | undefined>>
} {
  const [selectedPlatformTagId = allPlatformsId, setSelectedPlatformTagId] = useQueryParam(
    'platform',
    StringParam,
  )
  return { selectedPlatformTagId, setSelectedPlatformTagId }
}
