import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function DecoyTraffic({
  data,
}: {
  data: Queries.DecoyTrafficPageQuery
}): ReactElement {
  const decoyTrafficData = getEnglishStrings(data?.directus?.decoy_traffic?.strings)
  const generalTranslationData = getEnglishStrings(data?.directus?.general_translations?.strings)
  const queryParam = useFeaturePageSlug()

  return (
    <FeaturePageSkeleton
      title={decoyTrafficData?.title ?? ''}
      subtitle={decoyTrafficData?.subtitle ?? ''}
      summaryText={decoyTrafficData?.summary_text ?? ''}
      gridItems={[
        {
          title: decoyTrafficData?.traffic_based ?? '',
          description: decoyTrafficData?.traffic_based_subtitle ?? '',
        },
        {
          title: decoyTrafficData?.disguise ?? '',
          description: decoyTrafficData?.disguise_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {decoyTrafficData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationData?.by_the_way ?? '',
          items: [
            decoyTrafficData?.check_out_our ?? '',
            decoyTrafficData?.enabling_this_feature ?? '',
          ],
        },
      ]}
      metaDescription={decoyTrafficData?.meta_description ?? ''}
      metaKeywords={decoyTrafficData?.meta_keywords ?? []}
    />
  )
}

export const data = graphql`
  query DecoyTrafficPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      decoy_traffic {
        strings {
          languages_code {
            code
          }
          check_out_our
          cta
          disguise
          disguise_subtitle
          enabling_this_feature
          meta_description
          meta_keywords
          subtitle
          summary_text
          title
          traffic_based
          traffic_based_subtitle
        }
      }
    }
  }
`
