export const getCookieByName = (cookieName: string): string | undefined => {
  return document.cookie
    .split(';')
    .map(kvPairStr => kvPairStr.trim())
    .find(kvPairStr => kvPairStr.startsWith(`${cookieName}=`))
    ?.split('=')[1]
}

/**
 * @param cookieName Name of the cookie
 * @param cookieValue Value that the cookie will receive
 * @param expiresIn Expiration in minutes.
 * @param path [path=/] (Optional) Path of the cookie.
 */
export const setCookie = ({
  cookieName,
  cookieValue,
  expiresIn,
  path = '/',
}: {
  cookieName: string
  cookieValue: string
  expiresIn: number
  path?: string
}): void => {
  const expireDate = new Date(Date.now() + expiresIn * 1000).toUTCString()

  document.cookie = `${cookieName}=${cookieValue};expires=${expireDate};path=${path}`
}
