import { alpha } from '@theme-ui/color'
import Tag from 'components/Tag'
import { ReactElement } from 'react'
import ReactMarkdown from 'react-markdown'
import { Flex, Text } from 'theme-ui'

export default function FeatureSummary({
  text,
  summaryTranslation,
}: {
  text: string
  summaryTranslation: string
}): ReactElement {
  return (
    <Flex sx={{ justifyContent: 'center' }}>
      <Flex
        sx={{
          padding: ['2.4rem 1.6rem', '2.4rem 3.2rem'],
          border: `1px solid ${alpha('black', 0.5)}`,
          backgroundColor: alpha('black', 0.25),
          borderRadius: '1.6rem',
          gap: ['2.4rem', '3.2rem'],
          alignItems: 'center',
          maxWidth: ['100%', '65.8', '72.6rem'],
          justifyContent: 'space-between',
          flexDirection: ['column', 'row', 'row'],
        }}
      >
        <Tag title={summaryTranslation} backgroundColour="white10" textColour="white50" />
        <Text
          variant="text.body2"
          sx={{
            display: 'flex',
            color: 'white50',
            textAlign: ['center', 'left', 'left'],
            p: { m: 0 },
          }}
        >
          <ReactMarkdown>{text}</ReactMarkdown>
        </Text>
      </Flex>
    </Flex>
  )
}
