import Svg from 'components/UI/SVG'
import { Flex, Text } from 'theme-ui'

export default function FeatureText({
  icon,
  text,
  subtitle,
}: {
  icon: React.ElementType
  text: string
  subtitle?: string
}): JSX.Element {
  return (
    <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
      <Svg svg={icon} />
      <Text
        sx={{
          mt: '2.4rem',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '2.4rem',
        }}
      >
        {text}
      </Text>
      <Text
        color="white50"
        sx={{
          textAlign: 'center',
          mt: '2.4rem',
        }}
      >
        {subtitle}
      </Text>
    </Flex>
  )
}
