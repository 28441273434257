import ExternalLink from 'components/UI/ExternalLink'
import Svg from 'components/UI/SVG'
import DesktopIcon from 'images/desktop-download-icon.svg'
import ExtensionIcon from 'images/mobile-download-icon.svg'
import { Flex, get } from 'theme-ui'

export default function DownloadPlatformButton({
  platformIcon,
  downloadText,
  downloadUrl,
  platformType = 'desktop',
  style = 'dimmed',
}: {
  platformIcon: React.ElementType
  downloadText: string
  downloadUrl: string
  platformType: 'desktop' | 'extension'
  style?: 'bright' | 'dimmed'
}): JSX.Element {
  const styles = {
    bright: {
      variant: 'buttons.neonGreen',
      '& svg path': {
        transition: theme => `${get(theme, 'transitions.default')}`,
        fill: 'black',
      },
      ':hover': {
        backgroundColor: 'neonGreen15',
        color: 'neonGreen',
        '& svg path': {
          fill: 'neonGreen',
        },
      },
    },
    dimmed: {
      variant: 'buttons.neon',
      '& svg path': {
        transition: theme => `${get(theme, 'transitions.default')}`,
        fill: 'neonGreen',
      },
      ':hover': {
        backgroundColor: 'neonGreen',
        color: 'black',
        '& svg path': {
          fill: 'black',
        },
      },
    },
  }[style]

  return (
    <Flex sx={{ width: '100%' }}>
      <ExternalLink
        sxx={{
          transition: theme => `${get(theme, 'transitions.default')}`,
          minWidth: ['28.8rem', '28.5rem', '28.5rem'],
          textDecoration: 'none',
          span: {
            transition: theme => `${get(theme, 'transitions.default')}`,
          },
          ...styles,
        }}
        href={downloadUrl}
      >
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            gap: '0.8rem',
            position: 'relative',
            width: '100%',
          }}
        >
          <Svg
            svg={platformIcon}
            sx={{
              '& path': {
                fill: 'black',
              },
              width: '2.4rem',
              height: '2.4rem',
            }}
          />
          {downloadText}
          <Svg
            svg={platformType === 'desktop' ? DesktopIcon : ExtensionIcon}
            sx={{
              position: 'absolute',
              right: '-2rem',
              '& path': {
                fill: 'black',
              },
            }}
          />
        </Flex>
      </ExternalLink>
    </Flex>
  )
}
