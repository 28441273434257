import Tippy from '@tippyjs/react'
import Svg from 'components/UI/SVG'
import ExclamationMark from 'images/exclamation-mark.svg'
import GreenCheck from 'images/green-check.svg'
import NoP2P from 'images/no-p2p.svg'
import PlusIcon from 'images/plus-icon.svg'
import ProStar from 'images/pro-star.svg'
import { ReactElement, useState } from 'react'
import { Pop, Location } from 'redux/api/wsApi'
import { Flex, get } from 'theme-ui'
import useCountries from 'utils/useCountries'
import tenGigs from 'images/ten-gigs.svg'

interface Translation {
  usage: string
  pops_are: string
  disabled: string
  pro_only: string
  pops: string
}

export default function LocationStatus({
  location,
  translations,
}: {
  location: Location
  translations: Translation
}): ReactElement {
  const [isLocationStatusOpen, setIsLocationStatusOpen] = useState(false)
  const { flags, countries } = useCountries()

  const country = countries.find(x => x.code === location.country_code)
  const currentFlag = flags.find(x => x.slug === country?.name?.toLowerCase()?.replaceAll('_', '-'))
  const hasAnyPopWithError = location.pops.some(pop => pop.status === 2) //2 is the error status

  const height = ['13.6rem', '8.8rem']
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        borderRadius: isLocationStatusOpen ? '2.4rem 2.4rem 0 0' : '2.4rem',
        backgroundColor: 'white05',
        transformOrigin: 'top',
        overflow: 'hidden',
      }}
    >
      <Flex
        sx={{
          alignItems: ['flex-start', 'center', 'center'],
          justifyContent: ['center', 'space-between'],
          px: '2.4rem',
          flexDirection: ['column', 'row', 'row'],
          gap: ['1.6rem', '0'],
          flex: ['1', 'auto'],
          height,
        }}
      >
        <Flex sx={{ gap: '1.6rem', alignItems: 'center' }}>
          <Svg svg={currentFlag?.body} sx={{ width: '3.2rem', height: '3.2rem' }} />
          <span sx={{ variant: 'text.body1', color: 'white50' }}>{location.name}</span>
        </Flex>
        <Flex
          sx={{
            alignItems: 'center',
            gap: '1.6rem',
          }}
        >
          {location.p2p === 0 ? <Svg svg={NoP2P} /> : <></>}
          {location.premium_only ? (
            <Tippy
              delay={250}
              duration={0}
              content={
                <Flex sx={{ position: 'relative' }}>
                  <Flex
                    sx={{
                      maxWidth: '26.5rem',
                      p: '2.4rem 2rem',
                      backgroundColor: 'black',
                      borderRadius: '3.2rem',
                    }}
                  >
                    <span sx={{ textAlign: 'center', color: 'white50' }}>
                      {translations?.pro_only}
                    </span>
                  </Flex>
                  <div
                    style={{
                      width: 0,
                      height: 0,
                      borderLeft: '1rem solid transparent',
                      borderRight: '1rem solid transparent',
                      borderTop: '1.5rem solid black',
                      position: 'absolute',
                      bottom: '-10px',
                      left: '44%',
                    }}
                  />
                </Flex>
              }
            >
              <span>
                <Svg svg={ProStar} />
              </span>
            </Tippy>
          ) : (
            <></>
          )}
          <Tippy
            delay={250}
            duration={0}
            content={
              <Flex sx={{ position: 'relative' }}>
                <Flex
                  sx={{
                    maxWidth: '26.5rem',
                    p: '2.4rem 2rem',
                    backgroundColor: 'black',
                    borderRadius: '3.2rem',
                  }}
                >
                  <span sx={{ textAlign: 'center', color: 'white50' }}>
                    {translations?.pops_are}
                  </span>
                </Flex>
                <div
                  style={{
                    width: 0,
                    height: 0,
                    borderLeft: '1rem solid transparent',
                    borderRight: '1rem solid transparent',
                    borderTop: '1.5rem solid black',
                    position: 'absolute',
                    bottom: '-10px',
                    left: '48%',
                  }}
                />
              </Flex>
            }
          >
            <Flex
              sx={{
                gap: '1.6rem',
                alignItems: 'center',
                border: '2px solid',
                p: '0.8rem 1.6rem',
                borderRadius: '3.2rem',
                borderColor: hasAnyPopWithError ? 'orange10' : 'neonGreen10',
                ':hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <span
                sx={{ variant: 'text.body2', color: hasAnyPopWithError ? 'orange' : 'neonGreen' }}
              >
                {location.pops_enabled} / {location.pops.length} {translations?.pops}
              </span>
              {hasAnyPopWithError ? <Svg svg={ExclamationMark} /> : <Svg svg={GreenCheck} />}
            </Flex>
          </Tippy>
          <Svg
            svg={PlusIcon}
            sx={{
              ':hover': { cursor: 'pointer' },
              transform: isLocationStatusOpen ? 'rotate(45deg)' : '',
              transition: theme => `${get(theme, 'transitions.default')}`,
              userSelect: 'none',
            }}
            onClick={() => setIsLocationStatusOpen(!isLocationStatusOpen)}
          />
        </Flex>
      </Flex>
      <Flex
        sx={{
          flexDirection: 'column',
          display: isLocationStatusOpen ? 'flex' : 'none',
        }}
      >
        <Flex
          sx={{
            width: '100%',
            borderTop: ({ colors }) => `1px solid ${colors?.white10}`,
          }}
        />
        {location.pops.map(pop => (
          <POP key={pop.name} pop={pop} translations={translations} />
        ))}
      </Flex>
    </Flex>
  )
}

function POP({ pop, translations }: { pop: Pop; translations: Translation }): ReactElement {
  const isOver70Percent = Number(pop.usage.toFixed(0)) > 70
  const isOver90Percent = Number(pop.usage.toFixed(0)) > 90
  const isTenGbps = Number(pop.link_speed) === 10_000
  const height = ['11.8rem', '7.5rem']

  return (
    <Flex sx={{ flexDirection: 'column', height, position: 'relative' }}>
      <Flex
        sx={{
          justifyContent: 'space-between',
          flexDirection: ['column', 'row'],
          alignItems: ['flex-start', 'center'],
          m: '2.4rem',
          gap: '1.6rem',
          flex: 1,
        }}
      >
        <span sx={{ width: 'fit-content', color: 'white50' }}>{pop.name}</span>

        {pop.status === 1 && (
          <Flex sx={{ alignItems: 'center', gap: '1.6rem' }}>
            {isTenGbps && <Svg svg={tenGigs} />}
            <span
              sx={{
                color: isOver90Percent ? 'salmon' : isOver70Percent ? 'orange' : 'neonGreen',
                fontWeight: 'bold',
              }}
            >
              {pop.usage.toFixed(0)}% {translations?.usage}
            </span>
          </Flex>
        )}
        {pop.status === 2 && (
          <span sx={{ textTransform: 'uppercase' }}>{translations?.disabled}</span>
        )}
      </Flex>
      <Flex sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
        {pop.status === 2 ? (
          <>
            <Flex
              sx={{
                borderBottom: ({ colors }) => `2px solid ${colors?.neonGreen10}`,
                width: `100%`,
              }}
            />
          </>
        ) : (
          <>
            <Flex
              sx={{
                borderBottom: ({ colors }) =>
                  `2px solid ${
                    // checks if the usage is over 90% and sets the color to salmon
                    // checks if the usage is over 70% and sets the color to orange
                    // if the usage is under 70% it sets the color to neonGreen
                    isOver90Percent
                      ? colors?.salmon
                      : isOver70Percent
                      ? colors?.orange
                      : colors?.neonGreen
                  }`,
                width: `${pop.usage}%`,
              }}
            />
            <Flex
              sx={{
                width: `calc(100% - ${pop.usage}%)`,
                borderTop: ({ colors }) =>
                  `2px solid ${
                    isOver90Percent
                      ? colors?.salmon10
                      : isOver70Percent
                      ? colors?.orange10
                      : colors?.neonGreen10
                  }`,
              }}
            />
          </>
        )}
      </Flex>
    </Flex>
  )
}
