import { useState, useEffect } from 'react'
import { useThemeUI } from '@theme-ui/core'
import { defaultBreakpoints } from '@theme-ui/css'

type DefaultOptions = {
  defaultIndex?: number
}

/**
 * NOTE: This is copied from theme-ui's match-media package, but updated to allow for
 * breakpoints to be defined as explicit media queries (for example
 * '@media screen and (orientation: portrait)').
 *
 * In theme-ui's implementation of `useBreakpointIndex`, only breakpoints that are defined
 * as specific quantities like '48em' will work.
 * https://github.com/system-ui/theme-ui/blob/develop/packages/match-media/src/index.ts
 */
export default (options: DefaultOptions = {}): number => {
  const context = useThemeUI()
  const { defaultIndex = -1 } = options
  const breakpoints = (context.theme && context.theme.breakpoints) || defaultBreakpoints

  if (typeof defaultIndex !== 'number') {
    throw new TypeError(
      `Default breakpoint index should be a number. Got: ${defaultIndex}, ${typeof defaultIndex}`,
    )
  } else if (defaultIndex < -1 || defaultIndex > breakpoints.length - 1) {
    throw new RangeError(
      `Default breakpoint index out of range. Theme has ${breakpoints.length} breakpoints, got index ${defaultIndex}`,
    )
  }

  const [value, setValue] = useState(defaultIndex)
  useEffect(() => {
    const getIndex = () => {
      const matchedBreakpoints = breakpoints.filter(bp => {
        const mediaPrefix = '@media '
        if (bp.startsWith(mediaPrefix)) {
          return window.matchMedia(bp.substring(mediaPrefix.length)).matches
        } else {
          return window.matchMedia(`screen and (min-width: ${bp})`).matches
        }
      })

      const matchedIndices = [
        0,
        ...matchedBreakpoints.map(bp => breakpoints.findIndex(bp2 => bp2 === bp) + 1),
      ]
      const maxIndex = Math.max(...matchedIndices)
      return maxIndex
    }

    const onResize = () => {
      const newValue = getIndex()
      if (value !== newValue) {
        setValue(newValue)
      }
    }

    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [breakpoints, value])

  return value
}
