import StaticPageLayout from 'components/StaticPageLayout'
import ExternalLink from 'components/UI/ExternalLink'
import Svg from 'components/UI/SVG'
import BenefitsTable from 'components/download-page/BenefitsTable'
import DesktopPlatform from 'components/download-page/DesktopPlatform'
import ExtensionPlatform from 'components/download-page/ExtensionPlatform'
import MobilePlatform from 'components/download-page/MobilePlatform'
import PlatformTitle from 'components/download-page/PlatformTitle'
import TVPlatform from 'components/download-page/TVPlatform'
import WireGuardDisclaimer from 'components/features-page/WireGuardDisclaimer'
import Background from 'components/features-page/background/Background'
import RoundedBorder from 'components/features-page/background/RoundedBorder'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import BackgroundPattern from 'images/bg-pttrn.png'
import DownloadDropdown from 'images/download-dropdown.svg'
import DownloadWindscribe from 'images/download-windscribe-image.png'
import ExtensionLink from 'images/mobile-download-icon.svg'
import PlusDownloadIcon from 'images/plus-download-icon.png'
import { Fragment, ReactElement, useEffect, useRef, useState } from 'react'
import { Flex, Text, ThemeUIStyleObject, get } from 'theme-ui'
import {
  DesktopPlaforms,
  ExtensionPlatforms,
  MobilePlatforms,
  TVPlatforms,
  defineBrowser,
  defineMobilePlatform,
  defineOS,
  defineTV,
} from 'utils/downloadPageUtils'
import getEnglishStrings from 'utils/getEnglishStrings'
import useCurrentPlatform from '../utils/useCurrentPlatform'

interface DownloadObjectType {
  name: string
  downloadUrl: string
}

export interface DownloadObject {
  downloadTitle: string
  recommendedText: string
  icon: React.ElementType
  name: string
  platforms: string
  downloadUrl: string
  downloadObjects: DownloadObjectType[]
  learnMoreUrl: string
  installGuideUrl: string
  sourceCodeUrl: string
}

const externalLinkStyle: ThemeUIStyleObject = {
  minWidth: ['80%', '22rem', '30rem'],
  height: '4.8rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: theme => `${get(theme, 'transitions.default')}`,
  'svg path': {
    transition: theme => `${get(theme, 'transitions.default')}`,
  },
  ':hover': {
    color: 'white',
    borderColor: 'white',
    '& svg path': {
      fill: 'white',
    },
  },
}

const platformStyle: ThemeUIStyleObject = {
  alignItems: 'center',
  span: {
    transition: theme => `${get(theme, 'transitions.default')}`,
  },
  '& svg path': {
    opacity: 0.5,
    transition: theme => `${get(theme, 'transitions.default')}`,
  },
  ':hover': {
    cursor: 'pointer',
    span: {
      color: 'white',
    },
    '& svg path': {
      opacity: 1,
    },
  },
}

const baseImageStyle: ThemeUIStyleObject = {
  objectFit: 'scale-down',
  // minHeight: '33.7rem',
  maxWidth: ['31.2rem', '35rem', '45rem'],
}

const horizontalImageStyle: ThemeUIStyleObject = {
  width: ['28.8rem', '30.2rem', '40rem'],
}

export default function Download({ data }: { data: Queries.DownloadPageQuery }): ReactElement {
  const { browser, osName, platform } = useCurrentPlatform()
  const downloadPageStrings = getEnglishStrings(data.directus.download_page?.strings)
  const generalTranslationStrings = getEnglishStrings(data.directus.general_translations?.strings)

  const [selectedDesktopPlatform, setSelectedDesktopPlatform] = useState<DesktopPlaforms>('Windows')
  const [selectedExtensionPlatform, setSelectedExtensionPlatform] =
    useState<ExtensionPlatforms>('Chrome')
  const [selectedMobilePlatform, setSelectedMobilePlatform] = useState<MobilePlatforms>('Android')
  const [selectedTVPlatform, setSelectedTVPlatform] = useState<TVPlatforms>('FireTV')

  const [defaultDesktopPlatform, setDefaultDesktopPlatform] = useState<DesktopPlaforms>('MacOS')
  const [defaultExtensionPlatform, setDefaultExtensionPlatform] =
    useState<ExtensionPlatforms>('Chrome')

  const [isHydrated, setIsHydrated] = useState(false)

  const desktopRef = useRef<HTMLImageElement>(null)
  const extensionRef = useRef<HTMLImageElement>(null)

  const isDesktop = platform === 'desktop'

  const scrollToDesktop = () => {
    if (desktopRef.current) {
      desktopRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const scrollToExtension = () => {
    if (extensionRef.current) {
      extensionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  useEffect(() => {
    const ua = window.navigator.userAgent
    const isIpad =
      ua.toLowerCase().indexOf('macintosh') > -1 &&
      !!navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2

    //Sets the default platforms to the current current
    setSelectedDesktopPlatform(defineOS(osName))
    setSelectedExtensionPlatform(defineBrowser(browser))
    setSelectedMobilePlatform(isIpad ? 'iOS' : defineMobilePlatform(osName))
    setSelectedTVPlatform(defineTV(osName))

    setDefaultDesktopPlatform(defineOS(osName))
    setDefaultExtensionPlatform(defineBrowser(browser))

    setIsHydrated(true)
  }, [])

  useEffect(() => {
    if (!isHydrated) {
      return
    }

    const urlParams = new URLSearchParams(location.search)
    const platformParam = urlParams.get('platform')
    const osParam = urlParams.get('os')

    if (platformParam === 'desktop' && osParam && desktopRef.current) {
      scrollToDesktop()
    }

    if (platformParam === 'extension' && osParam && extensionRef.current) {
      scrollToExtension()
    }
  }, [isHydrated])

  return (
    <StaticPageLayout title={`Windscribe | ${downloadPageStrings?.download_windscribe ?? ''}`}>
      {isHydrated && (
        <Flex
          sx={{
            flexDirection: 'column',
            mt: '4.8rem',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              gap: ['1rem', '6rem', '6rem'],
            }}
          >
            <Flex
              sx={{ flexDirection: 'column', alignItems: ['center', 'flex-start', 'flex-start'] }}
            >
              <Text
                sx={{
                  fontSize: ['2.4rem', '3.2rem', '4.8rem'],
                  color: 'white',
                  mb: ['3.2rem', '1.6rem', '1.6rem'],
                  fontWeight: 'bold',
                }}
              >
                {downloadPageStrings?.download_windscribe}
              </Text>
              <Text
                sx={{
                  fontSize: ['1.8rem', '2.1rem', '2.1rem'],
                  color: 'white50',
                  mb: [0, 0, '4.8rem'],
                  maxWidth: ['54rem', '35.2rem', '35.2rem'],
                }}
              >
                {downloadPageStrings?.here_is_what_you_need}
              </Text>
              {isDesktop && (
                <Flex
                  sx={{
                    gap: ['1.6rem', '1.6rem', '4.8rem'],
                    flexDirection: ['column', 'column', 'row'],
                    alignItems: ['center', 'flex-start', 'center'],
                    mt: ['1.6rem', '1.6rem', 0],
                  }}
                >
                  <Flex
                    sx={platformStyle}
                    onClick={() => {
                      if (desktopRef.current) {
                        scrollToDesktop()
                        setSelectedDesktopPlatform(defaultDesktopPlatform)
                      }
                    }}
                    data-testid="desktop-section"
                  >
                    <Flex
                      sx={{
                        gap: ['1.6rem', '1.6rem', '1.6rem'],
                        alignItems: 'center',
                      }}
                    >
                      <Flex
                        sx={{
                          width: '3.2rem',
                          height: '3.2rem',
                          backgroundColor: 'neonGreen',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '50%',
                        }}
                      >
                        <Text
                          sx={{ color: 'black', fontSize: '1.8rem', fontWeight: 'bold' }}
                          as="p"
                        >
                          1
                        </Text>
                      </Flex>
                      <Text
                        sx={{
                          color: 'white50',
                          textDecoration: 'underline',
                          fontSize: '1.8rem',
                          fontWeight: 'bold',
                        }}
                      >
                        {
                          {
                            Windows: downloadPageStrings?.windows_app,
                            Linux: downloadPageStrings?.linux_app,
                            MacOS: downloadPageStrings?.macos_app,
                          }[defaultDesktopPlatform]
                        }
                      </Text>
                    </Flex>
                    <Svg svg={DownloadDropdown} sx={{ ml: '0.8rem' }} />
                  </Flex>
                  <Flex
                    sx={platformStyle}
                    onClick={() => {
                      if (extensionRef.current) {
                        scrollToExtension()
                        setSelectedExtensionPlatform(defaultExtensionPlatform)
                      }
                    }}
                    data-testid="extension-section"
                  >
                    <Flex sx={{ gap: ['1.6rem', '1.6rem', '1.6rem'], alignItems: 'center' }}>
                      <Flex
                        sx={{
                          width: '3.2rem',
                          height: '3.2rem',
                          backgroundColor: 'neonGreen',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '50%',
                        }}
                      >
                        <Text
                          sx={{ color: 'black', fontSize: '1.8rem', fontWeight: 'bold' }}
                          as="p"
                        >
                          2
                        </Text>
                      </Flex>
                      <Text
                        sx={{
                          color: 'white50',
                          textDecoration: 'underline',
                          fontSize: '1.8rem',
                          fontWeight: 'bold',
                        }}
                      >
                        {
                          {
                            Chrome: downloadPageStrings?.chrome_extension,
                            Firefox: downloadPageStrings?.firefox_extension,
                            Edge: downloadPageStrings?.edge_extension,
                          }[defaultExtensionPlatform]
                        }
                      </Text>
                    </Flex>
                    <Svg svg={DownloadDropdown} sx={{ ml: '0.8rem' }} />
                  </Flex>
                </Flex>
              )}
            </Flex>
            <img
              alt=""
              src={DownloadWindscribe}
              sx={{
                maxWidth: ['28.8rem', '30.2rem', '40rem'],
                maxHeight: ['31.2rem', '19.8rem', '26.2rem'],
                display: ['none', 'block', 'block'],
              }}
            />
          </Flex>
          {['desktop', 'background', 'extension', 'benefits', 'mobile', 'tv']
            .sort(function (x, y) {
              return x == platform ? -1 : y == platform ? 1 : 0
            })
            .map(type => (
              <Fragment key={type}>
                {
                  {
                    desktop: (
                      <DesktopPlatform
                        platformName={selectedDesktopPlatform}
                        setPlatform={setSelectedDesktopPlatform}
                        translations={data}
                        featuresList={[
                          downloadPageStrings?.servers_in_over ?? '',
                          downloadPageStrings?.six_different_protocols ?? '',
                          downloadPageStrings?.dual_mode_network ?? '',
                        ]}
                        image={
                          <Flex ref={desktopRef}>
                            {
                              <>
                                <StaticImage
                                  src="../images/windscribe-desktop-windows.png"
                                  alt={''}
                                  placeholder="blurred"
                                  loading="eager"
                                  sx={{
                                    ...baseImageStyle,
                                    display:
                                      selectedDesktopPlatform === 'Windows' ? 'block' : 'none',
                                  }}
                                />
                                <StaticImage
                                  src="../images/windscribe-desktop-linux.png"
                                  alt={''}
                                  placeholder="blurred"
                                  loading="eager"
                                  sx={{
                                    ...baseImageStyle,
                                    display: selectedDesktopPlatform === 'Linux' ? 'block' : 'none',
                                  }}
                                />
                                <StaticImage
                                  src="../images/windscribe-desktop-macos.png"
                                  alt={''}
                                  placeholder="blurred"
                                  loading="eager"
                                  sx={{
                                    ...baseImageStyle,
                                    display: selectedDesktopPlatform === 'MacOS' ? 'block' : 'none',
                                  }}
                                />
                              </>
                            }
                          </Flex>
                        }
                      />
                    ),
                    extension: (
                      <ExtensionPlatform
                        isDesktop={isDesktop}
                        platformName={selectedExtensionPlatform}
                        setPlatform={setSelectedExtensionPlatform}
                        translations={data}
                        featuresList={[
                          downloadPageStrings?.standalone_proxy ?? '',
                          downloadPageStrings?.over_a_dozen_built_in ?? '',
                          downloadPageStrings?.does_not_require ?? '',
                        ]}
                        image={
                          <Flex ref={extensionRef}>
                            <StaticImage
                              src="../images/windscribe-extension-chrome.png"
                              alt={''}
                              placeholder="blurred"
                              loading="eager"
                              sx={{
                                ...horizontalImageStyle,
                                display: selectedExtensionPlatform === 'Chrome' ? 'block' : 'none',
                              }}
                            />
                            <StaticImage
                              src="../images/windscribe-extension-firefox.png"
                              alt={''}
                              placeholder="blurred"
                              loading="eager"
                              sx={{
                                ...horizontalImageStyle,
                                display: selectedExtensionPlatform === 'Firefox' ? 'block' : 'none',
                              }}
                            />
                            <StaticImage
                              src="../images/windscribe-extension-edge.png"
                              alt={''}
                              placeholder="blurred"
                              loading="eager"
                              sx={{
                                ...horizontalImageStyle,
                                display: selectedExtensionPlatform === 'Edge' ? 'block' : 'none',
                              }}
                            />
                          </Flex>
                        }
                      />
                    ),
                    mobile: (
                      <MobilePlatform
                        platformName={selectedMobilePlatform}
                        setPlatform={setSelectedMobilePlatform}
                        translations={data}
                        featuresList={[
                          downloadPageStrings?.servers_in_over ?? '',
                          downloadPageStrings?.six_different_protocols ?? '',
                          downloadPageStrings?.customizable_in_app ?? '',
                        ]}
                        image={
                          <>
                            <StaticImage
                              src="../images/windscribe-mobile-android.png"
                              alt={''}
                              placeholder="blurred"
                              loading="eager"
                              sx={{
                                ...baseImageStyle,
                                display: selectedMobilePlatform === 'Android' ? 'block' : 'none',
                              }}
                            />
                            <StaticImage
                              src="../images/windscribe-mobile-ios.png"
                              alt={''}
                              placeholder="blurred"
                              loading="eager"
                              sx={{
                                ...baseImageStyle,
                                display: selectedMobilePlatform === 'iOS' ? 'block' : 'none',
                              }}
                            />
                          </>
                        }
                      />
                    ),
                    tv: (
                      <TVPlatform
                        platformName={selectedTVPlatform}
                        setPlatform={setSelectedTVPlatform}
                        translations={data}
                        featuresList={[
                          downloadPageStrings?.servers_in_over ?? '',
                          downloadPageStrings?.six_different_protocols ?? '',
                          downloadPageStrings?.network_and_per_app ?? '',
                        ]}
                        image={
                          <>
                            <StaticImage
                              src="../images/windscribe-tv-android.png"
                              alt={''}
                              placeholder="blurred"
                              loading="eager"
                              sx={{
                                ...baseImageStyle,
                                display: selectedTVPlatform === 'AndroidTV' ? 'block' : 'none',
                              }}
                              imgStyle={{ objectFit: 'contain', height: 'fit-content' }}
                            />
                            <StaticImage
                              src="../images/windscribe-tv-firetv.png"
                              alt={''}
                              placeholder="blurred"
                              loading="eager"
                              sx={{
                                ...baseImageStyle,
                                display: selectedTVPlatform === 'FireTV' ? 'block' : 'none',
                              }}
                              imgStyle={{ objectFit: 'contain', height: 'fit-content' }}
                            />
                          </>
                        }
                      />
                    ),
                    benefits: <BenefitsTable translations={data} isDesktop={isDesktop} />,
                    background: (
                      <>
                        {isDesktop && (
                          <Flex sx={{ mt: '16rem' }}>
                            <Background display={['flex', 'flex', 'flex']}>
                              <img
                                src={PlusDownloadIcon}
                                alt=""
                                sx={{
                                  width: '16rem',
                                  height: '11.2rem',
                                  position: 'absolute',
                                  bottom: '-3.5rem',
                                  left: 'calc(50vw - 16rem / 2)', //16rem is the width of the icon, divide it by two to center it
                                }}
                              />
                              <RoundedBorder
                                minHeight={['81.3rem', '81.3rem', '70.1rem']}
                                roundedEdge="top"
                                backgroundImage={({ colors }): string =>
                                  `linear-gradient(to bottom, ${colors?.kuretakeBlack} 33%, ${colors?.kuretakeBlack0} 100%)`
                                }
                              />
                              <Flex
                                sx={{
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  zIndex: -1,
                                  width: [0, '100vw', '95%'],
                                  maxWidth: '138rem',
                                  height: ['32.9rem', '32.9rem', '34.9rem'],
                                  backgroundImage: `url(${BackgroundPattern})`,
                                  position: 'absolute',
                                  left: '50%',
                                  transform: 'translateX(-50%)',
                                  borderRadius: '3.6rem 3.6rem 0 0',
                                  top: '4.2rem',
                                }}
                              />
                            </Background>
                          </Flex>
                        )}
                      </>
                    ),
                  }[type]
                }
              </Fragment>
            ))}
          <Flex
            sx={{ justifyContent: 'center', my: '8rem', flexDirection: 'column', gap: '4.8rem' }}
          >
            <PlatformTitle title={generalTranslationStrings?.other ?? ''} minWidth={['13.2rem']} />
            <ExternalLink
              variant="buttons.outlined"
              href="/knowledge-base/categories/guides?platform=router"
              sxx={externalLinkStyle}
            >
              <Flex
                sx={{
                  minWidth: '30.7rem',
                  minHeight: '4.8rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <Text>{downloadPageStrings?.windscribe_for_routers}</Text>
                <Svg
                  svg={ExtensionLink}
                  sx={{
                    position: 'absolute',
                    right: '1.5rem',
                    '& path': {
                      fill: 'white50',
                    },
                  }}
                />
              </Flex>
            </ExternalLink>
          </Flex>
          <Flex
            sx={{
              flexDirection: 'column',
              gap: ['3.2rem', '4.8rem', '4.8rem'],
            }}
          >
            <PlatformTitle
              title={generalTranslationStrings?.config_generators ?? ''}
              minWidth={['30.6rem']}
            />
            <Flex
              sx={{
                gap: ['1.6rem', '2.4rem', '3.2rem'],
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              <ExternalLink
                variant="buttons.outlined"
                href="/getconfig/openvpn"
                sxx={externalLinkStyle}
              >
                <Text>{generalTranslationStrings?.openvpn}</Text>
              </ExternalLink>
              <ExternalLink
                variant="buttons.outlined"
                href="/getconfig/ikev2"
                sxx={externalLinkStyle}
              >
                <Text>{generalTranslationStrings?.ikev2}</Text>
              </ExternalLink>
              <ExternalLink
                variant="buttons.outlined"
                href="/getconfig/wireguard"
                sxx={externalLinkStyle}
              >
                <Text>{generalTranslationStrings?.wireguard}</Text>®
              </ExternalLink>
            </Flex>
          </Flex>
          <WireGuardDisclaimer />
        </Flex>
      )}
    </StaticPageLayout>
  )
}

export const data = graphql`
  query DownloadPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          whitelisting
          tv
          mobile
          desktop
          download
          browser
          features
          firewall
          or
          config_generators
          ikev2
          openvpn
          wireguard
          other
        }
      }
      download_page {
        strings {
          languages_code {
            code
          }
          ad_and_tracker
          advanced_blocking_features
          advanced_privacy_features
          chrome_extension
          desktop_app_alone
          download_windscribe
          edge_extension
          firefox_extension
          here_is_what_you_need
          install_guide
          learn_more
          linux_app
          macos_app
          network_and_per_app
          recommended_for_linux
          recommended_for_macos
          recommended_for_windows
          servers_in_over
          six_different_protocols
          this_is_totally_optional
          webrtc_blocker
          whitelisting
          windows_app
          windscribe_for_android
          windscribe_for_chrome
          windscribe_for_edge
          windscribe_for_firefox
          windscribe_for_firetv
          windscribe_for_ios
          windscribe_for_kodi
          windscribe_for_linux
          windscribe_for_macos
          windscribe_for_nvidia_shield
          windscribe_for_routers
          windscribe_for_windows
          standalone_proxy
          over_a_dozen_built_in
          does_not_require
          source_code
          recommended_for_ios
          recommended_for_firefox
          recommended_for_edge
          recommended_for_chrome
          recommended_for_android
          meta_description
          meta_keywords
          the_desktop_app_alone
          the_windscribe_desktop_app
          censorship_circumvention
          double_hop
          desktop_app
          browser_extension
          desktop_and_browser
          customizable_in_app
          dual_mode_network
          windscribe_for_androidtv
          wireguard_is_a_registered
        }
      }
    }
  }
`
