import FeaturesPageTrackedButton from 'components/features-page/FeaturesPageTrackedButton'
import Svg from 'components/UI/SVG'
import chromeIcon from 'images/platforms/chrome.svg'
import edgeIcon from 'images/platforms/edge.svg'
import firefoxIcon from 'images/platforms/firefox.svg'
import { Flex, Text } from 'theme-ui'

interface OSType {
  icon: React.ElementType
  text: string
  link: string
}

interface ExtensionButton {
  chrome: OSType
  firefox: OSType
  edge: OSType
}

export default function BrowserDownloadButtons({
  chromeText,
  firefoxText,
  edgeText,
  type,
}: {
  chromeText: string
  firefoxText: string
  edgeText: string
  type: 'chrome' | 'firefox' | 'edge'
}): JSX.Element {
  const extensionButtons: ExtensionButton = {
    chrome: {
      icon: chromeIcon,
      text: chromeText,
      link: '/download?platform=extension&os=chrome',
    },
    firefox: {
      icon: firefoxIcon,
      text: firefoxText,
      link: '/download?platform=extension&os=firefox',
    },
    edge: {
      icon: edgeIcon,
      text: edgeText,
      link: '/download?platform=extension&os=edge',
    },
  }

  return (
    <FeaturesPageTrackedButton
      url={extensionButtons[type].link}
      dataTestId="browser-download-button"
    >
      <Flex sx={{ justifyContent: 'center', alignItems: 'center', gap: '1.6rem' }}>
        <Svg svg={extensionButtons[type].icon} />
        <Text>{extensionButtons[type].text}</Text>
      </Flex>
    </FeaturesPageTrackedButton>
  )
}
