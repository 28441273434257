import React, { ReactElement } from 'react'
import { Flex, Text, get } from 'theme-ui'
import Svg from 'components/UI/SVG'
import HomeIcon from 'images/home-icon.svg'
import BreadcrumbArrow from 'images/breadcrumb-arrow.svg'
import InternalLink from 'components/UI/InternalLink'
import ExternalLink from 'components/UI/ExternalLink'

export interface Breadcrumb {
  title: string
  href?: string // notice this is optional since not all breadcrumb components are clickable
  shouldUseExternalLink?: boolean
}

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[]
}

export default function Breadcrumbs({ breadcrumbs }: BreadcrumbsProps): ReactElement {
  if (breadcrumbs.length > 1) {
    return (
      <Flex
        sx={{
          width: '100%',
          justifyContent: 'center',
          px: '2.2rem',
          mb: '2.4rem',
          display: ['none', 'none', 'flex'],
        }}
      >
        <Flex
          sx={{
            fontFamily: 'IBM Plex Sans',
            fontSize: '1.8rem',
            fontWeight: 'bold',
            height: '4.2rem',
            backgroundColor: 'white10',
            px: '2.5rem',
            alignItems: 'center',
            borderRadius: '3rem',
            letterSpacing: '0.375rem',
          }}
        >
          {breadcrumbs.map((breadcrumb, index) => {
            const LinkComponent = breadcrumb.shouldUseExternalLink ? ExternalLink : InternalLink
            if (index === 0) {
              return (
                <Flex
                  data-testid="help-breadcrumb"
                  sx={{ minWidth: '9rem' }}
                  key={breadcrumb.title}
                >
                  {/*@ts-ignore*/}
                  <LinkComponent href={breadcrumb.href}>
                    <Flex
                      sx={{
                        gap: '1rem',
                        color: 'white50',
                        ':hover': {
                          color: 'white',
                          '.home-icon': {
                            opacity: 1,
                            transition: theme => `${get(theme, 'transitions.default')}`,
                          },
                        },
                      }}
                    >
                      <Svg
                        className="home-icon"
                        svg={HomeIcon}
                        sx={{
                          opacity: 0.5,
                          transition: theme => `${get(theme, 'transitions.default')}`,
                        }}
                      />
                      <Text sx={{ transition: theme => `${get(theme, 'transitions.default')}` }}>
                        {breadcrumb.title}
                      </Text>
                    </Flex>
                  </LinkComponent>
                </Flex>
              )
            }
            return (
              <Flex data-testid="category-breadcrumb" key={breadcrumb.title}>
                {breadcrumb.href ? (
                  <LinkComponent href={breadcrumb.href}>
                    <Flex
                      sx={{
                        alignItems: 'center',
                        color: 'white50',
                        ':hover': {
                          color: 'white',
                        },
                      }}
                    >
                      <Svg
                        svg={BreadcrumbArrow}
                        sx={{
                          marginLeft: '0.8rem',
                          marginRight: '1.6rem',
                          minWidth: '3.2rem',
                          minHeight: '4.2rem',
                        }}
                      />
                      <Text
                        data-testid="category-breadcrumb-text"
                        sx={{
                          width: '100%',
                          textTransform: 'uppercase',
                          whiteSpace: 'nowrap',
                          overflowX: 'hidden',
                          textOverflow: 'ellipsis',
                          transition: theme => `${get(theme, 'transitions.default')}`,
                        }}
                      >
                        {breadcrumb.title}
                      </Text>
                    </Flex>
                  </LinkComponent>
                ) : (
                  <Flex sx={{ alignItems: 'center' }}>
                    <Svg
                      svg={BreadcrumbArrow}
                      sx={{
                        marginLeft: '0.8rem',
                        marginRight: '1.6rem',
                        minWidth: '3.2rem',
                        minHeight: '4.2rem',
                      }}
                    />
                    <Text
                      data-testid="category-breadcrumb-text"
                      sx={{
                        width: '100%',
                        textTransform: 'uppercase',
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {breadcrumb.title}
                    </Text>
                  </Flex>
                )}
              </Flex>
            )
          })}
        </Flex>
      </Flex>
    )
  } else {
    return <Flex></Flex>
  }
}
