import StaticContentSkeleton from 'components/StaticContentSkeleton'
import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'
import Background from 'components/features-page/background/Background'
import RoundedBorder from 'components/features-page/background/RoundedBorder'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import ReactMarkdown from 'react-markdown'
import { Flex, Text } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'

export default function VulnerabilityDisclosure({
  data,
}: {
  data: Queries.VulnerabilityDisclosurePageQuery
}): ReactElement {
  const vulnerabilityDisclosureData = getEnglishStrings(
    data.directus.vulnerability_disclosure?.strings,
  )

  return (
    <StaticContentSkeleton
      title={vulnerabilityDisclosureData?.title ?? ''}
      metaDescription={vulnerabilityDisclosureData?.meta_description ?? ''}
      metaKeywords={vulnerabilityDisclosureData?.meta_keywords ?? []}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: '8rem',
          width: '100%',
        }}
      >
        <TitleAndSubtitle
          title={vulnerabilityDisclosureData?.title}
          updatedAt={vulnerabilityDisclosureData?.updated_at}
          subtitle={vulnerabilityDisclosureData?.subtitle}
        />

        <Background display={['flex', 'flex', 'flex']}>
          <RoundedBorder
            width={['100vw', '100vw', '100vw']}
            minHeight={['81.2rem', '81.2rem', '100rem']}
            roundedEdge="top"
            backgroundImage="linear-gradient(180deg, #030E1D 32.81%, rgba(3, 14, 29, 0.00) 100%)"
            maxWidth="128rem"
          />
        </Background>
      </Flex>
      <Item
        title={vulnerabilityDisclosureData?.introduction ?? ''}
        subtitle={vulnerabilityDisclosureData?.introduction_subtitle ?? ''}
      />

      <Item
        title={vulnerabilityDisclosureData?.guidelines ?? ''}
        subtitle={vulnerabilityDisclosureData?.guidelines_subtitle ?? ''}
      />

      <Item
        title={vulnerabilityDisclosureData?.scope ?? ''}
        subtitle={vulnerabilityDisclosureData?.scope_subtitle ?? ''}
      />

      <Item
        title={vulnerabilityDisclosureData?.things_to_avoid ?? ''}
        subtitle={vulnerabilityDisclosureData?.things_to_avoid_subtitle ?? ''}
      />

      <Item
        title={vulnerabilityDisclosureData?.our_response ?? ''}
        subtitle={vulnerabilityDisclosureData?.our_response_subtitle ?? ''}
      />

      <Item
        title={vulnerabilityDisclosureData?.bug_bounty ?? ''}
        subtitle={vulnerabilityDisclosureData?.bug_bounty_subtitle ?? ''}
      />

      <Item
        title={vulnerabilityDisclosureData?.reporting ?? ''}
        subtitle={vulnerabilityDisclosureData?.reporting_subtitle ?? ''}
      />

      <Item
        title={vulnerabilityDisclosureData?.vulnerability_disclosure ?? ''}
        subtitle={vulnerabilityDisclosureData?.vulnerability_disclosure_subtitle ?? ''}
      />
    </StaticContentSkeleton>
  )
}

function Item({ title, subtitle }: { title: string; subtitle: string }): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '1.6rem',
        maxWidth: 'maxContentWidth',
        width: '100%',
      }}
    >
      <Text variant="heading2" color="white">
        {title}
      </Text>
      <ReactMarkdown sx={{ color: 'white50', variant: 'text.body2' }}>{subtitle}</ReactMarkdown>
    </Flex>
  )
}

export const data = graphql`
  query VulnerabilityDisclosurePage {
    directus {
      vulnerability_disclosure {
        strings {
          languages_code {
            code
          }
          bug_bounty
          bug_bounty_subtitle
          guidelines
          guidelines_subtitle
          introduction
          introduction_subtitle
          our_response
          our_response_subtitle
          reporting
          reporting_subtitle
          scope
          scope_subtitle
          subtitle
          things_to_avoid
          things_to_avoid_subtitle
          title
          updated_at
          vulnerability_disclosure
          vulnerability_disclosure_subtitle
          meta_description
          meta_keywords
        }
      }
    }
  }
`
