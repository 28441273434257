import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import { graphql } from 'gatsby'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function MacSpoofingPage({
  data,
}: {
  data: Queries.MacSpoofingPageQuery
}): ReactElement {
  const macSpoofingData = getEnglishStrings(data.directus.mac_address_spoofing?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={macSpoofingData?.meta_description ?? ''}
      metaKeywords={macSpoofingData?.meta_keywords ?? []}
      title={macSpoofingData?.title ?? ''}
      subtitle={macSpoofingData?.subtitle ?? ''}
      summaryText={macSpoofingData?.summary_text ?? ''}
      gridItems={[
        {
          title: macSpoofingData?.what_is_a_mac_address ?? '',
          description: macSpoofingData?.what_is_a_mac_address_subtitle ?? '',
        },
        {
          title: macSpoofingData?.what_is_mac_spoofing ?? '',
          description: macSpoofingData?.what_is_mac_spoofing_subtitle ?? '',
        },
        {
          title: macSpoofingData?.improve_your ?? '',
          description: macSpoofingData?.improve_your_subtitle ?? '',
        },
      ]}
      cta={
        <InternalLink href={`/download?${queryParam}`} sx={{ variant: 'buttons.neon' }}>
          {macSpoofingData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[
        {
          title: generalTranslationsData?.by_the_way ?? '',
          items: [macSpoofingData?.you_can_also ?? ''],
        },
      ]}
    />
  )
}

export const data = graphql`
  query MacSpoofingPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          by_the_way
        }
      }
      mac_address_spoofing {
        strings {
          languages_code {
            code
          }
          cta
          improve_your
          improve_your_subtitle
          meta_description
          meta_keywords
          subtitle
          summary_text
          title
          what_is_a_mac_address
          what_is_a_mac_address_subtitle
          what_is_mac_spoofing
          what_is_mac_spoofing_subtitle
          you_can_also
        }
      }
    }
  }
`
