import React, { ReactElement } from 'react'
import { Flex, Text, ThemeUIStyleObject, get } from 'theme-ui'
import ArticlesArrow from 'images/articles-arrow.svg'
import Svg from 'components/UI/SVG'
import getEnglishStrings from 'utils/getEnglishStrings'
import categoriesIconMap from './categoriesIconMap'
import InternalLink from 'components/UI/InternalLink'
import getArticleSlug from 'utils/getArticleSlug'
import usePlatformTagId from 'utils/usePlatformTagId'

export const categoriesYOffset = 2.4

export default function Categories({
  articles,
  categories,
  categoryViewAllText,
}: {
  articles: Queries.KnowledgeBaseIndexPageQuery['directus']['knowledge_base_articles']
  categories: Queries.KnowledgeBaseIndexPageQuery['directus']['knowledge_base_categories']
  categoryViewAllText: string | undefined
}): ReactElement {
  const gapX = '4.8rem'
  const gapY = '4.8rem'

  const popularArticles = articles?.filter(article =>
    article?.tags?.find(tag => tag?.knowledge_base_tags_id?.slug === 'popular'),
  )

  return (
    <Flex
      data-testid="categories"
      sx={{
        flexDirection: 'column',
        maxWidth: '100%',
        width: ['100%', '100%', '138rem'],
        height: ['100%', '100%', '100%'],
        backgroundColor: 'bodyBG',
        alignItems: 'center',
        borderRadius: ['0rem', '3.2rem', '3.2rem'],
        marginTop: '10rem',
        padding: ['0', '0 4.8rem 0 4.8rem', '0'],
      }}
    >
      <Flex
        sx={{
          flexDirection: ['column', 'row', 'row'],
          width: ['100%', '100%', 'maxContentWidth'],
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          top: `-7.4rem`,
          gap: `${gapY} ${gapX}`,
          mx: ['1.2rem', '2.4rem'],
          flexWrap: ['nowrap', 'wrap', 'wrap'],
          // eslint-disable-next-line
          '& > *': {
            flex: [
              '0 1 100%',
              `1 0 calc(50% - calc(${gapX} / 2))`,
              `1 0 calc(50% - calc(${gapX} / 2))`,
            ],
          },
        }}
      >
        {categories?.map(category => {
          const categoryStrings = getEnglishStrings(category?.strings)
          const categoryArticles = articles?.filter(
            article => article?.category?.id === category?.id,
          )

          if (!categoryArticles.length) {
            return null
          }

          return (
            <Category
              name={categoryStrings?.name}
              articles={categoryArticles}
              articlesType="categories"
              slug={category?.slug}
              categoryViewAllText={categoryViewAllText}
              key={category?.slug}
            />
          )
        })}
        {/* Pseudo-category that is actually just articles tagged with 'popular' tag */}
        {!!popularArticles.length && (
          <Category
            name="Popular Articles"
            articles={popularArticles}
            articlesType="tags"
            slug="popular"
            key="popular"
            containerSx={{
              maxWidth: ['100%', '100%', `calc(100% - ${categoryMargin / 2}rem)`],
            }}
            categoryViewAllText={categoryViewAllText}
          />
        )}
      </Flex>
    </Flex>
  )
}

export const categoryMargin = 1.6

function Category({
  name,
  articles,
  articlesType,
  slug,
  categoryViewAllText,
  containerSx,
}: {
  name?: string
  articles: Queries.KnowledgeBaseIndexPageQuery['directus']['knowledge_base_articles']
  articlesType: string
  slug?: string
  categoryViewAllText?: string
  containerSx?: ThemeUIStyleObject
}) {
  const { selectedPlatformTagId } = usePlatformTagId()
  return (
    <Flex
      data-testid={`category-${slug}`}
      sx={{
        width: [`calc(100% - ${categoryMargin}rem * 2)`, '100%', '38rem'],
        maxWidth: '46rem',
        maxHeight: '34rem',
        mx: ['1.6rem', 0],
        backgroundColor: 'bodyBG',
        flexDirection: 'column',
        border: '0.2rem solid',
        borderColor: 'windscribeBlue50',
        borderRadius: '0.8rem',
        fontWeight: 'bold',
        alignItems: 'center',
        ...containerSx,
        cursor: 'pointer',
      }}
    >
      <InternalLink
        href={
          selectedPlatformTagId
            ? `/knowledge-base/${articlesType}/${slug}?platform=${selectedPlatformTagId}`
            : `/knowledge-base/${articlesType}/${slug}`
        }
        data-testid={`category-title-${slug}`}
      >
        <Flex
          sx={{
            borderBottom: '2px solid',
            borderColor: 'windscribeBlue50',
            width: '100%',
            my: '-0.2rem',
            display: 'flex',
            borderRadius: '0.8rem 0.8rem 0rem 0rem',
            boxShadow: ({ colors }): string => `0rem 1rem 1.5rem ${colors?.midnight25}`,
            height: '7.5rem',
            backgroundColor: 'winscribeBlue10',
            fontSize: '2.1rem',
            lineHeight: '7.5rem',
            color: 'white',
            alignItems: 'center',
          }}
        >
          <Svg svg={categoriesIconMap[name || '']} sx={{ mx: '1.6rem', flexShrink: 0 }} />
          <Text data-testid="category-name">{name}</Text>
        </Flex>
      </InternalLink>
      <Flex
        sx={{
          gap: '2rem',
          flexDirection: 'column',
          minHeight: '19rem',
          width: '100%',
          py: '2rem',
          marginBottom: '1rem',
          px: '2.4rem',
          cursor: 'default',
        }}
      >
        {articles?.slice(0, 4).map(article => {
          const articleStrings = getEnglishStrings(article?.strings)
          const articleSlug = getArticleSlug(
            article as Queries.DirectusData_knowledge_base_articles,
          )
          return (
            <Flex
              data-testid={`article-${articleSlug}`}
              key={article?.id}
              sx={{
                fontSize: '1.8rem',
                width: '100%',
                color: 'white50',
                ':hover': {
                  '.articles-arrow': {
                    transform: 'translateX(1rem)',
                    opacity: 1,
                  },
                },
              }}
            >
              <InternalLink
                href={
                  selectedPlatformTagId
                    ? `/knowledge-base/articles/${articleSlug}?platform=${selectedPlatformTagId}`
                    : `/knowledge-base/articles/${articleSlug}`
                }
              >
                <Flex
                  data-testid="article-link"
                  sx={{
                    textDecoration: 'none',
                    width: '100%',
                    maxWidth: '100%',
                    justifyContent: 'space-between',
                    display: 'flex',
                  }}
                >
                  <Text
                    data-testid="article-title"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflowX: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {articleStrings?.title}
                  </Text>
                  <Svg
                    svg={ArticlesArrow}
                    className="articles-arrow"
                    sx={{
                      minWidth: '2.4rem',
                      minHeight: '2.4rem',
                      opacity: 0.25,
                      transition: theme => `${get(theme, 'transitions.default')}`,
                      marginLeft: '2.4rem',
                    }}
                  />
                </Flex>
              </InternalLink>
            </Flex>
          )
        })}
      </Flex>
      <Flex sx={{ width: '100%', px: '2.4rem' }}>
        <Flex
          sx={{
            height: '0.1rem',
            width: '100%',
            backgroundColor: 'white10',
          }}
        ></Flex>
      </Flex>
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          px: '2.4rem',
        }}
      >
        <InternalLink
          href={
            selectedPlatformTagId
              ? `/knowledge-base/${articlesType}/${slug}?platform=${selectedPlatformTagId}`
              : `/knowledge-base/${articlesType}/${slug}`
          }
        >
          <Flex
            sx={{
              width: '100%',
              alignItems: 'center',
              height: '6.4rem',
              fontSize: '1.8rem',
              color: 'white50',
              '.articles-arrow': {
                transition: theme => `${get(theme, 'transitions.default')}`,
              },
              ':hover': {
                color: 'white',
                '.articles-arrow': {
                  transform: 'translateX(1rem)',
                  opacity: 1,
                },
              },
            }}
          >
            <Flex
              data-testid="category-view-all"
              sx={{
                textDecoration: 'none',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                transition: theme => `${get(theme, 'transitions.default')}`,
              }}
            >
              <Text>{categoryViewAllText}</Text>
              <Flex>
                <Text sx={{ marginRight: '1rem' }}>{articles?.length}</Text>
                <Svg
                  svg={ArticlesArrow}
                  className="articles-arrow"
                  sx={{ width: '2.4rem', height: '2.4rem', opacity: 0.25 }}
                />
              </Flex>
            </Flex>
          </Flex>
        </InternalLink>
      </Flex>
    </Flex>
  )
}
