import InternalLink from 'components/UI/InternalLink'
import FeaturePageSkeleton from 'components/features-page/FeaturePageSkeleton'
import WireGuardDisclaimer from 'components/features-page/WireGuardDisclaimer'
import { graphql } from 'gatsby'
import StrongestEncryption from 'images/strongest-encryption.png'
import { ReactElement } from 'react'
import getEnglishStrings from 'utils/getEnglishStrings'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

export default function StrongestEncryptionPage({
  data,
}: {
  data: Queries.StrongestEncryptionPageQuery
}): ReactElement {
  const strongestEncryptionData = getEnglishStrings(data.directus.strongest_encryption?.strings)
  const queryParam = useFeaturePageSlug()
  return (
    <FeaturePageSkeleton
      metaDescription={strongestEncryptionData?.meta_description || ''}
      metaKeywords={strongestEncryptionData?.meta_keywords || []}
      title={strongestEncryptionData?.title || ''}
      subtitle={strongestEncryptionData?.subtitle || ''}
      featureImage={StrongestEncryption}
      summaryText={strongestEncryptionData?.summary_text || ''}
      gridItems={[
        {
          title: strongestEncryptionData?.open_vpn || '',
          description: strongestEncryptionData?.open_vpn_subtitle || '',
        },
        {
          title: strongestEncryptionData?.browser_extensions || '',
          description: strongestEncryptionData?.browser_extension_subtitle || '',
        },
        {
          title: strongestEncryptionData?.ikev2 || '',
          description: strongestEncryptionData?.ikev2_subtitle || '',
        },
        {
          title: strongestEncryptionData?.wireguard || '',
          description: strongestEncryptionData?.wireguard_subtitle || '',
        },
      ]}
      cta={
        <InternalLink sx={{ variant: 'buttons.neon' }} href={`/download?${queryParam}`}>
          {strongestEncryptionData?.cta}
        </InternalLink>
      }
      aboutTheFeature={[]}
    >
      <WireGuardDisclaimer />
    </FeaturePageSkeleton>
  )
}

export const data = graphql`
  query StrongestEncryptionPage {
    directus {
      strongest_encryption {
        strings {
          languages_code {
            code
          }
          title
          subtitle
          wireguard
          wireguard_subtitle
          summary_text
          open_vpn_subtitle
          open_vpn
          ikev2_subtitle
          ikev2
          browser_extensions
          browser_extension_subtitle
          cta
          meta_description
          meta_keywords
        }
      }
    }
  }
`
