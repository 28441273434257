import FeaturesPageTrackedButton from 'components/features-page/FeaturesPageTrackedButton'
import Svg from 'components/UI/SVG'
import linuxIcon from 'images/linux-icon.svg'
import macOSIcon from 'images/macos-icon.svg'
import windowsIcon from 'images/windows-icon.svg'
import { Flex, Text } from 'theme-ui'

interface OSType {
  icon: React.ElementType
  text: string
  link: string
}

interface DesktopButton {
  windows: OSType
  macos: OSType
  linux: OSType
}

export default function DesktopDownloadButtons({
  windowsText,
  macOSText,
  osType: type,
  linuxText,
}: {
  windowsText: string
  macOSText: string
  linuxText: string
  osType: 'windows' | 'macos' | 'linux'
}): JSX.Element {
  const desktopButtons: DesktopButton = {
    windows: {
      icon: windowsIcon,
      text: windowsText,
      link: '/download?platform=desktop&os=windows',
    },
    macos: {
      icon: macOSIcon,
      text: macOSText,
      link: '/download?platform=desktop&os=macos',
    },
    linux: {
      icon: linuxIcon,
      text: linuxText,
      link: '/download?platform=desktop&os=linux',
    },
  }

  return (
    <FeaturesPageTrackedButton url={desktopButtons[type].link} dataTestId="desktop-download-button">
      <Flex sx={{ justifyContent: 'flex-start', alignItems: 'center', gap: '1.6rem' }}>
        <Svg svg={desktopButtons[type].icon} />
        <Text>{desktopButtons[type].text}</Text>
      </Flex>
    </FeaturesPageTrackedButton>
  )
}
