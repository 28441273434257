import StaticContentSkeleton from 'components/StaticContentSkeleton'
import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'
import Background from 'components/features-page/background/Background'
import RoundedBorder from 'components/features-page/background/RoundedBorder'
import { graphql } from 'gatsby'
import { ReactElement, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Box, Flex, Text } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'

export default function PrivacyPolicy({ data }: { data: Queries.TermsPageQuery }): ReactElement {
  const termsData = getEnglishStrings(data.directus.terms_of_use?.strings)
  const generalTranslationsData = getEnglishStrings(data.directus.general_translations?.strings)
  const [showMore, setShowMore] = useState(false)

  return (
    <StaticContentSkeleton
      title={termsData?.title ?? ''}
      metaDescription={termsData?.meta_description ?? ''}
      metaKeywords={termsData?.meta_keywords ?? []}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: '8rem',
          width: '100%',
        }}
      >
        <TitleAndSubtitle
          title={termsData?.title}
          updatedAt={termsData?.updated_at}
          subtitle={termsData?.subtitle}
        />

        <Background display={['flex', 'flex', 'flex']}>
          <RoundedBorder
            width={['100vw', '100vw', '100vw']}
            minHeight={['81.2rem', '81.2rem', '100rem']}
            roundedEdge="top"
            backgroundImage="linear-gradient(180deg, #030E1D 32.81%, rgba(3, 14, 29, 0.00) 100%)"
            maxWidth="128rem"
          />
        </Background>
      </Flex>
      <TermsItem
        title={termsData?.acceptance_of_terms ?? ''}
        items={[termsData?.this_agreement ?? '', termsData?.all_use_of_this ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.acceptance_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.copyright ?? ''}
        items={[termsData?.copyright_subtitle ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.copyright_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.service ?? ''}
        items={[termsData?.widscribe_is_providing ?? '', termsData?.the_speed_and_quality ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.service_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.email_promotions ?? ''}
        items={[termsData?.email_promotion_subtitle ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.email_promotion_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.no_rendering ?? ''}
        items={[termsData?.no_rendering_subtitle ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.no_rendering_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.prohibited_uses ?? ''}
        items={[termsData?.prohibited_uses_subtitle ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.prohibited_uses_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.termination ?? ''}
        items={[termsData?.termination_subtitle ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.termination_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.modifications ?? ''}
        items={[termsData?.modifications_subtitle ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.modifications_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.refunds ?? ''}
        items={[termsData?.refunds_subtitle ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.refunds_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.product_delivery ?? ''}
        items={[termsData?.product_delivery_subtitle ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.product_delivery_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.product_support ?? ''}
        items={[termsData?.product_support_subtitle ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.product_support_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.third_party ?? ''}
        items={[termsData?.third_party_subtitle ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.third_party_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.indemnification ?? ''}
        items={[termsData?.indemnification_subtitle ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.indemnification_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.limitation_of_liability ?? ''}
        items={[termsData?.limitation_of_liability_subtitle ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.limitation_of_liability_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.limitation_of_damage ?? ''}
        items={[termsData?.limitation_of_damage_subtitle ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.limitation_of_damage_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.severability ?? ''}
        items={[termsData?.severability_subtitle ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.severability_tldr ?? '',
        }}
      />
      <TermsItem
        title={termsData?.abuse ?? ''}
        items={[termsData?.abuse_subtitle ?? '']}
        tldr={{
          translation: generalTranslationsData?.tldr ?? '',
          content: termsData?.abuse_tldr ?? '',
        }}
      />
    </StaticContentSkeleton>
  )
}

function TermsItem({
  items,
  title,
  tldr,
}: {
  title: string
  items: string[]
  tldr: {
    translation: string
    content: string
  }
}): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '2.4rem',
        color: 'white50',
        maxWidth: 'maxContentWidth',
        width: '100%',
      }}
    >
      <Text variant="text.heading2" color="white">
        {title}
      </Text>
      {items.map((item, index) => (
        <ReactMarkdown
          key={index}
          sx={{
            p: { m: 0 },
          }}
        >
          {item}
        </ReactMarkdown>
      ))}
      <Flex sx={{ gap: '1.6rem', alignItems: 'center' }}>
        <Box
          sx={{
            p: '1rem 1.6rem',
            backgroundColor: 'white05',
            border: ({ colors }) => `1px solid ${colors?.white15}`,
            borderRadius: '2.4rem',
            minWidth: '8.1rem',
          }}
        >
          <Text>{tldr.translation}</Text>
        </Box>
        <Text>{tldr.content}</Text>
      </Flex>
    </Flex>
  )
}

export const data = graphql`
  query TermsPage {
    directus {
      general_translations {
        strings {
          languages_code {
            code
          }
          tldr
        }
      }
      terms_of_use {
        strings {
          languages_code {
            code
          }
          abuse
          abuse_subtitle
          abuse_tldr
          acceptance_of_terms
          acceptance_tldr
          all_use_of_this
          copyright
          copyright_subtitle
          copyright_tldr
          email_promotion_subtitle
          email_promotion_tldr
          email_promotions
          indemnification
          indemnification_subtitle
          indemnification_tldr
          limitation_of_damage
          limitation_of_damage_subtitle
          limitation_of_damage_tldr
          limitation_of_liability
          limitation_of_liability_subtitle
          limitation_of_liability_tldr
          modifications
          modifications_subtitle
          modifications_tldr
          no_rendering
          no_rendering_subtitle
          no_rendering_tldr
          product_delivery
          product_delivery_subtitle
          product_delivery_tldr
          product_support
          product_support_subtitle
          product_support_tldr
          prohibited_uses
          prohibited_uses_subtitle
          prohibited_uses_tldr
          refunds
          refunds_subtitle
          refunds_tldr
          service
          service_tldr
          severability
          severability_subtitle
          severability_tldr
          subtitle
          termination
          termination_subtitle
          termination_tldr
          the_speed_and_quality
          third_party
          third_party_subtitle
          third_party_tldr
          this_agreement
          title
          updated_at
          widscribe_is_providing
          meta_description
          meta_keywords
        }
      }
    }
  }
`
