import InternalLink from 'components/UI/InternalLink'
import EdgeIcon from 'images/extension-edge-icon.svg'
import Svg from 'components/UI/SVG'
import AppleLogo from 'images/apple-logo.svg'
import DesktopFeaturesIcon from 'images/desktop-features.svg'
import LinuxLogo from 'images/linux-logo.svg'
import WindowsLogo from 'images/windows-logo.svg'
import React, { Fragment, ReactElement } from 'react'
import { Box, Flex, Text, ThemeUIStyleObject, get } from 'theme-ui'
import BrowserExtensionIcon from 'images/browser-extension.svg'
import ChromeFeatureIcon from 'images/chrome-feature.svg'
import FirefoxFeatureIcon from 'images/firefox-feature.svg'
import AndroidFeatureIcon from 'images/android-feature.svg'
import MobileExtensionIcon from 'images/mobile-extension.svg'
import { useFeaturePageSlug } from 'utils/useFeaturePageSlug'

const iconStyles: ThemeUIStyleObject = {
  height: '4.8rem',
  width: '4.8rem',
  //This aims to reset the svgs
  'svg > path': {
    fill: 'white50',
    opacity: 1,
  },
  'svg > g': {
    opacity: 1,
  },
  'path,g': {
    transition: theme => `${get(theme, 'transitions.default')}`,
  },
  ':hover': {
    'path,g': {
      opacity: 1,
    },
  },
}

export default function BrowserFeatures({
  browserExtensionFeaturesText,
  desktopAppFeaturesText,
  mobileAppFeaturesText,
  activeGroup,
}: {
  browserExtensionFeaturesText: string | undefined
  desktopAppFeaturesText: string | undefined
  mobileAppFeaturesText: string | undefined
  activeGroup: 'desktop' | 'extension' | 'mobile'
}): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        fontSize: '2.8rem',
        fontWeight: 'bold',
        color: 'white50',
        marginBottom: '8rem',
        width: '100%',
        maxWidth: '98rem',
      }}
    >
      {activeGroup === 'desktop' && (
        <FeatureLine
          leftIcon={DesktopFeaturesIcon}
          platforms={[
            { icon: WindowsLogo, url: '/features/windows' },
            { icon: AppleLogo, url: '/features/macos' },
            { icon: LinuxLogo, url: '/features/linux' },
          ]}
          title={desktopAppFeaturesText ?? ''}
        />
      )}
      {activeGroup === 'extension' && (
        <FeatureLine
          leftIcon={BrowserExtensionIcon}
          platforms={[
            { icon: ChromeFeatureIcon, url: '/features/chrome' },
            { icon: FirefoxFeatureIcon, url: '/features/firefox' },
            { icon: EdgeIcon, url: '/features/edge' },
          ]}
          title={browserExtensionFeaturesText ?? ''}
        />
      )}
      {activeGroup === 'mobile' && (
        <FeatureLine
          leftIcon={MobileExtensionIcon}
          platforms={[
            { icon: AndroidFeatureIcon, url: '/features/android' },
            { icon: AppleLogo, url: '/features/ios' },
          ]}
          title={mobileAppFeaturesText ?? ''}
        />
      )}
    </Flex>
  )
}

interface Platform {
  icon: React.ElementType
  url: string
}

function FeatureLine({
  leftIcon,
  platforms,
  title,
}: {
  leftIcon: React.ElementType
  platforms: Platform[]
  title: string
}): ReactElement {
  const queryParam = useFeaturePageSlug()
  return (
    <Flex
      sx={{
        borderBottomColor: 'white10',
        flexDirection: 'column',
        gap: '2.4rem',
        maxWidth: '98rem',
        svg: {
          minWidth: '4.8rem',
          minHeight: '4.8rem',
        },
      }}
    >
      <FeatureDivider />
      <Flex sx={{ flexDirection: ['column', 'row'], gap: ['2.4rem', 0] }}>
        <Flex
          sx={{
            gap: '2.4rem',
            flex: '1 1 auto',
            alignItems: 'center',
            flexDirection: ['column', 'row'],
          }}
        >
          <Svg
            svg={leftIcon}
            sx={{
              width: '100%',
              maxWidth: '4.8rem',
              maxHeight: '4.8rem',
              'path,g': {
                fillOpacity: 1,
              },
            }}
          />
          <Text sx={{ color: 'white', fontSize: '2.4rem' }}>{title}</Text>
        </Flex>
        <Flex
          sx={{
            flex: '1 0 auto',
            width: ['100%', 'auto', 'auto'],
            justifyContent: ['center', 'flex-end'],
          }}
        >
          <Flex
            sx={{
              gap: '2.4rem',
              marginLeft: ['0rem', '2.4rem', '2.4rem'],
              flexShrink: 0,
            }}
          >
            {platforms.map(({ icon, url }, index) => (
              <Fragment key={url}>
                <InternalLink href={`${url}?${queryParam}`}>
                  <Svg key={url} svg={icon} sx={iconStyles} />
                </InternalLink>
                {platforms.length > 1 &&
                  index != platforms.length - 1 && ( //Checks that there is at least one element and that the current element is not the last one
                    <Flex
                      sx={{
                        flexShrink: 0,
                        width: '0.2rem',
                        height: '4.8rem',
                        backgroundColor: 'white10',
                      }}
                    />
                  )}
              </Fragment>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <FeatureDivider />
    </Flex>
  )
}

export function FeatureDivider(): ReactElement {
  return (
    <Box
      sx={{
        width: '100%',
        height: '2px',
        background: ({ colors }) =>
          `0.2rem linear-gradient(to right, transparent, ${colors?.white10}, transparent)`,
      }}
    />
  )
}
