import StaticContentSkeleton from 'components/StaticContentSkeleton'
import ExternalLink from 'components/UI/ExternalLink'
import Svg from 'components/UI/SVG'
import TitleAndSubtitle from 'components/UI/TitleAndSubtitle'
import Background from 'components/features-page/background/Background'
import RoundedBorder from 'components/features-page/background/RoundedBorder'
import { graphql } from 'gatsby'
import getInTouch from 'images/get-in-touch.svg'
import howWereDifferent from 'images/how-were-different.svg'
import lookingForHelp from 'images/looking-for-help.svg'
import needSupport from 'images/need-support.svg'
import { ReactElement, ReactNode } from 'react'
import ReactMarkdown from 'react-markdown'
import { Flex, Grid, Text } from 'theme-ui'
import getEnglishStrings from 'utils/getEnglishStrings'

export default function AboutUs({ data }: { data: Queries.AboutUsPageQuery }): ReactElement {
  const aboutUsData = getEnglishStrings(data.directus.about_us?.strings)

  return (
    <StaticContentSkeleton
      title={aboutUsData?.title ?? ''}
      metaDescription={aboutUsData?.meta_description ?? ''}
      metaKeywords={aboutUsData?.meta_keywords ?? []}
    >
      <>
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8rem',
            width: '100%',
          }}
        >
          <TitleAndSubtitle title={aboutUsData?.title} subtitle={aboutUsData?.subtitle} />

          <Background display={['flex', 'flex', 'flex']}>
            <RoundedBorder
              width={['100vw', '100vw', '100vw']}
              minHeight={['81.2rem', '81.2rem', '45rem']}
              roundedEdge="top"
              backgroundImage="linear-gradient(180deg, #030E1D 32.81%, rgba(3, 14, 29, 0.00) 100%)"
              maxWidth="128rem"
            />
          </Background>
          <Flex
            sx={{
              flexDirection: 'column',
              gap: '1.2rem',
              color: 'white50',
              maxWidth: 'maxContentWidth',
            }}
          >
            <ReactMarkdown>{aboutUsData?.internet_was_created ?? ''}</ReactMarkdown>
            <ReactMarkdown>{aboutUsData?.we_at_windscribe ?? ''}</ReactMarkdown>
            <ReactMarkdown>{aboutUsData?.we_aim_to ?? ''}</ReactMarkdown>
          </Flex>
        </Flex>

        <Flex />
        <Grid
          columns={[1, 2, 4]}
          sx={{ gap: ['4.8rem', '4.8rem', '0'], maxWidth: 'maxContentWidth' }}
        >
          <AboutItem>
            <Svg svg={howWereDifferent} />
            <Text variant="text.heading2">{aboutUsData?.how_were}</Text>
            <ExternalLink href="/ethics">{aboutUsData?.ethics_and}</ExternalLink>
          </AboutItem>
          <AboutItem>
            <Svg svg={lookingForHelp} />
            <Text variant="text.heading2">{aboutUsData?.looking_for}</Text>
            <ExternalLink href="/faq">{aboutUsData?.view_the_faq}</ExternalLink>
          </AboutItem>
          <AboutItem>
            <Svg svg={needSupport} />
            <Text variant="text.heading2">{aboutUsData?.need_support}</Text>
            <ExternalLink href="/support/ticket">{aboutUsData?.submit_a_ticket}</ExternalLink>
          </AboutItem>
          <AboutItem>
            <Svg svg={getInTouch} />
            <Text variant="text.heading2">{aboutUsData?.want_to_get}</Text>
            <ExternalLink href="mailto:hello@windscribe.com">
              {aboutUsData?.ticket_email}
            </ExternalLink>
          </AboutItem>
        </Grid>
      </>
    </StaticContentSkeleton>
  )
}

export const data = graphql`
  query AboutUsPage {
    directus {
      about_us {
        strings {
          languages_code {
            code
          }
          ethics_and
          how_were
          internet_was_created
          we_at_windscribe
          we_aim_to
          looking_for
          meta_description
          meta_keywords
          need_support
          submit_a_ticket
          ticket_email
          subtitle
          title
          view_the_faq
          want_to_get
        }
      }
    }
  }
`

function AboutItem({ children }: { children: ReactNode }) {
  return (
    <Flex
      sx={{
        gap: '3.2rem',
        flexDirection: 'column',
        alignItems: 'center',
        a: {
          width: ['fit-content', '100%', 'fit-content'],
          justifyContent: 'center',
          variant: 'buttons.neon',
        },
      }}
    >
      {children}
    </Flex>
  )
}
